import React, { Component } from "react";
import "../css/views/locationsProfile.css";
import "../css/theme/mainContent.css";
import "../css/theme/forms.css";
import "../css/theme/forms-block.css";
import "../css/theme/buttons.css";
import "../css/views/accountReport.css";
import LocationListing from "../components/LocationListing.js";
import { AppContext } from "../context/app-context";
import CreateEditLocationsModalWrapper from "../components/modals/modal-wrappers/CreateEditLocationsModalWrapper";
import { createLogoutOnFailureHandler } from "../util/LogoutUtil";
import request from "../util/SuperagentUtils.js";
import Busy from "../components/Busy";
import { toast } from "react-toastify";
import { withRouter } from "react-router";
import { ADMIN_EDIT_ROLES, isRoleApproved } from "../util/user/UserUtil";
import { ajaxRequest } from "../ajax.js";
import { networkErrorIssue } from "./thirdparty/checkout/checkoutConstants.js";
const $ = window.$;

class LocationsProfile extends Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);

    const appContext = this.context;
    const { user } = appContext;

    this.state = {
      accountId: user.id,
      showHelp: 1,
      globalLocations: [],
      locationToEdit: null,
      selectedSupplier: null,
      createEditModalOpen: false,
    };
    this._isMounted = false; // Flag to track component mount state
  }

  handleEditLocation = (location) => {
    this.setState({ locationToEdit: location });
  };

  handleViewLocation = (location) => {
    this.props.history.push(`/zones-profile/${location.id}`);
  };

  handleSelectedSupplier = (selectedSupplier) => {
    this.setState({ selectedSupplier });
  };

  setModalVisibility = (visible) => {
    this.setState({ createEditModalOpen: visible });
  };

  openCreateEditModal = () => {
    this.setModalVisibility(true);
  };

  hideCreateEditModal = () => {
    this.setModalVisibility(false);
  };

  handleCreateNewClick = () => {
    this.setState({
      locationToEdit: null,
      createEditModalOpen: true,
    });
  };

  componentDidMount() {
    this._isMounted = true; // Set flag to true when component mounts
    if (this.bxslider) {
      this.bxslider.destroySlider();
    }
    this.fetchLocations();
    $("html,body").scrollTop(0);
  }

  componentWillUnmount() {
    this._isMounted = false; // Set flag to false when component unmounts
  }

  fetchLocations = () => {
    const appContext = this.context;
    const { logout } = appContext;
    Busy.set(true);

    ajaxRequest({
      url: "location",
      type: "GET",
      xhrFields: {
        withCredentials: true,
      },
      success: this.fetchLocationsSuccess,
      crossDomain: true,
      error: this.fetchLocationsFailure,
      complete: function () {},
    });
  };

  fetchLocationsSuccess = (data) => {
    if (this._isMounted) {
      // Check if component is still mounted
      Busy.set(false);
      this.setState({
        globalLocations: data ? data : [],
      });
    }
  };

  fetchLocationsFailure = (jqXHR) => {
    if (this._isMounted) {
      // Check if component is still mounted
      Busy.set(false);
      if (jqXHR.status === 401) {
        return;
      }
      this.setState({
        globalLocations: [],
      });
      toast.error(LocationsProfile.getErrorMessage(jqXHR));
    }
  };

  static getErrorMessage(jqXHR) {
    if (!window.navigator.onLine) {
      return networkErrorIssue;
    }
    let jsonErrorMessage = jqXHR.responseJSON
      ? jqXHR.responseJSON.message
      : null;
    let trimmedJsonErrorMessage = jsonErrorMessage
      ? jsonErrorMessage.split(" ").join("")
      : null;
    return trimmedJsonErrorMessage
      ? trimmedJsonErrorMessage
      : "Internal Server Error";
  }

  handleDeleteLocationSuccess = (location) => {
    if (this._isMounted) {
      // Check if component is still mounted
      let locations = this.state.globalLocations;
      for (let i = 0; i < locations.length; i++) {
        if (locations[i] === location) {
          locations.splice(i, 1);
        }
      }
      Busy.set(false);
      this.setState({
        globalLocations: locations,
      });
      toast.success(
        "Successfully deleted " + location.locationName + " from locations!"
      );
    }
  };

  handleDeleteLocationFailure = (errorMessage) => {
    if (this._isMounted) {
      // Check if component is still mounted
      Busy.set(false);
      toast.error("Failed to delete location.  Reason:  " + errorMessage);
    }
  };

  updateEditLocation = (location) => {
    if (this._isMounted) {
      // Check if component is still mounted
      let locations = this.state.globalLocations;
      let found = false;
      for (let i = 0; i < locations.length; i++) {
        if (locations[i].id === location.id) {
          locations[i] = location;
          found = true;
          break;
        }
      }

      if (!found) {
        this.state.globalLocations.push(location);
      }
    }
  };

  render() {
    const appContext = this.context;
    const { user, logout } = appContext;

    return (
      <div
        id="ssLocationsProfile"
        className="grey-bg hs-bookings-container h-100"
      >
        <div>
          <header>
            <ul className="breadcrumb">
              <li>Location Management</li>
              <li>Locations</li>
            </ul>
            <h1 className="content-header-title">Manage Locations</h1>
          </header>

          <div>
            <div className="w100 pull-left">
              <div className="text-right">
                {isRoleApproved(
                  ADMIN_EDIT_ROLES,
                  this.props.account.userType
                ) && (
                  <button
                    type="button"
                    onClick={() => this.handleCreateNewClick()}
                    className="ss-button-secondary"
                  >
                    {this.state.globalLocations &&
                    this.state.globalLocations.length > 0
                      ? "Create Another Location"
                      : "Create Location"}
                  </button>
                )}
              </div>
              {this.state.globalLocations?.length > 0 &&
                this.state.globalLocations
                  .sort(
                    (a, b) =>
                      new Date(b.lastModifiedDate) -
                      new Date(a.lastModifiedDate)
                  )
                  .map((location, index) => (
                    <LocationListing
                      key={index}
                      handleEditLocation={this.handleEditLocation}
                      handleViewLocation={this.handleViewLocation}
                      updateEditLocation={this.updateEditLocation}
                      handleDeleteLocationSuccess={
                        this.handleDeleteLocationSuccess
                      }
                      handleDeleteLocationFailure={
                        this.handleDeleteLocationFailure
                      }
                      handleLogout={logout}
                      location={location}
                      account={user}
                      handleSupplierSelected={this.handleSelectedSupplier}
                      openEditModal={this.openCreateEditModal}
                    />
                  ))}
            </div>
            {this.state.globalLocations?.length > 0 ? (
              <div className="ss-load-more-records">
                You have reached end of the list
              </div>
            ) : (
              <div className="ss-load-more-records">No records found.</div>
            )}
          </div>
        </div>

        {this.state.createEditModalOpen && (
          <CreateEditLocationsModalWrapper
            closeModal={this.hideCreateEditModal}
            updateEditLocation={this.updateEditLocation}
            updateLocationsList={this.fetchLocations}
            locationToEdit={this.state.locationToEdit}
          />
        )}
      </div>
    );
  }
}

export default withRouter(LocationsProfile);
