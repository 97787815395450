import React, { useState } from "react";
import LoadedContainerCheckInForm from "../../LoadedContainerCheckInForm";
import EmptyContainerCheckInForm from "../../EmptyContainerCheckInForm";
import ChassisOnlyCheckInForm from "../../ChassisOnlyCheckInForm";
import TrailerLoadedCheckInForm from "../../TrailerLoadedCheckInForm";
import TrailerEmptyCheckInForm from "../../TrailerEmptyCheckInForm";
import TruckOnlyCheckInForm from "../../TruckOnlyCheckInForm";
import { EquipmentTypeForm } from "../../constants/securspace-constants";
import AssetTypeSelect from "./AssetTypeSelect";
import DriverSelect from "./DriverSelect";
import checkInImages from "../../../app-images/checkIn.png";
const GALLERY_BUCKET = "inventory";

const CheckInParentViewContainer = (props) => {
  const {
    drivers,
    driverOptions,
    booking,
    saveCheckInForm,
    handlePanelCloseEvent,
    handleEquipmentError,
    handleEquipmentErrorCleared,
    fieldMap,
    correlationId,
    options,
    closeSubViewHandler,
    selectedDriver,
    setSelectedDriver,
  } = props;

  const [formComponentsView, setFormComponentsView] = useState("");
  const [assetType, setAssetType] = useState("");

  const handleDriverChange = (event) => {
    const driverId = event.target.value.value;
    if (drivers.length > 0) {
      for (const driver of drivers) {
        if (driverId === driver.id) {
          setSelectedDriver(driver);
        }
      }
    }
  };

  const getCheckInFormType = (value) => {
    switch (value.getValue()) {
      case EquipmentTypeForm.CONTAINER_LOADED:
      case EquipmentTypeForm.TRUCK_CONTAINER_LOADED:
        setFormComponentsView(
          <LoadedContainerCheckInForm
            selectedDriver={selectedDriver}
            gallery={GALLERY_BUCKET}
            booking={booking}
            handlePanelCloseEvent={handlePanelCloseEvent}
            handleFormSave={saveCheckInForm}
            assetType={value}
            handleEquipmentError={handleEquipmentError}
            handleEquipmentErrorCleared={handleEquipmentErrorCleared}
            customFields={fieldMap}
            correlationId={correlationId}
          />
        );
        break;
      case EquipmentTypeForm.CONTAINER_EMPTY:
      case EquipmentTypeForm.TRUCK_CONTAINER_EMPTY:
        setFormComponentsView(
          <EmptyContainerCheckInForm
            selectedDriver={selectedDriver}
            gallery={GALLERY_BUCKET}
            booking={booking}
            handlePanelCloseEvent={handlePanelCloseEvent}
            handleFormSave={saveCheckInForm}
            assetType={value}
            handleEquipmentError={handleEquipmentError}
            handleEquipmentErrorCleared={handleEquipmentErrorCleared}
            customFields={fieldMap}
            correlationId={correlationId}
          />
        );
        break;
      case EquipmentTypeForm.CHASSIS_ONLY:
        setFormComponentsView(
          <ChassisOnlyCheckInForm
            selectedDriver={selectedDriver}
            gallery={GALLERY_BUCKET}
            booking={booking}
            handlePanelCloseEvent={handlePanelCloseEvent}
            handleFormSave={saveCheckInForm}
            assetType={value}
            customFields={fieldMap}
            correlationId={correlationId}
          />
        );
        break;
      case EquipmentTypeForm.REEFER_LOADED_PLUGIN:
      case EquipmentTypeForm.TRAILER_LOADED:
      case EquipmentTypeForm.TRUCK_TRAILER_LOADED:
      case EquipmentTypeForm.REEFER_LOADED_NO_PLUGIN:
      case EquipmentTypeForm.TRUCK_REEFER_LOADED_PLUGIN:
      case EquipmentTypeForm.TRUCK_REEFER_LOADED_NO_PLUGIN:
        setFormComponentsView(
          <TrailerLoadedCheckInForm
            selectedDriver={selectedDriver}
            gallery={GALLERY_BUCKET}
            booking={booking}
            handlePanelCloseEvent={handlePanelCloseEvent}
            handleFormSave={saveCheckInForm}
            assetType={value}
            customFields={fieldMap}
            correlationId={correlationId}
          />
        );
        break;
      case EquipmentTypeForm.TRUCK_TRAILER_EMPTY:
      case EquipmentTypeForm.TRAILER_EMPTY:
        setFormComponentsView(
          <TrailerEmptyCheckInForm
            selectedDriver={selectedDriver}
            gallery={GALLERY_BUCKET}
            booking={booking}
            handlePanelCloseEvent={handlePanelCloseEvent}
            handleFormSave={saveCheckInForm}
            assetType={value}
            customFields={fieldMap}
            correlationId={correlationId}
          />
        );
        break;
      case EquipmentTypeForm.TRUCK_ONLY:
        setFormComponentsView(
          <TruckOnlyCheckInForm
            selectedDriver={selectedDriver}
            gallery={GALLERY_BUCKET}
            booking={booking}
            handlePanelCloseEvent={handlePanelCloseEvent}
            handleFormSave={saveCheckInForm}
            assetType={value}
            customFields={fieldMap}
            correlationId={correlationId}
          />
        );
        break;
      default:
        setFormComponentsView(null);
    }
  };

  const handleAssetTypeChange = (event) => {
    const value = event.target.value;
    setAssetType(value);
    return getCheckInFormType(value);
  };

  return (
    <>
      <div className="popup-header">
        <img alt="" src={checkInImages} />
        <div>
          <h1 className="w100">Check In Item</h1>
          <h4 className="blue-txt">{booking.buyerAccount.companyName}</h4>
        </div>
        <button
          type="button"
          className="close pull-right"
          aria-label="Close"
          onClick={closeSubViewHandler}
        >
          <img alt="" src={"../../../app-images/close.png"} />
        </button>
      </div>

      <div className="ss-check-in-parent-container">
        <form className="ss-form ss-block">
          {
            <>
              <DriverSelect
                drivers={drivers}
                handleDriverChange={handleDriverChange}
                selectedDriver={selectedDriver}
                driverOptions={driverOptions}
              />
              <AssetTypeSelect
                selectedDriver={selectedDriver}
                options={options}
                assetType={assetType}
                handleAssetTypeChange={handleAssetTypeChange}
              />
            </>
          }
          {formComponentsView ? formComponentsView : ""}
        </form>
      </div>
    </>
  );
};

export default CheckInParentViewContainer;
