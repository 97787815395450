import React, { useEffect, useState } from "react";
import {
  requestLocation,
  requestLocations,
} from "../../location/requests/location-requests";
import ReportBackgroundContainer from "../../report/ReportBackgroundContainer";
import { toast } from "react-toastify";
import { getErrorMessageForStandardResponse } from "../../../util/NetworkErrorUtil";
import Location from "./Location";
import StyledButton from "../../form/StyledButton";
import { useHistory, useParams } from "react-router-dom";
import ReportEmpty from "../../report/ReportEmpty";
import {
  isRoleApproved,
  MANAGER_EDIT_ROLES,
} from "../../../util/user/UserUtil";
import Busy from "../../Busy";

const ViewAllZones = (props) => {
  const { account } = props;
  const { locationId } = useParams();
  const [locations, setLocations] = useState([]);
  const [show, setShow] = useState(false);

  const getLocations = async (locationId) => {
    try {
      if (locationId) {
        // if locationId is present we're in edit/view mode for a given location
        Busy.set(true);
        const response = await requestLocation(locationId);
        Busy.set(false);
        setShow(true);
        return [response.body];
      } else {
        // We're in view all zones for all locations
        Busy.set(true);

        const response = await requestLocations();
        Busy.set(false);
        setShow(true);
        return response.body;
      }
    } catch (error) {
      Busy.set(false);

      toast.error(getErrorMessageForStandardResponse(error));
    }
  };

  useEffect(() => {
    getLocations(locationId).then((locationList) => {
      setLocations(() => locationList);
    });
  }, [locationId]);

  let history = useHistory();

  const createZone = () => {
    history.push("/zone-builder");
  };

  return (
    <div>
      <ReportBackgroundContainer>
        <br />
        {isRoleApproved(MANAGER_EDIT_ROLES, account?.userType) && (
          <>
            <div className="zone-button">
              <StyledButton
                className={"ss-button-secondary"}
                label={"Create Another Zone"}
                onClick={createZone}
              />
            </div>
            <br />
          </>
        )}
        {!!locations &&
          locations?.map((location) => {
            return (
              <Location
                key={location.id}
                location={location}
                account={account}
              />
            );
          })}
        {!!locations &&
          show &&
          locations?.every((ele) => !ele.locationZones) && (
            <div className="centered-message">
              Zones are not available for this location
            </div>
          )}
        {!!locations &&
          locations?.length > 0 &&
          !locations?.every((ele) => !ele.locationZones) && (
            <div className="ss-load-more-records">
              You have reached end of the list
            </div>
          )}
        {!locations && <ReportEmpty />}
      </ReportBackgroundContainer>
    </div>
  );
};

export default ViewAllZones;
