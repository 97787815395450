import React, { Component } from "react";
import Busy from "../components/Busy";
import { toast } from "react-toastify";
import "../css/views/forgotPassword.css";
import "../App.css";
import { ajaxRequest } from "../ajax";
import checkinImage from "../app-images/checkIn.png";
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
    };
  }
  componentDidMount() {
    var elements = document.getElementsByTagName("INPUT");
    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    for (var i = 0; i < elements.length; i++) {
      elements[i].oninvalid = function (e) {
        e.target.setCustomValidity("");
        if (!e.target.validity.valid) {
          switch (e.srcElement.id) {
            case "username":
              if (!e.target.value)
                e.target.setCustomValidity("Email cannot be blank");
              else if (!validateEmail(e.target.value))
                e.target.setCustomValidity("Please enter valid mail id");
              break;
          }
        }
      };
      elements[i].oninput = function (e) {
        e.target.setCustomValidity("");
      };
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (event) => {
    Busy.set(true);
    ajaxRequest({
      url: "reset-password",
      data: JSON.stringify({
        username: this.state.username,
      }),
      type: "POST",
      contentType: "application/json; charset=UTF-8",
      success: this.handleSuccess,
      error: this.handleFailure,
    });
    event.preventDefault();
  };

  handleSuccess = (data) => {
    Busy.set(false);
    toast.success(
      "Password reset email sent. Please click the link in the email from 'seaporttrucklot@abm.com' to change your password. Check your spam folder if needed, and make sure to unblock the sender.",
      {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "success",
      }
    );
    this.props.setShowForgotPassword(false);
    this.props.resetSuccessful(true);
  };

  handleFailure = (jqXHR, textStatus, errorThrown) => {
    Busy.set(false);
    let errorMessage = jqXHR.responseJSON
      ? jqXHR.responseJSON.message
      : "Password reset failed";
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
      toastId: "failure1",
    });
    // this.props.resetSuccessful(false);
  };

  render() {
    return (
      <div className="ss-login">
        <div className="popup-header">
          <img alt="" src={checkinImage} />
          <h1 className="ss-login-gray">Forgot your password?</h1>
        </div>

        <form onSubmit={this.handleSubmit} className="login-form">
          <fieldset className="login-form-field">
            <p className="ss-login-gray">
              Enter your email address to reset your password.
            </p>
          </fieldset>
          <fieldset
            id="usernameFieldset"
            className="ss-forgot-password-field ss-forgot-password-username"
          >
            <label
              htmlFor="username"
              className="ss-forgot-password-desc ss-login-gray"
            >
              {" "}
              EMAIL{" "}
            </label>
            <input
              type="text"
              className="ss-forgot-password-username-input ss-forgot-password-desc"
              name="username"
              id="username"
              value={this.state.username}
              onChange={this.handleChange}
              placeholder="Please enter your email"
              pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
              required
            />
          </fieldset>
          <fieldset className="ss-forgot-password-field flex-center-horizontal">
            <button
              className="orange-button ss-action-button ss-forgot-password-submit"
              type="submit"
            >
              {" "}
              Submit{" "}
            </button>
          </fieldset>
          <h4
            onClick={() => this.props.setShowForgotPassword(false)}
            className="ss-return-login-gray flex-center-horizontal"
          >
            Return to login
          </h4>
          <div className="clear"></div>
        </form>
      </div>
    );
  }
}

export default ForgotPassword;
