import React from "react";
import ReportDataRow from "../report/ReportDataRow";
import ReportData from "../report/ReportData";
import ReportRow from "../report/ReportRow";
import { isRoleApproved, MANAGER_EDIT_ROLES } from "../../util/user/UserUtil";
import ZoneAvailabilityReportMenu from "./ZoneAvailabilityReportMenu";
import { formatCurrencyValue } from "../../util/PaymentUtils";

const ZoneAvailabilityListItem = (props) => {
  const { zone, account } = props;

  return (
    <ReportRow name="zonesReport">
      {isRoleApproved(MANAGER_EDIT_ROLES, account?.userType) && (
        <ZoneAvailabilityReportMenu zone={zone} />
      )}
      <ReportDataRow>
        <ReportData label="Zone Name">{zone?.name || "-"}</ReportData>
        <ReportData label="Available Spaces">
          {zone?.currentAvailableSpaces || "-"}
        </ReportData>

        <ReportData label="Reserved Spaces">
          {zone?.numberOfReservedSpaces || "-"}
        </ReportData>

        <ReportData label="Booked Spaces">
          {zone?.numberOfBookedSpaces || "-"}
        </ReportData>
        <ReportData label="Total Spaces">{zone?.totalSpaces || "-"}</ReportData>

        <ReportData label="One Time Rate">
          {!!zone?.pricePerDayInCents
            ? formatCurrencyValue(zone?.pricePerDayInCents, true)
            : "-"}
        </ReportData>

        <ReportData label="Recurring Rate">
          {!!zone?.pricePerMonthInCents
            ? formatCurrencyValue(zone?.pricePerMonthInCents, true)
            : "-"}
        </ReportData>
      </ReportDataRow>
    </ReportRow>
  );
};

export default ZoneAvailabilityListItem;
