import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { logout } from "../util/LogoutUtil";
import "../css/views/navMenu.css";
import SubMenu from "../components/SubMenu";
import classNames from "classnames";
import Badge from "./Badge";

class NavMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSubmenu: "",
      path: this.getHashPath(),
    };

    this.setActiveMenuItem = this.setActiveMenuItem.bind(this);
  }

  componentDidMount() {
    window.addEventListener("hashchange", this.handleHashChange);
    sessionStorage.clear();
    document.body.addEventListener("click", this.hideSubmenu);
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.handleHashChange);
    document.body.removeEventListener("click", this.hideSubmenu);
  }

  getHashPath = () => {
    const hash = window.location.hash;
    const path = hash.startsWith("#/")
      ? hash.includes("?")
        ? hash.substring(2, hash.indexOf("?"))
        : hash.substring(2)
      : hash.substring(1);
    return path;
  };

  logout = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.props.closeNavMenu();
    logout(this.props.handleLogout, false);
  };
  handleHashChange = () => {
    const newPath = this.getHashPath();
    this.setState({ path: newPath });
  };

  toggleSubmenu = (menuItemPath) => {
    if (sessionStorage.getItem("activeSubmenu") !== menuItemPath) {
      this.showSubmenu(menuItemPath);
    } else {
      this.hideSubmenu();
    }
  };

  showSubmenu = (menuItemPath) => {
    sessionStorage.setItem("activeSubmenu", menuItemPath);

    this.setState({ activeSubmenu: menuItemPath });
  };

  hideSubmenu = () => {
    this.setState({ activeSubmenu: "", displaySignUpView: false });
    sessionStorage.setItem("activeSubmenu", "");
  };

  setActiveMenuItem = (menuItem) => {
    this.props.closeNavMenu();
    this.setState({
      activeMenuItem: menuItem.path,
    });
    sessionStorage.setItem("activeMenuItem", menuItem.path);
    this.props.handleNavImageClick();
  };

  render() {
    return (
      <div
        id="ssNavMenuContainer"
        className={this.props.showMenu ? "ss-show" : "ss-hidden"}
        onScroll={(e) => e.stopPropagation()}
      >
        <img
          alt=""
          className="ss-close-left"
          src="../app-images/close.png"
          onClick={() => this.props.closeNavMenu()}
        />
        <ul id="ssNavMenu">
          {this.props.navMenu
            ? this.props.navMenu.map((menuItem, index) =>
                menuItem.submenus ? (
                  <li
                    key={index}
                    className={
                      "ss-nav-menu-item" + (menuItem.badge ? " has-badge " : "")
                    }
                  >
                    <a
                      href="/#"
                      className={
                        "ss-nav-submenu-toggle" +
                        (this.props.imageClickNav
                          ? menuItem?.submenus?.some(
                              (ele) =>
                                ele.path === this.props.mainMenuItemAfterLogin
                            )
                            ? " active"
                            : ""
                          : !!this.state.path
                          ? menuItem?.submenus?.some(
                              (ele) => ele.path === this.state.path
                            )
                            ? " active"
                            : ""
                          : menuItem?.submenus?.some(
                              (ele) =>
                                ele.path === this.props.mainMenuItemAfterLogin
                            )
                          ? " active"
                          : "")

                        // (this.props.imageClickNav
                        //   ? menuItem?.submenus?.some(
                        //       (ele) =>
                        //         ele.path === this.props.mainMenuItemAfterLogin
                        //     )
                        //     ? " active"
                        //     : ""
                        //   : sessionStorage.getItem("activeMenuItem") ===
                        //       menuItem.path ||
                        //     (!sessionStorage.getItem("activeMenuItem") &&
                        //       menuItem?.submenus?.some(
                        //         (ele) =>
                        //           ele.path === this.props.mainMenuItemAfterLogin
                        //       )) ||
                        //     menuItem?.submenus?.some((ele) => ele.path === path)
                        //   ? " active"
                        //   : "")
                      }
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        this.toggleSubmenu(menuItem.path);
                      }}
                    >
                      {menuItem.badge &&
                        !!this.props.pendingApprovalCount &&
                        this.props.pendingApprovalCount > 0 && (
                          <Badge
                            type="left"
                            pendingBookings={this.props.pendingApprovalCount}
                          />
                        )}
                      {menuItem.label}
                    </a>
                    <SubMenu
                      show={
                        sessionStorage.getItem("activeSubmenu") ===
                        menuItem.path
                      }
                      menuItem={menuItem}
                      menuItemIndex={index}
                      menuTotalItems={this.props.navMenu.length}
                      onSubMenuItemClick={this.setActiveMenuItem}
                      handleLogout={this.logout}
                      pendingApprovalCount={this.props.pendingApprovalCount}
                    />
                  </li>
                ) : (
                  <li key={index} className="ss-nav-menu-item">
                    {menuItem.modal ? (
                      <span
                        className={
                          menuItem.highlighted
                            ? "pointer ss-nav-menu-item-highlighted"
                            : "pointer"
                        }
                        onClick={() => this.setActiveMenuItem(menuItem)}
                      >
                        {menuItem.label}
                      </span>
                    ) : (
                      <NavLink
                        className={classNames(menuItem.linkClassName)}
                        activeClassName="active"
                        to={"/" + menuItem.path}
                        onClick={() => this.setActiveMenuItem(menuItem)}
                      >
                        <span
                          className={classNames(
                            {
                              "ss-nav-menu-item-highlighted":
                                menuItem.highlighted,
                            },
                            menuItem.className
                          )}
                        >
                          {menuItem.label}
                        </span>
                      </NavLink>
                    )}
                  </li>
                )
              )
            : null}
        </ul>
        <div id="menuCloser" onClick={() => this.props.closeNavMenu()} />
      </div>
    );
  }
}

export default NavMenu;
