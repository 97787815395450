import React, { useEffect, useRef, useState } from "react";
import "../../css/components/multiSelect.css";
import MultiSelectContent from "./MultiSelectContent";
import _ from "underscore";
import MultiSelectSearch from "./MultiSelectSearch";
import PropTypes from "prop-types";

const MultiSelect = (props) => {
  const [filter, setFilter] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isClosed, setIsClosed] = useState(true);
  const baseRef = useRef(null);
  const {
    value = [],
    options = [],
    onChange,
    label,
    dropDown,
    isDisabled = false,
    editWidth,
  } = props;

  useEffect(() => {
    if (filter && filter.length) {
      const matchedOptions = _.filter(options, (option) =>
        option?.displayName.toUpperCase().includes(filter.toUpperCase())
      );
      setFilteredOptions(matchedOptions);
    } else {
      setFilteredOptions(options);
    }
  }, [filter, options]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick, true);
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, [isClosed]);

  const handleOutsideClick = (event) => {
    if (baseRef.current && !baseRef.current.contains(event.target)) {
      setIsClosed(true);
      setFilter("");
    }
  };

  const filterOnChange = (event) => {
    setFilter(event.target.value);
  };

  const filterOnFocus = () => {
    setIsClosed(!isClosed);
  };

  const onSelectedLocationIdsChange = (event) => {
    const VIEW_ALL_NAME = "view-all";
    const name = event.target.name;
    const targetOptionValue = event.target.value;
    const checked = event.target.checked;
    let updatedValue = [];

    if (VIEW_ALL_NAME === name) {
      if (checked) {
        updatedValue = options.map((option) => option.value);
      } else {
        updatedValue = [];
      }
    } else {
      if (_.contains(value, targetOptionValue)) {
        updatedValue = _.reject(value, (v) => v === targetOptionValue);
      } else {
        updatedValue = [...value, targetOptionValue];
      }
    }

    onChange(updatedValue);
  };

  const selectedOptionCount = value.length;
  let placeholderText = "Please Select";
  if (selectedOptionCount === 1) {
    const selectedOption = options.find((option) => option.value === value[0]);
    placeholderText = selectedOption?.displayName;
  } else if (selectedOptionCount === options.length) {
    placeholderText = "View All";
  } else if (selectedOptionCount > 1) {
    placeholderText = `${selectedOptionCount} selected`;
  }

  return (
    <fieldset ref={baseRef} className="ss-stand-alone">
      <div className="ss-multiselect-dropdown ss-form ss-block">
        <MultiSelectSearch
          label={label}
          placeholder={placeholderText}
          onChange={filterOnChange}
          onFocus={filterOnFocus}
          isDisabled={isDisabled}
          dropDown="dropdownIcon"
          isClosed={isClosed}
        />
        {!isClosed && (
          <MultiSelectContent
            editWidth={editWidth}
            value={value}
            options={filteredOptions}
            onChange={onSelectedLocationIdsChange}
            isDisabled={isDisabled}
          />
        )}
      </div>
    </fieldset>
  );
};

MultiSelect.propTypes = {
  value: PropTypes.array.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      value: PropTypes.any.isRequired,
      displayName: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  dropDown: PropTypes.string.isRequired,
};

export default MultiSelect;
