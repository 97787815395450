import React, { useEffect, useState } from "react";
import "../../css/components/report/report-form-date.css";
import ReportFormInputLabel from "./ReportFormInputLabel";
import { DateFormats } from "../constants/securspace-constants";
import moment from "moment";
import DatePicker from "react-datepicker";
import "../../css/views/search.css";
import "react-datepicker/dist/react-datepicker.css";

const ReportFormDateInput = (props) => {
  const { date, onDateChange, label, styles, max, min } = props;

  //const DATE_INPUT_FORMAT = "yyyy-MM-DD";
  const DATE_INPUT_FORMAT = "MM-DD-YYYY";
  const [formattedDate, setFormattedDate] = useState(
    !!date ? moment(date).format(DATE_INPUT_FORMAT) : new Date()
  );

  useEffect(() => {
    if (date && moment(date).isValid()) {
      setFormattedDate(moment(date).format(DATE_INPUT_FORMAT));
    } else {
      onDateChange(moment().format(DateFormats.DAY));
    }
  }, [date, onDateChange]);

  const handleDateChange = (event) => {
    //const dateValue = event.target.value;
    onDateChange(moment(event).format(DateFormats.DAY));
  };

  return (
    <fieldset className="ss-report-form-date-fieldset">
      <ReportFormInputLabel label={label} />
      <DatePicker
        selected={formattedDate}
        className={
          !!styles
            ? `ss-report-form-date ${styles}`
            : "ss-report-form-date caret-color"
        }
        onChange={(date) => handleDateChange(date)}
        minDate={min}
        maxDate={max}
        onKeyDown={(e) => e.preventDefault()} // Prevent manual input
      />
      {/* <input
        type="date"
        className={`ss-report-form-date ${styles}`}
        name="formDate"
        placeholder="MM/DD/YYYY"
        value={formattedDate}
        min={min}
        max={max}
        onChange={handleDateChange}
        onKeyDown={(e) => e.preventDefault()}
      /> */}
    </fieldset>
  );
};

export default ReportFormDateInput;
