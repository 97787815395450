import React, { Component } from "react";
import URLUtils from "../util/URLUtils";
import Busy from "../components/Busy";
import Error from "../components/Error";
import "../css/views/changePassword.css";
import "../css/theme/mainContent.css";
import "../css/theme/forms.css";
import "../css/theme/forms-block.css";
import "../css/theme/buttons.css";
import "../css/views/forgotPassword.css";
import "../css/views/login.css";
import "../App.css";
import "./modalFom.css";
import showPwdImg from "../img/show-password.svg";
import hidePwdImg from "../img/hide-password.svg";
import { toast } from "react-toastify";
import { GlobalModalContext } from "../context/global-modal-context";
import { withRouter } from "react-router-dom";
import { ajaxRequest } from "../ajax";
import { Modal } from "react-router-modal";
import {
  oaklandPortalTextWelcome,
  setNewPassword,
} from "../components/constants/securspace-constants";

class ChangePassword extends Component {
  static contextType = GlobalModalContext;

  constructor(props) {
    super(props);
    this.state = {
      changePassword: "",
      changePasswordConfirm: "",
      passwordChangeSuccessful: false,
      errorMessage: "",
      isRevealPwd: false,
      isRevealPwdConfirm: false,
      showAlert: false,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const passwordRegex =
      /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/;

    this.setState({
      passwordChangeSuccessful: false,
      errorMessage: "",
    });

    if (!this.state.changePassword) {
      this.setState({ errorMessage: "Please enter a password." });
      return;
    }
    if (!this.state.changePassword.match(passwordRegex)) {
      this.setState({
        errorMessage:
          "Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character.",
      });
      return;
    }
    if (this.state.changePassword !== this.state.changePasswordConfirm) {
      this.setState({ errorMessage: "Passwords don't match." });
      return;
    }

    Busy.set(true);

    ajaxRequest({
      url: "change-password",
      data: JSON.stringify({
        token: URLUtils.getQueryVariable("token"),
        password: this.state.changePassword,
      }),
      type: "POST",
      contentType: "application/json; charset=UTF-8",
      success: this.handleSuccess,
      error: this.handleFailure,
    });
  };

  handleSuccess = (data) => {
    Busy.set(false);
    let successMessage = this.props.isNewUser
      ? "Successfully set password!"
      : "Password Reset Successful!";
    toast.success(successMessage, {
      position: toast.POSITION.TOP_RIGHT,
    });

    const { history } = this.props;
    history.push("/login");
  };

  handleFailure = (jqXHR, textStatus, errorThrown) => {
    Busy.set(false);
    this.setState({ showAlert: true });
    //this.notify("Password change failed");
  };

  notify = (errorMessage) => {
    if (!toast.isActive(this.toastId)) {
      this.toastId = toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  navigateToLogin = () => {
    const { history } = this.props;
    history.push("/login");
  };

  render() {
    return (
      <div className="grey-bg height-500px">
        {this.props.isNewUser ? (
          <header>
            <h1 className="content-header-title">{oaklandPortalTextWelcome}</h1>
            <div className="content-header-description">{setNewPassword}</div>
          </header>
        ) : (
          <header>
            <h1 className="content-header-title">Reset your password</h1>
            {/* <div
              className="content-header-description"
              style={{ marginTop: "10px" }}
            >
              Please enter a new password.
            </div> */}
          </header>
        )}
        <div id="ssChangePasswordFormContainer" className="container flex">
          <div>
            <form className="ss-form ss-block" onSubmit={this.handleSubmit}>
              <fieldset className="ss-top">
                <label htmlFor="password">NEW PASSWORD</label>
                <input
                  type={this.state.isRevealPwd ? "text" : "password"}
                  id="changePassword"
                  name="changePassword"
                  value={this.state.changePassword}
                  onChange={this.handleChange}
                  autoComplete="new-password"
                  placeholder="Please enter new password"
                />
                <img
                  className="revealChangePassword"
                  title={
                    this.state.isRevealPwd ? "Hide password" : "Show password"
                  }
                  src={this.state.isRevealPwd ? hidePwdImg : showPwdImg}
                  onClick={() =>
                    this.setState((prevState) => ({
                      isRevealPwd: !prevState.isRevealPwd,
                    }))
                  }
                />
              </fieldset>
              <fieldset
                id="changePasswordConfirmFieldset"
                className="ss-bottom"
              >
                <label htmlFor="confirmPassword">CONFIRM PASSWORD</label>
                <input
                  type={this.state.isRevealPwdConfirm ? "text" : "password"}
                  id="changePasswordConfirm"
                  name="changePasswordConfirm"
                  value={this.state.changePasswordConfirm}
                  onChange={this.handleChange}
                  autoComplete="new-password"
                  placeholder="Please enter confirm password"
                />
                <img
                  className="revealChangePassword"
                  title={
                    this.state.isRevealPwdConfirm
                      ? "Hide password"
                      : "Show password"
                  }
                  src={this.state.isRevealPwdConfirm ? hidePwdImg : showPwdImg}
                  onClick={() =>
                    this.setState((prevState) => ({
                      isRevealPwdConfirm: !prevState.isRevealPwdConfirm,
                    }))
                  }
                />
              </fieldset>
              {this.state.errorMessage ? (
                <div className="custom-margin-top-bottom">
                  {" "}
                  <Error>{this.state.errorMessage}</Error>
                </div>
              ) : (
                ""
              )}
              <div className="d-flex">
                <button
                  type="submit"
                  className="orange-button ss-action-button ss-forgot-password-submit"
                >
                  {this.props.isNewUser ? "Setup Password" : "Reset Password"}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div>
          {this.state.showAlert ? (
            <Modal className={"modal-form-change alert-message"}>
              <div>
                <div className="modal-content-change">
                  <div className={"modal-header d-flex"}>
                    <h5 className="modal-title">Reset Link Expired!</h5>
                  </div>
                  <div className="modal-body">
                    Your reset password link has expired. To initiate another
                    password reset,please proceed through the 'Forgot Password'
                    link again!
                  </div>
                  <div className="modal-footer d-flex justify-content-between">
                    <button
                      type="button"
                      className="ss-button-primary-modal-form"
                      onClick={this.navigateToLogin}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(ChangePassword);
