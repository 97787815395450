import React, { Component } from "react";
import { Link } from "react-router-dom";
import { createLogoutOnFailureHandler } from "../util/LogoutUtil";
import PaymentUtils from "../util/PaymentUtils";
import BooleanUtils from "../util/BooleanUtils";
import "../css/components/locationListing.css";
import "../css/theme/buttons.css";
import Busy from "./Busy";
import ConfirmDialog from "./ConfirmDialog";
import { ADMIN_EDIT_ROLES, isRoleApproved } from "../util/user/UserUtil";
import { ajaxRequest } from "../ajax";
import { s3BucketUrl } from "./constants/securspace-constants";

const $ = window.$;

class LocationListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteConfirmation: false,
    };
  }

  handleLocationEditClick = () => {
    this.props.handleEditLocation(this.props.location);
    this.props.openEditModal();
  };

  handleLocationViewClick = () => {
    this.props.handleViewLocation(this.props.location);
  };

  startDeleteProcess = () => {
    Busy.set(false);
    this.setState({
      showDeleteConfirmation: true,
    });
  };

  cancelDeleteProcess = () => {
    Busy.set(false);
    this.setState({
      showDeleteConfirmation: false,
    });
  };

  deleteLocation = () => {
    Busy.set(true);
    ajaxRequest({
      url: "location?locationId=" + this.props.location.id,
      type: "DELETE",
      success: this.handleDeleteLocationSuccess,
      statusCode: {
        401: createLogoutOnFailureHandler(this.props.handleLogout),
      },
      error: this.handleDeleteLocationFailure,
    });
  };

  handleDeleteLocationSuccess = () => {
    Busy.set(false);
    this.setState({
      showDeleteConfirmation: false,
    });
    this.props.handleDeleteLocationSuccess(this.props.location);
  };

  handleDeleteLocationFailure = (jqXHR, textStatus, errorThrown) => {
    Busy.set(false);
    this.setState({
      showDeleteConfirmation: false,
    });
    this.props.handleDeleteLocationFailure(
      jqXHR.responseJSON
        ? jqXHR.responseJSON.message
        : "An error occurred while attempting to delete this location"
    );
  };

  locationShowingInSearchResults() {
    return this.props.location.isVisible;
  }

  getNotVisibleInSearchResultReason() {
    if (
      !this.props.location.locationName ||
      !this.props.location.addressLine1 ||
      !this.props.location.city ||
      !this.props.location.state ||
      !this.props.location.zip
    ) {
      return "Missing Required Fields";
    } else if (
      !this.props.location.locationFeatures ||
      (this.props.location.locationFeatures &&
        this.props.location.locationFeatures.length === 0)
    ) {
      return "No Features Selected";
    } else if (!this.props.location.live) {
      return "Approval Required";
    }
  }

  render() {
    let width = $(window).width();
    // const replacedFileName = this.props.location.listingImageFileName
    //   ?.replace(/ /g, "%20") // Replace spaces with '%20'
    //   .replace(/\(/g, "%28") // Encode '(' as '%28'
    //   .replace(/\)/g, "%29"); // Encode ')' as '%29'

    // const backgroundImageUrl = `${
    //   this.props.location.listingImageFileName
    //     ? `${s3BucketUrl}listing-images/${replacedFileName.trim()}`
    //     : `${s3BucketUrl}app-images/Sorry-image-not-available.png`
    // }`;

    return (
      <div className="ss-booking-container">
        <div>
          {
            <div className="booking-list-item" style={{ height: "160px" }}>
              <div className="for-img">
                <div
                  style={{
                    backgroundImage: `url(${this.props?.location?.listingImageEncryptedUrl})`,
                  }}
                ></div>
              </div>
              <div className="for-content">
                <div className="booking-list-title">
                  <span>{this.props.location.locationName}</span>
                  <span>
                    <div className="ss-booking-button-container">
                      {isRoleApproved(
                        ADMIN_EDIT_ROLES,
                        this.props.account.userType
                      ) && (
                        <div>
                          <button
                            className="ss-button-danger"
                            onClick={this.startDeleteProcess}
                          >
                            Delete
                          </button>
                          <button
                            className="ss-button-primary"
                            onClick={this.handleLocationEditClick}
                          >
                            Edit
                          </button>
                        </div>
                      )}
                      <button
                        className="ss-button-primary.reverse"
                        onClick={this.handleLocationViewClick}
                        style={{ border: "1px solid" }}
                      >
                        View
                      </button>
                    </div>
                  </span>

                  <ConfirmDialog
                    showAlert={this.state.showDeleteConfirmation}
                    title="Delete Location"
                    onClose={this.cancelDeleteProcess}
                    proceedEventHandler={this.deleteLocation}
                  >
                    Are you sure you want to delete this location?
                  </ConfirmDialog>
                </div>
                <div>
                  <div
                    // className="flex"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "20% 20% 20% 20% 20%",
                    }}
                  >
                    <div>
                      <fieldset>
                        <div className="bg-icon icon-address">
                          {this.props.location.addressLine1}
                          <br />
                          {this.props.location.city &&
                          this.props.location.state &&
                          this.props.location.zip
                            ? this.props.location.city +
                              ", " +
                              this.props.location.state +
                              " " +
                              this.props.location.zip
                            : ""}
                        </div>
                      </fieldset>
                    </div>

                    <div>
                      <fieldset>
                        <div className="bg-icon icon-phone">
                          {!!this.props.location.phoneNumber
                            ? this.props.location.phoneNumber
                            : "-"}
                        </div>
                      </fieldset>
                    </div>

                    <div>
                      <fieldset>
                        <label>max one time spaces:</label>
                        <div>
                          {!!this.props.location.maxOneTimeSpaces
                            ? this.props.location.maxOneTimeSpaces
                            : "-"}
                        </div>
                      </fieldset>
                    </div>
                    {/* <div>
                      <fieldset>
                        <label>max one time duration:</label>
                        <div>
                          {!!this.props.location.maxOneTimeDuration
                            ? this.props.location.maxOneTimeDuration === "DAILY"
                              ? "ONE TIME"
                              : "RECURRING"
                            : "-"}
                        </div>
                      </fieldset>
                    </div> */}
                    <div>
                      <fieldset>
                        <label>max recurring spaces:</label>
                        <div>
                          {!!this.props.location.maxRecurringSpaces
                            ? this.props.location.maxRecurringSpaces
                            : "-"}
                        </div>
                      </fieldset>
                    </div>
                    {/* <div>
                      <fieldset>
                        <label>max recurring duration:</label>
                        <div>
                          {!!this.props.location.maxRecurringDuration
                            ? this.props.location.maxRecurringDuration ===
                              "DAILY"
                              ? "ONE TIME"
                              : "RECURRING"
                            : "-"}
                        </div>
                      </fieldset>
                    </div> */}

                    <div>
                      <fieldset>
                        <label>Overage Rate:</label>
                        <div>
                          {!!this.props.location.overageRate
                            ? "$" +
                              PaymentUtils.convertSmallestSubUnitToMainUnit(
                                this.props.location.overageRate,
                                PaymentUtils.CURRENCY_US_DOLLAR
                              )
                            : "-"}
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default LocationListing;
