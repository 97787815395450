import React, { useEffect, useState } from "react";
import ReportRow from "../../report/ReportRow";
import ReportDataRow from "../../report/ReportDataRow";
import ReportData from "../../report/ReportData";
import { toast } from "react-toastify";
import { parseCurrencyValue } from "../../../util/PaymentUtils";
import PropTypes, { number } from "prop-types";
import Input from "../../common/Input";
import OutlinedButton from "../../form/OutlinedButton";

const FineInfractionAddInputs = (props) => {
  const { addFineInfractions, setButtonDisabled, infractions } = props;
  const [price, setPrice] = useState("");
  const [infraction, setInfraction] = useState(null);
  const [infractionAddDisable, setInfractionAddDisable] = useState(true);
  let infractionAmountRef = React.createRef();

  const initializedInfractions = infractions || [];

  useEffect(() => {
    if (initializedInfractions.length > 0) {
      setButtonDisabled(() => false);
    }
    if (price > 0.0) {
      setInfractionAddDisable(false);
    } else {
      setInfractionAddDisable(true);
    }
  }, [initializedInfractions, setButtonDisabled, price]);

  const addInfraction = () => {
    if (infractions?.length > 0) {
      const InfractionValue =
        typeof infractions[infractions?.length - 1]?.infractionAmount ===
        "number"
          ? infractions[infractions?.length - 1]?.infractionAmount / 100
          : parseFloat(infractions[infractions?.length - 1]?.infractionAmount);

      if (parseFloat(price) < InfractionValue) {
        toast.error(
          "Infraction amount should be greater than previous infraction amount",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        return;
      }
    }
    if (!infractionAmountRef.current.value) {
      toast("Please provide a valid amount in dollars");
    } else {
      let newInfractionArray = [...initializedInfractions];
      newInfractionArray.push(infraction);

      setPrice("");
      addFineInfractions(newInfractionArray);
    }
  };

  const deleteInfraction = () => {
    let newInfractionArray = [...initializedInfractions];
    newInfractionArray.pop();

    addFineInfractions(newInfractionArray);
  };

  const onChange = (event) => {
    const value = event.target.value;
    const regex = /[A-Za-z]/;
    if (regex.test(value)) return;
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setPrice(value);
      // const parsed = parseCurrencyValue(value);
      setInfraction(() => ({
        infractionOffense: initializedInfractions.length + 1,
        // infractionAmount: parsed,
        infractionAmount: value,
      }));
    }
  };
  return (
    <div>
      <h3>Fine Infractions: </h3>
      <Input
        ref={infractionAmountRef}
        label={"Infraction Amount: "}
        className="form-control"
        type="text"
        name="infractionAmount"
        value={price}
        compName="infractionAmount"
        onChange={onChange}
      />
      <hr />
      <div>
        {infractions
          ? infractions.map((fineInfraction, index) => {
              return (
                <div key={index}>
                  <ReportRow name="fineInfraction">
                    <ReportDataRow>
                      <ReportData label={"Infraction Offense"}>
                        {index + 1}
                      </ReportData>
                      <ReportData label={"Infraction Amount"}>
                        $
                        {typeof fineInfraction?.infractionAmount === "number"
                          ? (fineInfraction?.infractionAmount / 100).toFixed(2)
                          : fineInfraction?.infractionAmount?.includes(".")
                          ? fineInfraction?.infractionAmount
                          : Number(fineInfraction?.infractionAmount).toFixed(2)}
                      </ReportData>
                    </ReportDataRow>
                  </ReportRow>
                </div>
              );
            })
          : ""}
      </div>
      <hr />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <OutlinedButton
          label={"ADD INFRACTION"}
          onClick={addInfraction}
          disabled={infractionAddDisable}
        />
        <br />
        {infractions?.length > 0 && (
          <OutlinedButton
            label={"DELETE INFRACTION"}
            onClick={deleteInfraction}
          />
        )}
      </div>
    </div>
  );
};

FineInfractionAddInputs.propTypes = {
  addFineInfractions: PropTypes.func,
  setButtonDisabled: PropTypes.func,
  buttonDisabled: PropTypes.bool,
};

export default FineInfractionAddInputs;
