import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import checkIcon from "../../img/check-mark.png";
import { useLocation, useHistory } from "react-router-dom";
import Busy from "../../components/Busy";
import { poartOfOaklandUrl } from "../../components/constants/securspace-constants";
import {
  oakLandPortalConstant,
  securityDepositPaymentSuccessfull,
  truncateStringPaymentSuccessful,
} from "./checkout/checkoutConstants";
import "./paymentSuccessful.css";

const PaymentSuccessful = () => {
  const location = useLocation();
  const [remainingTime, setRemainingTime] = useState(8); // Initial time
  const [isRedirecting, setIsRedirecting] = useState(false); // Track redirect state

  const data = location.state;

  const history = useHistory();

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime((prevTime) => prevTime - 1);
      } else {
        setIsRedirecting(true); // Set redirecting state
        window.location.href = poartOfOaklandUrl;
        clearInterval(intervalId); // Clear interval when time reaches 0
      }
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup function
  }, [remainingTime]);

  useEffect(() => {
    const redirectUrl = poartOfOaklandUrl;

    // Check if it's the very first visit
    if (!data) {
      setIsRedirecting(true); // Set redirecting state on refresh
      window.location.href = redirectUrl;
    } else {
      // Check if the page is refreshed
      if (window.performance && performance.navigation.type === 1) {
        setIsRedirecting(true); // Set redirecting state on refresh
        window.location.href = redirectUrl;
      }
    }

    // Handle back button (popstate event)
    const handlePopState = () => {
      setIsRedirecting(true); // Set redirecting state on back button
      window.location.href = redirectUrl;
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [history]);

  const handleClick = (e) => {
    Busy.set(false);
    e.preventDefault();
    Busy.set(true);
    setIsRedirecting(true); // Set redirecting state on button click
    window.location.href = poartOfOaklandUrl;
  };

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDateNew = `${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date
      .getDate()
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
    return formattedDateNew;
  };

  if (isRedirecting) {
    return <h1>Loading...</h1>; // Hide the UI when redirecting
  }

  return (
    <div>
      <div className="grey-bg">
        <div>
          <div className="container paymentContainer">
            <img src={checkIcon} alt="checkMark" className="imageCheckMark" />

            <h1 className="font-weight-bold">Payment Successful</h1>
            <p className="mt-3px">{securityDepositPaymentSuccessfull}</p>
          </div>

          <div className="container">
            <hr className="border-top" />
            <table className="tableStyle">
              <tr className="tableRow">
                <th className="tableH">Amount : </th>
                <th className="tableHContent">
                  $ {(data?.amount / 100).toFixed(2)}
                </th>
              </tr>
              <tr className="tableRow">
                <td className="tableH">Invoice Number : </td>
                <td className="tableHContent">{data?.invoiceNumber}</td>
              </tr>
              <tr className="tableRow">
                <td className="tableH">Payment Type :</td>
                <td className="tableHContent">
                  {data?.type === "fineType"
                    ? "Fine Assessment Charge"
                    : "Booking Payment"}
                </td>
              </tr>
              <tr className="tableRow">
                <td className="tableH">Transfer Type :</td>
                <td className="tableHContent">{data?.transferType}</td>
              </tr>
              <tr className="tableRow">
                <td className="tableH">Source :</td>
                <td className="tableHContent">
                  {!!data?.nickName
                    ? truncateStringPaymentSuccessful(data?.nickName)
                    : data?.source}
                </td>
              </tr>
              <tr className="tableRow">
                <td className="tableH">Recipient :</td>
                <td className="tableHContent">{oakLandPortalConstant}</td>
              </tr>
              <tr className="tableRow">
                <td className="tableH">Date Initiated :</td>
                <td className="tableHContent">
                  {formattedDate(data?.dateInitiated)}
                </td>
              </tr>
              <tr className="tableRow">
                <b>
                  Please click below if you are not redirected in{" "}
                  {remainingTime} seconds
                </b>
              </tr>
            </table>

            <button
              className="orange-button ss-action-button ss-login-submit paymentSuccessfulButton"
              onClick={(e) => handleClick(e)}
            >
              Back to Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PaymentSuccessful);
