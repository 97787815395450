import React from "react";
import StepWizardContainer from "./stepWizard/StepWizardContainer";
import OutlinedButton from "./form/OutlinedButton";
import SolidButton from "./form/SolidButton";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

const ConfirmDialogView = (props) => {
  const {
    headerText = "Confirm Action",
    backButtonText = "Cancel",
    handleBack = () => {
      history.goBack();
    },
    label = "OK",
    handleConfirm,
    children,
    comp,
  } = props;

  const history = useHistory();

  return (
    <div className="container">
      <h3>{headerText}</h3>
      <StepWizardContainer>
        <div>{children}</div>
        <br />
        <div
          className="d-flex justify-content-evenly"
          style={
            comp === "bookingCancelSubs"
              ? { justifyContent: "space-between" }
              : {}
          }
        >
          <SolidButton label={label} onClick={handleConfirm} compType={comp} />
          <OutlinedButton
            label={backButtonText}
            onClick={handleBack}
            compType={comp}
          />
        </div>
      </StepWizardContainer>
    </div>
  );
};

ConfirmDialogView.propTypes = {
  headerText: PropTypes.string,
  backButtonText: PropTypes.string,
  handleBack: PropTypes.func,
  label: PropTypes.string,
  handleConfirm: PropTypes.func.isRequired,
};

export default ConfirmDialogView;
