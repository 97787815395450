import React from "react";
import ReportDataRow from "../../../../components/report/ReportDataRow";
import ReportData from "../../../../components/report/ReportData";
import DateDisplay from "../../../../components/date/DateDisplay";
import MoneyDisplay from "../../../../components/money/MoneyDisplay";
const FineCheckout = (props) => {
  const { fineByIdDetails } = props;

  return (
    <ReportDataRow>
      <ReportData label={"Booking Number"}>
        {fineByIdDetails?.bookingNumber}
      </ReportData>
      <ReportData label={"Zone Name"}>
        {fineByIdDetails?.zoneName || "-"}
      </ReportData>

      <ReportData label={"Fine Type"}>
        {fineByIdDetails?.fineType?.name}
      </ReportData>
      {/* <ReportData label={"Duration"}>{fineByIdDetails.duration}</ReportData> */}
      <ReportData label={"Created On"}>
        {fineByIdDetails && fineByIdDetails?.createdOn && (
          <DateDisplay
            date={fineByIdDetails?.createdOn}
            format={"MM/DD/YYYY"}
          />
        )}
      </ReportData>

      <ReportData label={"Fine Amount"}>
        $ {(fineByIdDetails?.fineAmount / 100).toFixed(2)}
      </ReportData>
      <ReportData label={"Peak Pricing Additional Charge"}>
        {fineByIdDetails?.peakPricingAdditionalCharge
          ? `$${(fineByIdDetails.peakPricingAdditionalCharge / 100).toFixed(2)}`
          : "-"}
      </ReportData>

      <ReportData label={"Amount To Be Paid"}>
        {fineByIdDetails?.fineAmount &&
          fineByIdDetails?.peakPricingAdditionalCharge && (
            <MoneyDisplay
              dollarsAsLong={
                Number(fineByIdDetails?.peakPricingAdditionalCharge) +
                Number(fineByIdDetails?.fineAmount)
              }
            />
          )}
      </ReportData>
    </ReportDataRow>
  );
};

export default FineCheckout;
