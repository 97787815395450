import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Modal } from "react-router-modal";
import { ajaxRequest } from "../../../ajax";
import Busy from "../../../components/Busy";
import { toast } from "react-toastify";
import "../../../css/views/createEditLocation.css";
import "./deletePopUp.css";
import { TSYS_URL, DEVICE_ID, networkErrorIssue } from "./checkoutConstants";

function CreditCardModalPopUp({
  setCheckedButtonPayment,
  id,
  setLoadPaymentMethod,
  loadPaymentMethod,
  setCvv,
  cvv,
  setAchPayment,
}) {
  const [manifest, setManifest] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardHolderNameValidate, setCardHolderNameValidate] = useState("");
  const [expirationDateValidate, setExpirationDateValidate] = useState("");
  const [cardNumberValidate, setCardNumberValidate] = useState("");
  const [zipCodeValidate, setZipCodeValidate] = useState("");
  const [cvvValidate, setCvvValidate] = useState("");

  const [cardType, setCardType] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [maskedCardNumber, setMaskedCardNumber] = useState("");
  const [tsepToken, setTsepToken] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [responseCode, setResponseCode] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ajaxRequest({
      url: "tsys/getManifest",
      method: "GET",
      type: "GET",
      contentType: "application/json",
      dataType: "json",
      success: (res) => {
        setManifest(res?.manifestId);

        let url = `${TSYS_URL}${DEVICE_ID}?${res?.manifestId}`;
        const script = document.createElement("script");
        script.src = url;
        script.async = true;
        document.body.appendChild(script);
      },
      error: (err) => {
        toast.error(err?.message);
      },
    });
  }, []);

  useEffect(() => {
    function sayHello() {
      const cardNum = document.getElementById("tsep-cardNum");
      if (!!cardNum?.value) {
        setMaskedCardNumber(cardNum?.value?.slice(-4));
        if (cardNum?.value !== cardNumberValidate) {
          if (!!errorMessage) setErrorMessage("");
        }

        setCardNumberValidate(cardNum?.value);
      }
      const cardName = document.getElementById("tsep-cardHolderName");
      if (!!cardName?.value) {
        setCardHolderName(cardName?.value);
        if (cardNum?.value !== cardHolderNameValidate) {
          if (!!errorMessage) setErrorMessage("");
        }
        setCardHolderNameValidate(cardName?.value);
      }
      const cvv = document.getElementById("tsep-cvv2");
      if (cvv?.value) {
        setCvv(cvv?.value);
        if (cvv?.value !== cvvValidate) {
          if (!!errorMessage) setErrorMessage("");
        }
        setCvvValidate(cvv?.value);
      }
      const expirationDate = document.getElementById("tsep-datepicker");
      if (expirationDate?.value) {
        setExpirationDate(expirationDate?.value);
        if (expirationDate?.value !== expirationDateValidate) {
          if (!!errorMessage) setErrorMessage("");
        }
        setExpirationDateValidate(expirationDate?.value);
      }
      const zipCode = document.getElementById("tsep-zipCode");
      if (zipCode?.value) {
        setZipCode(zipCode?.value);
        if (zipCode?.value !== zipCode) {
          if (!!errorMessage) setErrorMessage("");
        }
        setZipCodeValidate(zipCode?.value);
      }
      setCardType(window.localStorage.getItem("cardType"));
      setTsepToken(window.localStorage.getItem("tsepToken"));
      setErrorMessage(window.localStorage.getItem("errorMessage"));
      setResponseCode(window.localStorage.getItem("responseCode"));
    }
    const intervalId = setInterval(sayHello, 12000);
  }, []);

  // useEffect(() => {
  //   function sayHello() {
  //     if (!!document.getElementById("tsep-cardNum")) {
  //       document
  //         .getElementById("tsep-cardNum")
  //         .addEventListener("change", function (ele) {
  //           console.log("ele", ele?.target.value);
  //           setCardNumberValidate(ele?.target?.value);
  //         });
  //     }
  //   }

  //   const intervalId = setInterval(sayHello, 12000);
  // }, [cardNumberValidate]);
  function handleChange(ele) {
    setCardNumberValidate(ele?.target?.value);
  }

  useEffect(() => {
    if (responseCode === "A0000") {
      window.localStorage.setItem("errorMessage", "");
      setErrorMessage("");
    }
    if (errorMessage === "Encryption Failure") {
      ajaxRequest({
        url: "tsys/getManifest",
        method: "GET",
        type: "GET",
        contentType: "application/json",
        dataType: "json",
        success: (res) => {
          setManifest(res?.manifestId);

          let url = `${TSYS_URL}${DEVICE_ID}?${res?.manifestId}`;
          const script = document.createElement("script");
          script.src = url;
          script.async = true;
          document.body.appendChild(script);
        },
        error: (err) => {
          toast.error(err?.message);
        },
      });
      document.getElementById("tsep-cardNum").value = "";
      document.getElementById("tsep-cardHolderName").value = "";
      document.getElementById("tsep-datepicker").value = "";
      document.getElementById("tsep-cvv2").value = "";
      document.getElementById("tsep-zipCode").value = "";
      window.localStorage.setItem("errorMessage", "");
      window.localStorage.setItem("cardType", "");
      window.localStorage.setItem("tsepToken", "");
      window.localStorage.setItem("responseCode", "");
      setCardHolderName("");
      setMaskedCardNumber("");
      setZipCode("");
      setCvv("");
      setExpirationDate("");
    }
  }, [responseCode]);
  useEffect(() => {
    const checkElementPresence = () => {
      const element = document.querySelector("#tsep-cardNum");
      Busy.set(true);
      // document.addEventListener("change", handleChange);
      if (element) {
        Busy.set(false);
      } else {
        //console.log("Element is not present");
      }
    };
    document.addEventListener("DOMContentLoaded", checkElementPresence);
    document.addEventListener("DOMSubtreeModified", checkElementPresence);
    return () => {
      document.removeEventListener("DOMContentLoaded", checkElementPresence);
      document.removeEventListener("DOMSubtreeModified", checkElementPresence);
    };
  }, []);
  useEffect(() => {
    const inputFields = document.querySelectorAll("input");

    inputFields.forEach((input) => {
      if (input.id && !input.name) {
        input.remove();
      }
    });
  });

  const scriptContentTsep = `
  function tsepHandler(eventType, event) {
     if(eventType === "TokenEvent" ){
      if(event?.status === "PASS"){
      window.localStorage.setItem("cardType",event.cardType)
      window.localStorage.setItem("tsepToken",event.tsepToken)
      window.localStorage.setItem("responseCode",event.responseCode)
      }else if(event?.status === "FAILURE" || event?.status ==="FAIL"){
      window.localStorage.setItem("responseCode",event.responseCode)
          }
     }else {
      window.localStorage.setItem("responseCode","")

     }
    if(event?.status ==="FAIL" || event?.status === "FAILURE") {
      window.localStorage.setItem("errorMessage",event?.message)
    }
    // if(eventType === "FocusOutEvent") {
    //   window.localStorage.setItem("errorMessage","")
    // }
}`;

  const handleSubmit = () => {
    const name = cardHolderName.split(" ");
    let firstName;
    let lastName;
    if (name.length > 0) {
      firstName = name[0];
      lastName = name[1];
    }
    // console.log("maskedCardNum", maskedCardNumber);
    // console.log("cardHolderName", cardHolderName);
    // console.log("expirationDate", expirationDate);
    // console.log("cvv", cvv);
    // console.log("zipCode", zipCode);
    // console.log("firstName", firstName);
    // console.log("lastName", lastName);
    // console.log("cardType", cardType);
    Busy.set(true);

    ajaxRequest({
      url: "payment-method/createPaymentMethod",
      data: JSON.stringify({
        accountId: id,
        cardNumber: maskedCardNumber,
        tsepToken: tsepToken,
        firstName: firstName,
        lastName: lastName,
        cardBrand: cardType,
        expirationDate: expirationDate,
        cvv2: cvv,
        zip: zipCode,
      }),
      contentType: "application/json",
      method: "POST",
      success: (res) => {
        window.localStorage.removeItem("cardType", "");
        window.localStorage.removeItem("tsepToken", "");
        window.localStorage.removeItem("errorMessage", "");
        window.localStorage.removeItem("responseCode", "");
        Busy.set(false);
        toast.success("Credit card added successfully!");
        setCheckedButtonPayment("showPaymentDetailsCREDITCARD");
        setLoadPaymentMethod(!loadPaymentMethod);
        setAchPayment(false);
      },

      error: (jqXHR) => {
        Busy.set(false);
        let errorMessage = jqXHR.responseJSON
          ? jqXHR.responseJSON.message
            ? jqXHR.responseJSON.message.trim()
            : ""
          : "";
        Busy.set(false);
        if (!window.navigator.onLine) {
          toast.error(networkErrorIssue);
        } else {
          toast.error(errorMessage);
        }
      },
    });
  };

  const handleCancel = () => {
    window.localStorage.removeItem("cardType", "");
    window.localStorage.removeItem("tsepToken", "");
    window.localStorage.removeItem("errorMessage", "");
    window.localStorage.removeItem("responseCode", "");

    setCheckedButtonPayment("showPaymentDetails");
  };
  // console.log("responseCode", responseCode);
  // console.log(
  //   "hey",
  //   !responseCode &&
  //     !!maskedCardNumber &&
  //     !!cardHolderName &&
  //     !!zipCode &&
  //     !!cvv &&
  //     !!expirationDate
  // );
  // console.log("value of response", !responseCode);
  // console.log(
  //   "maskedCardNumber ",
  //   maskedCardNumber,
  //   "cardHolderName ",
  //   cardHolderName,
  //   "zipCode ",
  //   zipCode,
  //   "cvv ",
  //   cvv,
  //   "expirationDate ",
  //   expirationDate
  // );
  // console.log("errorMessage", errorMessage);
  return (
    <Modal className="app-modal app-modal-in">
      <div id="ssCreateEditLocation">
        <div className="modal-content ">
          <Helmet>
            {/* <script src={`${TSYS_URL}${DEVICE_ID}?${manifest}`}></script> */}
            <script type="text/javascript" defer>
              {scriptContentTsep}
            </script>
          </Helmet>
          <h1 className="ss-report-header-title mt-align">
            Credit Card Details
          </h1>
          <div>
            <form method="POST" id="payment-form" className="formCreditCard">
              <div id="tsep-cardNumDiv" className="CardNumber">
                <label>CARD NUMBER</label>
              </div>
              <div id="tsep-cardHolderNameDiv" className="CardNumber">
                <label>CARDHOLDER NAME </label>
              </div>
              <div id="tsep-datepickerDiv" className="CardNumber">
                <label>EXPIRATION DATE </label>
              </div>
              <div id="tsep-cvv2Div" className="CardNumber">
                <label>CVV NUMBER</label>
              </div>
              <div id="tsep-zipCodeDiv" className="CardNumber">
                <label>ZIP CODE</label>
              </div>
              <div id="tsep-cardNumDiv" data-detect-card-type="Y"></div>
              <div className="errorMessage">{errorMessage}</div>
            </form>
            <div className="newbuttonDivSubmit">
              <button
                className="ss-button-secondary width200"
                onClick={() => handleCancel()}
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                disabled={!(responseCode === "A0000")}
                className={
                  !(responseCode === "A0000")
                    ? "ss-button-primary pointerEvent"
                    : "ss-button-primary width200"
                }
                // style={
                //   !(responseCode === "A0000")
                //     ? {
                //         pointerEvents: "none",
                //         opacity: "0.5",
                //         width: "200px",
                //       }
                //     : { width: "200px" }
                // }
              >
                {!responseCode &&
                !!maskedCardNumber &&
                !!cardHolderName &&
                !!zipCode &&
                !!cvv &&
                !!expirationDate
                  ? !responseCode
                    ? "Validating Card..."
                    : "Save"
                  : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CreditCardModalPopUp;
