import React from "react";
import PropTypes from "prop-types";
import Steps from "../../steps/Steps";
import Input from "../../common/Input";
import BooleanInput from "../../common/BooleanInput";
import "./zones.css";
import ZoneActivationDate from "./zoneActivationDate";
import SolidButton from "../../form/SolidButton";
import OutlinedButton from "../../form/OutlinedButton";

const ActivationDates = (props) => {
  const {
    stepNumber,
    saveToApi,
    active,
    onActiveChange,
    activationDate,
    onActivationDateChange,
    deactivationDate,
    onDeactivationDateChange,
    inactiveReason,
    onInactiveReasonChange,
    showInactiveReason,
    setButtonDisabled,
    cancel,
    comp,
  } = props;

  const className = "form-control orange";
  const dateType = "date";
  const checkBoxType = "checkbox";
  const activeName = "Active";
  const activeLabel = "Active: ";
  const activationDateName = "Activation Date";
  const deactivationDateName = "Deactivation Date";
  const activationDateLabel = "Activation Date :";
  const deactivationDateLabel = "Deactivation Date :";
  const inactiveReasonName = "Inactive Reason";
  const inactiveReasonLabel = "Inactive Reason: ";

  return (
    <div>
      {comp === "makeZoneInactive" ? (
        <>
          <Input
            label={inactiveReasonLabel}
            className={className}
            hide={!showInactiveReason}
            name={inactiveReasonName}
            value={inactiveReason}
            onChange={onInactiveReasonChange}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "30px",
            }}
          >
            <OutlinedButton label={"CANCEL"} onClick={cancel} />
            <SolidButton
              label={"SAVE"}
              onClick={saveToApi}
              disabled={!inactiveReason}
            />
          </div>
        </>
      ) : (
        <>
          <div className="powerAccessibleDiv">
            <BooleanInput
              label={activeLabel}
              className={className}
              type={checkBoxType}
              name={activeName}
              checked={active}
              onChange={onActiveChange}
            />
          </div>
          <hr />
          {showInactiveReason ? (
            <Input
              label={inactiveReasonLabel}
              className={className}
              hide={!showInactiveReason}
              name={inactiveReasonName}
              value={inactiveReason}
              onChange={onInactiveReasonChange}
            />
          ) : (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <ZoneActivationDate
                label={activationDateLabel}
                className={className}
                type={dateType}
                name={activationDateName}
                deactivationDate={deactivationDate}
                value={activationDate}
                placeHolder={"mm/dd/yyyy"}
                onChange={onActivationDateChange}
                activationDateMaxCheck="activationDateMaxCheck"
              />
              <ZoneActivationDate
                label={deactivationDateLabel}
                className={className}
                type={dateType}
                name={deactivationDateName}
                activationDate={activationDate}
                deactivationDate={deactivationDate}
                value={deactivationDate}
                placeHolder={"mm/dd/yyyy"}
                setButtonDisabled={setButtonDisabled}
                disabled={activationDate === "MM/dd/yyyy" ? true : false}
                onChange={onDeactivationDateChange}
              />
              {/* <Input
            label={activationDateLabel}
            className={className}
            type={dateType}
            name={activationDateName}
            deactivationDate={deactivationDate}
            value={activationDate}
            onChange={onActivationDateChange}
            activationDateMaxCheck="activationDateMaxCheck"
          /> */}
              {/* <Input
            label={deactivationDateLabel}
            className={className}
            type={dateType}
            name={deactivationDateName}
            activationDate={activationDate}
            deactivationDate={deactivationDate}
            value={deactivationDate}
            setButtonDisabled={setButtonDisabled}
            disabled={activationDate === "MM/dd/yyyy" ? true : false}
            onChange={onDeactivationDateChange}
          /> */}
            </div>
          )}
          <Steps
            canContinue={
              comp !== "edit"
                ? !(
                    (showInactiveReason && inactiveReason?.trim().length > 0) ||
                    (active && activationDate != "MM/dd/yyyy")
                  )
                : !(
                    (showInactiveReason && inactiveReason?.trim().length > 0) ||
                    active
                  )
            }
            step={stepNumber}
            {...props}
          />
        </>
      )}
    </div>
  );
};

ActivationDates.propType = {
  activationDate: PropTypes.string,
  onActivationDateChange: PropTypes.func,
  deactivationDate: PropTypes.string,
  onDeactivationDateChange: PropTypes.func,
  inactiveReason: PropTypes.string,
  onInactiveReasonChange: PropTypes.func,
};

export default ActivationDates;
