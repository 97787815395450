import React, { Component } from "react";
import { withRouter } from "react-router";
import Success from "../components/Success";
import Error from "../components/Error";
import Busy from "../components/Busy";
import showPwdImg from "../img/show-password.svg";
import hidePwdImg from "../img/hide-password.svg";
import { GlobalModalContext } from "../context/global-modal-context";
import { toast } from "react-toastify";
import URLUtils from "../util/URLUtils";
import "../css/views/login.css";
import { ajaxRequest } from "../ajax";
import checkInImage from "../app-images/checkIn.png";
import {
  getUsernameFromLocalStorage,
  saveUsernameToLocalStorage,
} from "../components/constants/securspace-constants";
class Login extends Component {
  static contextType = GlobalModalContext;

  constructor(props) {
    super(props);

    const timedOut = URLUtils.getQueryVariable("timeout");
    const loggedOut = URLUtils.getQueryVariable("loggedOut");
    const toastId = null;

    this.state = {
      username: !!localStorage.getItem("username")
        ? getUsernameFromLocalStorage("username")
        : "",
      password: !!localStorage.getItem("password")
        ? getUsernameFromLocalStorage("password")
        : "",
      remember: !!localStorage.getItem("rememberMe") ? true : false,
      //  showLocalValue: true,
      isRevealPwd: false,
      errorMessage: this.props.errorMessage ? this.props.errorMessage : "",
      redirectToHome: false,
      sessionTimedOut: timedOut,
      loggedOut: loggedOut,
      setShowForgotPassword: this.props.setShowForgotPassword,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.loggedOut !== this.props.loggedOut) {
      this.safeSetState({ loggedOut: nextProps.loggedOut });
    } else if (nextProps.sessionTimedOut !== this.props.sessionTimedOut) {
      this.safeSetState({ sessionTimedOut: nextProps.sessionTimedOut });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    var elements = document.getElementsByTagName("INPUT");
    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    for (var i = 0; i < elements.length; i++) {
      elements[i].oninvalid = function (e) {
        e.target.setCustomValidity("");
        if (!e.target.validity.valid) {
          switch (e.srcElement.id) {
            case "username":
              if (!e.target.value)
                e.target.setCustomValidity("Email cannot be blank");
              else if (!validateEmail(e.target.value))
                e.target.setCustomValidity("Please enter valid mail id");
              break;
            case "password":
              e.target.setCustomValidity("Password cannot be blank");
              break;
          }
        }
      };
      elements[i].oninput = function (e) {
        e.target.setCustomValidity("");
      };
    }

    this.mounted = true;
  }

  safeSetState = (changes) => {
    if (this.mounted) {
      this.setState(changes);
    }
  };

  handleChange = (event) => {
    if (this.state.redirectToHome) return;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    this.safeSetState({ [event.target.name]: value });
    // if (
    //   !(event.target.type === "checkbox") &&
    //   event?.target.name !== "password"
    // )
    //   this.safeSetState({ showLocalValue: false });
  };

  handleSubmit = (event) => {
    Busy.set(true);
    let formData = new FormData();
    formData.append("username", this.state.username);
    formData.append("password", this.state.password);
    formData.append("remember-me", this.state.remember);

    ajaxRequest({
      url: "login",
      data: formData,
      type: "POST",
      method: "POST",
      contentType: false,
      processData: false,
      cache: false,
      processData: false,
      success: this.handleSuccessLogin,
      error: this.handleFailedLogin,
    });

    event.preventDefault();
  };

  handleSuccess = (loggedInAccount) => {
    Busy.set(false);
    let noAccountForUser = "anonymousUser" === loggedInAccount.username;
    let accountToUse = noAccountForUser ? {} : loggedInAccount;
    let errorMessageAccount = "";

    this.safeSetState({
      account: accountToUse,
      //errorMessage: noAccountForUser ? "No account found for given username" : "",
      errorMessageAccount: !!noAccountForUser
        ? "No account found for given username"
        : "",
      loggedOut: false,
      sessionTimedOut: false,
    });
    if (!!errorMessageAccount) {
      this.notify(errorMessageAccount);
    }
    if (!!this.state.remember) {
      saveUsernameToLocalStorage(this.state.username, "username");
      saveUsernameToLocalStorage(this.state.password, "password");
      localStorage.setItem("rememberMe", true);
    } else {
      localStorage.setItem("username", "");
      localStorage.setItem("password", "");
      localStorage.setItem("rememberMe", "");
    }
    if (!loggedInAccount?.subscriptionType) {
      toast.error(
        "Access denied. Please create an account through the SPS portal and relogin",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else {
      this.props.handleAccountChange(accountToUse);
    }
  };

  handleSuccessLogin = (resp) => {
    ajaxRequest({
      url: "authority",
      method: "GET",
      type: "GET",
      contentType: "application/json",
      dataType: "json",
      success: this.handleSuccess,
      error: this.handleFailedLogin,
    });
  };

  notify = (errorMessage) => {
    if (!toast.isActive(this.toastId)) {
      this.toastId = toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  handleFailedLogin = (jqXHR, textStatus, errorThrown) => {
    Busy.set(false);
    let errorMessage = jqXHR.responseJSON
      ? jqXHR.responseJSON.message
      : "Internal Server Error";
    if (errorMessage && "Bad credentials" === errorMessage.trim()) {
      errorMessage = "Invalid username or password";
    }
    this.safeSetState({
      errorMessage: errorMessage,
      loggedOut: false,
      sessionTimedOut: false,
      username: "",
      password: "",
      remember: false,
    });
    this.notify(errorMessage);
  };

  render() {
    return (
      <div className="ss-login">
        <div className="popup-header">
          <img alt="" src={checkInImage} />
          <h1 className="ss-login-gray">Log In</h1>
        </div>
        <form onSubmit={this.handleSubmit} className="login-form">
          <fieldset className="login-form-field">
            <label htmlFor="username">EMAIL</label>
            <input
              type="text"
              autoFocus={true}
              id="username"
              className="ss-login-form-input"
              name="username"
              value={this.state.username}
              onChange={this.handleChange}
              autoComplete="on"
              pattern="^([a-zA-Z0-9_\-\.+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
              placeholder="Please enter your email"
              required
            />
          </fieldset>
          <fieldset
            className="login-form-field"
            style={{ position: "relative" }}
          >
            <label htmlFor="password"> PASSWORD </label>
            <input
              type={this.state.isRevealPwd ? "text" : "password"}
              id="password"
              className="ss-login-form-input password"
              name="password"
              value={this.state.password}
              onChange={this.handleChange}
              autoComplete="on"
              placeholder="Please enter your password"
              required
              style={{ paddingRight: "40px" }}
            />
            <img
              className="revealPassword"
              title={this.state.isRevealPwd ? "Hide password" : "Show password"}
              src={this.state.isRevealPwd ? hidePwdImg : showPwdImg}
              onClick={() =>
                this.setState((prevState) => ({
                  isRevealPwd: !prevState.isRevealPwd,
                }))
              }
            />
            <h4
              to="/forgot-password"
              onClick={() => this.state.setShowForgotPassword(true)}
              className="ss-forgot-label"
            >
              FORGOT PASSWORD?
            </h4>
          </fieldset>
          <fieldset className="login-form-field">
            <label className="ss-checkbox" style={{ width: "22%" }}>
              <input
                type="checkbox"
                className="ss-checkbox-container-checkbox"
                name="remember"
                checked={this.state.remember}
                onChange={this.handleChange}
              />{" "}
              Remember me
            </label>
          </fieldset>
          <fieldset
            className="login-form-field"
            style={{ marginBottom: "10px" }}
          >
            <button
              type="submit"
              id="loginSubmitButton"
              className="orange-button ss-action-button ss-login-submit"
            >
              LOG IN
            </button>
          </fieldset>
          <div>
            {this.state.sessionTimedOut ? (
              <Success>Session timed out. Please login again.</Success>
            ) : this.state.loggedOut ? (
              <Success>Successfully logged out</Success>
            ) : (
              ""
            )}
          </div>

          <div className="clear" />
        </form>
      </div>
    );
  }
}

export default withRouter(Login);
