import React, { Component } from "react";
import "../css/views/booking-common.css";
import "../css/theme/mainContent.css";
import "../css/theme/forms.css";
import "../css/theme/forms-block.css";
import "../css/theme/buttons.css";
import { ALL_EDIT_ROLES, isRoleApproved } from "../util/user/UserUtil";
import Busy from "./Busy";
import { requestCheckInValidation } from "./paymentMethods/request/payment-method-requests";
import { toast } from "react-toastify";

class SupplierBookingActionButtons extends Component {
  static showCancel(account, booking) {
    const ONE_HOUR = 60 * 60 * 1000;
    const TWENTY_FOUR_HOURS = 24 * ONE_HOUR;
    let timeBeforeBookingStart = new Date(booking.startDate) - new Date();
    return (
      timeBeforeBookingStart > TWENTY_FOUR_HOURS &&
      ["OWNER", "GATEMANAGER"].includes(account.userType)
    );
  }
  handleClick = async () => {
    Busy.set(true);
    await requestCheckInValidation(this.props.booking?.id)
      .then((res) => {
        if (res?.body?.available) {
          this.props.changeViewHandler("check-in-out-head", this.props.booking);
        }
      })
      .catch((err) => {
        if (err.response?.status === 409 && err.response?.data?.message) {
          const objErrorMessage = JSON.parse(err.response.data.message);
          toast.error(objErrorMessage?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // Fallback for other errors
          const objErrorMessage = JSON.parse(err?.message);

          const errorMessage =
            objErrorMessage?.message || "Internal server error";
          toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    Busy.set(false);
  };

  render() {
    return (
      <div className="ss-booking-button-container">
        {isRoleApproved(ALL_EDIT_ROLES, this.props.userType) && (
          <button
            className="ss-button-primary"
            style={{
              border: "1px solid #f79019",
              backgroundColor: "#f79019",
              color: "#fff",
            }}
            onClick={this.handleClick}
          >
            Check In
          </button>
        )}
        {SupplierBookingActionButtons.showCancel(
          this.props.account,
          this.props.booking
        ) ? (
          <button
            className="ss-button-danger"
            onClick={() => this.props.cancelBooking(this.props.booking.id)}
          >
            Cancel
          </button>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default SupplierBookingActionButtons;
