import React, { Component } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
} from "react-google-maps/lib";
import {
  formatCurrencyValue,
  parseCurrencyValue,
  validateCurrencyValue,
} from "../util/PaymentUtils";
import "../App.css";
import "../css/views/createEditLocation.css";
import "../css/theme/mainContent.css";
import "../css/theme/forms.css";
import "../css/theme/forms-block.css";
import "../css/theme/buttons.css";
import "../css/components/closeIcon.css";
import { createLogoutOnFailureHandler } from "../util/LogoutUtil";
import Error from "../components/Error";
import LocationFeatures from "../components/LocationFeatures";
import Busy from "../components/Busy";
import { createLocationFromAddress } from "../util/AddressUtil";
import MapStyle from "../components/MapStyle";
import ReferenceOption from "../controls/ReferenceOption";
import Select from "../components/Select";
import ReactTooltip from "react-tooltip";
import DropGallery from "../components/DropGallery";
import DropFile from "../components/DropFile";
import ZoneMap from "../components/zone/ZoneMap";
import Button from "../components/form/Button";
import { withRouter } from "react-router";
import { ajaxRequest } from "../ajax";
import { toast } from "react-toastify";

const $ = window.$;
const google = window.google;
const geocoder = new google.maps.Geocoder();

const GALLERY_BUCKET = "gallery";
const FILE_BUCKET = "listing-images";

let autocomplete;

const SupplierSearchMap = withGoogleMap((props) => (
  <GoogleMap
    ref={(map) => {
      // this.map = map;
    }}
    defaultZoom={4}
    center={props.center}
    zoom={props.zoom}
    options={props.options}
    onClick={props.onClick}
  >
    {props.markers.map((marker, index) => (
      <Marker
        key={index}
        position={marker.position}
        onClick={() => props.onMarkerClick(marker)}
        icon={marker.icon}
        animation={google.maps.Animation.DROP}
      >
        {marker.showInfo && (
          <InfoWindow onCloseClick={() => props.onMarkerClose(marker)}>
            <div>{marker.infoContent}</div>
          </InfoWindow>
        )}
      </Marker>
    ))}
  </GoogleMap>
));

class CreateEditLocation extends Component {
  constructor(props) {
    super(props);
    let mapData = {
      center: {
        lat: 37.09024,
        lng: -95.712891,
      },
      zoom: 4,
      markers: [],
    };
    this.state = {
      isScrollEnabled: true,
    };
    this.editZones = this.editZones.bind(this);
    let location;

    if (this.props.locationToEdit) {
      location = this.props.locationToEdit;
      location.maxOneTimeDuration = "ONE TIME";

      location.maxRecurringDuration = "RECURRING";
    } else {
      location = {
        id: "",
        locationName: "",
        locationDescription: "",
        instructions: "",
        addressLatitude: null,
        addressLongitude: null,
        addressLine1: null,
        addressLine2: null,
        city: null,
        state: null,
        zip: null,
        phoneNumber: "",
        listingImageFileName: "",
        listingImageOldFileName: null,
        locationGallery: [],
        locationGalleries: [],
        locationFeatures: [],
        createdOn: null,
        maxOneTimeSpaces: "",
        maxOneTimeDuration: "ONE TIME",
        maxRecurringSpaces: "",
        maxRecurringDuration: "RECURRING",
        removeLocationImage: false,
        live: false,
        totalNumberOfSpaces: "",
        overageRate: "",
        chargeOverages: false,
        overageGracePeriodInMinutes: "",
        locationZones: [],
        convertedZones: [],
      };
    }

    let lookupLocation = createLocationFromAddress(location);

    this.state = Object.assign(
      location,
      {
        updateSuccessful: false,
        errorMessage: "",
        lookupLocation: lookupLocation,
        oldListingImageFileName: location ? location.listingImageFileName : "",
        locationGallery: location ? location?.locationGalleries : [],
        features: CreateEditLocation.createFeatures(location),
        loggedOut: false,
        doneEditingLocation: false,
      },
      mapData
    );

    this.dropzone = null;
    this.dropfile = null;

    this.setAutocompleteFromAddress(lookupLocation);
  }

  static createFeatures(location) {
    let features = LocationFeatures.OPTIONS;
    let locationFeatures = location ? location.locationFeatures : null;
    return CreateEditLocation.createLocationItemList(
      features,
      locationFeatures,
      "feature"
    );
  }

  static createLocationItemList(
    locationItemLabels,
    locationItems,
    locationItemProperty
  ) {
    let locationItemList = {};
    for (let index = 0; index < locationItemLabels.length; index++) {
      let id = null;
      let label = locationItemLabels[index];
      let value = false;

      if (locationItems) {
        for (let i = 0; i < locationItems.length; i++) {
          let locationItem = locationItems[i];
          if (label === locationItem[locationItemProperty]) {
            id = locationItem.id;
            value = true;
          }
        }
      }

      locationItemList[locationItemProperty + index] = {
        id: id,
        label: label,
        value: value,
      };
    }
    return locationItemList;
  }

  componentDidMount() {
    let _this = this;
    let lookupLocation = document.getElementById("lookupLocation");
    autocomplete = new google.maps.places.Autocomplete(lookupLocation);
    autocomplete.addListener("place_changed", function () {
      let place = autocomplete.getPlace();
      if (place.geometry) {
        let streetNumber = "";
        let route = "";
        let city = "";
        let state = "";
        let zip = "";

        let geoLocation = place.geometry.location;

        for (let i = 0; i < place.address_components.length; i++) {
          let addressType = place.address_components[i].types[0];
          let addressType2 = "";
          if (place.address_components[i].types.length === 2) {
            addressType2 = place.address_components[i].types[1];
          }

          if (addressType === "street_number") {
            streetNumber = place.address_components[i]["short_name"];
          } else if (addressType === "route") {
            route = place.address_components[i]["long_name"];
          } else if (addressType === "locality") {
            city = place.address_components[i]["long_name"];
          } else if (addressType === "administrative_area_level_1") {
            state = place.address_components[i]["long_name"];
          } else if (addressType === "postal_code") {
            zip = place.address_components[i]["short_name"];
          } else if (addressType2 === "political" && !city) {
            city = place.address_components[i]["long_name"];
          }

          _this.setState({
            lookupLocation: place.formatted_address,
            addressLine1: streetNumber + " " + route,
            addressLine2: "",
            city: city,
            state: state,
            zip: zip,
            addressLatitude: geoLocation.lat(),
            addressLongitude: geoLocation.lng(),
          });
        }

        _this.setMarkerFromPlace(geoLocation);
        _this.centerMapOnGeometry(geoLocation, place.geometry.viewport);
      } else {
        alert("Address Not Found!");
      }
    });
    if (this.state.locationZones) {
      this.convertZones();
    }
    document.body.style.setProperty("overflow-y", "hidden", "important");
  }
  componentDidUpdate(prevProps, prevState) {
    // Enable or disable scrolling based on the state
    if (!!this.state.isScrollEnabled) {
      const elements = document.querySelectorAll(".app-modal,body");
      elements.forEach((element) => {
        element.style.overflowY = "hidden";
        element.style.setProperty("overflow-y", "hidden", "important");
      });
    } else {
      const elements = document.querySelectorAll(".app-modal");
      elements.forEach((element) => {
        element.style.overflowY = "auto";
        element.style.setProperty("overflow-y", "auto", "important");
      });
    }
  }

  componentWillUnmount() {
    // Ensure scrolling is enabled when component unmounts
    const elements = document.querySelectorAll(".app-modal");
    elements.forEach((element) => {
      element.style.overflowY = "auto";
      element.style.setProperty("overflow-y", "auto", "important");
    });
    document.body.style.removeProperty("overflow-y");
  }

  convertZones() {
    let zones = this.state.locationZones;
    for (let zone of zones) {
      for (let coordinates of zone.coordinates) {
        coordinates.latitude = Number(coordinates.latitude);
        coordinates.longitude = Number(coordinates.longitude);
      }
    }
    this.setState({ convertedZones: zones });
  }

  setAutocompleteFromAddress(address) {
    let _this = this;
    if (address) {
      geocoder.geocode({ address: address }, function (results, status) {
        if (status === "OK") {
          let geoLocation = results[0].geometry.location;
          _this.setMarkerFromPlace(geoLocation);
          _this.centerMapOnGeometry(geoLocation, results[0].geometry.viewport);
        } else {
          alert(
            "Geocode was not successful for the following reason: " + status
          );
        }
      });
    }
  }

  centerMapOnGeometry(loc, viewport) {
    this.setState({ center: loc });
    if (viewport && this.mapContainer) {
      this.mapContainer.state.map.fitBounds(viewport);
      this.setState({ zoom: 18 });
    } else {
      this.setState({ zoom: 18 });
    }
  }

  setMarkerFromPlace = (geoLocation) => {
    this.setState({
      markers: [
        {
          position: geoLocation,
          showInfo: false,
        },
      ],
    });
  };

  updateLocationGalleries = (updatedGalleries) => {
    // console.log("updatedGalleries", updatedGalleries);
    this.setState((prevState) => ({
      locationGalleries: updatedGalleries,
      location: {
        ...prevState.location,
        locationGalleries: updatedGalleries, // Keep location in sync
      },
    }));
  };
  updateLocationListingImage = () => {
    this.setState((prevState) => ({
      ...prevState,
      listingImageFileName: "",
    }));
  };

  handleChange = (event) => {
    //Clear out success and error messages when the user begins editing again.
    this.setState({
      updateSuccessful: false,
      errorMessage: "",
    });

    let name = event.target.name;

    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    if (
      "zip" === name &&
      (!CreateEditLocation.isInteger(value) || value > 99999)
    ) {
      return;
    }
    if (
      "phoneNumber" === name &&
      (!CreateEditLocation.isInteger(value) || value > 9999999999)
    ) {
      return;
    }
    if ("overageRate" === name) {
      if (!validateCurrencyValue(value)) {
        return;
      }
      value = parseCurrencyValue(value);
    }
    if ("listingImage" === name) {
      value = event.target.files;
    }
    if ("lookupLocation" === name) {
      //Clear this state every time the user types in the lookupLocation field as they invalidate the lat and lng.
      //Once the user selects an address from the lookup, then the lat and lng will get populated again.
      this.setState({
        addressLatitude: null,
        addressLongitude: null,
      });
    }

    this.setState({ [name]: value });
  };

  handleSelectChange = (event) => {
    this.setState({ [event.target.name]: event.target.value.value });
  };

  clearMessages() {
    this.setState({
      updateSuccessful: false,
      errorMessage: "",
    });
  }

  handleFeatureChange = (event) => {
    //Clear out success and error messages when the user begins editing again.
    this.setState({
      updateSuccessful: false,
      errorMessage: "",
    });

    let name = event.target.name;
    let value = event.target.checked;

    //This merges the new value for the value property into the feature object without overwriting the other property values
    let updatedValueFeature = Object.assign({}, this.state.features[name], {
      value: value,
    });
    //This merges the new value for the feature into the features object without overwriting the other equipment types
    let updatedValueFeatures = Object.assign({}, this.state.features, {
      [name]: updatedValueFeature,
    });

    this.setState({
      features: updatedValueFeatures,
    });
  };

  handleOverageChange = (event) => {
    //Clear out success and error messages when the user begins editing again.
    this.setState({
      updateSuccessful: false,
      errorMessage: "",
    });

    let value = event.target.checked;

    this.setState({
      chargeOverages: value,
    });
  };

  static isInteger(x) {
    return x.indexOf(".") < 0 && x % 1 === 0;
  }

  static preventDefault(event) {
    event.stopPropagation(); // Stop stuff happening
    event.preventDefault(); // Totally stop stuff happening
  }

  handleSubmit = (event) => {
    this.setState({ errorMessage: "" });
    if (!this.state.locationName) {
      this.setErrorMessage("Please enter a Location Name.");
      return;
    }
    let addressLookupFieldValue =
      document.getElementById("lookupLocation").value;
    if (!addressLookupFieldValue) {
      this.setErrorMessage("Please enter a Location Address.");
      return;
    }
    if (
      addressLookupFieldValue &&
      (!this.state.addressLongitude || !this.state.addressLatitude)
    ) {
      this.setErrorMessage(
        "Please select a Location Address from the address lookup."
      );
      return;
    }
    let addressLine1 = addressLookupFieldValue ? this.state.addressLine1 : null;
    if (!addressLine1 || (addressLine1 && !addressLine1.trim())) {
      this.setErrorMessage("Address must contain a street number and name.");
      return;
    }
    let city = addressLookupFieldValue ? this.state.city : null;
    if (!city) {
      this.setErrorMessage("Address must contain a city.");
      return;
    }
    let state = addressLookupFieldValue ? this.state.state : null;
    if (!state) {
      this.setErrorMessage("Address must contain a state.");
      return;
    }
    let zip = addressLookupFieldValue ? this.state.zip : null;
    if (!zip) {
      this.setErrorMessage("Address must contain a zip code.");
      return;
    }

    if (!this.state.maxOneTimeSpaces && this.state.maxOneTimeSpaces !== 0) {
      this.setErrorMessage("Maximum one time spaces is required!");
      return;
    }
    if (!this.state.maxRecurringSpaces && this.state.maxRecurringSpaces !== 0) {
      this.setErrorMessage("Maximum recurring spaces is required!");
      return;
    }

    if (!!this.state.chargeOverages && !this.state.overageRate) {
      this.setErrorMessage("Overage Rate is required!");
      return;
    }
    if (
      !!this.state.chargeOverages &&
      !this.state.overageGracePeriodInMinutes
    ) {
      this.setErrorMessage("Overage Grace Period is required!");
      return;
    }

    if (!this.state.phoneNumber) {
      this.setErrorMessage("Please enter a phone number for this location.");
      return;
    }

    let allUploadFiles = [];

    if (this.dropfile && this.dropfile?.files.length > 0) {
      for (let i = 0; i < this.dropfile?.files.length; i++) {
        let file = this.dropfile?.files[i];

        if (file && file.type !== "fake") {
          //new file
          allUploadFiles.push({
            files: [file],
            fileNameProperty: "listingImageFileName",
            folder: "listing-images",
            oldFileName: this.state.oldListingImageFileName,
            isImageFile: true,
          });
        }
      }
    }
    if (!this.state.listingImageFileName && allUploadFiles.length === 0) {
      this.setErrorMessage("Listing image is required!");
      return;
    }

    if (this.validateFiles(allUploadFiles)) {
      Busy.set(true);
      let _this = this;
      let listingImageDetail;
      let listingImageFileName = this.props.locationToEdit
        ? this.props.locationToEdit.listingImageFileName
        : "";
      let valuesToSend;
      if (allUploadFiles?.length > 0) {
        let fileToUpload = allUploadFiles.shift();
        let file = fileToUpload.files ? fileToUpload.files[0] : "";
        valuesToSend = {
          name: file.name,
          size: file.size,
          type: file.type,
          dataUrl: file.dataURL,
        };
        listingImageFileName = file?.name?.replace(/\s+/g, "");
        let profileFileName =
          this.props.account.id + "_" + file?.name?.replace(/\s+/g, "");
        listingImageDetail = {
          name: profileFileName,
          oldName: fileToUpload.oldFileName,
          contentType: file.type,
          inputStream: file.dataURL,
        };
      }
      let uploadedGalleryFiles = [];

      if (this.dropzone && this.dropzone.files.length > 0) {
        // Initialize the array outside the loop

        // console.log("Number of files:", this.dropzone.files.length); // Debug log

        for (let i = 0; i < this.dropzone.files.length; i++) {
          let file = this.dropzone.files[i];

          if (file && file.type !== "fake") {
            // New file
            let inventoryId = this.props.account.id;
            let fileNewName = file?.name?.replace(/\s+/g, "");
            const val = {
              name: fileNewName,
              inventoryId: inventoryId,
              contentType: file.type,
              inputStream: file.dataURL,
            };
            const fileData = {
              name: file.name,
              size: file.size,
              type: file.type,
              dataUrl: file.dataURL,
            };
            // console.log("val", val);
            // Add the file object to the array
            // uploadedGalleryFiles.push(val);
            uploadedGalleryFiles.push(fileData);
          }
          //console.log("Uploaded gallery files:", uploadedGalleryFiles); // Debug log to verify array content
        }
      }

      _this
        .saveProfile(
          //listingImageDetail,
          valuesToSend,
          uploadedGalleryFiles,
          listingImageFileName
        )
        .then(() => {
          //  console.log("Profile saved successfully!");
        })
        .catch((error) => {
          //console.error("Error saving profile:", error);
        });

      // this.uploadFilesAndSubmitForm(this, allUploadFiles);
    }
  };

  validateFiles(allUploadFiles) {
    for (let i = 0; i < allUploadFiles.length; i++) {
      let fileToUpload = allUploadFiles[i];
      let file = fileToUpload.files ? fileToUpload.files[0] : "";
      if (file.size > 20480000) {
        Busy.set(false);
        this.setState({
          errorMessage: "Selected file is too large. Max file size is 20 MB.",
        });
        return false;
      }
      if (fileToUpload.isImageFile) {
        if (file && !CreateEditLocation.isImageFile(file)) {
          Busy.set(false);
          this.setState({
            errorMessage:
              "File selected for listing image is not an image file.",
          });
          return false;
        }
      }
    }
    return true;
  }

  uploadFilesAndSubmitForm(_this, allUploadFiles) {
    if (!filesToUpload || filesToUpload.length === 0) {
      _this
        .saveProfile()
        .then(() => {
          // console.log("Profile saved successfully!");
        })
        .catch((error) => {
          //console.error("Error saving profile:", error);
        });
      return;
    }

    _this
      .saveProfile()
      .then(() => {
        _this.uploadRemainingFiles(_this, filesToUpload);
      })
      .catch((error) => {
        // console.error("Error saving profile:", error);
      });
    // _this
    //   .saveProfile()
    //   .then(() => {
    //     let fileToUpload = filesToUpload.shift();
    //     let file = fileToUpload.files ? fileToUpload.files[0] : "";

    //     if (file && file.name) {
    //       _this.uploadFile(
    //         fileToUpload.folder,
    //         fileToUpload.oldFileName,
    //         file,
    //         function (uploadedFileName, textStatus, jqXHR) {
    //           _this.setState({
    //             [fileToUpload.fileNameProperty]: uploadedFileName,
    //           });
    //           _this.uploadFilesAndSubmitForm(_this, filesToUpload);
    //         }
    //       );
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error saving profile:", error);
    //   });
  }

  uploadRemainingFiles(_this, filesToUpload) {
    if (!filesToUpload || filesToUpload.length === 0) {
      return;
    }

    let fileToUpload = filesToUpload.shift();
    let file = fileToUpload.files ? fileToUpload.files[0] : "";

    if (file && file.name) {
      _this.uploadFile(
        fileToUpload.folder,
        fileToUpload.oldFileName,
        file,
        function (uploadedFileName, textStatus, jqXHR) {
          _this.setState({ [fileToUpload.fileNameProperty]: uploadedFileName });
          _this.uploadRemainingFiles(_this, filesToUpload); // Recursive call for the next file
        }
      );
    }
  }

  uploadFile(folder, oldFileName, file, onSuccess) {
    let _this = this;

    let data = new FormData();
    data.append("file", file);
    let time = new Date().getTime();
    let profileFileName =
      this.props.account.id +
      "_" +
      time +
      "_" +
      file?.name?.replace(/\s+/g, "");
    ajaxRequest({
      url: `file/upload/${folder}?name=${profileFileName}&oldName=${oldFileName}`,
      type: "POST",
      data: data,
      cache: false,
      processData: false, // Don't process the files
      contentType: false, // Set content type to false as jQuery will tell the server its a query string request
      success: function (data, textStatus, jqXHR) {
        onSuccess(data, textStatus, jqXHR);
      },
      statusCode: {
        401: createLogoutOnFailureHandler(this.props.handleLogout),
      },
      error: function (jqXHR, textStatus, errorThrown) {
        Busy.set(false);
        _this.setState({ errorMessage: "File upload failed:  " + textStatus });
      },
    });
  }

  uploadFileData(folder, file, onSuccess) {
    let _this = this;

    let data = new FormData();
    data.append("inputStream", file.dataURL);
    let time = new Date().getTime();
    let profileFileName = this.props.account.id;
    //+ "_" + time + "_" + file.name;
    let fileNewName = file?.name?.replace(/\s+/g, "");
    let url = `file/upload/gallery?name=${fileNewName}&inventoryId=${profileFileName}&contentType=${file.type}`;
    ajaxRequest({
      url: url,
      type: "POST",
      data: data,
      cache: false,
      processData: false, // Don't process the files
      contentType: false, // Set content type to false as jQuery will tell the server its a query string request
      success: function (data, textStatus, jqXHR) {
        onSuccess(data, textStatus, jqXHR);
      },
      statusCode: {
        401: createLogoutOnFailureHandler(this.props.handleLogout),
      },
      error: function (jqXHR, textStatus, errorThrown) {
        Busy.set(false);
        _this.setState({ errorMessage: "File upload failed:  " + textStatus });
      },
    });
  }

  static isImageFile(file) {
    let fileType = file["type"];
    let ValidImageTypes = ["image/gif", "image/jpeg", "image/png"];
    return $.inArray(fileType, ValidImageTypes) >= 0;
  }
  dataUrlToFile(dataUrl, fileName) {
    // Step 1: Extract the MIME type and base64 data
    const [metadata, base64Data] = dataUrl.split(",");

    // Step 2: Decode the base64 string into binary data
    const byteString = atob(base64Data);

    // Step 3: Convert binary string to an array of bytes
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    // Step 4: Create a Blob object from the byte array
    const mimeType = metadata.match(/:(.*?);/)[1]; // Extract MIME type (e.g., "image/jpeg")
    const blob = new Blob([uintArray], { type: mimeType });

    // Step 5: Create a File from the Blob
    const file = new File([blob], fileName, { type: mimeType });

    return file;
  }

  saveProfile = (
    listingImageDetail,
    uploadedGalleryFiles,
    listingImageFileName
  ) => {
    //console.log("listingImageFileName", listingImageFileName);
    let _this = this;
    let addressLookupFieldValue =
      document.getElementById("lookupLocation").value;

    let featuresList = [];
    Object.keys(this.state.features).forEach(function (key) {
      let feature = _this.state.features[key];
      if (feature.value) {
        featuresList.push({
          id: feature.id,
          feature: feature.label,
        });
      }
    });

    const location = {
      id: this.state.id,
      inventoryId: this.props.account.id,
      locationName: this.state.locationName.toUpperCase(),
      locationDescription: this.state.locationDescription,
      locationGalleries: this.state.locationGalleries,
      addressLatitude: addressLookupFieldValue
        ? this.state.addressLatitude
        : null,
      addressLongitude: addressLookupFieldValue
        ? this.state.addressLongitude
        : null,
      addressLine1: addressLookupFieldValue ? this.state.addressLine1 : null,
      addressLine2: addressLookupFieldValue ? this.state.addressLine2 : null,
      instructions: this?.state?.instructions,
      city: addressLookupFieldValue ? this.state.city : null,
      state: addressLookupFieldValue ? this.state.state : null,
      zip: addressLookupFieldValue ? this.state.zip : null,
      phoneNumber: this.state.phoneNumber,
      listingImageOldFileName: !!listingImageDetail
        ? this.state.listingImageOldFileName
        : null,
      overageRate: this.state.chargeOverages ? this.state.overageRate : "",
      listingImageFileName: listingImageFileName,
      account: {
        id: this.props.account.id,
      },
      locationFeatures: featuresList,
      live: this.state.live,
      chargeOverages: this.state.chargeOverages,
      overageGracePeriodInMinutes:
        !!this.state.overageGracePeriodInMinutes && this.state.chargeOverages
          ? this.state.overageGracePeriodInMinutes
          : "",
      maxOneTimeSpaces: !!this.state.maxOneTimeSpaces
        ? this.state.maxOneTimeSpaces
        : 0,
      maxOneTimeDuration: "DAILY",
      maxRecurringSpaces: !!this.state.maxRecurringSpaces
        ? this.state.maxRecurringSpaces
        : 0,
      maxRecurringDuration: "MONTHLY",
    };
    let listingImageDetailFile = null;
    if (!!listingImageDetail) {
      listingImageDetailFile = this.dataUrlToFile(
        listingImageDetail?.dataUrl,
        listingImageDetail?.name
      );
    }

    const updatedArray = [];
    if (!!uploadedGalleryFiles) {
      for (let i = 0; i < uploadedGalleryFiles?.length; i++) {
        const newFile = this.dataUrlToFile(
          uploadedGalleryFiles[i]?.dataUrl,
          uploadedGalleryFiles[i]?.name
        );
        updatedArray.push(newFile);
      }
    }

    const dataToBeSent = new FormData();
    dataToBeSent.append("location", JSON.stringify(location));
    dataToBeSent.append("listingImage", listingImageDetailFile);
    if (updatedArray?.length == 0) dataToBeSent.append("imageGallery", null);
    updatedArray.forEach((file, index) => {
      dataToBeSent.append("imageGallery", file); // Notice the "[]" which indicates an array in FormData
    });

    const listingImageDetailVal = listingImageDetail;
    const uploadedGalleryFilesVal = uploadedGalleryFiles;

    return new Promise((resolve, reject) => {
      ajaxRequest({
        url: "location",
        data: dataToBeSent,
        type: "POST",
        contentType: false, // Don't manually set contentType when sending FormData
        processData: false, // Don't process data, let FormData handle it
        dataType: "json",
        success: (response) => {
          this.handleSuccess(response);
          resolve(response); // Resolve the Promise on success
        },
        statusCode: {
          401: createLogoutOnFailureHandler(this.props.handleLogout),
        },
        error: (error) => {
          _this.handleFailure(error);
          reject(error); // Reject the Promise on error
        },
      });
    });
  };

  handleSuccess = (updatedLocation) => {
    Busy.set(false);
    // this.setState(
    //   Object.assign(updatedLocation, {
    //     oldListingImageFileName: updatedLocation.listingImageFileName,
    //     updateSuccessful: true,
    //     errorMessage: "",
    //     features: CreateEditLocation.createFeatures(updatedLocation),
    //     doneEditingLocation: true,
    //   })
    // );
    const locationUpdateText = this.props.locationToEdit
      ? "Location updated successfully!"
      : "Location created successfully!";
    toast.success(locationUpdateText, {
      position: toast.POSITION.TOP_RIGHT,
    });

    this.props.updateEditLocation(updatedLocation);
    this.props.updateLocationsList();
    setTimeout(this.props.closeModal(), 500);
  };

  saveGallery = (updatedLocation) => {
    let uploadedGalleryFiles = [];
    this.galleryFiles = [];

    if (this.dropzone && this.dropzone.files.length > 0) {
      for (let i = 0; i < this.dropzone.files.length; i++) {
        let file = this.dropzone.files[i];
        if (file && file.type !== "fake") {
          //new file
          uploadedGalleryFiles.push(file);
        } else {
          this.galleryFiles.push(file?.name?.replace(/\s+/g, ""));
        }
      }
    }
    this.uploadGallery(this, uploadedGalleryFiles, updatedLocation);
  };

  updateGalleryTable = (updatedLocation) => {
    // save files names in gallery table and delete???

    ajaxRequest({
      url: "location/gallery",
      data: JSON.stringify({
        location: updatedLocation,
        galleryFiles: this.galleryFiles,
      }),
      type: "POST",
      contentType: "application/json; charset=UTF-8",
      dataType: "json",
      success: this.handleSuccess,
      statusCode: {
        401: createLogoutOnFailureHandler(this.props.handleLogout),
      },
      error: this.handleFailure,
    });
  };

  uploadGallery(_this, uploadedGalleryFiles, updatedLocation) {
    if (!uploadedGalleryFiles || uploadedGalleryFiles.length === 0) {
      //save gallery imahes in the location_gallery table
      _this.updateGalleryTable(updatedLocation);
      return;
    }

    let file = uploadedGalleryFiles.shift();

    if (file && file.name) {
      _this.uploadFileData(
        GALLERY_BUCKET + "/" + this.state.id,
        file,
        function (uploadedFileName, textStatus, jqXHR) {
          _this.galleryFiles.push(uploadedFileName);
          _this.uploadGallery(_this, uploadedGalleryFiles, updatedLocation);
        }
      );
    }
  }

  updateDropzone = (dropzone) => {
    this.dropzone = dropzone;
  };

  updateDropfile = (dropzone) => {
    this.dropfile = dropzone;
    this.setState({ errorMessage: "" });
  };

  handleFailure = (jqXHR, textStatus, errorThrown) => {
    Busy.set(false);

    this.setState({
      updateSuccessful: false,
      errorMessage: jqXHR.responseJSON
        ? jqXHR.responseJSON.message
        : jqXHR?.status === 409
        ? "Location name should not be same"
        : "Internal Server Error",
    });
  };

  setErrorMessage(message) {
    Busy.set(false);
    this.setState({
      updateSuccessful: false,
      errorMessage: message,
    });
  }

  static validateEmail(email) {
    let reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    return reg.test(email);
  }

  handleDropdownFocus = () => {
    // Disable scrolling when the dropdown is clicked (focused)
    this.setState({ isScrollEnabled: true });
  };

  handleDropdownBlur = () => {
    // Re-enable scrolling when the dropdown is no longer focused (blurred)
    this.setState({ isScrollEnabled: false });
  };

  editZones() {
    this.props.history.push(`/zones-profile/${this.state.id}`);
  }

  render() {
    let _this = this;
    // console.log("this.props.locationToEdit", this.props.locationToEdit);
    // console.log("state", this.state);
    // console.log("hey", this.state);
    return (
      <div id="ssCreateEditLocation">
        <div className="modal-content ">
          <div className="popup-header">
            <img alt="" src="../app-images/locations/edit_location_icon.svg" />
            <h1>
              {this.props.locationToEdit ? "Edit" : "Create"} Location Profile
            </h1>
            <span
              className="pointer pull-right"
              onClick={() => this.props.closeModal()}
            >
              <img alt="" src="../app-images/close.png" />
            </span>
          </div>

          <form className="ss-form ss-block">
            <div id="ssLocationProfileFormContainer">
              <p className="ss-summary ss-top-summary">
                <span className="border-bottom-blue-line">
                  Location information
                </span>
              </p>

              <fieldset className="hs-field position-relative">
                <label htmlFor="locationName">
                  LOCATION NAME
                  <span
                    data-tip
                    data-for="locationName"
                    className="location-name-tip"
                  >
                    {" "}
                    <i className="fa fa-question-circle" />
                  </span>
                </label>

                <input
                  type="text"
                  id="locationName"
                  name="locationName"
                  value={this.state.locationName.toUpperCase()}
                  onChange={this.handleChange}
                  placeholder="Enter Location Name"
                  autocomplete="off"
                />
                <ReactTooltip
                  id="locationName"
                  type="success"
                  effect="solid"
                  className="location-name-tip-hover location-tooltip-top"
                >
                  <span>
                    Location name should not be the same as your company name.
                  </span>
                </ReactTooltip>
              </fieldset>

              <fieldset className="hs-field">
                <label htmlFor="companyDescription">LOCATION DESCRIPTION</label>
                <textarea
                  id="locationDescription"
                  name="locationDescription"
                  value={this.state.locationDescription}
                  onChange={this.handleChange}
                  placeholder="Enter Location Description"
                />
              </fieldset>

              <fieldset className="hs-field">
                <label htmlFor="lookupLocation">LOCATION ADDRESS</label>
                <input
                  type="text"
                  id="lookupLocation"
                  name="lookupLocation"
                  value={this.state.lookupLocation}
                  onChange={this.handleChange}
                  onFocus={this.handleDropdownFocus}
                  onBlur={this.handleDropdownBlur}
                  placeholder="Enter Location Address"
                />
              </fieldset>

              <fieldset className="ss-stand-alone  hs-field">
                <label htmlFor="phoneNumber">PHONE NUMBER</label>
                <input
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  maxLength={10}
                  value={this.state.phoneNumber}
                  onChange={this.handleChange}
                  placeholder="Enter Phone Number"
                  autocomplete="off"
                />
              </fieldset>

              <SupplierSearchMap
                ref={(mapContainer) => (this.mapContainer = mapContainer)}
                containerElement={
                  <div id="locations-map" className="heightCustomeVal" />
                }
                mapElement={<div className="heightCustomeVal" />}
                center={this.state.center}
                zoom={this.state.zoom}
                options={{
                  styles: MapStyle,
                  mapTypeControl: false,
                  streetViewControl: false,
                  fullscreenControl: false,
                  zoomControl: true,
                  scrollwheel: false,
                  mapTypeId: "satellite",
                  zoomControlOptions: {
                    position: google.maps.ControlPosition.TOP_RIGHT,
                  },
                }}
                markers={this.state.markers}
              />

              {this.state.locationZones?.length > 0 ? (
                <ZoneMap
                  zones={this.state.convertedZones}
                  containerElement={
                    <div id="locations-map" className="heightCustomeVal" />
                  }
                  mapElement={<div className="heightCustomeVal" />}
                />
              ) : (
                ""
              )}
              {this.state.id ? (
                <Button
                  className={"ss-button-secondary"}
                  label={"ADD/EDIT ZONES"}
                  onClick={this.editZones}
                />
              ) : (
                ""
              )}
              <br />
              <fieldset className="hs-field position-relative">
                <label htmlFor="instructions">
                  FACILITY INSTRUCTIONS
                  <span
                    data-tip
                    data-for="facilityInstrTooltip"
                    className="location-name-tip"
                  >
                    {" "}
                    <i className="fa fa-question-circle" />
                  </span>
                </label>
                <ReactTooltip
                  id="facilityInstrTooltip"
                  type="success"
                  effect="solid"
                  // className="location-name-tip-hover location-tooltip-facility"
                >
                  <span>
                    These instructions will be provided to Customers in their
                    Booking confirmation email.
                  </span>
                </ReactTooltip>

                <textarea
                  id="instructions"
                  name="instructions"
                  value={this.state.instructions ? this.state.instructions : ""}
                  onChange={this.handleChange}
                  placeholder="Enter Instructions on how to access your facility"
                />
              </fieldset>

              <p className="ss-summary">
                <span className="border-bottom-blue-line">Overage Options</span>
              </p>
              <div className="flex-center m-b">
                <div className="location-items-column">
                  <label className={"ss-checkbox"}>
                    <input
                      type="checkbox"
                      name={"Charge Overages"}
                      onChange={_this.handleOverageChange}
                      checked={this.state.chargeOverages}
                      className="checkbox-outline"
                    />
                    <span>{"Charge Overages"}</span>
                  </label>
                </div>
              </div>

              {this.state.chargeOverages ? (
                <div>
                  <fieldset className={"ss-top hs-field"}>
                    <label htmlFor="overageRate">OVERAGE RATE</label>
                    <input
                      type="text"
                      id="overageRate"
                      name="overageRate"
                      value={
                        this.state.overageRate
                          ? formatCurrencyValue(this.state.overageRate)
                          : this.state.overageRate
                      }
                      onChange={this.handleChange}
                      style={{
                        width: "100%",
                      }}
                      maxLength={10}
                      autocomplete="off"
                      placeholder="Enter the overage rate charged for a single space"
                    />
                  </fieldset>
                  <fieldset className={"ss-top hs-field"}>
                    <label htmlFor="overageGracePeriodInMinutes">
                      OVERAGE GRACE PERIOD IN MINUTES
                    </label>
                    <input
                      type="number"
                      id="overageGracePeriodInMinutes"
                      name="overageGracePeriodInMinutes"
                      value={this.state.overageGracePeriodInMinutes}
                      onChange={this.handleChange}
                      style={{
                        width: "100%",
                      }}
                      autocomplete="off"
                      maxLength={10}
                      placeholder="Enter the overage grace period for a single space"
                    />
                  </fieldset>
                </div>
              ) : (
                ""
              )}

              <p className="ss-summary">
                <span className="border-bottom-blue-line">
                  Location Features
                </span>
              </p>
              {/*<p>Select which features apply to this location.</p>*/}
              <div className="flex-center m-b">
                <div className="location-items-column">
                  <OptionColumn
                    object={this.state.features}
                    startIndex="0"
                    endIndexExclusive="6"
                    onChange={_this.handleFeatureChange}
                  />
                </div>
                <div className="location-items-column">
                  <OptionColumn
                    object={this.state.features}
                    startIndex="6"
                    endIndexExclusive="999"
                    onChange={_this.handleFeatureChange}
                  />
                </div>
              </div>

              <p className="ss-summary">
                <span className="border-bottom-blue-line">
                  Booking Requirements
                </span>
              </p>
              <fieldset className="ss-top hs-field price-day">
                <label htmlFor="maxOneTimeSpaces">
                  MAXIMUM ONE TIME SPACES
                </label>
                <input
                  className="widthCustomVal"
                  type="text"
                  id="maxOneTimeSpaces"
                  name="maxOneTimeSpaces"
                  value={this.state.maxOneTimeSpaces}
                  onChange={this.handleChange}
                  maxLength={10}
                  autocomplete="off"
                  onInput={(e) => {
                    const value = e.target.value;
                    if (!/^\d*$/.test(value)) {
                      e.target.value = value.slice(0, -1);
                    }
                  }}
                  placeholder="Enter number of one time spaces."
                />
              </fieldset>
              <fieldset className="ss-top hs-field price-month bg-custom-light">
                <label htmlFor="country">MAXIMUM ONE TIME DURATION</label>
                <input
                  className="cursor-not-allowed"
                  type="text"
                  id="country"
                  name="country"
                  value="ONE TIME"
                  readOnly
                />
                {/* <Select
                  id="maxOneTimeDuration"
                  name="maxOneTimeDuration"
                  comp="location"
                  className="ss-book-space-form-asset-type"
                  handleChange={this.handleSelectChange}
                  selectedOption={this.state.maxOneTimeDuration}
                  placeholder="Select the maximum duration one time bookings."
                  options={[
                    new ReferenceOption("ONE TIME", "ONE TIME"),
                    new ReferenceOption("RECURRING", "RECURRING"),
                  ]}
                /> */}
              </fieldset>
              <fieldset className="ss-top hs-field price-day">
                <label htmlFor="maxRecurringSpaces">
                  MAXIMUM RECURRING SPACES
                </label>
                <input
                  className="widthCustomVal"
                  type="text"
                  onInput={(e) => {
                    const value = e.target.value;
                    if (!/^\d*$/.test(value)) {
                      e.target.value = value.slice(0, -1);
                    }
                  }}
                  id="maxRecurringSpaces"
                  name="maxRecurringSpaces"
                  value={this.state.maxRecurringSpaces}
                  onChange={this.handleChange}
                  autocomplete="off"
                  maxLength={10}
                  placeholder="Enter number of recurring spaces."
                />
              </fieldset>
              <fieldset className="ss-top hs-field price-month bg-custom-light">
                <label htmlFor="country"> MAXIMUM RECURRING DURATION</label>
                <input
                  type="text"
                  id="country"
                  name="country"
                  value="RECURRING"
                  readOnly
                  className="cursor-not-allowed"
                />
                {/* <Select
                  id="maxRecurringDuration"
                  name="maxRecurringDuration"
                  comp="location"
                  className="ss-book-space-form-asset-type"
                  handleChange={this.handleSelectChange}
                  selectedOption={this.state.maxRecurringDuration}
                  placeholder="Select the maximum duration one time bookings."
                  options={[
                    new ReferenceOption("ONE TIME", "ONE TIME"),
                    new ReferenceOption("RECURRING", "RECURRING"),
                  ]}
                /> */}
              </fieldset>

              {/*I created new components over the top of the awful file chooser input and tied it together with a*/}
              {/*label with a htmlFor attribute pointing at the file chooser.*/}
              <p className="ss-summary">
                <span className="border-bottom-blue-line">Listing image</span>
              </p>
              <p>
                Select an image file that will display on Supplier search
                results.
              </p>

              {/*<input type="file" id="listingImage" name="listingImage"*/}
              {/*onChange={this.handleChange}/>*/}
              {/*<span>{this.state.listingImage && this.state.listingImage.length > 0 ? this.state.listingImage[0].name : 'No file chosen'}</span>*/}

              <fieldset className="ss-top ss-dz">
                {/* <DropFile
                  bucket={FILE_BUCKET}
                  locationFile={this.state.listingImageFileName}
                  locationId={this.state.id}
                  updateDropzone={this.updateDropfile}
                /> */}
                <DropGallery
                  bucket={FILE_BUCKET}
                  locationGallery={
                    this.props.locationToEdit
                      ? [
                          {
                            galleryImageFileName:
                              this.state.listingImageFileName,
                          },
                        ]
                      : []
                  }
                  locationId={this.state.id}
                  updateDropzone={this.updateDropfile}
                  updateLocationListingImage={this.updateLocationListingImage} // Pass the function as a prop
                  comp="listingImage"
                />
              </fieldset>

              {/*{this.state.listingImageFileName ?*/}
              {/*<fieldset className="">*/}
              {/*<img alt="" className="img-responsive"*/}
              {/*/></fieldset> : ''}*/}

              <p className="ss-summary ss-top m-t">
                <span className="border-bottom-blue-line">Image gallery</span>
              </p>
              <p>Drop files here to upload</p>
              <fieldset className="ss-top ss-dz">
                <DropGallery
                  bucket={GALLERY_BUCKET}
                  locationGalleries={this.state.locationGalleries}
                  locationGallery={this?.state?.locationGallery || []}
                  locationId={this.props.account.id}
                  updateDropzone={this.updateDropzone}
                  updateLocationGalleries={this.updateLocationGalleries} // Pass the function as a prop
                  comp="imageGallery"
                />
              </fieldset>

              {!!this.state.errorMessage ? (
                <Error>{this.state.errorMessage}</Error>
              ) : (
                ""
              )}
            </div>
            <div className="modal-footer border-0">
              <div className="table text-left justify-content">
                <button
                  onClick={() => this.props.closeModal()}
                  type="button"
                  className="ss-button-primary-modal-form reverse"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="ss-button-primary-modal-form"
                  onClick={this.handleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function OptionColumn(props) {
  return (
    <div>
      {Object.keys(props.object).map((key, index) =>
        index >= props.startIndex && index < props.endIndexExclusive ? (
          <label className={"ss-checkbox"} key={key}>
            <input
              type="checkbox"
              name={key}
              className="checkbox-outline"
              onChange={props.onChange}
              checked={props.object[key].value}
            />
            <span>{props.object[key].label}</span>
          </label>
        ) : (
          ""
        )
      )}
    </div>
  );
}

export default withRouter(CreateEditLocation);
