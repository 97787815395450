import request from "../../../util/SuperagentUtils";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const getPaymentMethods = (accountId) => {
  return (
    request
      .get(`${baseUrl}/api/payment-method/${accountId}`)
      // .query({ accountId })
      .withCredentials()
  );
};

export const getAccount = (jwt) => {
  return request
    .get(`${baseUrl}/api/account`)
    .auth(jwt, { type: "bearer" })
    .withCredentials();
};
export const getSecurityDepositPaidAmount = (accountId) => {
  return request
    .get(`${baseUrl}/api/security-deposit-paid/${accountId}`)
    .withCredentials();
};
export const getActiveBookingAmount = (accountId) => {
  return request
    .get(`${baseUrl}/api/active-booking-amount/${accountId}`)
    .withCredentials();
};

export const getReservation = (reservationId) => {
  return request
    .get(`${baseUrl}/api/zone/reservation/${reservationId}`)
    .withCredentials();
};
export const getSecurityDepositPaid = (accountId, token) => {
  return request
    .get(`${baseUrl}/api/security-deposit/get-token/${accountId}/${token}`)
    .withCredentials();
};
export const getFineById = (fineId) => {
  return request
    .get(`${baseUrl}/api/fine-assessments/fine-by-id/${fineId}`)
    .withCredentials();
};

export const regenerateNewUrl = (reservationId) => {
  return request
    .post(
      `${baseUrl}/api/reservation/regenerate-payment-link/${reservationId}?isApproved=true`
    )
    .withCredentials();
};
export const getZone = (reservationId) => {
  return request.get(`${baseUrl}/api/zone/${reservationId}`).withCredentials();
};

export const completeReservation = (reservationId, paymentMethodId) => {
  return request
    .post(
      `${baseUrl}/api/zone/reservation/${reservationId}/complete/${paymentMethodId}`
    )
    .set("Accept", "application/json")
    .withCredentials();
};
