import React, { useEffect } from "react";
import { toast } from "react-toastify";

const Input = (props, ref) => {
  const {
    className,
    name,
    label,
    placeHolder,
    value,
    onChange,
    type,
    hide,
    disabled,
    id,
    startDate,
    activationDate,
    deactivationDate,
    setButtonDisabled,
    compName,
    activationDateMaxCheck,
  } = props;
  useEffect(() => {
    if (!!deactivationDate) {
      if (activationDate > deactivationDate) {
        if (!!setButtonDisabled) setButtonDisabled(true);
      }
    }
  }, []);

  const handleKeyDown = (e) => {
    if (type === "date") e.preventDefault();
  };
  const handleChange = (e) => {
    if (!!activationDate) {
      if (value < activationDate) {
        toast.error("Deactivation date must be greater than activation date");
      } else {
        onChange(e);
      }
    }
    onChange(e);
  };

  return (
    <div style={{ display: hide ? "none" : "block" }}>
      <label>{label}</label>
      {compName === "peakPricingCharge" ? (
        <span
          style={{
            position: "absolute",
            marginTop: "27px",
            marginLeft: "-200px",
          }}
        >
          $
        </span>
      ) : compName === "infractionAmount" ? (
        <span
          style={{
            position: "absolute",
            marginTop: "27px",
            marginLeft: "-116px",
          }}
        >
          $
        </span>
      ) : (
        <></>
      )}
      <input
        ref={ref}
        className={className}
        type={type}
        name={name}
        placeholder={placeHolder}
        value={value}
        disabled={disabled}
        onChange={(e) => handleChange(e)}
        onKeyDown={handleKeyDown}
        min={
          id !== "endDate"
            ? activationDate != "MM/dd/yyyy" && !!activationDate
              ? activationDate
              : new Date().toISOString().split("T")[0]
            : startDate != "" && !!startDate
            ? startDate
            : value != ""
            ? !!value
              ? value
              : new Date().toISOString().split("T")[0]
            : new Date().toISOString().split("T")[0]
        }
        max={
          activationDateMaxCheck == "activationDateMaxCheck"
            ? deactivationDate != "MM/dd/yyyy"
              ? deactivationDate
              : ""
            : ""
        }
      />
    </div>
  );
};

export default React.forwardRef(Input);
