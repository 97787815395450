import React from "react";
import "../../css/components/report/report-header.css";
import * as PropTypes from "prop-types";

const ReportHeader = (props) => {
  const { children, parentMenu, type } = props;
  return (
    <header style={type === "securityDeposit" ? { paddingBottom: "0px" } : {}}>
      <ul className="breadcrumb">
        {parentMenu && (
          <>
            <li>{parentMenu}</li>
            <li>{children}</li>
          </>
        )}
      </ul>
      <h1 className="ss-report-header-title">{children}</h1>
    </header>
  );
};

ReportHeader.propTypes = {
  parentMenu: PropTypes.any,
};

export default ReportHeader;
