import React, { Component } from "react";
import "../css/theme/mainContent.css";
import "../css/theme/forms.css";
import "../css/theme/forms-block.css";
import "../css/components/badge.css";
import "../css/components/report/report-form-date.css";
import "../css/views/accountReport.css";
import "../css/views/search.css";
import Busy from "../components/Busy";
import { createLogoutOnFailureHandler } from "../util/LogoutUtil";
import DataList from "./DataList";
import DateArrow from "../components/DateArrow";
import Select from "../components/Select";
import { ajaxRequest } from "../ajax";
import { toast } from "react-toastify";
import calenderIcon from "../img/calendar.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReportEmpty from "../components/report/ReportEmpty";
import ReportFormInputLabel from "../components/report/ReportFormInputLabel";
import moment from "moment";
import { extractDay } from "../components/constants/securspace-constants";
import { networkErrorIssue } from "./thirdparty/checkout/checkoutConstants";

const $ = window.$;
const DATE_INPUT_FORMAT = "MM-DD-YYYY";

class AccountReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      criteriaFieldOptions: [],
      selectedCriteriaFieldValue: this.props.criteriaField ? "All" : "",
      startDate: AccountReport.addDaysToToday(
        -this.props.defaultDaysInDateRange
      ),
      endDate: AccountReport.addDaysToToday(
        this.props.defaultEndDateIsToday ? 0 : this.props.defaultDaysInDateRange
      ),
      reportList: [],
      filteredReportList: [],
    };

    this.labels = {};
    this.props.reportFields.forEach((field) => {
      if (field.label) {
        this.labels[field.name] = field.label;
      }
    });

    // Class property to track component mount status
    this._isMounted = false;
  }

  static addDaysToToday(numberOfDaysToAdd) {
    return AccountReport.addDaysToDate(new Date(), numberOfDaysToAdd);
  }

  static addDaysToDate(date, numberOfDaysToAdd) {
    date.setDate(date.getDate() + numberOfDaysToAdd);
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0
    let yyyy = date.getFullYear();
    return mm + "/" + dd + "/" + yyyy;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.account !== this.props.account ||
      nextProps.data !== this.props.data
    ) {
      //this.initDatePickers();
      this.loadReportData(nextProps.account, nextProps.data);
    } else if (!this.props.reloadData && nextProps.reloadData) {
      this.loadReportData(nextProps.account, nextProps.data);
    }
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.getReportDataUrl) {
      // this.initDatePickers();
      this.loadReportData(this.props.account, this.props.data);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // initDatePickers() {
  //   $("#startDate")
  //     .datepicker({ format: "m/d/yyyy", orientation: "bottom" })
  //     .on("changeDate", this.handleChange);
  //   $("#endDate")
  //     .datepicker({ format: "m/d/yyyy", orientation: "bottom" })
  //     .on("changeDate", this.handleChange);
  //   $("#datesFieldset").datepicker({
  //     inputs: $("#startDate, #endDate"),
  //   });
  // }

  checkDateValues = (event, nameOfDate) => {
    let account = this.props.account;
    // let name = event.target.name;
    // let value = event.target.value;
    // console.log("value", value);
    let name = nameOfDate;
    let value = moment(event).format("YYYY-MM-DD");

    //event?.preventDefault();
    let day1 = extractDay(value);
    let day2 =
      nameOfDate === "endDate"
        ? extractDay(this.state.endDate)
        : extractDay(this.state.startDate);

    this.setState({ [name]: value }, () => {
      let diff = Math.floor(
        (Date.parse(this.state.endDate) - Date.parse(this.state.startDate)) /
          86400000
      );
      if (
        this.props.maxDateRangeInDays &&
        diff > this.props.maxDateRangeInDays
      ) {
        let adjustInterval = diff - this.props.maxDateRangeInDays;
        if (name === "startDate") {
          let endDate = new Date(this.state.endDate);
          this.setState({
            endDate: AccountReport.addDaysToDate(endDate, -adjustInterval),
          });
        } else if (name === "endDate") {
          let startDate = new Date(this.state.startDate);
          this.setState({
            startDate: AccountReport.addDaysToDate(startDate, adjustInterval),
          });
        }
      }

      if (account && account.id) {
        Busy.set(true);
        ajaxRequest({
          url: this.props.getReportDataUrl(
            account,
            moment(this.state.startDate).format("M/D/YYYY"),
            moment(this.state.endDate).format("M/D/YYYY")
          ),
          type: "GET",
          contentType: "application/json; charset=UTF-8",
          success: this.handleSuccess,
          statusCode: {
            401: createLogoutOnFailureHandler(this.props.handleLogout),
          },
          error: this.handleFailure,
        });
      }
    });
  };

  handleChange = (event) => {
    let name = event.target.name;
    if (name === "startDate" || name === "endDate") {
      this.checkDateValues(event, name);
    } else {
      this.setState({ [name]: event.target.value }, () =>
        this.filterByDatesAndCriteria()
      );
    }
  };

  loadReportData = (account, data) => {
    if (data) {
      this.setState(
        {
          criteriaFieldOptions: this.props.criteriaField
            ? [
                "All",
                ...[
                  ...new Set(
                    data.map((item) => {
                      let criteriaFieldParts =
                        this.props.criteriaField.split(".");
                      return criteriaFieldParts.length === 1
                        ? item[criteriaFieldParts[0]]
                        : item[criteriaFieldParts[0]][criteriaFieldParts[1]];
                    })
                  ),
                ].sort(),
              ]
            : [],
          reportList: data,
        },
        () => this.filterByDatesAndCriteria()
      );
    } else if (this.props.getReportDataUrl) {
      if (account && account.id) {
        Busy.set(true);
        ajaxRequest({
          url: this.props.getReportDataUrl(
            account,
            this.state.startDate,
            this.state.endDate
          ),
          type: "GET",
          contentType: "application/json; charset=UTF-8",
          success: this.handleSuccess,
          statusCode: {
            401: createLogoutOnFailureHandler(this.props.handleLogout),
          },
          error: this.handleFailure,
        });
      }
    }
  };

  filterByDatesAndCriteria = () => {
    setTimeout(this.doFilterByDatesAndCriteria);
  };

  doFilterByDatesAndCriteria = () => {
    let filteredList;
    if (
      this.state.reportList &&
      this.state.reportList.length &&
      this.state.reportList.length > 0
    ) {
      if (this.props.dateField || this.props.criteriaField) {
        let criteriaField = this.props.criteriaField;
        let dateField = this.props.dateField;
        let selectedCriteriaFieldValue = this.state.selectedCriteriaFieldValue;
        let startDate = new Date(this.state.startDate);
        let endDate = moment(new Date(this.state.endDate))
          .endOf("day")
          .toDate();

        filteredList = this.state.reportList.filter((reportListItem) => {
          let criteriaMatches = false;
          if (criteriaField) {
            let criteriaFieldParts = this.props.criteriaField.split(".");
            let reportListItemCriteriaFieldValue =
              criteriaFieldParts.length === 1
                ? reportListItem[criteriaFieldParts[0]]
                : reportListItem[criteriaFieldParts[0]][criteriaFieldParts[1]];
            criteriaMatches =
              selectedCriteriaFieldValue &&
              ("All" === selectedCriteriaFieldValue ||
                reportListItemCriteriaFieldValue ===
                  selectedCriteriaFieldValue);
          } else {
            criteriaMatches = true;
          }
          let dateMatches = false;
          if (dateField) {
            let fieldValue = reportListItem[dateField];
            if (fieldValue) {
              let dateFieldValue = new Date(fieldValue);
              dateMatches =
                dateFieldValue >= startDate && dateFieldValue <= endDate;
            } else {
              dateMatches = true;
            }
          } else {
            dateMatches = true;
          }
          return criteriaMatches && dateMatches;
        });
      } else {
        filteredList = this.state.reportList;
      }
    } else {
      filteredList = [];
    }
    if (this._isMounted) {
      this.setState({ filteredReportList: filteredList });
      Busy.set(false);
    }
  };

  handleSuccess = (data) => {
    if (this._isMounted) {
      this.setState(
        {
          criteriaFieldOptions: this.props.criteriaField
            ? [
                "All",
                ...[
                  ...new Set(
                    data.map((item) => {
                      let criteriaFieldParts =
                        this.props.criteriaField.split(".");
                      return criteriaFieldParts.length === 1
                        ? item[criteriaFieldParts[0]]
                        : item[criteriaFieldParts[0]][criteriaFieldParts[1]];
                    })
                  ),
                ].sort(),
              ]
            : [],
          reportList: data,
        },
        () => {
          this.filterByDatesAndCriteria();
        }
      );
      if (this.props.dataReloaded) {
        this.props.dataReloaded();
      }
    }
  };

  handleFailure = (jqXHR) => {
    if (this.props.dataReloaded) {
      this.props.dataReloaded();
    }
    Busy.set(false);
    if (!window.navigator.onLine) {
      toast.error(networkErrorIssue);
      return;
    }
    let errorMessage = jqXHR.responseJSON
      ? jqXHR.responseJSON.message
      : "Internal Server Error";
    errorMessage = errorMessage ? errorMessage.trim() : errorMessage;
    toast.error(errorMessage);
  };

  render() {
    return (
      <div className="grey-bg hs-bookings-container h-100">
        <div>
          <header>
            <ul className="breadcrumb">
              <li>
                {this.props.parentMenu ? this.props.parentMenu : "Reports"}
              </li>
              <li>
                {this.props.parentMenu === "Reports"
                  ? "ACTIVITY SUMMARY"
                  : this.props.title}
              </li>
            </ul>
            <h1 className="content-header-title">{this.props.title}</h1>
          </header>

          {this.props.criteriaField || this.props.dateField ? (
            <>
              <form
                className="ss-form ss-block"
                style={
                  this.props.criteriaField && this.props.dateField
                    ? { display: "flex", justifyContent: "space-between" }
                    : { width: "49%" }
                }
              >
                {this.props.criteriaField && (
                  <fieldset
                    id="datesFieldset"
                    style={{
                      padding: "0px 10px 0 10px",
                      border: "none",
                      borderBottom: "solid 1px #dddddd",
                    }}
                  >
                    <div style={{ width: "49%" }}>
                      <fieldset
                        className="ss-fieldset-row-inner-left"
                        style={{ marginLeft: "10px", marginRight: "30px" }}
                      >
                        <label
                          style={{ marginTop: "4px", paddingBottom: "5px" }}
                        >
                          {this.labels[this.props.criteriaField]
                            ? this.labels[
                                this.props.criteriaField
                              ].toUpperCase()
                            : ""}
                        </label>
                        <div
                          style={{
                            border: "2px solid black",
                            width: "350px",
                            height: "31px",
                            borderRadius: "5px",
                            marginBottom: "8px",
                          }}
                        >
                          <Select
                            id="selectedCriteriaFieldValue"
                            name="selectedCriteriaFieldValue"
                            style={{ width: "400px" }}
                            handleChange={this.handleChange}
                            selectedOption={
                              this.state.selectedCriteriaFieldValue
                            }
                            placeholder="Please select"
                            options={this.state.criteriaFieldOptions}
                          />
                        </div>
                      </fieldset>
                    </div>
                  </fieldset>
                )}
                {this.props.dateField && (
                  <fieldset
                    id="datesFieldset"
                    style={
                      this.props.showEndDate
                        ? {
                            padding: "0px 10px 0 10px",
                            border: "none",
                            borderBottom: "solid 1px #dddddd",
                          }
                        : {
                            padding: "0px 10px 0 10px",
                            border: "none",
                            borderBottom: "solid 1px #dddddd",
                            marginRight: "26rem",
                          }
                    }
                  >
                    <div>
                      <fieldset
                        className="ss-report-form-date-fieldset"
                        style={{ border: "none", marginTop: "-10px" }}
                      >
                        <ReportFormInputLabel label="START DATE" />
                        <DatePicker
                          selected={moment(this.state.startDate).format(
                            DATE_INPUT_FORMAT
                          )}
                          className="ss-report-form-date caret-color"
                          onChange={(date) =>
                            this.checkDateValues(date, "startDate")
                          }
                          maxDate={
                            this.props.showEndDate
                              ? moment(this.state.endDate).format("YYYY-MM-DD")
                              : ""
                          }
                          onKeyDown={(e) => e.preventDefault()} // Prevent manual input
                        />

                        {/* <input
                          type="date"
                          className="ss-report-form-date"
                          name="startDate"
                          placeholder="MM/DD/YYYY"
                          value={moment(this.state.startDate).format(
                            DATE_INPUT_FORMAT
                          )}
                          max={
                            this.props.showEndDate
                              ? moment(this.state.endDate).format("YYYY-MM-DD")
                              : ""
                          }
                          style={{ width: "auto" }}
                          onChange={this.handleChange}
                          onKeyDown={(e) => e.preventDefault()} // Prevent manual input
                        /> */}
                      </fieldset>
                      {this.props.showEndDate && (
                        <fieldset
                          className="ss-report-form-date-fieldset"
                          style={{ border: "none", marginTop: "-10px" }}
                        >
                          <ReportFormInputLabel label="END DATE" />
                          <DatePicker
                            selected={moment(this.state.endDate).format(
                              DATE_INPUT_FORMAT
                            )}
                            className="ss-report-form-date caret-color"
                            onChange={(date) =>
                              this.checkDateValues(date, "endDate")
                            }
                            minDate={moment(this.state.startDate).format(
                              "YYYY-MM-DD"
                            )}
                            onKeyDown={(e) => e.preventDefault()} // Prevent manual input
                          />
                          {/* <input
                            type="date"
                            className="ss-report-form-date"
                            name="endDate"
                            placeholder="MM/DD/YYYY"
                            value={moment(this.state.endDate).format(
                              DATE_INPUT_FORMAT
                            )}
                            min={moment(this.state.startDate).format(
                              "YYYY-MM-DD"
                            )}
                            style={{ width: "auto" }}
                            onChange={this.handleChange}
                            onKeyDown={(e) => e.preventDefault()} // Prevent manual input
                          /> */}
                        </fieldset>
                      )}
                    </div>
                  </fieldset>
                )}
              </form>
              <hr />
            </>
          ) : (
            ""
          )}
          {!!this.state.filteredReportList &&
          this.state.filteredReportList.length > 0 ? (
            <DataList
              dataType={this.props.title}
              columnWidth={this.props.columnWidth}
              reportFields={this.props.reportFields}
              data={this.state.filteredReportList}
              defaultGroupBy={this.props.defaultGroupBy}
              defaultGroupSortDirction={this.props.defaultGroupSortDirction}
              defaultSortBy={this.props.defaultSortBy}
              defaultSortByDirection={this.props.defaultSortByDirection}
              visibleRecordBatchSize={this.props.visibleRecordBatchSize}
              actions={this.props.actionList}
              payOutGroupFunction={this.props.payOutGroupFunction}
              hideGroupBy={this.props.hideGroupBy}
              groupSummaryFields={this.props.groupSummaryFields}
              initialSearchText={this.props.initialSearchText}
              accumulateLocations={this.props.accumulateLocations}
              shouldShowAccumulateButton={this.props.shouldShowAccumulateButton}
              defaultAccumulate={this.props.defaultAccumulate}
              handleAccumulateLocations={this.props.handleAccumulateLocations}
            />
          ) : (
            <ReportEmpty />
          )}
        </div>
      </div>
    );
  }
}

export default AccountReport;
