import React, { useEffect, useState } from "react";
import ReportBackgroundContainer from "../components/report/ReportBackgroundContainer";
import { AdminInvoiceListWithGroupAndSort } from "../components/invoice/admin/AdminInvoiceList";
import ReportFormContainer from "../components/report/ReportFormContainer";
import ReportFormDateInput from "../components/report/ReportFormDateInput";
import { DateFormats } from "../components/constants/securspace-constants";
import moment from "moment";
import Filter from "./bookings/Filter";
import { searchRecordsByKeywordInvoice } from "../util/SearchUtils";
import { requestAdminInvoices } from "../components/invoice/requests/invoice-requests";
import { toastErrorMessage } from "../util/NetworkErrorUtil";
import ReportEmpty from "../components/report/ReportEmpty";
import { extractDay } from "../components/constants/securspace-constants";
import Busy from "../components/Busy";
import "../App.css";

const RevenueReconciliationReport = () => {
  const [invoices, setInvoices] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().subtract(30, "days").format(DateFormats.DAY)
  );
  const [endDate, setEndDate] = useState(moment().format(DateFormats.DAY));
  const [searchBox, setSearchBox] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (startDate && endDate) {
      Busy.set(true);
      requestAdminInvoices(startDate, endDate)
        .then((response) => {
          setInvoices(
            response?.body?.sort(
              (a, b) => b?.transactionNumber - a?.transactionNumber
            )
          );
          Busy.set(false);
        }, toastErrorMessage)
        .catch(() => {
          Busy.set(false);
          toastErrorMessage;
        });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (invoices) {
      setFilteredData(searchRecordsByKeywordInvoice(searchBox, invoices));
    }
  }, [searchBox, invoices]);

  const handleSearchBox = (event) => {
    setSearchBox(event.target.value);
  };

  const reportFields = [
    { label: "INVOICE NUMBER", name: "transactionNumber" },
    { label: "CUSTOMER AMOUNT", name: "buyerAmount" },
    { label: "PAYMENT PROCESSOR AMOUNT", name: "paymentProcessorFees" },
    { label: "PARTNER AMOUNT", name: "supplierAmount" },
    { label: "BOOKING STATE", name: "locationState" },
    { label: "CHARGE TYPE", name: "transactionType" },
    { label: "INVOICE CREATED", name: "createdOn" },
    { label: "PAYMENT TYPE", name: "paymentType" },
    { label: "PAYMENT INITIATED", name: "paymentCreatedOn" },
    { label: "PAYMENT COMPLETED", name: "paymentCompletedOn" },
    { label: "CUSTOMER", name: "buyerEmail" },
    { label: "LOCATION", name: "locationName" },
    { label: "PARTNER", name: "supplierCompanyName" },
    { label: "BOOKING", name: "bookingNumber" },
    { label: "SPACES", name: "numberOfSpaces" },
    { label: "STATUS", name: "status" },
    { label: "BOOKING CREATED ON", name: "bookingCreatedOn" },
    { label: "BOOKING CREATED BY", name: "bookingCreatedBy" },
  ];

  const handleStartDate = (date) => {
    let day1 = extractDay(date);
    let day2 = extractDay(startDate);

    // if (!(day1 === day2)) {
    //   setStartDate(date);
    // }
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    let day1 = extractDay(date);
    let day2 = extractDay(endDate);

    // if (!(day1 === day2)) {
    //   setEndDate(date);
    // }
    setEndDate(date);
  };

  return (
    <ReportBackgroundContainer>
      <header>
        <ul className="breadcrumb">
          <li>Report</li>
          <li>Revenue Reconciliation</li>
        </ul>
        <h1 className="ss-report-header-title">
          Revenue Reconciliation Report
        </h1>
      </header>
      <div className="width49">
        <ReportFormContainer>
          <ReportFormDateInput
            label={"START DATE:"}
            date={startDate}
            onDateChange={handleStartDate}
          />
          <ReportFormDateInput
            label={"END DATE:"}
            date={endDate}
            onDateChange={handleEndDate}
          />
        </ReportFormContainer>
      </div>
      {!!invoices && invoices?.length > 0 ? (
        <>
          <Filter
            searchBox={searchBox}
            searchChangeHandler={handleSearchBox}
            placeHolder="Type any field to filter revenue reconcillation result"
          />
          {filteredData?.length > 0 && (
            <AdminInvoiceListWithGroupAndSort
              invoices={filteredData}
              reportFields={reportFields}
              reportName="Revenue Reconciliation Report"
            />
          )}
          {filteredData?.length === 0 && <ReportEmpty />}
          {filteredData?.length > 0 && (
            <div className="text-center"> You have reached end of the list</div>
          )}
          <div className="ss-supplier-active-bookings-endlist">
            <h6>{!!filteredData && filteredData.length > 0 ? " " : " "}</h6>
          </div>
        </>
      ) : (
        <ReportEmpty />
      )}
    </ReportBackgroundContainer>
  );
};

export default RevenueReconciliationReport;
