import React from "react";
import ReportFlexRightContainer from "../../../report/ReportFlexRightContainer";
import ReportKebabButton from "../../../report/ReportKebabButton";
import { MenuItem } from "@material-ui/core";
import {
  deleteFineAssessment,
  resendFineAssessmentLink,
  refundFineAssessment,
} from "../../../checkin/request/fine-request";
import { toast } from "react-toastify";
import { getErrorMessageForStandardResponse } from "../../../../util/NetworkErrorUtil";
import Busy from "../../../Busy";

const FineAssessmentsMenu = (props) => {
  const { fineAssessment, onFineAssessmentUpdate } = props;

  const handleCancelFineAssessment = () => {
    Busy.set(true);

    deleteFineAssessment(fineAssessment.id)
      .then(
        () => {
          Busy.set(false);

          onFineAssessmentUpdate(fineAssessment.id);
          toast.success("Fine has been cancelled successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
        (error) => {
          Busy.set(false);
          toast.error(getErrorMessageForStandardResponse(error));
        }
      )
      .catch((error) => {
        Busy.set(false);
        toast.error(getErrorMessageForStandardResponse(error));
      });
  };
  const handleResendFineAssessmentLink = () => {
    Busy.set(true);

    resendFineAssessmentLink(fineAssessment.id)
      .then(
        (res) => {
          Busy.set(false);

          onFineAssessmentUpdate(fineAssessment.id);
          toast.success(res?.text, {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
        (error) => {
          Busy.set(false);
          toast.error(getErrorMessageForStandardResponse(error));
        }
      )
      .catch((error) => {
        Busy.set(false);
        toast.error(getErrorMessageForStandardResponse(error));
      });
  };

  const handleRefundFineAssessment = () => {
    if (!fineAssessment.refund) {
      Busy.set(true);
      refundFineAssessment(fineAssessment.id)
        .then(
          (res) => {
            Busy.set(false);
            onFineAssessmentUpdate(fineAssessment.id);
            toast.success("Fine refund successfully processed.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          },
          (error) => {
            Busy.set(false);
            toast.error(getErrorMessageForStandardResponse(error));
          }
        )
        .catch((error) => {
          Busy.set(false);
          toast.error(getErrorMessageForStandardResponse(error));
        });
    } else {
      toast("Assessment is already marked to process for refund");
    }
  };

  return (
    <ReportFlexRightContainer>
      <ReportKebabButton>
        {fineAssessment?.processed ? (
          <MenuItem onClick={handleRefundFineAssessment}>
            Refund Fine Assessment
          </MenuItem>
        ) : (
          <>
            <MenuItem onClick={handleCancelFineAssessment}>
              Cancel Fine Assessment
            </MenuItem>
            <MenuItem onClick={handleResendFineAssessmentLink}>
              Resend Fine Payment Link
            </MenuItem>
          </>
        )}
      </ReportKebabButton>
    </ReportFlexRightContainer>
  );
};

export default FineAssessmentsMenu;
