import React, { useState } from "react";
import ReportDataRow from "../report/ReportDataRow";
import ReportData from "../report/ReportData";
import { formatCurrencyValue } from "../../util/PaymentUtils";
import getDisplayValue from "../SupplierTransactionStatus";
import { Modal, makeStyles } from "@material-ui/core";
import { formatBookingTransaction } from "../../util/InvoiceUtils";
import { formatEquipmentLength } from "../../util/booking/bookingUtil";
import {
  DateFormats,
  getCardName,
  InvoiceStatusType,
} from "../constants/securspace-constants";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "100%",
    height: "100%",
    overflow: "scroll",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  close: {
    color: "black",
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    marginLeft: "auto",
    fontSize: "3em",
    position: "absolute",
    top: "-0.2em",
    right: "0.2em",
    zIndex: "9999",
    paddingBottom: "1.2em",
    "&:focus": {
      outline: "none",
    },
  },
  modalContent: {
    width: "30em",
    position: "relative",
    backgroundColor: "aliceblue",
    padding: "5px",
  },
}));

const InvoiceCard = (props) => {
  const { invoice } = props;
  const [showModal, setShowModal] = useState(false);
  const classes = useStyles();
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      {invoice && (
        <ReportDataRow>
          <ReportData label={"CUSTOMER"}>
            {invoice?.buyerCompanyName || "-"}
          </ReportData>
          <ReportData label={"TYPE"}>
            {!!invoice?.transactionType
              ? formatBookingTransaction(invoice?.transactionType)
              : "-"}
          </ReportData>
          <ReportData label={"SPACES"}>
            {invoice?.numberOfSpaces || "-"}
          </ReportData>
          <ReportData label={"AMOUNT"}>
            {invoice?.supplierAmount
              ? formatCurrencyValue(invoice.supplierAmount)
              : "-"}
          </ReportData>

          <ReportData label={"STATUS"}>
            {!!invoice?.status ? InvoiceStatusType[invoice?.status] : "-"}
            <span onClick={() => setShowModal(true)}>
              {" "}
              <i className="fa fa-info-circle" />
            </span>
          </ReportData>
          <ReportData label={"SERVICE DATES"}>
            {invoice?.serviceDates || "-"}
          </ReportData>
          <ReportData label={"INVOICE CREATED"}>
            {invoice?.createdOn || "-"}
          </ReportData>
          <ReportData label={"PAYMENT INITIATED"}>
            {invoice?.paymentCreatedOn || "-"}
          </ReportData>
          <ReportData label={"PAYMENT COMPLETED"}>
            {invoice?.paymentCompletedOn || "-"}
          </ReportData>
          <ReportData label={"INVOICE NUMBER"}>
            {invoice?.transactionNumber || "-"}
          </ReportData>
          <ReportData label={"PAYMENT TYPE"}>
            {invoice?.paymentType || "-"}
          </ReportData>

          <ReportData label={"CHARGED DATE"}>
            {invoice?.createdOn || "-"}
          </ReportData>

          <ReportData label={"BOOKING NUMBER"}>
            {invoice?.bookingNumber || "-"}
          </ReportData>
          <ReportData label={"Equipment Length"}>
            {invoice?.equipmentLength
              ? formatEquipmentLength(invoice.equipmentLength)
              : "-"}
          </ReportData>
          <ReportData label={"LOCATION NAME"}>
            {invoice?.locationName || "-"}
          </ReportData>
          <ReportData label={"TRANSACTION ID"}>
            {invoice?.tsysTransactionId || "-"}
          </ReportData>
          <ReportData label={"PAYMENT REFUNDED DATE"}>
            {!!invoice?.paymentRefundDate
              ? moment(invoice?.paymentRefundDate).format(DateFormats.DAY)
              : "-"}
          </ReportData>
        </ReportDataRow>
      )}
      <Modal open={showModal} onClose={handleClose} className={classes.modal}>
        <div className={classes.modalContent}>
          <button
            type="button"
            className={classes.close}
            aria-label="close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <ReportData label={"TSYS CARD TYPE"}>
            {!!invoice?.tsysCardType ? getCardName(invoice?.tsysCardType) : "-"}
          </ReportData>

          <ReportData label={"TSYS TOTAL AMOUNT"}>
            {invoice?.tsysTotalAmount
              ? formatCurrencyValue(invoice?.tsysTotalAmount)
              : "-"}
          </ReportData>
          <ReportData label={"TSYS PROCESSED AMOUNT"}>
            {invoice?.tsysProcessedAmount
              ? formatCurrencyValue(invoice?.tsysProcessedAmount)
              : "-"}
          </ReportData>

          <ReportData label={"TSYS TRANSACTION AMOUNT"}>
            {invoice?.tsysTransactionAmount
              ? formatCurrencyValue(invoice?.tsysTransactionAmount)
              : "-"}
          </ReportData>

          <ReportData label={"TSYS PAYOUT COMPLETd ON"}>
            {!!invoice?.tsysPayoutCompletedOn
              ? moment(invoice?.tsysPayoutCompletedOn).format(DateFormats.DAY)
              : "-"}{" "}
          </ReportData>
          <ReportData label={"TSYS RESPONSE MESSAGE"}>
            {invoice?.tsysResponseMessage || "-"}
          </ReportData>
          <ReportData label={"TSYS STATUS"}>
            {invoice?.tsysStatus || "-"}
          </ReportData>
          <ReportData label={"TSYS ID"}>
            {invoice?.tsysTaskId || "-"}
          </ReportData>
          <ReportData label={"TSYS TRANSACTION ID"}>
            {invoice?.tsysTransactionId || "-"}
          </ReportData>
        </div>
      </Modal>
    </>
  );
};

export default InvoiceCard;
