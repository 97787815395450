import React from "react";
import ReportRow from "../../components/report/ReportRow";
import BookingCard from "./BookingCard";
import BookingReportKebabButton from "./BookingReportKebabButton";
import { isRoleApproved, MANAGER_EDIT_ROLES } from "../../util/user/UserUtil";

const BookingList = (props) => {
  const { account, bookings, setShow, setBookingId, setOrderNumber } = props;

  return (
    <div>
      {bookings &&
        bookings.map((booking) => {
          return (
            <div key={booking.id}>
              <ReportRow>
                {isRoleApproved(MANAGER_EDIT_ROLES, account?.userType) && (
                  <BookingReportKebabButton
                    userType={account?.userType}
                    bookingId={booking.id}
                    booking={booking}
                    setShow={setShow}
                    setBookingId={setBookingId}
                    setOrderNumber={setOrderNumber}
                  />
                )}
                <BookingCard booking={booking} />
              </ReportRow>
            </div>
          );
        })}
      <div className="ss-load-more-records">
        You have reached end of the list
      </div>
    </div>
  );
};

export default BookingList;
