import React, { useEffect, useState } from "react";
import StepWizard from "react-step-wizard";
import { useHistory } from "react-router-dom";
import CoordinatesBuilder from "./CoordinatesBuilder";
import LocationIdSelect from "../../fines/LocationIdSelect";
import ZoneNameNumberSpacesPowerStep from "./ZoneNameNumberSpacesPowerStep";
import EquipmentLengths from "./EquipmentLengths";
import ActivationDates from "./ActivationDates";
import Price from "./Price";
import AllowBookings from "./AllowBookings";
import ViewZone from "./ViewZone";
import { zoneEdit } from "../zoneRequest";
import { toast } from "react-toastify";
import { getErrorMessageForStandardResponse } from "../../../util/NetworkErrorUtil";
import { withRouter } from "react-router";
import AutoApprovedZone from "./AutoApprovedZone";
import StepWizardContainer from "../../stepWizard/StepWizardContainer";
import ValidateBookings from "./ValidateBookings";
import {
  formatDateWithHIYYMMDD,
  formatDateYYMMDD,
} from "../../../util/DateUtils";
import { getLocations } from "../../checkin/request/fine-request";
import Busy from "../../Busy";

const ZoneEditor = (props) => {
  const [zone] = useState(
    !!props?.location?.state?.zone
      ? props?.location?.state?.zone
      : JSON.parse(localStorage.getItem("zone"))
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [locationId, setLocationId] = useState(zone ? zone.locationId : "");
  const [locations, setLocations] = useState([]);
  const [showToastMsg, setShowToastMsg] = useState(false);
  const [zoneName, setZoneName] = useState(zone ? zone.name : "Zone Name");
  const [totalSpaces, setTotalSpaces] = useState(zone ? zone.totalSpaces : 1);
  const [powerAccessible, setPowerAccessible] = useState(
    zone ? zone.powerAccessible : false
  );
  const [equipmentLengths] = useState([
    { label: "25' MAX (Truck Only)", value: "TWENTY_FIVE_FOOT" },
    { label: "45' MAX (Small Container)", value: "FORTY_FIVE_FOOT" },
    { label: "53' MAX (Large Container)", value: "FIFTY_THREE_FOOT" },
    { label: "83' MAX (Truck + Trailer)", value: "EIGHTY_THREE_FOOT" },
  ]);
  const [selectedEquipmentLength, setSelectedEquipmentLength] = useState(
    zone ? zone.equipmentLength : equipmentLengths[0].value
  );

  const [active, setActive] = useState(zone ? zone.active : false);
  const [activationDate, setActivationDate] = useState(
    zone && zone.activationDate ? zone.activationDate : "MM/dd/yyyy"
  );
  const [deactivationDate, setDeactivationDate] = useState(
    zone && zone.deactivationDate ? zone.deactivationDate : "MM/dd/yyyy"
  );
  const [inactiveReason, setInactiveReason] = useState(
    zone?.inactiveReason ? zone.inactiveReason : ""
  );
  const [showInactiveReason, setShowInactiveReason] = useState(
    !!zone?.inactiveReason
  );
  const [selectedZoneName, setSelectedZoneName] = useState();

  const [pricePerDayInCents, setPricePerDayInCents] = useState(
    zone ? zone.pricePerDayInCents || 0.0 : 100
  );
  const [pricePerWeekInCents, setPricePerWeekInCents] = useState(
    zone ? zone.pricePerWeekInCents || 0.0 : 100
  );
  const [pricePerMonthInCents, setPricePerMonthInCents] = useState(
    zone ? zone.pricePerMonthInCents || 0.0 : 100
  );

  const [allowOneTimeBookings, setAllowOneTimeBookings] = useState(
    zone ? zone.allowOneTimeBookings : false
  );
  const [allowWeeklyBookings, setAllowWeeklyBookings] = useState(
    zone ? zone.allowWeeklyBookings : false
  );
  const [allowMonthlyBookings, setAllowMonthlyBookings] = useState(
    zone ? zone.allowMonthlyBookings : false
  );

  const [autoApproved, setAutoApproved] = useState(
    zone ? zone.autoApproved : false
  );

  const [coordinates, setCoordinates] = useState(zone ? zone.coordinates : []);

  let history = useHistory();

  useEffect(() => {
    getLocations()
      .then(
        (promise) => {
          setLocations(promise.body);
        },
        (error) => {
          toast.error(getErrorMessageForStandardResponse(error));
        }
      )
      .catch((error) => {
        toast.error(getErrorMessageForStandardResponse(error));
      });

    // if (locationId) {
    //   setButtonDisabled(false);
    // }
  }, [setLocations, locationId]);
  // useEffect(() => {
  //   if (selectedZoneName?.length > 0) {
  //     if (selectedZoneName?.some((ele) => ele === zoneName)) {
  //       toast.error("Zone Name should not be same", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     }
  //   }
  // }, [zoneName, selectedZoneName]);

  const onLocationIdChange = (event) => {
    setLocationId(event.id);
  };

  const onZoneNameChange = (event) => {
    if (selectedZoneName?.length > 0) {
      if (
        selectedZoneName?.some((ele) => ele === event.target.value) &&
        zone.name !== event.target.value
      ) {
        setShowToastMsg(true);
        toast.error("Zone Name should not be same", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        setShowToastMsg(false);
      }
    }
    setZoneName(event.target.value);
  };

  const onTotalSpacesChange = (event) => {
    const { value } = event.target;
    const numValue = Number.parseInt(value);
    if (value === "") {
      setTotalSpaces(value);
    } else if (numValue > -1) {
      setTotalSpaces(numValue);
    }
  };

  const onPowerAccessibleChange = () => {
    setPowerAccessible(!powerAccessible);
  };

  const onEquipmentLengthChange = (event) => {
    setSelectedEquipmentLength(event.target.value);
  };

  const onActiveChange = (event) => {
    const { checked } = event.target;
    setActive(checked);
    if (zone && zone?.id && !checked) {
      setShowInactiveReason(true);
      if (!inactiveReason) {
        toast.warn("Please provide a reason for inactivating this zone");
      }
    } else {
      setShowInactiveReason(false);
      toast.dismiss();
    }
  };

  const onActivationDateChange = (event) => {
    setActivationDate(event);
    // setActivationDate(event.target.value);
  };

  const onDeactivationDateChange = (event) => {
    setDeactivationDate(event);
    // setDeactivationDate(event.target.value);
  };

  const onInactiveReasonChange = (event) => {
    setInactiveReason(event.target.value);
    toast.dismiss();
  };

  const onPricePerDateInCentsChange = (event) => {
    const { value } = event.target;
    const numVal = Number(value);
    if (value && numVal > -1) {
      setPricePerDayInCents(numVal * 100);
    } else {
      setPricePerDayInCents(null);
    }
  };

  const onPricePerWeekInCentsChange = (event) => {
    const { value } = event.target;
    const numVal = Number(value);
    if (value && numVal > -1) {
      setPricePerWeekInCents(numVal * 100);
    } else {
      setPricePerWeekInCents(null);
    }
  };

  const onPricePerMonthInCentsChange = (event) => {
    const { value } = event.target;
    const numVal = Number(value);
    if (value && numVal > -1) {
      setPricePerMonthInCents(numVal * 100);
    } else {
      setPricePerMonthInCents(null);
    }
  };

  const onAllowOneTimeBookingsChange = () => {
    setAllowOneTimeBookings((prev) => !prev);
  };

  const onAllowWeeklyBookingsChange = () => {
    setAllowWeeklyBookings((prev) => !prev);
  };

  const onAllowMonthlyBookingsChange = () => {
    setAllowMonthlyBookings((prev) => !prev);
  };

  const onAutoApprovedChange = () => {
    setAutoApproved(!autoApproved);
  };

  const onCoordinatesChange = (coordinates) => {
    let coordinatesArray = [];
    for (let coordinatesKey in coordinates) {
      const ZoneCoordinates = {
        order: Number(coordinatesKey),
        latitude: coordinates[coordinatesKey].lat.toString(),
        longitude: coordinates[coordinatesKey].lng.toString(),
      };
      coordinatesArray.push(ZoneCoordinates);
    }
    setCoordinates(coordinatesArray);
  };

  const cancel = () => {
    history.goBack();
  };

  const buildLocationZone = () => {
    return {
      id: zone?.id,
      name: zoneName,
      locationId: locationId,
      totalSpaces: totalSpaces,
      powerAccessible: powerAccessible,
      equipmentLength: selectedEquipmentLength,
      active: !!props?.location?.state?.inactive ? false : active,
      activationDate:
        activationDate === "MM/dd/yyyy"
          ? null
          : formatDateWithHIYYMMDD(activationDate),
      deactivationDate:
        deactivationDate === "MM/dd/yyyy"
          ? null
          : formatDateWithHIYYMMDD(deactivationDate),
      inactiveReason: !!props?.location?.state?.inactive
        ? inactiveReason
        : active
        ? null
        : inactiveReason,
      pricePerDayInCents: pricePerDayInCents,
      pricePerWeekInCents: pricePerWeekInCents,
      pricePerMonthInCents: pricePerMonthInCents,
      allowOneTimeBookings: allowOneTimeBookings,
      allowWeeklyBookings: allowWeeklyBookings,
      allowMonthlyBookings: allowMonthlyBookings,
      autoApproved: autoApproved,
      coordinates: coordinates,
      createdBy: zone?.createdBy,
      creationDate: zone?.creationDate,
      lastModifiedBy: zone?.lastModifiedBy,
      lastModifiedDate: zone?.lastModifiedDate,
    };
  };

  const convertZoneCoordinates = () => {
    let zone = buildLocationZone();
    if (zone.coordinates.length > 1) {
      for (let coordinates of zone.coordinates) {
        coordinates.latitude = Number(coordinates.latitude);
        coordinates.longitude = Number(coordinates.longitude);
      }
      return zone;
    }
  };

  const findLocationById = (locationId) => {
    return locations.find((location) => location?.id === locationId);
  };

  const getOtherZonesByLocationId = (locationId) => {
    const location = findLocationById(locationId);
    const zones = location?.locationZones ? location.locationZones : [];
    // exclude drawing polygon for edited zone
    if (zone && zone.id) {
      return zones.filter((otherZone) => otherZone.id !== zone.id);
    }
    return zones;
  };

  const saveToApi = () => {
    Busy.set(true);
    zoneEdit(buildLocationZone())
      .then(
        () => {
          Busy.set(false);
          !!props?.location?.state?.inactive
            ? toast.success("Zone inactive successfully!")
            : toast.success("Zone saved successfully!");
          history.goBack();
        },
        (error) => {
          Busy.set(false);
          toast.error(getErrorMessageForStandardResponse(error));
        }
      )
      .catch((error) => {
        Busy.set(false);
        toast.error(getErrorMessageForStandardResponse(error));
      });
  };
  // console.log("zone", zone);

  return (
    <div className="container">
      <h3>ZONE EDITOR</h3>
      <StepWizardContainer>
        {!!props?.location?.state?.inactive ? (
          <>
            {" "}
            <StepWizard>
              <ActivationDates
                active={active}
                onActiveChange={onActiveChange}
                activationDate={activationDate}
                onActivationDateChange={onActivationDateChange}
                deactivationDate={deactivationDate}
                onDeactivationDateChange={onDeactivationDateChange}
                inactiveReason={inactiveReason}
                onInactiveReasonChange={onInactiveReasonChange}
                showInactiveReason={true}
                buttonDisabled={buttonDisabled}
                setButtonDisabled={setButtonDisabled}
                stepNumber={4}
                saveToApi={saveToApi}
                cancel={cancel}
                comp="makeZoneInactive"
              />
            </StepWizard>
          </>
        ) : (
          <>
            <StepWizard>
              <LocationIdSelect
                locations={locations}
                onChange={onLocationIdChange}
                stepNumber={1}
                buttonDisabled={buttonDisabled}
                cancel={cancel}
                comp="zoneInputWidth"
                setButtonDisabled={setButtonDisabled}
                setSelectedZoneName={setSelectedZoneName}
              />
              <ZoneNameNumberSpacesPowerStep
                zoneName={zoneName}
                onZoneNameChange={onZoneNameChange}
                totalSpaces={totalSpaces}
                onTotalSpacesChange={onTotalSpacesChange}
                powerAccessible={powerAccessible}
                onPowerAccessibleChange={onPowerAccessibleChange}
                stepNumber={2}
                buttonDisabled={buttonDisabled}
                canContinue={
                  !(
                    (
                      totalSpaces !== "" &&
                      totalSpaces > 0 &&
                      !!zoneName &&
                      !showToastMsg
                    )
                    //  &&
                    // (selectedZoneName?.length > 0
                    //   ? !selectedZoneName?.some((ele) => ele === zoneName)
                    //   : true)
                  )
                }
                setButtonDisabled={setButtonDisabled}
                cancel={cancel}
              />
              <EquipmentLengths
                equipmentLengths={equipmentLengths}
                selectedEquipmentLength={selectedEquipmentLength}
                onEquipmentLengthChange={onEquipmentLengthChange}
                buttonDisabled={buttonDisabled}
                stepNumber={3}
                cancel={cancel}
              />
              <ActivationDates
                active={active}
                onActiveChange={onActiveChange}
                activationDate={activationDate}
                onActivationDateChange={onActivationDateChange}
                deactivationDate={deactivationDate}
                onDeactivationDateChange={onDeactivationDateChange}
                inactiveReason={inactiveReason}
                onInactiveReasonChange={onInactiveReasonChange}
                showInactiveReason={showInactiveReason}
                buttonDisabled={buttonDisabled}
                setButtonDisabled={setButtonDisabled}
                stepNumber={4}
                cancel={cancel}
                comp="edit"
              />
              <AllowBookings
                allowOneTimeBookings={allowOneTimeBookings}
                allowWeeklyBookings={allowWeeklyBookings}
                allowMonthlyBookings={allowMonthlyBookings}
                onAllowOneTimeBookingsChange={onAllowOneTimeBookingsChange}
                onAllowWeeklyBookingsChange={onAllowWeeklyBookingsChange}
                onAllowMonthlyBookingsChange={onAllowMonthlyBookingsChange}
                pricePerDayInCents={pricePerDayInCents}
                pricePerWeekInCents={pricePerWeekInCents}
                pricePerMonthInCents={pricePerMonthInCents}
                buttonDisabled={buttonDisabled}
                stepNumber={5}
                cancel={cancel}
              />
              <Price
                pricePerDayInCents={pricePerDayInCents}
                pricePerWeekInCents={pricePerWeekInCents}
                pricePerMonthInCents={pricePerMonthInCents}
                onPricePerDateInCentsChange={onPricePerDateInCentsChange}
                onPricePerWeekInCentsChange={onPricePerWeekInCentsChange}
                onPricePerMonthInCentsChange={onPricePerMonthInCentsChange}
                allowOneTimeBookings={allowOneTimeBookings}
                allowWeeklyBookings={allowWeeklyBookings}
                allowMonthlyBookings={allowMonthlyBookings}
                buttonDisabled={buttonDisabled}
                stepNumber={6}
                cancel={cancel}
              />
              <AutoApprovedZone
                autoApproved={autoApproved}
                onAutoApprovedChange={onAutoApprovedChange}
                buttonDisabled={buttonDisabled}
                stepNumber={7}
                cancel={cancel}
              />
              <ValidateBookings
                zone={zone}
                equipmentLength={selectedEquipmentLength}
                powerAccessible={powerAccessible}
                numberOfSpaces={totalSpaces}
                active={active}
                stepNumber={8}
                cancel={cancel}
                buttonDisabled={buttonDisabled}
                setButtonDisabled={setButtonDisabled}
              />
              <CoordinatesBuilder
                coordinates={coordinates}
                otherZones={getOtherZonesByLocationId(locationId)}
                location={findLocationById(locationId)}
                onCoordinatesChange={onCoordinatesChange}
                buttonDisabled={buttonDisabled}
                stepNumber={9}
                cancel={cancel}
              />
              <ViewZone
                zone={convertZoneCoordinates()}
                buttonDisabled={buttonDisabled}
                saveToApi={saveToApi}
                stepNumber={10}
                cancel={cancel}
              />
            </StepWizard>
          </>
        )}
      </StepWizardContainer>
    </div>
  );
};

export default withRouter(ZoneEditor);
