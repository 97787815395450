import React from "react";
import Input from "../common/Input";
import "../zonebuilder/zones/zones.css";
import ZoneActivationDate from "../zonebuilder/zones/zoneActivationDate";

const StartEndDate = (props) => {
  const {
    startDate,
    onStartDateChange,
    endDate,
    onEndDateChange,
    selectedFrequency,
  } = props;

  const className = "form-control orange";
  const classNameStartDate = "margin-start-date";
  const classNameEndDate = "margin-end-date";
  const dateType = "date";
  const startDateLabel = "Start Date: ";
  const endDateLabel = "End Date: ";

  return (
    <div
      style={{ display: "flex", justifyContent: "space-between", width: "80%" }}
    >
      <ZoneActivationDate
        label={startDateLabel}
        classNameStartDate={classNameStartDate}
        type={dateType}
        name={startDate}
        activationDate={startDate}
        deactivationDate={endDate}
        value={startDate}
        onChange={onStartDateChange}
        placeHolder={"mm/dd/yyyy"}
        activationDateMaxCheck="startDateMaxCheck"
      />
      {/* <Input
        label={startDateLabel}
        className={className}
        type={dateType}
        name={startDate}
        value={startDate}
        placeHolder={"MM/dd/yyyy"}
        onChange={onStartDateChange}
      /> */}
      {/* <hr /> */}
      {/* <Input
           label={endDateLabel}
           className={className}
           type={dateType}
           name={endDate}
           value={endDate}
           id="endDate"
           startDate={startDate}
           endDate={endDate}
           disabled={startDate === "" ? true : false}
           placeHolder={"MM/dd/yyyy"}
           onChange={onEndDateChange}
           /> */}
      {selectedFrequency !== "RECURRING" && (
        <ZoneActivationDate
          label={endDateLabel}
          className={className}
          classNameEndDate={classNameEndDate}
          type={dateType}
          name={endDate}
          placeHolder={"mm/dd/yyyy"}
          activationDate={startDate}
          deactivationDate={endDate}
          value={endDate}
          disabled={startDate === "" ? true : false}
          onChange={onEndDateChange}
        />
      )}
    </div>
  );
};

export default StartEndDate;
