import React from "react";
import { Route } from "react-router";
import Checkout from "../../views/thirdparty/checkout/Checkout";

const CheckoutContent = () => {
  return [
    <Route path="/checkout/:reservationId" key="/checkout">
      <Checkout />
    </Route>,
  ];
};

export default CheckoutContent;
