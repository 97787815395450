import moment from "moment/moment";
import {
  getKeyByValueBookingTransactionType,
  getKeyByValueInvoiceType,
} from "../components/constants/securspace-constants";
export const searchRecordsByKeywordUser = (keyword, records) => {
  const filtered = [];
  if (keyword) {
    for (const record of records) {
      for (const element of Object.values(record)) {
        if (
          element
            ?.toString()
            .toLowerCase()
            .includes(keyword?.toLowerCase()?.trim())
        ) {
          filtered.push(record);
          break;
        }
      }
    }
    return filtered;
  }
  return records;
};

export const searchRecordsByKeywordUserName = (keyword, records) => {
  const filtered = [];

  if (keyword) {
    for (const record of records) {
      if (
        record?.username
          ?.toString()
          .toLowerCase()
          .includes(keyword.toLowerCase().trim())
      ) {
        filtered.push(record);
      }
    }
    return filtered;
  }

  return records;
};

export const searchRecordsByKeywordSecurity = (keyword, records) => {
  const filtered = [];
  if (keyword) {
    let lowerCaseKeyword = keyword.toLowerCase().trim();
    if (lowerCaseKeyword === "IN PROGRESS") lowerCaseKeyword = "IN_PROGRESS";
    for (const record of records) {
      for (const element of Object.values(record)) {
        if (element?.toString().toLowerCase() === lowerCaseKeyword) {
          filtered.push(record);
          break;
        }
      }
    }
    return filtered;
  }
  return records;
};

export const searchRecordsByKeyword = (keyword, records) => {
  const filtered = [];
  if (keyword) {
    let lowerCaseKeyword;
    lowerCaseKeyword = keyword.toLowerCase().trim();
    if (keyword === "YES") {
      lowerCaseKeyword = "true";
    }

    for (const record of records) {
      for (const element of Object.values(record)) {
        if (
          element?.toString().toLowerCase() === lowerCaseKeyword ||
          element?.name?.toString().toLowerCase() === lowerCaseKeyword ||
          element === keyword * 100
        ) {
          filtered.push(record);
          break;
        }
      }
    }
    return filtered;
  }
  return records;
};
export const searchRecordsByKeywordZoneAvailability = (keyword, records) => {
  const filtered = [];
  if (keyword) {
    const lowerCaseKeyword = keyword.toLowerCase().trim();
    for (const record of records) {
      for (const element of Object.values(record)) {
        if (
          element?.toString().toLowerCase() === lowerCaseKeyword ||
          element === keyword * 100
        ) {
          filtered.push(record);
          break;
        }
      }
    }
    return filtered;
  }
  return records;
};

const EquipmentLengthsConstant = Object.freeze({
  TWENTY_FIVE_FOOT: "25' MAX (Truck Only)",
  FORTY_FIVE_FOOT: "45' MAX (Small Container)",
  FIFTY_THREE_FOOT: "53' MAX (Large Container)",
  EIGHTY_THREE_FOOT: "83' MAX (Truck + Trailer)",
});
const AssetTypesMap = Object.freeze({
  CONTAINER_LOADED: "Container / Chassis (Loaded)",
  CONTAINER_EMPTY: "Container / Chassis (Empty)",
  CHASSIS_ONLY: "Chassis Only",
  TRUCK_CONTAINER_LOADED: "Truck + Container / Chassis (Loaded)",
  TRUCK_CONTAINER_EMPTY: "Truck + Container / Chassis (Empty)",
  TRUCK_TRAILER_LOADED: "Truck + Trailer (Loaded)",
  TRUCK_TRAILER_EMPTY: "Truck + Trailer (Empty)",
  TRUCK_ONLY: "Truck Only",
  TRAILER_LOADED: "Trailer (Loaded)",
  TRAILER_EMPTY: "Trailer (Empty)",
  REEFER_LOADED_PLUGIN: "Refrigerated (Loaded) (Plug In)",
  REEFER_LOADED_NO_PLUGIN: "Reefer (Loaded) (No Plug In)",
  TRUCK_REEFER_LOADED_PLUGIN: "Truck + Reefer (Loaded) (Plug In)",
  TRUCK_REEFER_LOADED_NO_PLUGIN: "Truck + Reefer (Loaded) (No Plug In)",
});

const formatAssetType = (equipmentLength) => {
  switch (equipmentLength) {
    case Object.values(AssetTypesMap)[0]:
      return Object.keys(AssetTypesMap)[0];
    case Object.values(AssetTypesMap)[1]:
      return Object.keys(AssetTypesMap)[1];
    case Object.values(AssetTypesMap)[2]:
      return Object.keys(AssetTypesMap)[2];
    case Object.values(AssetTypesMap)[3]:
      return Object.keys(AssetTypesMap)[3];
    default:
      return equipmentLength.toLowerCase().trim();
  }
};

const formatEquipmentLength = (equipmentLength) => {
  switch (equipmentLength) {
    case Object.values(EquipmentLengthsConstant)[0]:
      return Object.keys(EquipmentLengthsConstant)[0];
    case Object.values(EquipmentLengthsConstant)[1]:
      return Object.keys(EquipmentLengthsConstant)[1];
    case Object.values(EquipmentLengthsConstant)[2]:
      return Object.keys(EquipmentLengthsConstant)[2];
    case Object.values(EquipmentLengthsConstant)[3]:
      return Object.keys(EquipmentLengthsConstant)[3];
    default:
      return formatAssetType(equipmentLength);
  }
};

const dateFormat = "MM/DD/YYYY";

export const searchRecordsByKeywordInvoice = (keyword, records) => {
  const filtered = [];
  if (keyword) {
    let lowerCaseKeyword;
    if (getKeyByValueBookingTransactionType(keyword)) {
      lowerCaseKeyword = getKeyByValueBookingTransactionType(keyword);
    } else if (getKeyByValueInvoiceType(keyword)) {
      lowerCaseKeyword = getKeyByValueInvoiceType(keyword);
    } else {
      lowerCaseKeyword = formatEquipmentLength(keyword);
    }

    for (const record of records) {
      for (const element of Object.values(record)) {
        const name =
          record?.accountId?.firstName + " " + record?.accountId?.lastName;
        if (
          element?.toString().toLowerCase() ===
            lowerCaseKeyword?.toLowerCase() ||
          moment(new Date(element)).format(dateFormat) === keyword ||
          element === keyword * 100 ||
          name?.toString().toLowerCase() === lowerCaseKeyword?.toLowerCase()
        ) {
          filtered.push(record);
          break;
        }
      }
    }
    return filtered;
  }
  return records;
};

export const searchRecordsByKeywordWaitListAvailability = (
  keyword,
  records
) => {
  const filtered = [];
  if (keyword === "SECURITY DEPOSIT CHARGE") return records;
  if (keyword) {
    let lowerCaseKeyword = formatEquipmentLength(keyword);
    if (lowerCaseKeyword === "in progress") lowerCaseKeyword = "IN_PROGRESS";
    if (lowerCaseKeyword === "refunded") lowerCaseKeyword = "COMPLETED";
    if (lowerCaseKeyword === "one time") lowerCaseKeyword = "ONE_TIME";
    if (lowerCaseKeyword === "yes") lowerCaseKeyword = "true";
    else if (lowerCaseKeyword === "no") lowerCaseKeyword = "false";
    for (const record of records) {
      for (const element of Object.values(record)) {
        const name =
          record?.accountId?.firstName + " " + record?.accountId?.lastName;
        if (
          element?.toString().toLowerCase() ===
            lowerCaseKeyword?.toLowerCase() ||
          moment(new Date(element)).format(dateFormat) === keyword ||
          element === keyword * 100 ||
          name?.toString().toLowerCase() === lowerCaseKeyword?.toLowerCase()
        ) {
          filtered.push(record);
          break;
        }
      }
    }
    return filtered;
  }
  return records;
};
