import React, { useContext } from "react";
import ReportKebabButton from "../../components/report/ReportKebabButton";
import { MenuItem } from "@material-ui/core";
import ReportFlexRightContainer from "../../components/report/ReportFlexRightContainer";
import { AppContext } from "../../context/app-context";
import { isRoleApproved, ADMIN_EDIT_ROLES } from "../../util/user/UserUtil";

const InvoiceReportKebabButton = (props) => {
  const { user } = useContext(AppContext);
  const { invoice, setBookingTransactionId, setRefundModal } = props;

  const viewInvoice = (item) => {
    const baseUrl = user.baseUrl;

    window.open(
      baseUrl +
        "/api/invoices-by-invoice-number/" +
        item?.encryptedTransactionNumber
    );
  };

  const viewInventoryLog = (item) => {
    const baseUrl = user.baseUrl || window.location.origin;

    window.open(
      baseUrl +
        "/api/overage-daily-report-by-invoice-number/" +
        item.transactionNumber
    );
  };

  return (
    <ReportFlexRightContainer>
      <ReportKebabButton>
        <MenuItem onClick={() => viewInvoice(invoice)}>View Invoice</MenuItem>
        {!!invoice?.serviceDates && (
          <MenuItem onClick={() => viewInventoryLog(invoice)}>
            View Inventory Log
          </MenuItem>
        )}
        {!!invoice?.payoutCompletedOn && (
          <MenuItem
            onClick={() => {
              setRefundModal(true);
              setBookingTransactionId(invoice.transactionId);
            }}
            disabled={!isRoleApproved(ADMIN_EDIT_ROLES, user.userType)}
          >
            Refund Payment
          </MenuItem>
        )}
      </ReportKebabButton>
    </ReportFlexRightContainer>
  );
};

export default InvoiceReportKebabButton;
