import {
  AccountNav,
  GateManagementNav,
  MarketplaceOnlyGmsUserNav,
  ReportsNav,
  SupplierNav,
} from "./navs";

const GATE_MANAGEMENT_SUBNAV = {
  path: "gate-management",
  label: "GATE MANAGEMENT",
  submenuWidth: 180,
  submenus: [
    GateManagementNav.CHECK_IN,
    GateManagementNav.SUPPLIER_INVENTORY,
    GateManagementNav.SUPPLIER_INTERCHANGES,
    GateManagementNav.DRIVERS,
  ],
};
const LOCATION_MANAGEMENT_SUBNAV = {
  path: "location-management",
  label: "LOCATION MANAGEMENT",
  submenuWidth: 180,
  submenus: [AccountNav.LOCATIONS_PROFILE, AccountNav.ZONES_PROFILE],
};
const LOCATION_MANAGEMENT_SUBNAV_GM = {
  path: "location-management",
  label: "LOCATION MANAGEMENT",
  submenuWidth: 180,
  submenus: [AccountNav.ZONES_PROFILE],
};

const INVOICES = {
  path: "supplier-invoices",
  label: "INVOICES",
  submenuWidth: 180,
  // submenus: [ReportsNav.SUPPLIER_INVOICES],
};

const SECURITY_DEPOSIT = {
  path: "security-deposit",
  label: "SECURITY DEPOSITS",
  submenuWidth: 180,
  //submenus: [ReportsNav.SECURITY_DEPOSIT],
};
const USER_MANAGEMENT = {
  path: "user-management",
  label: "USER MANAGEMENT",
  submenuWidth: 180,
  //  submenus: [AccountNav.USER_MANAGEMENT],
};

const FINE_MANAGEMENT_SUBNAV = {
  path: "fine-management",
  label: "FINES",
  submenuWidth: 180,
  submenus: [
    SupplierNav.ADD_FINE,
    SupplierNav.VIEW_EDIT_FINES,
    SupplierNav.FINES_ASSESSMENTS,
  ],
};

const FINE_VIEW_SUBNAV = {
  path: "fine-management",
  label: "FINES",
  submenuWidth: 180,
  submenus: [SupplierNav.VIEW_EDIT_FINES, SupplierNav.FINES_ASSESSMENTS],
};

const ACCOUNT_SUBNAV = {
  path: "account",
  label: "ACCOUNT",
  submenuWidth: 170,
  submenus: [
    AccountNav.COMPANY_PROFILE,
    AccountNav.NOTIFICATIONS_SETTINGS,
    AccountNav.SIGN_OUT,
  ],
};

const GATE_ACCOUNT_SUBNAV = {
  path: "account",
  label: "ACCOUNT",
  submenuWidth: 170,
  submenus: [AccountNav.SIGN_OUT],
};

const CLERK_REPORTS_SUBNAV = {
  path: "reports",
  label: "REPORTS",
  submenuWidth: 170,
  submenus: [SupplierNav.ZONES_REPORT],
};

const REPORTS_SUBNAV = {
  path: "reports",
  label: "REPORTS",
  submenuWidth: 200,
  submenus: [
    ReportsNav.SUPPLIER_ACTIVITY_SUMMARY,
    SupplierNav.ZONES_REPORT,
    ReportsNav.WAITLIST_REPORT,
    ReportsNav.MONTHLY_DURATION_REPORT,
    ReportsNav.REVENUE_REPORT,
    ReportsNav.ZONE_REVENUE_REPORT,
    ReportsNav.REVENUE_RECONCILIATION_REPORT,
  ],
};

const GATE_MANAGER_REPORTS_SUBNAV = {
  path: "reports",
  label: "REPORTS",
  submenuWidth: 200,
  submenus: [
    ReportsNav.SUPPLIER_ACTIVITY_SUMMARY,
    SupplierNav.ZONES_REPORT,
    ReportsNav.WAITLIST_REPORT,
    ReportsNav.MONTHLY_DURATION_REPORT,
  ],
};

const makeBookingsSubmenu = (...additionalItems) => {
  const subMenus = Array.prototype.concat(
    [
      SupplierNav.RESERVATIONS,
      SupplierNav.SUPPLIER_BOOKINGS,
      SupplierNav.ACTIVE_WAITLIST,
    ],
    additionalItems
  );

  return {
    path: "bookings",
    label: "BOOKINGS",
    submenuWidth: 220,
    badge: true,
    submenus: subMenus,
  };
};

const makeBookingsSubmenuClerk = (...additionalItems) => {
  const subMenus = Array.prototype.concat(
    [SupplierNav.RESERVATIONS],
    additionalItems
  );

  return {
    path: "bookings",
    label: "BOOKINGS",
    submenuWidth: 220,
    badge: true,
    submenus: subMenus,
  };
};

export const SUPPLIER_OWNER_GMS_LITE_NAV = [
  GATE_MANAGEMENT_SUBNAV,
  LOCATION_MANAGEMENT_SUBNAV,
  FINE_MANAGEMENT_SUBNAV,
  makeBookingsSubmenu(),
  INVOICES,
  SECURITY_DEPOSIT,
  REPORTS_SUBNAV,
  USER_MANAGEMENT,
  ACCOUNT_SUBNAV,
];

export const SUPPLIER_OWNER_GMS_PRO_NAV = [
  GATE_MANAGEMENT_SUBNAV,
  LOCATION_MANAGEMENT_SUBNAV,
  FINE_MANAGEMENT_SUBNAV,
  makeBookingsSubmenu(SupplierNav.SUPPLIER_BOOKED_SPACE_CALENDAR),
  INVOICES,
  SECURITY_DEPOSIT,
  REPORTS_SUBNAV,
  USER_MANAGEMENT,
  ACCOUNT_SUBNAV,
];

export const READ_ONLY_ADMIN_NAV = [
  GATE_MANAGEMENT_SUBNAV,
  LOCATION_MANAGEMENT_SUBNAV,
  makeBookingsSubmenu(SupplierNav.SUPPLIER_BOOKED_SPACE_CALENDAR),
  INVOICES,
  SECURITY_DEPOSIT,
  REPORTS_SUBNAV,
  USER_MANAGEMENT,
  ACCOUNT_SUBNAV,
];

export const SUPPLIER_OWNER_MARKETPLACE_ONLY_NAV = [
  SupplierNav.GMS_LANDING,
  makeBookingsSubmenu(),
  INVOICES,
  SECURITY_DEPOSIT,
  REPORTS_SUBNAV,
  USER_MANAGEMENT,
  ACCOUNT_SUBNAV,
];

export const SUPPLIER_GATE_MANAGER_NAV = [
  GATE_MANAGEMENT_SUBNAV,
  LOCATION_MANAGEMENT_SUBNAV_GM,
  makeBookingsSubmenu(SupplierNav.SUPPLIER_BOOKED_SPACE_CALENDAR),
  //  INVOICES,
  //  SECURITY_DEPOSIT,
  GATE_MANAGER_REPORTS_SUBNAV,
  // USER_MANAGEMENT,
  GATE_ACCOUNT_SUBNAV,
];

export const SUPPLIER_GATE_CLERK_NAV = [
  GATE_MANAGEMENT_SUBNAV,
  LOCATION_MANAGEMENT_SUBNAV_GM,
  makeBookingsSubmenuClerk(),
  // INVOICES,
  // SECURITY_DEPOSIT,
  CLERK_REPORTS_SUBNAV,
  // USER_MANAGEMENT,
  GATE_ACCOUNT_SUBNAV,
];

export const MARKETPLACE_ONLY_GATE_USER_NAV = [
  MarketplaceOnlyGmsUserNav.GMS_UPGRADE,
  LOCATION_MANAGEMENT_SUBNAV,
  SupplierNav.GMS_LANDING,
  // INVOICES,
  // SECURITY_DEPOSIT,
  REPORTS_SUBNAV,
  // USER_MANAGEMENT,
  AccountNav.SIGN_OUT,
];
