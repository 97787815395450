import React from "react";
import "../css/theme/buttons.css";
import "../css/components/buttonSelector.css";

const CustomFilePicker = ({ id, onFileChange, account }) => {
  const triggerFileInput = () => {
    document.getElementById(id).click();
  };

  return (
    <div className="custom-file-picker-button">
      <input
        className="displayNone"
        id={id}
        type="file"
        onChange={onFileChange}
        accept={id === "listingImageFilePicker" ? "image/png, image/jpeg" : ""}
        disabled={account.userType === "READ_ONLY_ADMIN"}
      />
      <button
        className="internal-icon"
        type="button"
        onClick={triggerFileInput}
      >
        Choose File
      </button>
    </div>
  );
};

export default CustomFilePicker;
