import React, { Component } from "react";
import { GlobalModalContext } from "../context/global-modal-context";
import Link from "react-router-dom/es/Link";
import "../css/components/footer.css";
import { withRouter } from "react-router";
import poweredBy from "../img/poweredBy.png";
class Footer extends Component {
  static contextType = GlobalModalContext;

  render() {
    return (
      <footer className="footer">
        <section className="ss-footer-section">
          <div className="ss-footer-icon-container" style={{ display: "flex" }}>
            <div className="poweredBy">
              <Link to="/">
                <img className="ss-footer-img" src={poweredBy} alt="SPS" />
              </Link>
            </div>
            <div className="footer-copyright">
              &copy; Port of Oakland Smart Parking System 2024 - All rights
              reserved
            </div>
          </div>

          <div className="ss-footer-ul"></div>
        </section>
      </footer>
    );
  }
}

export default withRouter(Footer);
