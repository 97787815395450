import React, { useEffect, useState } from "react";
import { Modal } from "react-router-modal";
import Busy from "../../../components/Busy";
import { ajaxRequest } from "../../../ajax";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { PostMessageStatus } from "../../../components/constants/securspace-constants";
import { networkErrorIssue } from "./checkoutConstants";
import "./deletePopUp.css";

function CrediCardDisplayPopUp({
  paymentMethods,
  setBookSpaceForm,
  selectingCreditCardIndex,
  setSelectingAchIndex,
  setSelectingCreditCardIndex,
  reservationId,
  id,
  fineAmount,
  finePeakPrice,
  securityDepositPaid,
  securityDepositAmountState,
  securityDepositRequiredState,
  setSecurityDepositRequiredState,
  setsecurityDepositPaymentStatus,
  setdateInitiatedSecurityDeposit,
  setsourceSecurityDeposit,
  setsecurityDepositnickName,
  setSecurityInvoiceNumber,
  setSecurityDepositPaymentType,
  token,
}) {
  const [lastFourDigit, setLastFourDigit] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cardBrand, setCardBrand] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [tsepToken, setTsepToken] = useState("");
  const [cvvEntered, setCvvEntered] = useState("");
  const [zip, setZip] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [cvvModified, setCvvModified] = useState("");
  const [fullName, setFullName] = useState("");

  const history = useHistory();
  const postMessageAndClose = (status, data) => {
    let openerParent = window.opener || window.parent;
    const webkit = window.webkit;
    const Android = window.Android;
    const SOURCE = "sps-checkout";

    // opener will return the window that opened it
    // parent will return any sort if iframe or webview that opened it
    // if (openerParent) {
    //   openerParent.postMessage(
    //     {
    //       status: status,
    //       data: data,
    //       source: SOURCE,
    //     },
    //     openerParent.origin
    //   );

    //   // if there is no opener, window.close will not work, so don't call it
    //   if (window.opener) {
    //     setTimeout(() => {
    //       window.close();
    //     }, 2500);
    //   }
    //   history.push("");
    // }

    // custom ios postMessage listener logic
    webkit &&
      webkit.messageHandlers &&
      webkit.messageHandlers.nativeapp.postMessage({
        status: status,
        data: data,
        source: SOURCE,
      });

    if (Android && Android.onSuccess) {
      Android.onSuccess({
        status: status,
        data: data,
        source: SOURCE,
      });
    } else if (Android && Android.onCancel) {
      Android.onCancel({
        status: status,
        data: data,
        source: SOURCE,
      });
    }
  };

  useEffect(() => {
    if (selectingCreditCardIndex !== null && selectingCreditCardIndex !== -1) {
      setLastFourDigit(paymentMethods[selectingCreditCardIndex]?.lastFour);
      setExpiryDate(paymentMethods[selectingCreditCardIndex]?.expiryDate);
      setCardBrand(paymentMethods[selectingCreditCardIndex]?.cardBrand);
      setFirstName(paymentMethods[selectingCreditCardIndex]?.firstName);
      setTsepToken(paymentMethods[selectingCreditCardIndex]?.tsepToken);
      setLastName(paymentMethods[selectingCreditCardIndex]?.lastName);
      const fullName =
        paymentMethods[selectingCreditCardIndex]?.firstName +
        " " +
        (!!paymentMethods[selectingCreditCardIndex]?.lastName
          ? paymentMethods[selectingCreditCardIndex]?.lastName
          : "");

      setFullName(fullName);
      setZip(paymentMethods[selectingCreditCardIndex]?.zip);
      setPaymentId(paymentMethods[selectingCreditCardIndex]?.id);
    } else {
      paymentMethods?.map((ele) => {
        if (
          ele.defaultPaymentMethodForProfile === true &&
          ele?.type === "CARD"
        ) {
          setLastFourDigit(ele?.lastFour);
          setExpiryDate(ele?.expiryDate);
          setCardBrand(ele?.cardBrand);
          setFirstName(ele?.firstname);
          setLastName(ele?.lastname);
          const fullName = ele?.firstName + " " + ele?.lastName;
          setFullName(fullName);
          setTsepToken(ele?.tsepToken);
          setZip(ele?.zip);
          setPaymentId(ele?.id);
        }
      });
    }
  }, []);

  const handleCancel = () => {
    setBookSpaceForm(false);
    setSelectingCreditCardIndex(null);
    setSelectingAchIndex(null);
    // postMessageAndClose(PostMessageStatus.CANCEL, null);
  };

  const handleSubmitCreditCardPayment = () => {
    Busy.set(true);
    let urlUpdated;

    const baseUrl = `zone/reservation/tsys/credit-card/${paymentId}/complete?reservationId=${reservationId}&buyerAccountId=${id}`;
    const fineUrl = `fine-assessments/pay-fine/${paymentId}/credit-card/${reservationId}`;

    if (securityDepositPaid) {
      urlUpdated = Number(fineAmount) > 0 ? fineUrl : baseUrl;
    } else {
      urlUpdated = baseUrl;
      // securityDepositRequiredState || Number(fineAmount) > 0
      //   ? fineUrl
      //   : baseUrl;
    }
    urlUpdated = Number(fineAmount) > 0 ? fineUrl : baseUrl;

    let payload = {
      cardNumber: tsepToken,
      firstName: firstName,
      lastName: lastName,
      cardBrand: cardBrand,
      token: token,
      expirationDate: expiryDate,
      securityDepositPayment: !!securityDepositPaid
        ? false
        : !!securityDepositRequiredState,
      transactionAmount: securityDepositAmountState,
      cvv2: cvvEntered,
      zip: zip,
    };

    if (Number(fineAmount) > 0) {
      payload.securityDepositPayment = false;
      payload.transactionAmount = ((fineAmount + finePeakPrice) / 100).toFixed(
        2
      );
    }

    ajaxRequest({
      url: urlUpdated,
      data: JSON.stringify(payload),
      contentType: "application/json",
      method: "POST",
      success: (res) => {
        Busy.set(false);
        setBookSpaceForm(false);
        setSelectingCreditCardIndex(null);
        setSelectingAchIndex(null);
        //  postMessageAndClose(PostMessageStatus.SUCCESS);
        if (Number(fineAmount) > 0) {
          toast.success("Space Booked Successfully!");
          history.push({
            pathname: "/payment-successful",
            state: {
              amount: Number(fineAmount) + Number(finePeakPrice),
              invoiceNumber: res?.invoiceNumber,
              transferType: "Credit Card Transfer",
              dateInitiated: res?.approvedOn,
              nickName: res?.nickName,
              type: "fineType",
              source: `Card number ending with ${lastFourDigit}`,
            },
          });
          postMessageAndClose(PostMessageStatus.SUCCESS, res);
        } else if (securityDepositPaid) {
          toast.success("Space Booked Successfully!");
          history.push({
            pathname: "/payment-successful",
            state: {
              amount: res?.initialPaymentAmount,
              invoiceNumber: res?.invoiceNumber,
              transferType: "Credit Card Transfer",
              dateInitiated: res?.approvedOn,
              nickName: res?.nickName,
              source: `Card number ending with ${lastFourDigit}`,
            },
          });
          postMessageAndClose(PostMessageStatus.SUCCESS, res);
        } else if (securityDepositRequiredState) {
          toast.success("Payment done successfully!");
          setSecurityDepositRequiredState(res?.securityDepositRequired);
          setsecurityDepositPaymentStatus(true);
          setdateInitiatedSecurityDeposit(res?.dateOfDepositProcessed);
          setsourceSecurityDeposit(`Card number ending with ${lastFourDigit}`);
          setsecurityDepositnickName(res?.nickName);
          setSecurityInvoiceNumber(res?.invoiceNumber);
          setSecurityDepositPaymentType("Credit Card Transfer");
        } else {
          toast.success("Space Booked Successfully!");
          history.push({
            pathname: "/payment-successful",
            state: {
              amount: res?.initialPaymentAmount,
              invoiceNumber: res?.invoiceNumber,
              transferType: "Credit Card Transfer",
              dateInitiated: res?.approvedOn,
              nickName: res?.nickName,
              source: `Card number ending with ${lastFourDigit}`,
            },
          });
          postMessageAndClose(PostMessageStatus.SUCCESS, res);
        }
      },
      error: (jqXHR) => {
        Busy.set(false);
        let errorMessage = jqXHR.responseJSON
          ? jqXHR.responseJSON.message
            ? jqXHR.responseJSON.message.trim()
            : ""
          : "";
        if (!window.navigator.onLine) {
          toast.error(networkErrorIssue);
        } else {
          toast.error(errorMessage);
        }
      },
    });
  };
  const handleChange = (e) => {
    let value = e.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setCvvEntered(value);
    }
    // let inputValue = e.target.value.replace(/\D/g, "").slice(0, 4);
    // setCvvEntered(inputValue?.trim());
    // const newValue = e.target.value.trim();
    // if (!!newValue) {
    //   toast.error("Cvv can't be blank");
    //   return;
    // }
    // setCvvEntered(newValue);
    // const maskedValue = newValue.replace(/\d/g, "*");
    // setCvvModified(maskedValue);
  };

  return (
    <>
      <Modal className="app-modal app-modal-in  ">
        <div className="modal-content padding">
          <h1 className="ss-report-header-title text-align">
            Credit Card Details
          </h1>
          <div className="formCreditCard">
            <div className="p-0 CardNumber bgColor">
              <label>CARD NUMBER</label>
              <input
                type="text"
                value={"XXXX XXXX XXXX " + lastFourDigit}
                disabled
                className="custom-cursor"
              ></input>
            </div>
            <div className="p-0 CardNumber bgColor">
              <label>CARDHOLDER NAME</label>
              <input
                type="text"
                value={fullName}
                disabled
                className="custom-cursor"
              ></input>
            </div>
            <div className="inputDiv p-0 CardNumber bgColor">
              <label>EXPIRATION DATE</label>
              <input
                type="text"
                value={expiryDate}
                disabled
                className="no-cursor"
              ></input>
            </div>
            <div className="inputDiv p-0  CardNumber">
              <label>CVV NUMBER</label>
              <input
                type="password"
                value={cvvEntered}
                onChange={handleChange}
                placeholder="CVV"
              ></input>
            </div>
          </div>

          <div className="newbuttonDivSubmit newButtonDiv">
            <button
              className="ss-button-secondary width200"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              // onClick={handleSubmit}
              className={
                cvvEntered?.length >= 3 &&
                !!expiryDate &&
                !!lastFourDigit &&
                !!fullName
                  ? "ss-button-primary processPayment"
                  : "ss-button-primary processPayment pointer-events"
              }
              disabled={
                !(
                  cvvEntered?.length >= 3 &&
                  !!expiryDate &&
                  !!lastFourDigit &&
                  !!fullName
                )
              }
              // style={
              //   cvvEntered?.length >= 3 &&
              //   !!expiryDate &&
              //   !!lastFourDigit &&
              //   !!fullName
              //     ? {}
              //     : { pointerEvents: "none", opacity: "0.5" }
              // }
              onClick={handleSubmitCreditCardPayment}
            >
              Process Payment
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
export default CrediCardDisplayPopUp;
