import React, { useContext, useState } from "react";
import ReportKebabButton from "../../components/report/ReportKebabButton";
import { MenuItem } from "@material-ui/core";
import ReportFlexRightContainer from "../../components/report/ReportFlexRightContainer";
import { AppContext } from "../../context/app-context";
import { ADMIN_EDIT_ROLES, isRoleApproved } from "../../util/user/UserUtil";
import { getImageTokenLimitResponse } from "../reservationbuiler/requests/resevation-requests";
import { toast } from "react-toastify";

const SecurityDepositKebabButton = ({
  setShowModal,
  setSecurityDepositParticularRecordDetails,
  ele,
}) => {
  const { user } = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imagePathWithToken, setImagePathWithToken] = useState([]);

  const viewInvoice = () => {
    const baseUrl = user.baseUrl;

    window.open(
      baseUrl + "/api/invoices-by-invoice-number/" + ele?.encryptedInvoiceNumber
    );
  };
  const fetchImageTokens = async () => {
    try {
      const tokenResponses = await Promise.all(
        ele?.imageUrl?.map((val) =>
          getImageTokenLimitResponse(encodeURIComponent(val.image)).catch(
            (err) => {
              toast.error("Internal server error!");
              return null;
            }
          )
        )
      );

      const successfulTokens = tokenResponses
        .filter((res) => res !== null)
        .map((res) => ({ image: res.text }));

      setImagePathWithToken((prev) => [...prev, ...successfulTokens]);
    } catch (err) {
      // Optional: Handle any unexpected errors
      toast.error("Something went wrong!");
    }
  };

  const toggleModal = async () => {
    await fetchImageTokens();
    setIsModalOpen(!isModalOpen);
  };
  const viewImage = () => {
    return (
      <div className="unselectable">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="popup-header">
              <h1>View Images</h1>
              <button
                type="button"
                className="close pull-right"
                aria-label="Close"
                onClick={toggleModal} // Close modal on button click
              >
                <img alt="" src="../app-images/close.png" />
              </button>
            </div>
            <div className="modal-body">
              {imagePathWithToken && imagePathWithToken?.length > 0 ? (
                <ul className="inventory-images">
                  {imagePathWithToken?.map((item, key) => (
                    <li key={key} style={{ margin: "10px" }}>
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(item?.image, "_blank"); // Opens the correct image URL
                        }}
                        target="_blank" // Opens link in a new tab
                        rel="noopener noreferrer" // Prevents security vulnerability
                      >
                        <img
                          alt=""
                          src={item?.image}
                          style={{
                            marginRight: "10px",
                            border: "2px solid black",
                            height: "260px",
                          }}
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleClick = () => {
    setSecurityDepositParticularRecordDetails(ele);
    setShowModal(true);
  };

  return (
    <>
      <ReportFlexRightContainer>
        <ReportKebabButton>
          {!!ele?.refundStatus &&
            ele?.refundStatus !== "COMPLETED" &&
            isRoleApproved(ADMIN_EDIT_ROLES, user?.userType) && (
              <MenuItem onClick={handleClick}>Edit Refund Status</MenuItem>
            )}
          <MenuItem onClick={() => viewInvoice()}>View Invoice</MenuItem>
          {ele?.refundStatus === "COMPLETED" && (
            <MenuItem onClick={toggleModal}>View Images</MenuItem>
          )}
        </ReportKebabButton>
      </ReportFlexRightContainer>
      {isModalOpen && viewImage()}
    </>
  );
};

export default SecurityDepositKebabButton;
