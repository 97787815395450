import React, { useEffect, useState } from "react";
import { getReservationChargeAmount } from "./requests/charge-amount-requests";
import { getErrorMessageForStandardResponse } from "../../util/NetworkErrorUtil";
import { ErrorOutlineRounded } from "@material-ui/icons";
import ReportDataRow from "../report/ReportDataRow";
import ReportData from "../report/ReportData";
import MoneyDisplay from "../money/MoneyDisplay";
import Busy from "../Busy";

const DisplayChargeAmount = (props) => {
  const { reservationId, paymentMethodId, securityDepositAmount } = props;
  const [chargeAmount, setChargeAmount] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (reservationId && paymentMethodId) {
      Busy.set(true);
      getReservationChargeAmount(reservationId, paymentMethodId)
        .then((response) => {
          setChargeAmount(response.body);
          Busy.set(false);
        })
        .catch((error) => {
          setErrorMessage(getErrorMessageForStandardResponse(error));
          Busy.set(false);
        });
    }
  }, [reservationId, paymentMethodId, setChargeAmount, setErrorMessage]);

  return (
    <>
      {chargeAmount ? (
        <ReportDataRow>
          <ReportData label={"Security Deposit"}>
            {<MoneyDisplay dollarsAsLong={securityDepositAmount} />}
          </ReportData>
          <ReportData label={"Booking Amount"}>
            {chargeAmount && chargeAmount.initialBookingPaymentAmount && (
              <MoneyDisplay
                dollarsAsLong={chargeAmount.initialBookingPaymentAmount}
              />
            )}
          </ReportData>
          <ReportData label={"Grand Total"}>
            <MoneyDisplay
              dollarsAsLong={
                Number(chargeAmount.initialBookingPaymentAmount) +
                Number(securityDepositAmount)
              }
            />{" "}
          </ReportData>
          <ReportData label={"Amount To Be Paid"}>
            {chargeAmount && chargeAmount.initialBookingPaymentAmount && (
              <MoneyDisplay
                dollarsAsLong={chargeAmount.initialBookingPaymentAmount}
              />
            )}
          </ReportData>
          <ReportData label={"Recurring Charge Amount"}>
            {chargeAmount && chargeAmount.recurringBookingChargeAmount && (
              <MoneyDisplay
                dollarsAsLong={chargeAmount.recurringBookingChargeAmount}
              />
            )}
          </ReportData>
        </ReportDataRow>
      ) : (
        errorMessage && (
          <div>
            <ErrorOutlineRounded />
            {errorMessage}
          </div>
        )
      )}
    </>
  );
};

export default DisplayChargeAmount;
