export const stateConstant = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "American Samoa", value: "AS" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "District of Columbia", value: "DC" },
  { label: "Federated States of Micronesia", value: "FM" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Guam", value: "GU" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Marshall Islands", value: "MH" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Palau", value: "PW" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virgin Islands", value: "VI" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];

export const truncateStringPaymentSuccessful = (str, maxLength = 25) => {
  return str.length > maxLength ? str.substring(0, maxLength - 3) + "..." : str;
};

export const truncateString = (str, maxLength = 15) => {
  return str.length > maxLength ? str.substring(0, maxLength - 3) + "..." : str;
};
export const paymentExpiredText =
  "Your payment link has expired.Please contact the administrator to resend the checkout email,or try making the reservation again!";

export const linkConvertedToBooking =
  "This link has already been converted to booking!";

export const requiredSecurityDepositText =
  "to pay the required security deposit before booking the space";

export const reservationExpiredText =
  "Sorry, your reservation has expired, you will need to resubmit your inquiry and try again later.";

export const networkErrorIssue =
  "Unable to complete the request potentially due to network connectivity issue";

export const securityDepositPaymentSuccessfull =
  "Your payment has been completed! Details of transactions are included below:";

export const oakLandPortalConstant =
  "Oakland Portal Smart Parking System on behalf of Port of Oakland";

export const continueToBookSpace = "Continue to Book Space";
export const TSYS_URL = "https://stagegw.transnox.com/transit-tsep-web/jsView/";
//export const DEVICE_ID = "88700000035204"; // this is for local url
export const DEVICE_ID = "88600000035202"; // this is for dev url
