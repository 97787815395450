import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/app-context";
import { DEFAULT_NAV } from "./nav/DefaultNav";
import { ADMIN_NAV } from "./nav/AdminNav";
import { Link } from "react-router-dom";
import NavMenu from "../views/NavMenu";
import OaklandPortalImage from "../app-images/oakLandPortal.jpeg";
import {
  MARKETPLACE_ONLY_GATE_USER_NAV,
  READ_ONLY_ADMIN_NAV,
  SUPPLIER_GATE_CLERK_NAV,
  SUPPLIER_GATE_MANAGER_NAV,
  SUPPLIER_OWNER_GMS_LITE_NAV,
  SUPPLIER_OWNER_GMS_PRO_NAV,
  SUPPLIER_OWNER_MARKETPLACE_ONLY_NAV,
} from "./nav/SupplierNav";
import { getLandingRedirectPathForUser } from "./route-utils";
import {
  AccountType,
  SubscriptionType,
  UserType,
} from "../components/constants/securspace-constants";

import "./style/user-based-nav.css";

const getUserBasedNavOptions = (user: {
  type: String,
  userType: String,
  subscriptionType: String,
}) => {
  const { type } = user || {};

  let navOptions;

  switch (type) {
    case AccountType.ADMIN:
      navOptions = ADMIN_NAV;
      break;
    case AccountType.SUPPLIER:
      navOptions = getSupplierNavOptions(user);
      break;
    case AccountType.BUYER:
      navOptions = [];
      break;
    default:
      navOptions = DEFAULT_NAV;
  }

  return navOptions;
};

const getSupplierNavOptions = (user: {
  userType: String,
  subscriptionType: String,
}) => {
  const { subscriptionType } = user || {};

  let navOptions;

  switch (subscriptionType) {
    case SubscriptionType.GMS_LITE:
    case SubscriptionType.GMS_PRO:
      navOptions = getSupplierGmsSubscribersNavOptions(user);
      break;
    case SubscriptionType.MARKETPLACE_ONLY:
    default:
      navOptions = getSupplierMarketplaceOnlyNavOptions(user);
  }

  return navOptions;
};

const getSupplierGmsSubscribersNavOptions = (user) => {
  let navOptions;

  const { subscriptionType, userType } = user || {};

  switch (userType) {
    case UserType.GATE_CLERK:
      navOptions = SUPPLIER_GATE_CLERK_NAV;
      break;
    case UserType.GATE_MANAGER:
      navOptions = SUPPLIER_GATE_MANAGER_NAV;
      break;
    case UserType.OWNER:
    case UserType.ADMIN:
      navOptions = getSupplierOwnerNavOptions(subscriptionType);
      break;
    case UserType.READ_ONLY_ADMIN:
      navOptions = getReadOnlyAdminOptions();
      break;
    default:
      navOptions = DEFAULT_NAV;
  }

  return navOptions;
};

const getSupplierMarketplaceOnlyNavOptions = (user) => {
  let navOptions;

  const { userType } = user || {};

  switch (userType) {
    case UserType.GATE_CLERK:
    case UserType.GATE_MANAGER:
      navOptions = MARKETPLACE_ONLY_GATE_USER_NAV;
      break;
    case UserType.ADMIN:
    case UserType.OWNER:
    default:
      navOptions = getSupplierOwnerNavOptions(
        SubscriptionType.MARKETPLACE_ONLY
      );
  }

  return navOptions;
};

const getSupplierOwnerNavOptions = (subscriptionType: String) => {
  let navOptions;

  switch (subscriptionType) {
    case SubscriptionType.MARKETPLACE_ONLY:
      navOptions = SUPPLIER_OWNER_MARKETPLACE_ONLY_NAV;
      break;
    case SubscriptionType.GMS_LITE:
      navOptions = SUPPLIER_OWNER_GMS_LITE_NAV;
      break;
    case SubscriptionType.GMS_PRO:
      navOptions = SUPPLIER_OWNER_GMS_PRO_NAV;
      break;
    default:
      navOptions = DEFAULT_NAV;
  }

  return navOptions;
};

const getReadOnlyAdminOptions = () => {
  return READ_ONLY_ADMIN_NAV;
};

const UserBasedNav = ({ pendingApprovalCount }) => {
  const context = useContext(AppContext);
  const { user, clearUser } = context;
  const [showMenu, setShowMenu] = useState(false);
  const [imageClickNav, setImageClickNav] = useState(false);

  const navOptions = getUserBasedNavOptions(user);
  const handleClick = () => {
    setImageClickNav(true);
  };
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const presetnUrl = window.location.href;
  const presetnUrlValue = presetnUrl.substring(
    presetnUrl?.lastIndexOf("/") + 1
  );
  const fullUrl = window.location.href;

  // Get the hash part (everything after the #)

  return (
    <div id="appNav" className="user-based-nav-container">
      <div>
        {urlParams.has("token") || presetnUrlValue === "payment-successful" ? (
          <div>
            <img id="navLogoTrans" src={OaklandPortalImage} alt="SPS" />
          </div>
        ) : (
          <Link id="navLogoContainer" to="/">
            <img
              id="navLogoTrans"
              src={OaklandPortalImage}
              alt="SPS"
              onClick={() => handleClick()}
            />
          </Link>
        )}

        <div id="navMenuContainer">
          {!(
            urlParams.has("token") || presetnUrlValue === "payment-successful"
          ) && <div id="toggleMenuIcon" onClick={() => setShowMenu(true)} />}
          {!urlParams.has("token") && (
            <NavMenu
              navMenu={navOptions}
              handleLogout={clearUser}
              showMenu={showMenu}
              mainMenuItemAfterLogin={getLandingRedirectPathForUser(
                user
              ).substring(1)}
              closeNavMenu={() => setShowMenu(false)}
              pendingApprovalCount={pendingApprovalCount}
              imageClickNav={imageClickNav}
              handleNavImageClick={() => setImageClickNav(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserBasedNav;
