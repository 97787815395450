import React, { useEffect, useState } from "react";
import ReservationData from "./ReservationData";
import { getReservations } from "./ReservationRequests";
import { getErrorMessageForStandardResponse } from "../../util/NetworkErrorUtil";
import ReportBackgroundContainer from "../report/ReportBackgroundContainer";
import ReportHeader from "../report/ReportHeader";
import { toast } from "react-toastify";
import Filter from "../../views/bookings/Filter";
import GroupAndSort from "../../views/bookings/GroupAndSort";
import _ from "underscore";
import {
  SORT_DIRECTION_ASC,
  SORT_DIRECTION_DESC,
} from "../../util/report/ReportUtils";
import StyledButton from "../form/StyledButton";
import { useHistory } from "react-router";
import { ALL_EDIT_ROLES, isRoleApproved } from "../../util/user/UserUtil";
import {
  AuthorityType,
  compareObjectsAscending,
  compareObjectsDescending,
} from "../constants/securspace-constants";
import ReportEmpty from "../report/ReportEmpty";
import { searchRecordsByKeywordWaitListAvailability } from "../../util/SearchUtils";
import Busy from "../Busy";
import { networkErrorIssue } from "../../views/thirdparty/checkout/checkoutConstants";
import "../../App.css";

const Reservations = (props) => {
  const history = useHistory();
  const { account } = props;

  const reportFields = [
    { label: "Approved", name: "approved" },
    { label: "Zone Name", name: "zoneName" },
    { label: "Company Name", name: "companyName" },
    { label: "Location Name", name: "locationName" },
    { label: "Number of Spaces", name: "numberOfSpaces" },
    { label: "Equipment Length", name: "equipmentLength" },
    { label: "Start Date", name: "startDate" },
    { label: "End Date", name: "endDate" },
  ];

  const [reservations, setReservations] = useState([]);
  const [updateReservation, setUpdateReservation] = useState("🍔");
  const [groupBy, setGroupBy] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION_ASC);
  const [groupDirection, setGroupDirection] = useState(SORT_DIRECTION_ASC);
  const [searchBox, setSearchBox] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [brokeredAndWaitList, setBrokeredAndWaitList] = useState([]);
  const [thirdPartyReservations, setThirdPartyReservations] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let isMounted = true;

    Busy.set(true);

    getReservations()
      .then(
        (promise) => {
          if (isMounted) {
            let data = promise.body;
            setReservations(data);
            setFilteredData(
              data.sort((a, b) => b.creationDate - a.creationDate)
            );
          }
          Busy.set(false);
        },
        (error) => {
          if (isMounted) {
            if (!window.navigator.onLine) {
              toast.error(networkErrorIssue);
            } else {
              toast.error(getErrorMessageForStandardResponse(error));
            }
          }
          Busy.set(false);
        }
      )
      .catch((error) => {
        if (isMounted) {
          toast.error(getErrorMessageForStandardResponse(error));
        }
        Busy.set(false);
      });

    return () => {
      isMounted = false; // Cleanup flag to prevent state updates on unmounted component
    };
  }, [updateReservation]);

  useEffect(() => {
    let brokeredAndWaitList = [];
    let thirdParty = [];
    if (filteredData && filteredData.length > 0) {
      for (const reservation of filteredData) {
        if (
          reservation?.originType === AuthorityType.THIRD_PARTY &&
          !reservation?.approved
        ) {
          thirdParty.push(reservation);
        } else {
          brokeredAndWaitList.push(reservation);
        }
      }
      setBrokeredAndWaitList(brokeredAndWaitList);
      setThirdPartyReservations(thirdParty);
    } else {
      setBrokeredAndWaitList([]);
      setThirdPartyReservations([]);
    }
  }, [filteredData]);

  const searchChangeHandler = (event) => {
    let value = event.target.value;
    setSearchBox(value);
    const newArray = searchRecordsByKeywordWaitListAvailability(
      value,
      reservations
    );
    setFilteredData(newArray);

    //search(value);
  };

  const search = (value) => {
    let filtered = [];
    for (const item of reservations) {
      value.split(" ").forEach((word) => {
        for (const element of Object.entries(item)) {
          if (
            element.toString().toLowerCase().indexOf(word.toLowerCase()) !== -1
          ) {
            filtered.push(item);
            break;
          }
        }
      });
    }
    setFilteredData(filtered);
  };

  const groupByChangeHandler = (event) => {
    let value = event.target.value;
    setGroupBy(value);
    groupData(value);
  };

  const groupData = (value) => {
    let groupedData = _.groupBy(filteredData, value);
    let toArray = _.toArray(groupedData);
    let flattened = _.flatten(toArray);
    setFilteredData(flattened);
  };

  const sortByChangeHandler = (event) => {
    let value = event.target.value;
    setSortBy(value);
    sortData(value);
  };

  const sortData = (value) => {
    let tempData = [...filteredData];
    if (tempData) {
      if (sortDirection === SORT_DIRECTION_ASC) {
        tempData = tempData.sort((a, b) => {
          return compareObjectsAscending(a, b, value);
        });
      } else if (sortDirection === SORT_DIRECTION_DESC) {
        tempData = tempData.sort((a, b) => {
          return compareObjectsDescending(a, b, value);
        });
      }
    }
    setFilteredData(tempData);
  };

  const sortDirectionChangeHandler = () => {
    setSortDirection(
      sortDirection === SORT_DIRECTION_ASC
        ? SORT_DIRECTION_DESC
        : SORT_DIRECTION_ASC
    );
    setFilteredData([...filteredData.reverse()]);
  };

  const groupDirectionChangeHandler = () => {
    setGroupDirection(
      groupDirection === SORT_DIRECTION_ASC
        ? SORT_DIRECTION_DESC
        : SORT_DIRECTION_ASC
    );
    setFilteredData([...filteredData.reverse()]);
  };

  const createReservation = () => {
    history.push("/reservation-builder");
  };

  return (
    <>
      <ReportBackgroundContainer>
        <br />
        <div className="zone-button">
          {isRoleApproved(ALL_EDIT_ROLES, account?.userType) && (
            <StyledButton
              className={"ss-button-secondary"}
              label={"Create a reservation"}
              onClick={createReservation}
            />
          )}
        </div>
        <br />
        <header>
          <ul className="breadcrumb">
            <li>BOOKINGS</li>
            <li>Reservations</li>
          </ul>
          <h1 className="content-header-title">Reservations</h1>
        </header>
        <Filter
          searchBox={searchBox}
          searchChangeHandler={searchChangeHandler}
          placeHolder="Type any field to filter reservation result"
        />

        {filteredData?.length > 0 ? (
          <>
            <GroupAndSort
              groupByOptions={reportFields}
              groupByChangeHandler={groupByChangeHandler}
              groupBy={groupBy}
              groupDirection={groupDirection}
              groupDirectionChangeHandler={groupDirectionChangeHandler}
              sortBy={sortBy}
              sortByChangeHandler={sortByChangeHandler}
              sortDirection={sortDirection}
              sortDirectionChangeHandler={sortDirectionChangeHandler}
              asc={SORT_DIRECTION_ASC}
              desc={SORT_DIRECTION_DESC}
            />
          </>
        ) : (
          <ReportEmpty />
        )}

        {thirdPartyReservations && thirdPartyReservations.length > 0 && (
          <div>
            <h3>Pending Approval:</h3>
            <ReservationData
              account={account}
              setUpdateReservation={setUpdateReservation}
              reservations={thirdPartyReservations}
            />
            <div className="ss-load-more-records">
              You have reached end of the list
            </div>
          </div>
        )}
        {brokeredAndWaitList && brokeredAndWaitList.length > 0 && (
          <div>
            <h3 className="mb11px">Pending Customer Payment:</h3>
            <ReservationData
              account={account}
              setUpdateReservation={setUpdateReservation}
              reservations={brokeredAndWaitList}
            />
            <div className="ss-load-more-records">
              You have reached end of the list
            </div>
          </div>
        )}
      </ReportBackgroundContainer>
    </>
  );
};

export default Reservations;
