import React, { useEffect, useState } from "react";
import {
  galleryCheckout,
  requestCheckInFieldsForAccountId,
  saveCheckInFormRequest,
  uploadFileDataRequest,
} from "../request/check-in-requests";
import { correlationIdRequest } from "../request/correlationId-request";
import { toast } from "react-toastify";
import Busy from "../../Busy";
import ReferenceOption from "../../../controls/ReferenceOption";
import CheckInParentViewContainer from "./CheckInParentViewContainer";
import { getErrorMessageForStandardResponse } from "../../../util/NetworkErrorUtil";
import "../../../css/components/checkInParentControl.css";
import { DriverOption } from "../../../controls/DriverOption";

const GALLERY_BUCKET = "inventory";

const CheckInParentControl = (props) => {
  const {
    drivers,
    accountId,
    assetTypes,
    closeSubViewHandler,
    saveCompletedCallback,
    closeFormHandler,
    booking,
  } = props;

  const [fieldMap, setFieldMap] = useState();
  const [correlationId, setCorrelationId] = useState();
  const [options, setOptions] = useState();
  const [driverOptions, setDriverOptions] = useState();
  const [equipmentDataErrorMessage, setEquipmentDataErrorMessage] = useState();
  const [selectedDriver, setSelectedDriver] = useState();

  useEffect(() => {
    const promise1 = requestCheckInFieldsForAccountId(accountId);
    const promise2 = correlationIdRequest();
    Promise.allSettled([promise1, promise2])
      .then(([p1, p2]) => {
        try {
          setFieldMap(p1.value.body);
          setCorrelationId(p2.value.body);
        } catch (error) {
          toast.error("There was an error loading Checkin Form");
          closeFormHandler();
        }
      })
      .then(() => {
        Busy.set(false);
        if (assetTypes) {
          let options = assetTypes.map((item) => {
            return new ReferenceOption(item.key, item.value);
          });
          setOptions(options);
        }

        if (drivers?.length > 0) {
          let driverOptions = drivers.map((item) => {
            return new DriverOption(item);
          });
          setDriverOptions(driverOptions);
        } else if (drivers?.length === 0) {
          toast.error("There are no approved drivers for this booking");
          closeFormHandler();
        }
      });
  }, [accountId, assetTypes, closeFormHandler, drivers]);

  const saveGallery = (checkin, dropzone) => {
    let uploadedGalleryFiles = [];
    if (dropzone && dropzone.files.length > 0) {
      for (let i = 0; i < dropzone.files.length; i++) {
        let file = dropzone.files[i];
        if (file && file.type !== "fake") {
          uploadedGalleryFiles.push(file);
        }
      }
    }
    uploadGallery(uploadedGalleryFiles, checkin, dropzone);
  };

  const uploadGallery = (uploadedGalleryFiles, checkin, dropzone) => {
    let galleryFiles = [];
    uploadedGalleryFiles.forEach((file) => {
      if (file && file.name) {
        let time = new Date().getTime();
        let profileFileName = time + "_" + file.name;
        uploadFileData(
          GALLERY_BUCKET,
          file,
          dropzone,
          checkin,
          profileFileName
        );
        galleryFiles.push(profileFileName);
      }
    });

    updateGalleryTable(checkin, galleryFiles);
  };

  const uploadFileData = (folder, file, dropzone, checkin, profileFileName) => {
    uploadFileDataRequest(folder, file, profileFileName, checkin.id).then(
      (response) => {
        const data = response.body;
        if (data) {
          if (dropzone && dropzone.files.length > 0) {
            saveGallery(checkin, dropzone);
          } else {
            Busy.set(false);
            saveCompletedCallback();
          }
        }
      }
    );
  };

  const updateGalleryTable = (checkin, galleryFiles) => {
    let data = {
      activity: checkin,
      galleryFiles: galleryFiles,
    };

    galleryCheckout(checkin, data).then(() => {
      Busy.set(false);
      saveCompletedCallback();
    });
  };

  const handleEquipmentError = (errorMessage) => {
    setEquipmentDataErrorMessage(errorMessage);
  };

  const handleEquipmentErrorCleared = () => {
    setEquipmentDataErrorMessage(null);
  };

  const handlePanelCloseEvent = () => {
    closeSubViewHandler();
  };
  const dataUrlToFile = (dataUrl, fileName) => {
    // Step 1: Extract the MIME type and base64 data
    const [metadata, base64Data] = dataUrl.split(",");

    // Step 2: Decode the base64 string into binary data
    const byteString = atob(base64Data);

    // Step 3: Convert binary string to an array of bytes
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    // Step 4: Create a Blob object from the byte array
    const mimeType = metadata.match(/:(.*?);/)[1]; // Extract MIME type (e.g., "image/jpeg")
    const blob = new Blob([uintArray], { type: mimeType });

    // Step 5: Create a File from the Blob
    const file = new File([blob], fileName, { type: mimeType });

    return file;
  };

  const saveCheckInForm = (formValues) => {
    let dropzone;
    if (typeof formValues.dropzone !== "undefined") {
      dropzone = formValues.dropzone;
    } else {
      dropzone = null;
    }

    Busy.set(true);

    let data = {
      bookingId: booking.id,
      locationId: booking.location.id,
      buyerId: booking.buyerAccount.id,
      containerNumber: formValues.containerNumber,
      trailerNumber: formValues.trailerNumber,
      chassisNumber: formValues.chassisNumber,
      chassisLicensePlateNumber: formValues.chassisLicensePlateNumber,
      sealNumber: formValues.sealNumber,
      driver: selectedDriver,
      truckLicensePlateNumber: formValues.truckLicensePlateNumber,
      notes: formValues.notes,
      assetSize: formValues.assetSize,
      assetType: formValues.assetType.value,
      equipmentDataErrorMessage: equipmentDataErrorMessage,
      correlationId: correlationId,
    };
    let uploadedGalleryFiles = [];

    if (dropzone && dropzone.files.length > 0) {
      for (let i = 0; i < dropzone.files.length; i++) {
        let file = dropzone.files[i];
        if (file && file.type !== "fake") {
          const fileData = {
            name: file.name,
            size: file.size,
            type: file.type,
            dataUrl: file.dataURL,
          };
          uploadedGalleryFiles.push(fileData);
        }
      }
    }
    const updatedArray = [];
    if (!!uploadedGalleryFiles) {
      for (let i = 0; i < uploadedGalleryFiles?.length; i++) {
        const newFile = dataUrlToFile(
          uploadedGalleryFiles[i]?.dataUrl,
          uploadedGalleryFiles[i]?.name
        );
        updatedArray.push(newFile);
      }
    }
    const dataToBeSent = new FormData();
    dataToBeSent.append("checkInRequest", JSON.stringify(data));
    if (updatedArray?.length == 0) dataToBeSent.append("checkInImages", null);
    updatedArray.forEach((file, index) => {
      dataToBeSent.append("checkInImages", file); // Notice the "[]" which indicates an array in FormData
    });

    saveCheckInFormRequest(dataToBeSent)
      .then(
        (response) => {
          Busy.set(false);
          saveCompletedCallback();
          // const checkin = response.body;
          // if (checkin.id) {
          //   if (dropzone && dropzone.files.length > 0) {
          //     saveGallery(checkin, dropzone);
          //   } else {
          //     Busy.set(false);
          //     saveCompletedCallback();
          //   }
          // }
        },
        (error) => {
          toast.error(getErrorMessageForStandardResponse(error));
          Busy.set(false);
        }
      )
      .catch((error) => {
        toast.error(getErrorMessageForStandardResponse(error));
        Busy.set(false);
      });
    let ele = document.getElementById("root");
    ele.style.overflow = "";
  };

  return (
    <>
      <CheckInParentViewContainer
        driverOptions={driverOptions}
        drivers={drivers}
        selectedDriver={selectedDriver}
        setSelectedDriver={setSelectedDriver}
        booking={booking}
        saveCheckInForm={saveCheckInForm}
        handlePanelCloseEvent={handlePanelCloseEvent}
        handleEquipmentError={handleEquipmentError}
        handleEquipmentErrorCleared={handleEquipmentErrorCleared}
        fieldMap={fieldMap}
        correlationId={correlationId}
        options={options}
        closeFormHandler={closeFormHandler}
        closeSubViewHandler={closeSubViewHandler}
      />
    </>
  );
};

export default CheckInParentControl;
