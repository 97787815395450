import React, { Component } from "react";
import "../css/views/booking-common.css";
import "../css/theme/mainContent.css";
import "../css/theme/forms.css";
import "../css/theme/forms-block.css";
import "../css/theme/buttons.css";
import Error from "./Error";
import { createLogoutOnFailureHandler } from "../util/LogoutUtil";
import Busy from "./Busy";
import DropGallery from "../components/DropGallery";
import OCREngine from "../util/OCREngineUtil";
import { AppContext } from "../context/app-context";
import OCREnabledField from "./checkin/component/OCREnabledField";
import CheckoutTable from "./checkout/CheckoutTable";
import { getApprovedDrivers } from "./checkout/checkoutRequests";
import { getErrorMessageForStandardResponse } from "../util/NetworkErrorUtil";
import { toast } from "react-toastify";
import DriverSelect from "./checkin/component/DriverSelect";
import { DriverOption } from "../controls/DriverOption";
import { ajaxRequest } from "../ajax";

const GALLERY_BUCKET = "inventory";

class CheckOutForm extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = Object.assign({
      driverLicenseNumber: "",
      driverFirstName: "",
      driverLastName: "",
      truckLicensePlateNumber: this.props.inventory?.truckLicensePlateNumber
        ? this.props.inventory?.truckLicensePlateNumber
        : "",
      notes: "",
      approvedDrivers: [],
      driverOptions: null,
      selectedDriver: "",
    });

    this.dropzone = null;
  }

  componentDidMount() {
    this.loadApprovedDrivers();
  }

  loadApprovedDrivers = () => {
    getApprovedDrivers(this.props.inventory.buyerId)
      .then(
        (response) => {
          const drivers = response.body;
          this.setState({ approvedDrivers: drivers });
          if (drivers?.length > 0) {
            let driverOptions = drivers.map((item) => {
              return new DriverOption(item);
            });
            this.setState({ driverOptions: driverOptions });
          } else if (drivers?.length === 0) {
            toast.error("There are no approved drivers for this booking");
          }
        },
        (error) => {
          toast(getErrorMessageForStandardResponse(error));
        }
      )
      .catch((error) => {
        toast(getErrorMessageForStandardResponse(error));
      });
  };

  updateDropzone = (dropzone) => {
    this.dropzone = dropzone;
  };

  closeSubViewHandler = () => {
    this.props.closeSubViewHandler();
  };
  dataUrlToFile(dataUrl, fileName) {
    // Step 1: Extract the MIME type and base64 data
    const [metadata, base64Data] = dataUrl.split(",");

    // Step 2: Decode the base64 string into binary data
    const byteString = atob(base64Data);

    // Step 3: Convert binary string to an array of bytes
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    // Step 4: Create a Blob object from the byte array
    const mimeType = metadata.match(/:(.*?);/)[1]; // Extract MIME type (e.g., "image/jpeg")
    const blob = new Blob([uintArray], { type: mimeType });

    // Step 5: Create a File from the Blob
    const file = new File([blob], fileName, { type: mimeType });

    return file;
  }

  handleFieldChange = (event) => {
    this.setState({ [event.target.name]: event.target.value.toUpperCase() });
  };

  checkOutAsset = () => {
    let _this = this;
    this.setState({ errorMessage: "" });

    if (!this.state.driverFirstName) {
      this.setState({ errorMessage: "Please Select a Driver!" });
      return;
    }

    if (!this.state.driverLastName) {
      this.setState({ errorMessage: "Please Select a Driver!" });
      return;
    }
    if (!this.state.truckLicensePlateNumber) {
      this.setState({
        errorMessage: "Truck License Plate Number is required!",
      });
      return;
    }

    Busy.set(true);

    const dataPayload = {
      id: this.props.inventory.id,
      bookingId: this.props.inventory.bookingId,
      locationId: this.props.inventory.locationId,
      driver: this.state.selectedDriver,
      truckLicensePlateNumber: this.state.truckLicensePlateNumber,
      notes: this.state.notes,
      correlationId: this.props.inventory.correlationId,
    };
    let uploadedGalleryFiles = [];

    if (_this.dropzone && _this.dropzone.files.length > 0) {
      for (let i = 0; i < _this.dropzone.files.length; i++) {
        let file = _this.dropzone.files[i];
        if (file && file.type !== "fake") {
          const fileData = {
            name: file.name,
            size: file.size,
            type: file.type,
            dataUrl: file.dataURL,
          };
          uploadedGalleryFiles.push(fileData);
        }
      }
    }
    const updatedArray = [];
    if (!!uploadedGalleryFiles) {
      for (let i = 0; i < uploadedGalleryFiles?.length; i++) {
        const newFile = this.dataUrlToFile(
          uploadedGalleryFiles[i]?.dataUrl,
          uploadedGalleryFiles[i]?.name
        );
        updatedArray.push(newFile);
      }
    }
    const dataToBeSent = new FormData();
    dataToBeSent.append("checkOutRequest", JSON.stringify(dataPayload));
    if (updatedArray?.length == 0) dataToBeSent.append("checkOutImages", null);
    updatedArray.forEach((file, index) => {
      dataToBeSent.append("checkOutImages", file); // Notice the "[]" which indicates an array in FormData
    });

    ajaxRequest({
      url: "check-out-images",
      data: dataToBeSent,
      type: "POST",
      contentType: false, // Don't manually set contentType when sending FormData
      processData: false, // Don't process data, let FormData handle it
      dataType: "json",
      success: (data) => {
        Busy.set(false);
        _this.props.checkOutCompletedCallback();
        // if (data.id) {
        //   if (_this.dropzone && _this.dropzone.files.length > 0) {
        //     _this.saveGallery(data);
        //   } else {

        //   }
        // }
      },
      statusCode: {
        401: createLogoutOnFailureHandler(this.props.handleLogout),
      },
      error: () => {
        Busy.set(false);
      },
    });
  };

  saveGallery = (checkout) => {
    let uploadedGalleryFiles = [];
    this.galleryFiles = [];

    if (this.dropzone && this.dropzone.files.length > 0) {
      for (let i = 0; i < this.dropzone.files.length; i++) {
        let file = this.dropzone.files[i];
        if (file && file.type !== "fake") {
          //new file
          uploadedGalleryFiles.push(file);
        } else {
          this.galleryFiles.push(file?.name?.replace(/\s+/g, ""));
        }
      }
    }
    this.uploadGallery(this, uploadedGalleryFiles, checkout);
  };

  uploadGallery(_this, uploadedGalleryFiles, checkout) {
    if (!uploadedGalleryFiles || uploadedGalleryFiles.length === 0) {
      _this.updateGalleryTable(checkout);
      return;
    }

    let file = uploadedGalleryFiles.shift();

    if (file && file.name) {
      _this.uploadFileData(
        GALLERY_BUCKET,
        file,
        checkout.id,
        function (uploadedFileName, textStatus, jqXHR) {
          _this.galleryFiles.push(uploadedFileName);
          _this.uploadGallery(_this, uploadedGalleryFiles, checkout);
        }
      );
    }
  }

  uploadFileData(folder, file, inventoryId, onSuccess) {
    let _this = this;

    let data = new FormData();
    data.append("inputStream", file.dataURL);
    let time = new Date().getTime();
    let profileFileName = time + "_" + file?.name?.replace(/\s+/g, "");
    ajaxRequest({
      url: `file/upload/${folder}?name=${profileFileName}&inventoryId=${inventoryId}&contentType=${file.type}`,
      type: "POST",
      data: data,
      cache: false,
      processData: false, // Don't process the files
      contentType: false, // Set content type to false as jQuery will tell the server its a query string request
      success: function (data, textStatus, jqXHR) {
        onSuccess(data, textStatus, jqXHR);
      },
      statusCode: {
        401: createLogoutOnFailureHandler(this.props.handleLogout),
      },
      error: function (jqXHR, textStatus, errorThrown) {
        Busy.set(false);
        _this.setState({ errorMessage: "File upload failed:  " + textStatus });
      },
    });
  }

  updateGalleryTable(checkout) {
    let _this = this;
    // save files names in gallery table and delete???

    ajaxRequest({
      url: "gallery-check-out",
      data: JSON.stringify({
        activity: checkout,
        galleryFiles: this.galleryFiles,
      }),
      type: "POST",
      contentType: "application/json; charset=UTF-8",
      dataType: "json",
      success: (data) => {
        Busy.set(false);
        _this.props.checkOutCompletedCallback();
      },
      statusCode: {
        401: createLogoutOnFailureHandler(this.props.handleLogout),
      },
      error: () => {
        Busy.set(false);
      },
    });
  }

  readBarcodeResults = (results) => {
    let fields = OCREngine.setDriversLicenseFields(results);
    let firstName = fields.firstName;
    let lastName = fields.lastName;
    let driversLicense = fields.driversLicense;

    this.setState({
      driverFirstName: firstName,
      driverLastName: lastName,
      driverLicenseNumber: driversLicense,
    });
  };

  handleDriverChange = (event) => {
    const driverId = event.target.value.value;
    if (this.state.approvedDrivers.length > 0) {
      for (const driver of this.state.approvedDrivers) {
        if (driverId === driver.id) {
          this.setState({
            selectedDriver: driver,
            driverLicenseNumber: driver.licenseNumber,
            driverFirstName: driver.firstName,
            driverLastName: driver.lastName,
          });
          this.setState({ errorMessage: "" });
        }
      }
    }
  };

  render() {
    const appContext = this.context;
    const { user } = appContext;

    return (
      <form className="ss-form ss-block no-padding">
        <div className="modal-body">
          <CheckoutTable
            containerNumber={this.props.inventory.containerNumber}
            trailerNumber={this.props.inventory.trailerNumber}
            chassisNumber={this.props.inventory.chassisNumber}
            chassisLicensePlateNumber={
              this.props.inventory.chassisLicensePlateNumber
            }
            sealNumber={this.props.inventory.sealNumber}
            assetType={this.props.inventory.assetType}
            assetSize={this.props.inventory.assetSize}
            checkInDate={this.props.inventory.checkInDate}
          />
          <DriverSelect
            drivers={this.state.approvedDrivers}
            handleDriverChange={this.handleDriverChange}
            selectedDriver={this.state.selectedDriver}
            driverOptions={this.state.driverOptions}
          />
          <OCREnabledField
            name="truckLicensePlateNumber"
            label="TRUCK LICENSE PLATE NUMBER"
            value={this.state.truckLicensePlateNumber}
            onChange={this.handleFieldChange}
            setText={(text) => this.setState({ truckLicensePlateNumber: text })}
            placeholder="Enter the truck license plate number"
            isEnabled={user.rekognitionPrivileges}
            correlationId={this.props.inventory.correlationId}
          />
          <fieldset className="ss-middle custom-border">
            <label htmlFor="notes">REMARKS OR NOTES</label>
            <textarea
              id="notes"
              name="notes"
              value={this.state.notes}
              onChange={(event) =>
                this.setState({ [event.target.name]: event.target.value })
              }
              placeholder="Enter any notes about the check out."
            />
          </fieldset>

          {this.state.errorMessage ? (
            <Error>{this.state.errorMessage}</Error>
          ) : (
            ""
          )}

          <br />
          <p>Click below to add images</p>
          <fieldset className="ss-top ss-dz">
            <DropGallery
              bucket={GALLERY_BUCKET}
              locationGallery={this.state.locationGallery}
              locationId={this.state.id}
              updateDropzone={this.updateDropzone}
              comp="checkOut"
            />
          </fieldset>
        </div>
        <div className="modal-footer">
          <div className="ss-check-in-button-container text-center">
            <button
              type="button"
              className="ss-button-primary ss-dialog-button"
              onClick={() => this.checkOutAsset()}
            >
              Check Out
            </button>
            <button
              type="button"
              className="ss-button-secondary  ss-dialog-button"
              onClick={this.props.closeSubViewHandler}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default CheckOutForm;
