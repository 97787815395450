import React, { useState, useEffect } from "react";
import Steps from "../../steps/Steps";
import PropTypes from "prop-types";
import ReportFormDateInput from "../../report/ReportFormDateInput";
import moment from "moment";
import "./fine.css";

const FineStartEndDateAdd = (props) => {
  const { stepNumber, startDate, endDate, addStartDate, addEndDate } = props;

  const [disableContinue, setDisableContinue] = useState(true);

  useEffect(() => {
    const invalidDateRange = moment(startDate).isAfter(endDate);

    if (invalidDateRange) {
      setDisableContinue(true);
    } else {
      setDisableContinue(false);
    }
  }, [startDate, endDate]);

  const datePickerClassName = "ss-report-form-fine-date-picker";

  return (
    <div>
      <ReportFormDateInput
        label={"START DATE:"}
        date={startDate}
        onDateChange={addStartDate}
        styles={datePickerClassName}
      />
      <ReportFormDateInput
        label={"END DATE:"}
        date={endDate}
        onDateChange={addEndDate}
        styles={datePickerClassName}
      />
      <Steps step={stepNumber} canContinue={disableContinue} {...props} />
    </div>
  );
};

FineStartEndDateAdd.propTypes = {
  stepNumber: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  addStartDate: PropTypes.func,
  addEndDate: PropTypes.func,
};

export default FineStartEndDateAdd;
