import React, { useEffect, useState, useRef } from "react";
import useQuery from "../../../util/query/useQuery";
import useJwt from "../../../util/jwt/useJwt";
import {
  getPaymentMethods,
  getReservation,
  getFineById,
  getZone,
  getSecurityDepositPaid,
  regenerateNewUrl,
} from "../requests/thirdparty-requests";
import { getErrorMessageForStandardResponse } from "../../../util/NetworkErrorUtil";
import { ErrorRounded } from "@material-ui/icons";
import { useParams } from "react-router";
import ReservationCheckout from "../../../components/reservation/ReservationCheckout";
import DisplayChargeAmount from "../../../components/chargeAmount/DisplayChargeAmount";
import moment from "moment";
import SolidButton from "../../../components/form/SolidButton";
import ReportBackgroundContainer from "../../../components/report/ReportBackgroundContainer";
import ReportHeader from "../../../components/report/ReportHeader";
import ReportTitle from "../../../components/report/ReportTitle";
import { toast } from "react-toastify";
import ZoneCheckout from "../../../components/zone/ZoneCheckout";
import {
  PostMessageStatus,
  poartOfOaklandUrl,
} from "../../../components/constants/securspace-constants";
import OutlinedButton from "../../../components/form/OutlinedButton";
import Busy from "../../../components/Busy";
import { useHistory, useLocation } from "react-router-dom";
import { Modal } from "react-router-modal";
import { ajaxRequest } from "../../../ajax";
import "../../../css/views/createEditLocation.css";
import "../../../css/theme/forms.css";
import "../../../css/theme/forms-block.css";
import "../../../css/theme/buttons.css";
import {
  linkConvertedToBooking,
  networkErrorIssue,
  paymentExpiredText,
  requiredSecurityDepositText,
  reservationExpiredText,
  stateConstant,
} from "./checkoutConstants";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./checkout.css";
import "./deletePopUp.css";
import CreditCardModalPopUp from "./creditCardModalPopup";
import DisplayAddedPaymentMethod from "./displayAddedPaymentMethod";
import CrediCardDisplayPopUp from "./creditCardDisplayPopUp";
import ReportDataRow from "../../../components/report/ReportDataRow";
import ReportData from "../../../components/report/ReportData";
import SecurityDepositSuccessfull from "./securityDepositSuccessfull";
import FineCheckout from "./fineCheckout/fineCheckout";
const Checkout = () => {
  const { reservationId } = useParams();
  const { token } = useQuery();

  const {
    id,
    username,
    fineAmount,
    finePeakPrice,
    exp,
    securityDepositAmount,
    securityDepositRequired,
  } = useJwt();
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const bankNameRef = useRef(null);
  const address1Ref = useRef(null);
  const address2Ref = useRef(null);
  const cityRef = useRef(null);
  const zipRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const [expirePopUp, setExpirePopUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [reservation, setReservation] = useState({});
  const [zone, setZone] = useState({});
  const [disabled, setDisabled] = useState(true);
  const history = useHistory();
  const [checkedButtonPayment, setCheckedButtonPayment] = useState("");
  const [securityDepositAmountState, setSecurityDepositAmountState] = useState(
    (securityDepositAmount / 100).toFixed(2)
  ); // please change this to securityDepositAmount instead of 1000 once testing is done
  const [securityDepositRequiredState, setSecurityDepositRequiredState] =
    useState(securityDepositRequired); // change this to normal value once testing is done
  const [securityDepositPaid, setSecurityDepositPaid] = useState(false);
  const [linkUsed, setLinkUsed] = useState(false);
  const [securityInvoiceNumber, setSecurityInvoiceNumber] = useState("");
  const [securityDepositPaymentType, setSecurityDepositPaymentType] =
    useState("");

  const [securityDepositnickName, setsecurityDepositnickName] = useState("");
  const [sourceSecurityDeposit, setsourceSecurityDeposit] = useState("");
  const [dateInitiatedSecurityDeposit, setdateInitiatedSecurityDeposit] =
    useState("");
  const [securityDepositPaymentStatus, setsecurityDepositPaymentStatus] =
    useState(false);
  const [fineByIdDetails, setFineByIdDetails] = useState({});

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [zip, setZip] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [accountType, setAccountType] = useState(null);
  const [bookSpaceForm, setBookSpaceForm] = useState(false);
  const [routingNumber, setRoutingNumber] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null);
  const [disablePayment, setDisablePayment] = useState(false);
  const [errorValidationMessageZip, setErrorValidationMessageZip] =
    useState("");
  const [errorValidationMessagePhone, setErrorValidationMessagePhone] =
    useState("");
  const [loadPaymentMethod, setLoadPaymentMethod] = useState(false);
  const [cvv, setCvv] = useState("");
  const [selectingCreditCardIndex, setSelectingCreditCardIndex] =
    useState(null);
  const [selectingAchIndex, setSelectingAchIndex] = useState(null);
  const [showSecurityDepositPayment, setShowSecurityDepositPayment] =
    useState(false);

  const [loadComponent, setLoadComponent] = useState(false);

  const [showAchBookSpaceModal, setShowAchBookSpaceModal] = useState(false);
  const [showCreditCardModal, setShowCreditCardModal] = useState(false);
  const [achPayment, setAchPayment] = useState(false);
  const [selectedIndex1, setSelectedIndex1] = useState(null);
  const [selectedIndex2, setSelectedIndex2] = useState(null);

  const SOURCE = "sps-checkout";
  const Android = window.Android;
  const webkit = window.webkit;

  useEffect(() => {}, [loadComponent]);

  useEffect(() => {
    window.closeCheckoutWindow = () => {
      window.close();
    };
  }, []);

  useEffect(() => {
    if (moment().isAfter(moment.unix(exp)) && !(Number(fineAmount) > 0)) {
      setErrorMessage(reservationExpiredText);
      setExpirePopUp(true);
    }
  }, [exp, setErrorMessage]);

  useEffect(() => {
    Busy.set(true);
    getSecurityDepositPaid(id, token)
      .then((response) => {
        setSecurityDepositPaid(!!response?.body?.securityDepositPaid);
        setLinkUsed(!!response?.body?.used);
      })
      .catch((err) => {
        setErrorMessage(getErrorMessageForStandardResponse(err));
        Busy.set(false);
      });
  }, []);

  useEffect(() => {
    Busy.set(true);
    if (Number(fineAmount) > 0) {
      getFineById(reservationId)
        .then((responseFine) => {
          setFineByIdDetails(responseFine.body);
          Busy.set(false);
        })
        .catch((error) => {
          setErrorMessage(getErrorMessageForStandardResponse(error));
          Busy.set(false);
        });
    } else {
      getReservation(reservationId)
        .then((responseReservation) => {
          setReservation(responseReservation.body);
          getZone(responseReservation.body.zoneId)
            .then((zoneResponse) => {
              setZone(zoneResponse.body);
            })
            .catch((error) => {
              setErrorMessage(getErrorMessageForStandardResponse(error));
            });
          Busy.set(false);
        })
        .catch((error) => {
          setErrorMessage(getErrorMessageForStandardResponse(error));
          Busy.set(false);
        });
    }
  }, []);
  useEffect(() => {
    if (selectedIndex1 === null && selectedIndex2 === null) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setSelectingCreditCardIndex(selectedIndex1);
    setSelectingAchIndex(selectedIndex2);
  }, [selectedIndex1, selectedIndex2, selectingCreditCardIndex]);
  useEffect(() => {
    Busy.set(true);
    getPaymentMethods(id)
      .then((responsePayment) => {
        setPaymentMethods(responsePayment.body);
        responsePayment?.body?.forEach((paymentMethod) => {
          if (paymentMethod?.defaultPaymentMethodForProfile)
            setPaymentMethodId(paymentMethod.id);

          if (paymentMethod.paymentProfileId && paymentMethod.type === "ACH") {
            setDisabled(false);
            // setCheckedButtonPayment("abc");
            setAccountType(paymentMethod?.accountType);
            setBankName(paymentMethod?.bankName);
            //setDisablePayment(true);
            setCheckedButtonPayment("showPaymentDetails");
          } else if (paymentMethod.type === "CARD") {
            setDisabled(false);
            //setDisablePayment(true);
            setCheckedButtonPayment("showPaymentDetails");
          }
        });

        Busy.set(false);
      })
      .catch((error) => {
        setErrorMessage(getErrorMessageForStandardResponse(error));
        Busy.set(false);
      });
  }, [loadPaymentMethod]);

  const handlePaymentIdSelected = (event) => {
    setPaymentMethodId(event.target.id);
    setDisabled(false);
  };

  const handlePaymentMethodAdded = () => {
    getPaymentMethods(id)
      .then((response) => {
        setPaymentMethods(response.body);
        setPaymentMethodId(paymentMethodId);
      }, toastMessage)
      .catch(toastMessage);
  };

  const handlePaymentMethodClick = () => {
    history.push({
      pathname: "/manage-payments",
      search: `?token=${token}&showBack=true`,
    });
  };

  const handleBookSpace = () => {
    setBookSpaceForm(true);
    if (selectingCreditCardIndex !== null && selectingCreditCardIndex !== -1) {
      setShowCreditCardModal(true);
      setShowAchBookSpaceModal(false);
      return;
    }
    if (selectingAchIndex !== null && selectingAchIndex !== -1) {
      setShowAchBookSpaceModal(true);
      setShowCreditCardModal(false);
      return;
    }
    if (!!cvv) {
      setShowCreditCardModal(true);
      setShowAchBookSpaceModal(false);
      return;
    }
  };

  const blurActiveInput = () => {
    if (document.activeElement && document.activeElement.tagName === "INPUT") {
      document.activeElement.blur();
    }
  };

  // console.log("selectingCreditCardIndex", selectingCreditCardIndex);
  // console.log("selectingAchIndex", selectingAchIndex);
  // console.log("setShowAchBookSpaceModal", showAchBookSpaceModal);
  // console.log("showCreditCardModal", showCreditCardModal);
  // console.log("selectedIndex1", selectedIndex1);
  // console.log("selectedIndex2", selectedIndex2);
  // console.log("windowLocation", window.location.href);

  const toastMessage = (error) => {
    const errorMessage = getErrorMessageForStandardResponse(
      error,
      "Failed to complete booking",
      true
    );
    if (errorMessage) toast.error(errorMessage);
    Busy.set(false);
  };

  const showCancelButton = () => {
    let iosNativeApp =
      webkit && webkit.messageHandlers && webkit.messageHandlers.nativeapp;
    let opener = window.opener;
    let androidNativeApp = Android && Android.onCancel;

    return iosNativeApp || opener || androidNativeApp;
  };

  const handleCancel = () => {
    postMessageAndClose(PostMessageStatus.CANCEL);
  };

  const postMessageAndClose = (status, data) => {
    // let openerParent = window.opener || window.parent;

    // opener will return the window that opened it
    // parent will return any sort if iframe or webview that opened it
    // if (openerParent) {
    //   openerParent.postMessage(
    //     {
    //       status: status,
    //       data: data,
    //       source: SOURCE,
    //     },
    //     openerParent.origin
    //   );

    //    //if there is no opener, window.close will not work, so don't call it
    //   if (window.opener) {
    //     setTimeout(() => {
    //       window.close();
    //     }, 2500);
    //   }
    //   history.push("");
    // }

    // custom ios postMessage listener logic
    webkit &&
      webkit.messageHandlers &&
      webkit.messageHandlers.nativeapp.postMessage({
        status: status,
        data: data,
        source: SOURCE,
      });

    if (Android && Android.onSuccess) {
      Android.onSuccess({
        status: status,
        data: data,
        source: SOURCE,
      });
    } else if (Android && Android.onCancel) {
      Android.onCancel({
        status: status,
        data: data,
        source: SOURCE,
      });
    }
  };

  const handleChecked = (e) => {
    setCheckedButtonPayment(e.target.value);
  };

  const handleSubmit = () => {
    Busy.set(true);

    ajaxRequest({
      url: "payment-method/createPaymentMethod",
      data: JSON.stringify({
        accountId: id,
        bankName: bankName,
        accountType: accountType,
        firstName: firstName,
        lastName: lastName,
        customerPhone: phoneNumber,
        addressLine1: address1,
        addressLine2: address2,
        zip: zip,
        city: city,
        state: state,
        country: "USA",
      }),
      contentType: "application/json",
      method: "POST",
      success: (res) => {
        Busy.set(false);
        toast.success("ACH account added successfully!");
        setAchPayment(true);
        setPaymentMethodId(res.id);
        // setDisabled(false);
        //setDisablePayment(true);
        setLoadPaymentMethod(!loadPaymentMethod);
        setCvv("");
        setCheckedButtonPayment("showPaymentDetailsACH");
        setFirstName(null);
        setLastName(null);
        setAddress1(null);
        setAddress2(null);
        setCity(null);
        setZip(null);
        setPhoneNumber(null);
        setAccountType(null);
        setState(null);
        setErrorValidationMessagePhone("");
        setErrorValidationMessageZip("");
      },

      error: (jqXHR) => {
        Busy.set(false);
        let errorMessage = jqXHR.responseJSON
          ? jqXHR.responseJSON.message
            ? jqXHR.responseJSON.message.trim()
            : ""
          : "";
        if (!window.navigator.onLine) {
          toast.error(networkErrorIssue);
        } else {
          toast.error(errorMessage);
        }
      },
    });
  };
  // console.log("accounttype", accountType);

  const handleSubmitBookSpace = () => {
    // Busy.set(false);
    const paymentId = paymentMethods?.filter((ele) => ele.type === "ACH")[0].id;
    let urlUpdated;

    const baseUrl = `zone/reservation/tsys/ach/${paymentId}/complete?reservationId=${reservationId}&buyerAccountId=${id}`;
    const fineUrl = `fine-assessments/pay-fine/${paymentId}/ach/${reservationId}`;
    if (Number(fineAmount) > 0) {
      urlUpdated = fineUrl;
    } else {
      urlUpdated = baseUrl;
    }

    let payload = {
      bankName: bankName,
      routingNumber: routingNumber,
      accountNumber: accountNumber,
      accountType: accountType,
      token: token,
    };
    if (Number(fineAmount) > 0) {
      payload.securityDepositPayment = false;
      payload.transactionAmount = ((fineAmount + finePeakPrice) / 100).toFixed(
        2
      );
    }
    if (!securityDepositPaid) {
      if (securityDepositRequiredState) {
        payload.securityDepositPayment = true;
        payload.transactionAmount = (securityDepositAmount / 100).toFixed(2);
      }
    }

    ajaxRequest({
      url: urlUpdated,
      // securityDepositRequiredState
      //   ? `zone/reservation/tsys/ach/${paymentId}/complete?reservationId=${reservationId}&buyerAccountId=${id}`
      //   : Number(fineAmount) > 0
      //   ? `fine-assessments/${paymentId}/pay-fine-ach/${reservationId}`
      //   : `zone/reservation/tsys/ach/${paymentId}/complete?reservationId=${reservationId}&buyerAccountId=${id}`,
      data: JSON.stringify(payload),
      contentType: "application/json",
      method: "POST",
      success: (res) => {
        Busy.set(false);
        const accountNum = accountNumber?.slice(-4);
        if (Number(fineAmount) > 0) {
          toast.success("Fine Paid Successfully!");
          setBookSpaceForm(false);
          history.push({
            pathname: "/payment-successful",
            state: {
              amount: Number(fineAmount) + Number(finePeakPrice),
              invoiceNumber: res?.invoiceNumber,
              transferType: "Bank Transfer",
              dateInitiated: res?.createdOn,
              nickName: res?.nickName,
              type: "fineType",
              source: `Bank Account ending in ${accountNum} - ${bankName}`,
            },
          });
        } else {
          if (securityDepositPaid) {
            toast.success("Space Booked Successfully!");
            setBookSpaceForm(false);
            history.push({
              pathname: "/payment-successful",
              state: {
                amount: res?.initialPaymentAmount,
                invoiceNumber: res?.invoiceNumber,
                transferType:
                  res?.paymentType === "ACH"
                    ? "Bank Transfer"
                    : "Credit Card Transfer",
                dateInitiated: res?.approvedOn,
                nickName: res?.nickName,
                source: `Bank Account ending in ${accountNum} - ${bankName}`,
              },
            });
            postMessageAndClose(PostMessageStatus.SUCCESS, res);
          } else {
            if (securityDepositRequiredState) {
              toast.success("Payment done successfully!");
              setSecurityDepositRequiredState(res?.securityDepositRequired);
              setsecurityDepositPaymentStatus(true);
              setdateInitiatedSecurityDeposit(res?.dateOfDepositProcessed);
              setsourceSecurityDeposit(
                `Bank Account ending in ${accountNum} - ${bankName}`
              );
              setsecurityDepositnickName(res?.nickName);
              setSecurityInvoiceNumber(res?.invoiceNumber);
              setSecurityDepositPaymentType("ACH Transfer");
              setSecurityDepositRequiredState(res?.securityDepositRequired);
              setBookSpaceForm(false);
              setRoutingNumber("");
              setAccountNumber("");
            } else {
              toast.success("Space Booked Successfully!");
              setBookSpaceForm(false);
              history.push({
                pathname: "/payment-successful",
                state: {
                  amount: res?.initialPaymentAmount,
                  invoiceNumber: res?.invoiceNumber,
                  transferType:
                    res?.paymentType === "ACH"
                      ? "Bank Transfer"
                      : "Credit Card Transfer",
                  dateInitiated: res?.approvedOn,
                  nickName: res?.nickName,
                  source: `Bank Account ending in ${accountNum} - ${bankName}`,
                },
              });
              postMessageAndClose(PostMessageStatus.SUCCESS, res);
            }
          }
        }
      },
      error: (jqXHR) => {
        Busy.set(false);
        let errorMessage = jqXHR.responseJSON
          ? jqXHR.responseJSON.message
            ? jqXHR.responseJSON.message.trim()
            : ""
          : "";
        if (!window.navigator.onLine) {
          toast.error(networkErrorIssue);
        } else {
          toast.error(errorMessage);
        }
      },
    });
    Busy.set(true);
  };

  const onInputChange = (e, setTxt) => {
    const value = e.target.value;
    const nonNumericValue = value.replace(/[0-9]/g, "");

    const re = /^[A-Za-z]+$/;
    if (
      !!nonNumericValue ||
      re.test(nonNumericValue) ||
      nonNumericValue === ""
    ) {
      setTxt(nonNumericValue);
    }
  };

  const onNumberChange = (e, setPhone) => {
    const regex = /[A-Za-z]/;
    if (regex.test(e.target.value)) return;
    if (e.target.name === "zip") {
      if (e.target.value?.trim().length !== 5) {
        setErrorValidationMessageZip("Zip code should be of 5 digits");
      } else {
        setErrorValidationMessageZip("");
      }
    }
    if (e.target.name === "phoneNumber") {
      if (e.target.value?.trim().length !== 10) {
        setErrorValidationMessagePhone("Phone number should be of 10 digits");
      } else {
        setErrorValidationMessagePhone("");
      }
    }
    const value = e.target.value?.trim();
    if (!isNaN(value)) setPhone(value);
  };

  const segregatedData = (data, val) => {
    const updateArr = data?.reduce((acc, item) => {
      const type = item.type;
      acc[type] = (acc[type] || []).concat(item);
      return acc;
    }, []);
    return updateArr[val];
  };
  // console.log("accountType", accountType);

  const handleCancelAch = () => {
    setCheckedButtonPayment("showPaymentDetails");
    setFirstName(null);
    setLastName(null);
    setBankName(null);
    setAddress1(null);
    setAddress2(null);
    setCity(null);
    setZip(null);
    setPhoneNumber(null);
    setAccountType(null);
    setState(null);
    setErrorValidationMessagePhone("");
    setErrorValidationMessageZip("");
  };

  const handlePaySecurityDeposit = () => {
    // history.push({
    //   pathname: "/payment-successful",
    //   state: {
    //     amount: 1000,
    //     invoiceNumber: 123,
    //     type: "Security Deposit",
    //     transferType: "Bank Transfer",
    //     //dateInitiated: res?.approvedOn,
    //     tokenValue: tokenValue,
    //     reservationIdValue: reservationIdValue,
    //     dateInitiated: "04/22/2024",
    //     nickName: "",
    //     source: `Bank Account ending in 4356 - SBI`,
    //   },
    // });
  };
  const handleCancelAchBookSpace = () => {
    setBookSpaceForm(false);
    setRoutingNumber("");
    setAccountNumber("");
    //postMessageAndClose(PostMessageStatus.CANCEL);
  };
  // console.log("errorMessage", errorMessage);

  const regenerateUrl = () => {
    Busy.set(true);
    regenerateNewUrl(reservationId)
      .then((response) => {
        if (response?.body?.paymentLink) {
          window.location.href = response?.body?.paymentLink;

          setLoadComponent(!loadComponent);
        }
        Busy.set(false);
        window.location.reload();
      })
      .catch((err) => {
        setErrorMessage(getErrorMessageForStandardResponse(err));
        Busy.set(false);
      });
  };

  // console.log("bankName", bankName);
  // console.log("showCreditCardModal", showCreditCardModal);
  // console.log("showAchBookSpaceModal", showAchBookSpaceModal);
  // console.log("firstName", firstName);
  // console.log("state", state);
  // console.log("accountType", accountType);
  // console.log("securityDepositPaymentStatus", securityDepositPaymentStatus);
  //console.log("securityDepositAmount", securityDepositAmount);
  // console.log("errorMessage", errorMessage);
  //console.log("linkUsed", linkUsed);
  // console.log("expirePopUp", expirePopUp);
  //  console.log("fineByIdDetails", fineByIdDetails);
  // console.log("fineAmount", fineAmount);

  return (
    <>
      {" "}
      {securityDepositPaymentStatus ? (
        <SecurityDepositSuccessfull
          dateInitiatedSecurityDeposit={dateInitiatedSecurityDeposit}
          setsecurityDepositPaymentStatus={setsecurityDepositPaymentStatus}
          sourceSecurityDeposit={sourceSecurityDeposit}
          securityDepositnickName={securityDepositnickName}
          securityDepositPaymentType={securityDepositPaymentType}
          securityDepositAmount={(securityDepositAmount / 100).toFixed(2)}
          securityInvoiceNumber={securityInvoiceNumber}
        />
      ) : (
        <>
          {!errorMessage && !linkUsed && (
            <ReportBackgroundContainer>
              {securityDepositPaid ? (
                <>
                  <ReportHeader parentMenu={"Checkout"}>
                    {Number(fineAmount) > 0
                      ? "Fine Payment"
                      : "Reservation Checkout"}
                  </ReportHeader>
                  <ReportTitle>
                    {Number(fineAmount) > 0
                      ? "Fine Payment Info"
                      : "Reservation Info"}
                  </ReportTitle>
                  {Number(fineAmount) > 0 ? (
                    <div className="mb30">
                      <FineCheckout fineByIdDetails={fineByIdDetails} />
                    </div>
                  ) : (
                    <>
                      <ReservationCheckout reservation={reservation} />
                      <ReportTitle>Zone Info</ReportTitle>
                      <ZoneCheckout zone={zone} />
                      {paymentMethodId && (
                        <>
                          <ReportTitle>Cost Summary</ReportTitle>
                          <DisplayChargeAmount
                            reservationId={reservationId}
                            paymentMethodId={paymentMethodId}
                            securityDepositAmount={securityDepositAmount}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              ) : securityDepositRequiredState ? (
                <>
                  <ReportHeader parentMenu={"Checkout"} type="securityDeposit">
                    Security Deposit
                  </ReportHeader>
                  <ReportTitle>Cost Summary</ReportTitle>
                  <ReportDataRow>
                    <ReportData label={"Initial Security Deposit Amount"}>
                      $ {Number(securityDepositAmountState)}
                    </ReportData>
                  </ReportDataRow>
                </>
              ) : (
                <>
                  <ReportHeader parentMenu={"Checkout"}>
                    {Number(fineAmount) > 0
                      ? "Fine Payment"
                      : "Reservation Checkout"}
                  </ReportHeader>
                  <ReportTitle>
                    {Number(fineAmount) > 0
                      ? "Fine Payment Info"
                      : "Reservation Info"}
                  </ReportTitle>
                  {Number(fineAmount) > 0 ? (
                    <div className="mb30">
                      <FineCheckout fineByIdDetails={fineByIdDetails} />
                    </div>
                  ) : (
                    <>
                      <ReservationCheckout reservation={reservation} />
                      <ReportTitle>Zone Info</ReportTitle>
                      <ZoneCheckout zone={zone} />
                      {paymentMethodId && (
                        <>
                          <ReportTitle>Cost Summary</ReportTitle>
                          <DisplayChargeAmount
                            reservationId={reservationId}
                            paymentMethodId={paymentMethodId}
                            securityDepositAmount={securityDepositAmount}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              {!disablePayment && (
                <>
                  <div className="inputLabel">
                    <ReportTitle>Add Payment Method</ReportTitle>
                    <span>
                      <input
                        type="checkbox"
                        id="paywithcreditcard"
                        name="paywithcreditcard"
                        value="creditcard"
                        onClick={handleChecked}
                        checked={
                          segregatedData(paymentMethods, "CARD")?.length >= 3
                        }
                        disabled={
                          segregatedData(paymentMethods, "CARD")?.length >= 3
                        }
                        style={{
                          marginRight: "10px",
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                        // className="verticalAlign"
                        // disabled={true}
                      />
                      <label
                        htmlFor="paywithcreditcard"
                        className="alignDisplay"
                      >
                        Add Credit Card
                      </label>
                    </span>
                    <span>
                      <input
                        type="checkbox"
                        id="paywithach"
                        name="paywithach"
                        value="ach"
                        onClick={handleChecked}
                        style={{
                          marginLeft: "24px",
                          marginRight: "10px",
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                        // className="verticalAlign-custom"
                        checked={
                          checkedButtonPayment === "ach" ||
                          paymentMethods?.some((ele) => ele?.type === "ACH")
                        }
                        disabled={paymentMethods?.some(
                          (ele) => ele?.type === "ACH"
                        )}
                      />
                      <label
                        for="paywithach"
                        className={
                          checkedButtonPayment === "submitted" ||
                          paymentMethods?.some((ele) => ele?.type === "ACH")
                            ? "element-class"
                            : "element-class-name"
                        }
                      >
                        Add ACH
                      </label>
                    </span>
                  </div>
                  {checkedButtonPayment === "creditcard" && (
                    <CreditCardModalPopUp
                      setCheckedButtonPayment={setCheckedButtonPayment}
                      setLoadPaymentMethod={setLoadPaymentMethod}
                      loadPaymentMethod={loadPaymentMethod}
                      id={id}
                      setCvv={setCvv}
                      cvv={cvv}
                      setAchPayment={setAchPayment}
                    />
                  )}

                  {checkedButtonPayment === "ach" && (
                    <Modal className="app-modal app-modal-in">
                      <div id="ssCreateEditLocation">
                        <div className="modal-content ">
                          <h1 className="content-header-title margin-top-27">
                            ACH Details
                          </h1>
                          <form className="ss-form ss-block pb30">
                            <div className="displayFlex">
                              <fieldset className="ss-stand-alone  hs-field">
                                <label htmlFor="fName">FIRST NAME</label>
                                <input
                                  type="text"
                                  id="firstname"
                                  name="firstname"
                                  value={firstName}
                                  ref={firstNameRef}
                                  onChange={(e) =>
                                    onInputChange(e, setFirstName)
                                  }
                                  placeholder="Enter your first name"
                                />
                              </fieldset>
                              <fieldset className="ss-stand-alone  hs-field">
                                <label htmlFor="lName">LAST NAME</label>
                                <input
                                  type="text"
                                  id="lastname"
                                  name="lastname"
                                  value={lastName}
                                  ref={lastNameRef}
                                  onChange={(e) =>
                                    onInputChange(e, setLastName)
                                  }
                                  placeholder="Enter your last name"
                                />
                              </fieldset>
                            </div>
                            <div className="displayFlex">
                              <fieldset className="ss-stand-alone  hs-field">
                                <label htmlFor="bankName">BANK NAME</label>
                                <input
                                  type="text"
                                  id="bankName"
                                  name="bankName"
                                  ref={bankNameRef}
                                  value={bankName}
                                  onChange={(e) =>
                                    onInputChange(e, setBankName)
                                  }
                                  placeholder="Enter bank name"
                                />
                              </fieldset>

                              <fieldset className="ss-stand-alone  hs-field">
                                <label for="accountType">ACCOUNT TYPE </label>

                                <Dropdown
                                  options={[
                                    { label: "CHECKING", value: "CHECKING" },
                                    { label: "SAVING", value: "SAVING" },
                                    { label: "CREDIT", value: "CREDIT" },
                                  ]}
                                  onChange={(ele) => setAccountType(ele.value)}
                                  onFocus={blurActiveInput}
                                  value={accountType}
                                  placeholder="Please select"
                                  controlClassName="myControlClassName"
                                  menuClassName="custom-menu"
                                  arrowClassName="myArrowClassName"
                                />
                              </fieldset>
                            </div>
                            {/* <div className="displayFlex">
                              <fieldset className="ss-stand-alone  hs-field">
                                <label htmlFor="address">ADDRESS LINE 1</label>
                                <input
                                  type="text"
                                  id="address1"
                                  name="address1"
                                  ref={address1Ref}
                                  value={address1}
                                  onChange={(e) => setAddress1(e.target.value)}
                                  placeholder="Enter adddress line 1"
                                />
                              </fieldset>
                              <fieldset className="ss-stand-alone  hs-field">
                                <label htmlFor="address2">ADDRESS LINE 2</label>
                                <input
                                  type="text"
                                  id="address2"
                                  name="address2"
                                  value={address2}
                                  ref={address2Ref}
                                  onChange={(e) => setAddress2(e.target.value)}
                                  placeholder="Enter adddress line 2"
                                />
                              </fieldset>
                            </div> */}

                            {/* <div className="displayFlex">
                              <fieldset className="ss-stand-alone  hs-field">
                                <label htmlFor="city">CITY</label>
                                <input
                                  type="text"
                                  id="city"
                                  name="city"
                                  value={city}
                                  ref={cityRef}
                                  onChange={(e) => onInputChange(e, setCity)}
                                  placeholder="Enter city"
                                />
                              </fieldset>

                              <fieldset className="ss-stand-alone  hs-field">
                                <label htmlFor="state">STATE</label>

                                <Dropdown
                                  options={stateConstant}
                                  onChange={(ele) => setState(ele.value)}
                                  onFocus={blurActiveInput}
                                  value={state}
                                  placeholder="Please select"
                                  controlClassName="myControlClassName"
                                  arrowClassName="myArrowClassName"
                                />
                              </fieldset>
                            </div> */}
                            {/* <div className="displayFlex">
                              <fieldset className="ss-stand-alone  hs-field country">
                                <label htmlFor="zip">ZIP CODE</label>
                                <input
                                  type="text"
                                  id="zip"
                                  name="zip"
                                  maxLength={5}
                                  ref={zipRef}
                                  className="width100"
                                  value={zip}
                                  onChange={(e) => onNumberChange(e, setZip)}
                                  placeholder="Enter zip code"
                                />
                              </fieldset>
                              <span className="ss-error-form zipMessage">
                                {errorValidationMessageZip}
                              </span>
                              <fieldset className="ss-stand-alone  hs-field bgColor">
                                <label htmlFor="country">COUNTRY</label>
                                <input
                                  type="text"
                                  id="country"
                                  name="country"
                                  value="USA"
                                  readOnly
                                  className="cursorNotAllow"
                                />
                              </fieldset>
                            </div> */}
                            {/* <div>
                              <fieldset className="ss-stand-alone  hs-field phoneError">
                                <label htmlFor="phoneNumber">
                                  PHONE NUMBER
                                </label>
                                <input
                                  type="tel"
                                  id="phoneNumber"
                                  name="phoneNumber"
                                  className="width100"
                                  value={phoneNumber}
                                  ref={phoneNumberRef}
                                  maxLength={10}
                                  required
                                  onChange={(e) =>
                                    onNumberChange(e, setPhoneNumber)
                                  }
                                  placeholder="Enter phone number"
                                />
                              </fieldset>
                              {!!errorValidationMessagePhone ? (
                                <span className="ss-error-formPhone">
                                  {errorValidationMessagePhone}
                                </span>
                              ) : (
                                <div className="mb10"></div>
                              )}
                            </div> */}

                            <div className="buttonDivSubmit displayFlex">
                              <button
                                type="button"
                                className="ss-button-secondary width200"
                                onClick={handleCancelAch}
                              >
                                Cancel
                              </button>

                              <button
                                type="button"
                                className={
                                  !(
                                    !!firstName &&
                                    !!lastName &&
                                    //phoneNumber?.length === 10 &&
                                    // !!address1 &&
                                    // !!address2 &&
                                    // !!city &&
                                    // zip?.length === 5 &&
                                    // state !== "" &&
                                    // accountType !== "" &&
                                    !!bankName
                                  )
                                    ? "ss-button-primary widthOpacity"
                                    : "ss-button-primary width200"
                                }
                                onClick={handleSubmit}
                                disabled={
                                  !(
                                    !!firstName &&
                                    !!lastName &&
                                    // phoneNumber?.length === 10 &&
                                    // !!address1 &&
                                    // !!address2 &&
                                    // !!city &&
                                    // zip?.length === 5 &&
                                    // state !== "" &&
                                    //</div> accountType !== "" &&
                                    !!bankName
                                  )
                                }
                              >
                                Save
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Modal>
                  )}
                </>
              )}
              {paymentMethods?.length > 0 && (
                <DisplayAddedPaymentMethod
                  checkedButtonPayment={checkedButtonPayment}
                  paymentMethods={paymentMethods}
                  setLoadPaymentMethod={setLoadPaymentMethod}
                  loadPaymentMethod={loadPaymentMethod}
                  cvv={cvv}
                  setAccountType={setAccountType}
                  setSelectingAchIndex={setSelectingAchIndex}
                  setSelectingCreditCardIndex={setSelectingCreditCardIndex}
                  achPayment={achPayment}
                  setSelectedIndex1={setSelectedIndex1}
                  setSelectedIndex2={setSelectedIndex2}
                  selectedIndex2={selectedIndex2}
                  selectedIndex1={selectedIndex1}
                  setBankName={setBankName}
                  setAchPayment={setAchPayment}
                />
              )}

              {bookSpaceForm && showAchBookSpaceModal && (
                <Modal className="app-modal app-modal-in">
                  <div id="ssCreateEditLocation">
                    <div className="modal-content ">
                      <h1 className="content-header-title mt27">
                        BANK Details
                      </h1>
                      <form className="ss-form ss-block pb30">
                        <div className="displayFlex">
                          <fieldset className="ss-stand-alone  hs-field bgColor">
                            <label htmlFor="bankName">BANK NAME</label>
                            <input
                              type="text"
                              id="bankNameBookSpace"
                              name="bankNameBookSpace"
                              value={bankName}
                              // onChange={(e) => onInputChange(e, setBankName)}
                              placeholder="Enter bank name"
                              readonly
                            />
                          </fieldset>
                          <fieldset className="ss-stand-alone  hs-field">
                            <label htmlFor="fName">ROUTING NUMBER</label>
                            <input
                              type="text"
                              id="routingNumber"
                              name="routingNumber"
                              value={routingNumber}
                              maxLength={9}
                              onChange={(e) =>
                                onNumberChange(e, setRoutingNumber)
                              }
                              placeholder="Enter your routing number"
                            />
                          </fieldset>
                        </div>

                        <div className="displayFlex">
                          <fieldset className="ss-stand-alone  hs-field">
                            <label htmlFor="fName">ACCOUNT NUMBER</label>
                            <input
                              type="text"
                              id="accountNumber"
                              name="accountNumber"
                              value={accountNumber}
                              maxLength={12}
                              onChange={(e) =>
                                onNumberChange(e, setAccountNumber)
                              }
                              placeholder="Enter your account number"
                            />
                          </fieldset>
                          {/* <fieldset className="ss-stand-alone  hs-field">
                            <label htmlFor="fName">CHECK NUMBER</label>
                            <input
                              type="text"
                              id="checkNumber"
                              name="checkNumber"
                              value={checkNumber}
                              maxLength={19}
                              onChange={(e) =>
                                onNumberChange(e, setCheckNumber)
                              }
                              placeholder="Enter your check number"
                            />
                          </fieldset> */}
                        </div>

                        <div className="displayFlex">
                          <button
                            type="button"
                            className="ss-button-secondary"
                            style={{ width: "200px", marginLeft: "3px" }}
                            onClick={handleCancelAchBookSpace}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="ss-button-primary"
                            style={
                              !(
                                !!bankName &&
                                !!routingNumber &&
                                !!accountNumber
                              )
                                ? {
                                    opacity: "0.5",
                                    width: "200px",
                                    paddingLeft: "21px",
                                  }
                                : { width: "200px", paddingLeft: "24px" }
                            }
                            onClick={handleSubmitBookSpace}
                            //onClick={handlePaySecurityDeposit}
                            disabled={
                              !(
                                !!bankName &&
                                !!routingNumber &&
                                !!accountNumber
                              )
                            }
                          >
                            Process Payment
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Modal>
              )}

              {bookSpaceForm && showCreditCardModal && (
                <CrediCardDisplayPopUp
                  paymentMethods={paymentMethods}
                  setBookSpaceForm={setBookSpaceForm}
                  selectingCreditCardIndex={selectingCreditCardIndex}
                  setSelectingAchIndex={setSelectingAchIndex}
                  setSelectingCreditCardIndex={setSelectingCreditCardIndex}
                  reservationId={reservationId}
                  setSecurityDepositRequiredState={
                    setSecurityDepositRequiredState
                  }
                  securityDepositPaid={securityDepositPaid}
                  token={token}
                  fineAmount={fineAmount}
                  finePeakPrice={finePeakPrice}
                  securityDepositAmountState={securityDepositAmountState}
                  id={id}
                  securityDepositRequiredState={securityDepositRequiredState}
                  setsecurityDepositPaymentStatus={
                    setsecurityDepositPaymentStatus
                  }
                  setdateInitiatedSecurityDeposit={
                    setdateInitiatedSecurityDeposit
                  }
                  setsourceSecurityDeposit={setsourceSecurityDeposit}
                  setSecurityInvoiceNumber={setSecurityInvoiceNumber}
                  setsecurityDepositnickName={setsecurityDepositnickName}
                  setSecurityDepositPaymentType={setSecurityDepositPaymentType}
                />
              )}
              {securityDepositRequiredState && !securityDepositPaid && (
                <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <input
                    type="checkbox"
                    className="securityCheckBox"
                    onClick={() =>
                      setShowSecurityDepositPayment(!showSecurityDepositPayment)
                    }
                  />{" "}
                  I <b>acknowledge</b> and <b>agree</b>{" "}
                  {requiredSecurityDepositText}
                </div>
              )}

              {securityDepositPaid ? (
                <div style={{ marginTop: "30px" }}>
                  <SolidButton
                    id={"ss-book-space-button"}
                    disabled={disabled}
                    label={Number(fineAmount) > 0 ? "Pay Now" : "Book Space"}
                    onClick={handleBookSpace}
                  />
                </div>
              ) : securityDepositRequiredState ? (
                <>
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <SolidButton
                      id={"ss-book-space-button"}
                      disabled={
                        !showSecurityDepositPayment ||
                        !(paymentMethods.length > 0)
                      }
                      label={"Pay Now"}
                      onClick={handleBookSpace}
                      // onClick={handlePaySecurityDeposit}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div style={{ marginTop: "30px" }}>
                    <SolidButton
                      id={"ss-book-space-button"}
                      disabled={disabled}
                      label={Number(fineAmount) > 0 ? "Pay Now" : "Book Space"}
                      onClick={handleBookSpace}
                    />
                  </div>
                </>
              )}

              <br />

              <div style={{ marginTop: "60px" }}></div>
            </ReportBackgroundContainer>
          )}
          {
            <div>
              {/converted to a booking/i.test(errorMessage) ||
              /(valid payment profile|This fine has been canceled)/i.test(
                errorMessage
              ) ||
              !!linkUsed ? (
                <Modal className={"modal-form alert-message"}>
                  <div
                    style={{
                      width: "80%",
                      marginTop: "18%",
                      marginLeft: "7%",
                    }}
                  >
                    <div className="modal-content">
                      <div
                        className={"modal-header d-flex"}
                        style={{ justifyContent: "center" }}
                      >
                        <h5 style={{ color: "red", textAlign: "center" }}>
                          {" "}
                          {/(valid payment profile|This fine has been canceled)/i.test(
                            errorMessage
                          )
                            ? errorMessage
                            : Number(fineAmount) > 0
                            ? "This link has already been used for fine payment!"
                            : linkConvertedToBooking}
                        </h5>
                      </div>
                      <div
                        className="modal-footer"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <button
                          type="button"
                          className="ss-button-primary-modal-form-checkout"
                          onClick={() =>
                            (window.location.href = poartOfOaklandUrl)
                          }
                        >
                          Go to Home Page
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal>
              ) : (
                !!errorMessage && (
                  <Modal className={"modal-form alert-message"}>
                    <div
                      style={{
                        width: "80%",
                        marginTop: "18%",
                        marginLeft: "7%",
                      }}
                    >
                      <div className="modal-content">
                        <div className={"modal-header d-flex"}>
                          <h5 style={{ color: "red", marginLeft: "10px" }}>
                            {" "}
                            {/invalid token | reservation has expired/i.test(
                              errorMessage
                            )
                              ? "Your Payment link has expired. Please generate a new one to complete your booking."
                              : errorMessage}
                          </h5>
                        </div>
                        <div
                          className="modal-footerp"
                          style={{ padding: "8px" }}
                        >
                          <button
                            type="button"
                            className="ss-button-primary-modal-form-checkout buttonCss"
                            onClick={() =>
                              (window.location.href = poartOfOaklandUrl)
                            }
                          >
                            Go to Home Page
                          </button>
                          {(/invalid token | reservation has expired/i.test(
                            errorMessage
                          ) ||
                            expirePopUp) && (
                            <button
                              type="button"
                              className="ss-button-primary-modal-form-checkout"
                              onClick={regenerateUrl}
                            >
                              Generate New Link
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Modal>
                )
              )}
            </div>
          }
        </>
      )}
    </>
  );
};

export default Checkout;
