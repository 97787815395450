import React, { useEffect, useState } from "react";
import ReportBackgroundContainer from "../report/ReportBackgroundContainer";
import Filter from "../../views/bookings/Filter";
import { ZoneItemsWithGroupAndSort } from "./ZoneRevenueContent";
import { toast } from "react-toastify";
import { getErrorMessageForStandardResponse } from "../../util/NetworkErrorUtil";
import { getZoneReportData } from "./zoneRequests";
import { searchRecordsByKeywordWaitListAvailability } from "../../util/SearchUtils";
import moment from "moment";
import { DateFormats } from "../constants/securspace-constants";
import ReportFormContainer from "../report/ReportFormContainer";
import ReportFormDateInput from "../report/ReportFormDateInput";
import ReportEmpty from "../report/ReportEmpty";
import { extractDay } from "../constants/securspace-constants";
import Busy from "../Busy";
import "../../App.css";

const ZoneRevenueReport = () => {
  const reportFields = [
    { label: "Location Name", name: "locationName" },
    { label: "Zone Name", name: "zoneName" },
    { label: "Power Accessible", name: "powerAccessible" },
    { label: "Equipment Length", name: "equipmentLength" },
    { label: "Total Revenue", name: "totalRevenue" },
  ];

  const defaultStartDate = moment()
    .subtract(30, "days")
    .format(DateFormats.DAY);
  const defaultEndDate = moment().format(DateFormats.DAY);

  const [zones, setZones] = useState();
  const [searchBox, setSearchBox] = useState("");
  const [filteredData, setFilteredData] = useState();
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  useEffect(() => {
    let isMounted = true;
    Busy.set(true);
    getZoneReportData(startDate, endDate)
      .then(
        (promise) => {
          if (isMounted) {
            let data = promise.body;
            setZones(data);
          }
          Busy.set(false);
        },
        (error) => {
          if (isMounted) {
            toast.error(getErrorMessageForStandardResponse(error));
          }
          Busy.set(false);
        }
      )
      .catch((error) => {
        if (isMounted) {
          toast.error(getErrorMessageForStandardResponse(error));
        }
        Busy.set(false);
      });

    return () => {
      isMounted = false;
    };
  }, [startDate, endDate]);

  useEffect(() => {
    if (zones && searchBox) {
      setFilteredData(
        searchRecordsByKeywordWaitListAvailability(searchBox, zones)
      );
    } else {
      setFilteredData(zones);
    }
  }, [zones, searchBox]);

  const searchChangeHandler = (event) => {
    let value = event.target.value;
    setSearchBox(value);
  };

  const handleStartDate = (date) => {
    let day1 = extractDay(date);
    let day2 = extractDay(startDate);

    // if (!(day1 === day2)) {
    //   setStartDate(date);
    // }
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    let day1 = extractDay(date);
    let day2 = extractDay(endDate);

    // if (!(day1 === day2)) {
    //   setEndDate(date);
    // }
    setEndDate(date);
  };

  return (
    <ReportBackgroundContainer>
      <header>
        <ul className="breadcrumb">
          <li>Report</li>
          <li>Zone Revenue Report</li>
        </ul>
        <h1 className="ss-report-header-title">Revenue by Zone</h1>
      </header>
      <div className="width49">
        <ReportFormContainer>
          <ReportFormDateInput
            label={"START DATE:"}
            date={startDate}
            onDateChange={handleStartDate}
          />
          <ReportFormDateInput
            label={"END DATE:"}
            date={endDate}
            onDateChange={handleEndDate}
          />
        </ReportFormContainer>
      </div>

      {!!zones && zones?.length > 0 ? (
        <>
          <Filter
            searchBox={searchBox}
            searchChangeHandler={searchChangeHandler}
            placeHolder="Type any field to filter zone revenue report result"
          />
          {filteredData?.length > 0 && (
            <ZoneItemsWithGroupAndSort
              reportFields={reportFields}
              zones={filteredData}
              reportTitle={"Zone Revenue Report"}
              reportName="Zone Revenue Report"
            />
          )}
          {filteredData?.length > 0 && (
            <div className="text-center"> You have reached end of the list</div>
          )}
          {filteredData?.length === 0 && <ReportEmpty />}
          <div className="ss-supplier-active-bookings-endlist">
            <h6>{!!filteredData && filteredData.length > 0 ? " " : " "}</h6>
          </div>
        </>
      ) : (
        <ReportEmpty />
      )}
    </ReportBackgroundContainer>
  );
};

export default ZoneRevenueReport;
