import React, { useState } from "react";
import Busy from "../../../components/Busy";
import { toast } from "react-toastify";
import { Modal } from "react-router-modal";
import { requestRenameThirdPartyPaymentMethod } from "../../../components/paymentMethods/request/payment-method-requests";
import "../../../App.css";
function EditPopUp({
  nickName,
  id,
  setLoadPaymentMethod,
  loadPaymentMethod,
  setShowModalEdit,
}) {
  const [nickNameEdit, setNickNameEdit] = useState(nickName);
  const handleClickEdit = async () => {
    Busy.set(true);

    await requestRenameThirdPartyPaymentMethod({ id, nickNameEdit })
      .then((res) => toast.success(res?.text))
      .catch((err) => {
        const errorMessage = err?.message || "An error occurred.";
        const errMessageObj = JSON.parse(errorMessage);
        toast.error(errMessageObj?.message);
      });
    Busy.set(false);
    setShowModalEdit(false);
    setLoadPaymentMethod(!loadPaymentMethod);
  };

  const handleCancel = () => {
    setShowModalEdit(false);
  };
  return (
    <>
      <>
        <Modal className="app-modal app-modal-in modalPopup">
          <div className="modal-content popUp">
            <div className="headline">
              <h1 className="ss-report-header-title">Add/Edit Nickname</h1>
              <button
                type="button"
                className="close mt-2"
                onClick={handleCancel}
              >
                <img alt="" src="../../../app-images/close.png" />
              </button>
            </div>

            <fieldset className="hs-field mt-4">
              <label htmlFor="nickName">Nickname</label>
              <input
                type="text"
                id="nickName"
                name="nickName"
                value={nickNameEdit}
                onChange={(e) => setNickNameEdit(e.target.value)}
                placeholder="Enter your nickname"
                className="width100"
              />
            </fieldset>
            <div className="newbuttonDivSubmitEdit">
              <button
                className={
                  !nickNameEdit
                    ? "ss-button-primary opacity-width"
                    : "ss-button-primary width35"
                }
                onClick={() => handleClickEdit()}
                disabled={!nickNameEdit}
              >
                Save
              </button>
            </div>
          </div>
        </Modal>
      </>
    </>
  );
}

export default EditPopUp;
