import React, { useEffect } from "react";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./zones.css";
import moment from "moment";

const zoneActivationDate = (props) => {
  const {
    className,
    classNameEndDate,
    classNameStartDate,
    label,
    placeHolder,
    value,
    onChange,
    type,
    hide,
    disabled,
    id,
    startDate,
    activationDate,
    deactivationDate,
    setButtonDisabled,
    activationDateMaxCheck,
  } = props;
  const caretColour = "caretColour";
  useEffect(() => {
    if (!!deactivationDate) {
      if (activationDate > deactivationDate) {
        if (!!setButtonDisabled) setButtonDisabled(true);
      }
    }
  }, []);

  const handleKeyDown = (e) => {
    if (type === "date") e.preventDefault();
  };
  const handleChange = (e) => {
    // if (!!activationDate) {
    //   if (value < activationDate) {
    //     console.log("value", value);
    //     console.log("activation", activationDate);
    //     toast.error("Deactivation date must be greater than activation date");
    //   } else {
    //     onChange(moment(e).format("YYYY-MM-DD"));
    //   }
    // }
    onChange(moment(e).format("MM-DD-YYYY"));
  };
  // console.log("value", value);
  // console.log("activationDate", activationDate);
  //   console.log("deactivationDate", deactivationDate);
  // console.log(
  //   "val",
  //   activationDate != "MM/dd/yyyy" && !!activationDate
  //     ? activationDate
  //     : !!value
  //     ? value
  //     : new Date()
  // );

  return (
    <div style={{ display: hide ? "none" : "block" }}>
      <label
        style={
          classNameStartDate
            ? { marginRight: "53px" }
            : classNameEndDate
            ? { marginRight: "64px" }
            : { marginRight: "5px" }
        }
      >
        {label}{" "}
      </label>

      <DatePicker
        selected={value != "MM/dd/yyyy" ? value : null}
        className={!!classNameEndDate ? classNameEndDate : caretColour}
        placeholderText={placeHolder}
        onChange={(e) => handleChange(e)}
        disabled={!!disabled}
        onFocus={(e) => e.target.blur()}
        minDate={
          activationDate != "MM/dd/yyyy" && !!activationDate
            ? activationDate
            : // : value != "MM/dd/yyyy" && !!value
              // ? value
              new Date()
        }
        // minDate={
        //   id !== "endDate"
        //     ? activationDate != "MM/dd/yyyy" && !!activationDate
        //       ? activationDate
        //       : new Date().toISOString().split("T")[0]
        //     : startDate != "" && !!startDate
        //     ? startDate
        //     : value != ""
        //     ? !!value
        //       ? value
        //       : new Date().toISOString().split("T")[0]
        //     : new Date().toISOString().split("T")[0]
        // }
        maxDate={
          activationDateMaxCheck == "activationDateMaxCheck"
            ? deactivationDate != "MM/dd/yyyy"
              ? deactivationDate
              : null
            : activationDateMaxCheck == "startDateMaxCheck"
            ? !!deactivationDate
              ? deactivationDate
              : null
            : null
        }
        onKeyDown={(e) => e.preventDefault()} // Prevent manual input
      />
      {/* <input
        className={className}
        type={type}
        name={name}
        placeholder={placeHolder}
        value={value}
        disabled={disabled}
        onChange={(e) => handleChange(e)}
        onKeyDown={handleKeyDown}
        min={
          id !== "endDate"
            ? activationDate != "MM/dd/yyyy" && !!activationDate
              ? activationDate
              : new Date().toISOString().split("T")[0]
            : startDate != "" && !!startDate
            ? startDate
            : value != ""
            ? !!value
              ? value
              : new Date().toISOString().split("T")[0]
            : new Date().toISOString().split("T")[0]
        }
        max={
          activationDateMaxCheck == "activationDateMaxCheck"
            ? deactivationDate != "MM/dd/yyyy"
              ? deactivationDate
              : ""
            : ""
        }
      /> */}
    </div>
  );
};

export default React.forwardRef(zoneActivationDate);
