import React, { useState } from "react";
import { getErrorMessageForStandardResponse } from "../../util/NetworkErrorUtil";
import { MenuItem } from "@material-ui/core";
import {
  approveReservation,
  deleteReservation,
  resendCheckoutEmail,
} from "./ReservationRequests";
import ReportFlexRightContainer from "../report/ReportFlexRightContainer";
import ReportKebabButton from "../report/ReportKebabButton";
import { toast } from "react-toastify";

const ReservationMenu = (props) => {
  const [toastId, setToastId] = useState(null);
  const { setUpdateReservation, reservation } = props;

  const MyOptions = {
    RESERVE: "Approve Reservation",
    REMOVE: "Decline Reservation",
    RESEND_EMAIL: "Resend Checkout Email",
  };

  const handleApprove = (reservation) => {
    approveReservation(reservation.id)
      .then(
        () => {
          setUpdateReservation(reservation.id);
          toast.success(
            `Reservation approved successfully. We've successfully secured your space. Please use the payment link that was sent to your registered email address, ${reservation?.createdBy}, to finish the payment procedure.`,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        },
        (error) => {
          getErrorMessageForStandardResponse(error);
        }
      )
      .catch((error) => {
        getErrorMessageForStandardResponse(error);
      });
  };

  const handleDelete = (reservation) => {
    deleteReservation(reservation.id)
      .then(
        () => {
          setUpdateReservation(reservation.id);
          toast.error("Reservation declined.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        },
        (error) => {
          getErrorMessageForStandardResponse(error);
        }
      )
      .catch((error) => {
        getErrorMessageForStandardResponse(error);
      });
  };

  const handleResendEmail = (reservation) => {
    resendCheckoutEmail(reservation.id)
      .then(
        () => {
          if (!toast.isActive(toastId)) {
            const toastVal = toast.info("Checkout email resent", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setToastId(toastVal);
          }
        },
        (error) => {
          getErrorMessageForStandardResponse(error);
        }
      )
      .catch((error) => {
        getErrorMessageForStandardResponse(error);
      });
  };

  return (
    <ReportFlexRightContainer>
      <ReportKebabButton>
        <div>
          {!reservation.approved ? (
            <div>
              <MenuItem
                key={MyOptions.RESERVE}
                onClick={() => handleApprove(reservation)}
              >
                {MyOptions.RESERVE}
              </MenuItem>
              <MenuItem
                key={MyOptions.REMOVE}
                onClick={() => handleDelete(reservation)}
              >
                {MyOptions.REMOVE}
              </MenuItem>
            </div>
          ) : (
            <MenuItem
              key={MyOptions.RESEND_EMAIL}
              onClick={() => handleResendEmail(reservation)}
            >
              {MyOptions.RESEND_EMAIL}
            </MenuItem>
          )}
        </div>
      </ReportKebabButton>
    </ReportFlexRightContainer>
  );
};

export default ReservationMenu;
