import React, { Component } from "react";
import AccountReport from "./AccountReport";
import EditInventory from "../components/EditInventory";
import { createLogoutOnFailureHandler } from "../util/LogoutUtil";
import Busy from "../components/Busy";
import CheckOutForm from "../components/CheckOutForm";
import ModalDialog from "../components/ModalDialog";
import ConfirmDialogBlock from "../components/ConfirmDialogBlock";
import { toast } from "react-toastify";
import URLUtils from "../util/URLUtils";
import { validateContainerNumber } from "../util/ContainerValidator";
import AssessFine from "../components/fines/AssessFine";
import { formatBool } from "../util/booking/bookingUtil";
import { UserType } from "../components/constants/securspace-constants";
import { ajaxRequest } from "../ajax";
import { networkErrorIssue } from "./thirdparty/checkout/checkoutConstants";

export default class SupplierInventoryReport extends Component {
  constructor(props) {
    super(props);

    let initialSearchText = URLUtils.getQueryVariable("equipmentNumber");
    if (!initialSearchText) {
      initialSearchText = "";
    }

    this.state = {
      account: this.props.account,
      editItem: null,
      checkOutItem: null,
      assessFine: null,
      showCancelConfirmation: false,
      initialSearchText: initialSearchText,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.account !== this.props.account) {
      this.setState({ account: nextProps.account });
      this.loadReportData(nextProps.account);
    }
  }

  componentDidMount() {
    this.loadReportData(this.props.account);
  }

  loadReportData = (account) => {
    if (account && account.id) {
      Busy.set(true);
      ajaxRequest({
        url: `suppliers/${account.id}/inventory-report`,
        type: "GET",
        contentType: "application/json; charset=UTF-8",
        success: this.handleSuccess,
        statusCode: {
          401: createLogoutOnFailureHandler(this.props.handleLogout),
        },
        error: this.handleFailure,
      });
    }
  };

  handleSuccess = (data) => {
    Busy.set(false);
    this.setState({
      reportList: data,
    });
  };

  handleFailure = (jqXHR) => {
    Busy.set(false);
    if (!window.navigator.onLine) {
      toast.error(networkErrorIssue);
      return;
    }
    let errorMessage = jqXHR.responseJSON
      ? jqXHR.responseJSON.message
      : "Internal Server Error";
    errorMessage = errorMessage ? errorMessage.trim() : errorMessage;
    toast.error(errorMessage);
  };

  editInventoryItem = (item) => {
    this.setState({
      editItem: item,
    });
    // let ele = document.getElementById("root");
    // ele.style.overflow = "hidden";
  };

  handleSubPanelCloseEventDialog = () => {
    this.setState({
      showCancelConfirmation: true,
    });
  };

  handleSubPanelCloseEvent = (event) => {
    this.setState({
      editItem: null,
      checkOutItem: null,
      assessFine: null,
      showCancelConfirmation: false,
    });
    let ele = document.getElementById("root");
    ele.style.overflow = "";
    this.loadReportData(this.props.account);
  };

  handlePostSaveEvent = (event) => {
    this.setState({
      editItem: null,
    });

    this.loadReportData(this.state.account);
  };

  checkOutAsset = (item) => {
    this.setState({
      checkOutItem: item,
    });
    let ele = document.getElementById("root");
    ele.style.overflow = "hidden";
  };

  assessFine = (item) => {
    this.setState({
      assessFine: item,
    });
    let ele = document.getElementById("root");
    ele.style.overflow = "hidden";
  };

  checkOutCompletedCallback = () => {
    this.setState({
      checkOutItem: null,
    });

    toast.success("Successfully checked out!");
    let ele = document.getElementById("root");
    ele.style.overflow = "";
    this.loadReportData(this.state.account);
  };

  render() {
    let actionList;
    if (this.props.account.userType === UserType.READ_ONLY_ADMIN) {
      actionList = [];
    } else if (this.props.account.userType === UserType.GATE_CLERK) {
      actionList = [
        {
          displayValue: "Check Out",
          action: this.checkOutAsset,
        },
        {
          displayValue: "Assess Fine",
          action: this.assessFine,
        },
      ];
    } else {
      actionList = [
        {
          displayValue: "Check Out",
          action: this.checkOutAsset,
        },
        {
          displayValue: "Assess Fine",
          action: this.assessFine,
        },
        {
          displayValue: "Edit",
          action: this.editInventoryItem,
        },
      ];
    }
    let mainReport = (
      <AccountReport
        title="Current Inventory"
        parentMenu="Gate Management"
        data={this.state.reportList}
        defaultSortBy="Please select"
        defaultSortByDirection="ASC"
        defaultDaysInDateRange={30}
        visibleRecordBatchSize={20}
        criteriaField="locationName"
        initialSearchText={this.state.initialSearchText}
        reportFields={[
          {
            label: "CHECK IN",
            name: "checkInDate",
          },
          {
            label: "CONTAINER NUMBER",
            name: "containerNumber",
            formatter: (value) => (value ? value : "-"),
          },
          {
            label: "CONTAINER VALIDATION",
            name: "containerNumberValidation",
            shouldShowField: (item) => {
              return item && item.containerNumber;
            },
            formatter: (value, item) => {
              const { containerNumber } = item;
              return validateContainerNumber(containerNumber)
                ? "OK"
                : "INVALID CONTAINER NUMBER";
            },
            reportValueStyle: "report-value-error",
            shouldApplyReportValueStyle: (item) => {
              return !(item && validateContainerNumber(item.containerNumber));
            },
          },
          {
            label: "TRAILER NUMBER",
            name: "trailerNumber",
            formatter: (value) => (value ? value : "-"),
          },
          {
            label: "CHASSIS NUMBER",
            name: "chassisNumber",
            formatter: (value) => (value ? value : "-"),
          },
          {
            label: "CHASSIS LICENSE PLATE NUMBER",
            name: "chassisLicensePlateNumber",
            formatter: (value) => (value ? value : "-"),
          },
          {
            label: "EQUIPMENT LENGTH",
            name: "assetSize",
            formatter: (value) => (value ? `${value}` : ""),
          },
          {
            label: "SEAL NUMBER",
            name: "sealNumber",
            formatter: (value) => (value ? value : "-"),
          },
          {
            label: "EQUIPMENT TYPE",
            name: "assetType",
            formatter: (value) => {
              return value === "CONTAINER_LOADED"
                ? "Container / Chassis (Loaded)"
                : value === "CONTAINER_EMPTY"
                ? "Container / Chassis (Empty)"
                : value === "CHASSIS_ONLY"
                ? "Chassis Only"
                : value === "TRUCK_CONTAINER_LOADED"
                ? "Truck + Container / Chassis (Loaded)"
                : value === "TRUCK_CONTAINER_EMPTY"
                ? "Truck + Container / Chassis (Empty)"
                : value === "TRUCK_TRAILER_LOADED"
                ? "Truck + Trailer (Loaded)"
                : value === "TRUCK_TRAILER_EMPTY"
                ? "Truck + Trailer (Empty)"
                : value === "TRUCK_ONLY"
                ? "Truck Only"
                : value === "TRAILER_LOADED"
                ? "Trailer (Loaded)"
                : value === "TRAILER_EMPTY"
                ? "Trailer (Empty)"
                : value === "REEFER_LOADED_PLUGIN"
                ? "Refrigerated (Loaded) (Plug In)"
                : value === "REEFER_LOADED_NO_PLUGIN"
                ? "Reefer (Loaded) (No Plug In)"
                : value === "TRUCK_REEFER_LOADED_PLUGIN"
                ? "Truck + Reefer (Loaded) (Plug In)"
                : value === "TRUCK_REEFER_LOADED_NO_PLUGIN"
                ? "Truck + Reefer (Loaded) (No Plug In)"
                : value;
            },
          },
          {
            label: "BOOKING NUMBER",
            name: "orderNumber",
          },
          {
            label: "CUSTOMER",
            name: "buyerName",
          },
          {
            label: "LOCATION",
            name: "locationName",
          },
          {
            label: "DRIVER FIRST NAME",
            name: "driverFirstName",
          },
          {
            label: "DRIVER LAST NAME",
            name: "driverLastName",
          },
          {
            label: "DRIVER LICENSE NUMBER",
            name: "driverLicenseNumber",
            formatter: (value) => (value ? value : "-"),
          },
          {
            label: "TRUCK LICENSE PLATE NUMBER",
            name: "truckLicensePlateNumber",
            formatter: (value) => (value ? value : "-"),
          },
          {
            label: "ZONE",
            name: "zoneName",
            formatter: (value) => (value ? value : "-"),
          },
          {
            label: "NOTES",
            name: "notes",
          },
          {
            label: "TOW AWAY",
            name: "towAway",
            formatter: (towAway) => {
              return formatBool(towAway);
            },
          },
        ]}
        account={this.state.account}
        actionList={actionList}
      />
    );

    let editView = (
      <ModalDialog
        dialogClass="modal fade show"
        title="Edit Inventory "
        handleCloseEvent={this.handleSubPanelCloseEvent}
      >
        <EditInventory
          handlePanelCloseEvent={this.handleSubPanelCloseEvent}
          editItem={this.state.editItem}
          handlePostSaveEvent={this.handlePostSaveEvent}
        />
      </ModalDialog>
    );

    let checkOutView = (
      <ModalDialog
        dialogClass="modal fade show z-index-999"
        subtitle={
          this.state.checkOutItem ? this.state.checkOutItem.buyerName : null
        }
        title="Check Out Asset"
        handleCloseEvent={this.handleSubPanelCloseEventDialog}
      >
        <CheckOutForm
          inventory={this.state.checkOutItem}
          closeSubViewHandler={this.handleSubPanelCloseEventDialog}
          checkOutCompletedCallback={this.checkOutCompletedCallback}
        />
      </ModalDialog>
    );

    let assessFineView = (
      <ModalDialog
        dialogClass="modal fade show z-index-999"
        subtitle={
          this.state.assessFine ? this.state.assessFine.buyerName : null
        }
        title="Assess Fine"
        handleCloseEvent={this.handleSubPanelCloseEventDialog}
      >
        <AssessFine
          handleSubPanelCloseEvent={this.handleSubPanelCloseEvent}
          containerNumber={
            this.state.assessFine ? this.state.assessFine.containerNumber : null
          }
          trailerNumber={
            this.state.assessFine ? this.state.assessFine.trailerNumber : null
          }
          chassisNumber={
            this.state.assessFine ? this.state.assessFine.chassisNumber : null
          }
          truckLicensePlateNumber={
            this.state.assessFine
              ? this.state.assessFine.truckLicensePlateNumber
              : null
          }
          chassisLicensePlateNumber={
            this.state?.assessFine?.chassisLicensePlateNumber
          }
          assetType={
            this.state.assessFine ? this.state.assessFine?.assetType : null
          }
          driverLicenseNumber={this.state?.assessFine?.driverLicenseNumber}
          assetSize={this.state?.assessFine?.assetSize}
          sealNumber={this.state?.assessFine?.sealNumber}
          locationId={
            this.state.assessFine ? this.state.assessFine.locationId : null
          }
          towAway={this.state?.assessFine?.towAway}
        />
      </ModalDialog>
    );

    return (
      <div className="h-100">
        <ConfirmDialogBlock
          showAlert={this.state.showCancelConfirmation}
          title="Confirmation"
          onClose={() => {
            this.setState({ showCancelConfirmation: false });
          }}
          proceedEventHandler={this.handleSubPanelCloseEvent}
          comp="inventory"
        >
          Are you sure you want to cancel?
        </ConfirmDialogBlock>
        {this.state.editItem ? editView : ""}
        {this.state.checkOutItem ? checkOutView : ""}
        {this.state.assessFine ? assessFineView : ""}
        {mainReport}
      </div>
    );
  }
}
