import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import FineTypeInput from "./FineTypeInput";
import PropTypes from "prop-types";
import Steps from "../../steps/Steps";

const SetFineType = (props) => {
  const { stepNumber, fine, editMode, setFineType } = props;
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    if (fine && fine.fineType) {
      setButtonDisabled(false);
    }
    if (!fine?.fineType?.name) setButtonDisabled(true);
  }, [fine]);

  const onChange = (event) => {
    const fineType = event.target.value;
    if (editMode) {
      if (!fineType) {
        toast("Please select a fine type");
      }
    } else {
      setFineType(fineType);
      setButtonDisabled(false);
    }
  };

  return (
    <div>
      <FineTypeInput onChange={onChange} fine={fine} />
      <Steps step={stepNumber} canContinue={buttonDisabled} {...props} />
    </div>
  );
};

SetFineType.propTypes = {
  stepNumber: PropTypes.number,
  fine: PropTypes.shape({
    id: PropTypes.string,
    fineType: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
  editMode: PropTypes.bool,
  fineTypeAdd: PropTypes.func,
};

export default SetFineType;
