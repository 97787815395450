import React, { useEffect, useState } from "react";
import Steps from "../steps/Steps";
import { searchAvailableZones } from "./requests/resevation-requests";
import SelectZone from "./SelectZone";
import { ZoneRefOption } from "../../controls/ZoneRefOption";
import { formatDateWithHIYYMMDD } from "../../util/DateUtils";
import { isRoleApproved, MANAGER_EDIT_ROLES } from "../../util/user/UserUtil";
import OverrideZones from "./OverrideZones";
import { getAllZones } from "../zonebuilder/zoneRequest";
import { toast } from "react-toastify";
import { getErrorMessageForStandardResponse } from "../../util/NetworkErrorUtil";
import OverridePricing from "./OverridePricing";
import { Modal } from "react-router-modal";

const ZonePicker = (props) => {
  const {
    account,
    stepNumber,
    powerAccessible,
    selectedEquipmentLength,
    numberOfSpaces,
    startDate,
    setSelectedZone1,
    setSelectedZone,
    endDate,
    selectedFrequency,
    selectedDuration,
    selectedZone,
    selectedZone1,
    onZoneChange,
    onZoneChange1,
    zoneOverride,
    setZoneOverride,
    price,
    onPriceChange,
    zonePriceOverride,
    setZonePriceOverride,
    previousStep,
  } = props;

  const [zones, setZones] = useState();
  const [allZones, setAllZones] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [maxOneTimeSpaceArray, setMaxOneTimeSpaceArray] = useState([]);
  const [maxRecurringSpaceArray, setMaxRecurringSpaceArray] = useState([]);
  const [maxOneTimeSpaceAllZoneArray, setMaxOneTimeSpaceAllZoneArray] =
    useState([]);
  const [maxRecurringSpaceAllZoneArray, setMaxRecurringSpaceAllZoneArray] =
    useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showMaxOneTimeVal, setShowMaxOneTimeVal] = useState(0);
  const [showMaxRecurringVal, setShowMaxRecurringVal] = useState(0);

  useEffect(() => {
    if (
      selectedEquipmentLength &&
      numberOfSpaces &&
      startDate &&
      selectedFrequency
    ) {
      let reservationRequest = {
        powerAccessible: powerAccessible,
        numberOfSpaces: numberOfSpaces,
        equipmentLength: selectedEquipmentLength,
        startDate: formatDateWithHIYYMMDD(startDate),
        frequency: selectedFrequency ? selectedFrequency : "ONE_TIME",
        duration: selectedFrequency === "ONE_TIME" ? "DAILY" : "MONTHLY",
      };

      if (endDate && endDate !== "") {
        reservationRequest.endDate = formatDateWithHIYYMMDD(endDate);
      }

      searchAvailableZones(reservationRequest)
        .then((response) => {
          if (selectedFrequency === "ONE_TIME") {
            setMaxOneTimeSpaceArray(
              response.body.map((zone) => {
                return {
                  id: zone?.id,
                  maxOneTimeSpaces: zone?.maxOneTimeSpaces,
                  maxRecurringSpaces: zone?.maxRecurringSpaces,
                };
              })
            );
            if (response.body.length > 0) {
              setZones(
                response.body
                  .filter((ele) => !!ele?.active)
                  .map((zone) => {
                    return new ZoneRefOption(zone);
                  })
              );
            } else {
              setZones([]);
            }
          } else if (selectedFrequency === "RECURRING") {
            setMaxRecurringSpaceArray(
              response.body.map((zone) => {
                return {
                  id: zone?.id,
                  maxOneTimeSpaces: zone?.maxOneTimeSpaces,
                  maxRecurringSpaces: zone?.maxRecurringSpaces,
                };
              })
            );
          }
          if (response.body.length > 0) {
            setZones(
              response.body
                .filter((ele) => !!ele?.active)
                .map((zone) => {
                  return new ZoneRefOption(zone);
                })
            );
          } else {
            setZones([]);
          }
        })
        .catch((error) => {
          toast.error(getErrorMessageForStandardResponse(error), {
            position: toast.POSITION.TOP_RIGHT,
          });
          // Catching unimportant errors the user doesn't need to see when searching for zones with various criteria
        });
    }

    if (startDate !== "") {
      getAllZones(
        formatDateWithHIYYMMDD(startDate),
        formatDateWithHIYYMMDD(endDate)
      )
        .then(
          (response) => {
            if (selectedFrequency === "ONE_TIME") {
              setMaxOneTimeSpaceAllZoneArray(
                response.body
                  .filter((zone) => zone?.allowOneTimeBookings)
                  .map((zone) => {
                    return {
                      id: zone.id,
                      maxOneTimeSpaces: zone?.maxOneTimeSpaces,
                      maxRecurringSpaces: zone?.maxRecurringSpaces,
                    };
                  })
              );
              if (response.body.length > 0) {
                setAllZones(
                  response.body
                    .filter(
                      (zone) => zone?.allowOneTimeBookings && !!zone?.active
                    )
                    .map((zone) => {
                      return new ZoneRefOption(zone);
                    })
                );
              } else {
                setAllZones([]);
              }
            } else if (selectedFrequency === "RECURRING") {
              setMaxRecurringSpaceAllZoneArray(
                response.body
                  .filter((zone) => zone?.allowMonthlyBookings)
                  .map((zone) => {
                    return {
                      id: zone.id,
                      maxOneTimeSpaces: zone?.maxOneTimeSpaces,
                      maxRecurringSpaces: zone?.maxRecurringSpaces,
                    };
                  })
              );
              if (response.body.length > 0) {
                setAllZones(
                  response.body
                    .filter(
                      (zone) => zone?.allowMonthlyBookings && !!zone?.active
                    )
                    .map((zone) => {
                      return new ZoneRefOption(zone);
                    })
                );
              } else {
                setAllZones([]);
              }
            }
          },
          (error) => {
            toast.error(getErrorMessageForStandardResponse(error));
          }
        )
        .catch((error) => {
          toast.error(getErrorMessageForStandardResponse(error));
        });
    }
  }, [
    powerAccessible,
    selectedEquipmentLength,
    numberOfSpaces,
    startDate,
    endDate,
    selectedFrequency,
  ]);
  useEffect(() => {
    if (zoneOverride) {
      if (selectedZone) {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    } else {
      if (!!selectedZone1?.displayValue) {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    }

    if ((zoneOverride && selectedZone) || selectedZone1) {
      if (zonePriceOverride)
        !!price ? setButtonDisabled(false) : setButtonDisabled(true);
    }
  }, [selectedZone, zoneOverride, selectedZone1, zonePriceOverride, price]);

  useEffect(() => {
    if (!zoneOverride || !zonePriceOverride) {
      if (!selectedZone1) {
        onPriceChange("");
        setZonePriceOverride(false);
      } else {
        if (!zonePriceOverride) {
          onPriceChange("");
        }
      }
    }
  }, [zoneOverride, zonePriceOverride]);

  useEffect(() => {
    if (
      selectedFrequency === "ONE_TIME" &&
      !zoneOverride &&
      maxOneTimeSpaceArray?.some(
        (ele) =>
          ele?.id === selectedZone1?.value &&
          ele?.maxOneTimeSpaces < numberOfSpaces
      )
    ) {
      setShowAlert(true);
      setButtonDisabled(true);
      setShowMaxOneTimeVal(
        maxOneTimeSpaceArray?.filter(
          (ele) =>
            ele?.id === selectedZone1?.value &&
            ele?.maxOneTimeSpaces < numberOfSpaces
        )[0]?.maxOneTimeSpaces
      );
      setShowMaxRecurringVal(
        maxOneTimeSpaceArray?.filter(
          (ele) =>
            ele?.id === selectedZone1?.value &&
            ele?.maxOneTimeSpaces < numberOfSpaces
        )[0]?.maxRecurringSpaces
      );
    } else if (
      selectedFrequency === "RECURRING" &&
      !zoneOverride &&
      maxRecurringSpaceArray?.some(
        (ele) =>
          ele?.id === selectedZone1?.value &&
          ele?.maxRecurringSpaces < numberOfSpaces
      )
    ) {
      setShowAlert(true);
      setButtonDisabled(true);
      setShowMaxOneTimeVal(
        maxRecurringSpaceArray?.filter(
          (ele) =>
            ele?.id === selectedZone1?.value &&
            ele?.maxRecurringSpaces < numberOfSpaces
        )[0]?.maxOneTimeSpaces
      );
      setShowMaxRecurringVal(
        maxRecurringSpaceArray?.filter(
          (ele) =>
            ele?.id === selectedZone1?.value &&
            ele?.maxRecurringSpaces < numberOfSpaces
        )[0]?.maxRecurringSpaces
      );
    }
  }, [selectedZone1]);
  useEffect(() => {
    if (
      selectedFrequency === "ONE_TIME" &&
      !!zoneOverride &&
      maxOneTimeSpaceAllZoneArray?.some(
        (ele) =>
          ele?.id === selectedZone?.value &&
          ele?.maxOneTimeSpaces < numberOfSpaces
      )
    ) {
      setShowMaxOneTimeVal(
        maxOneTimeSpaceAllZoneArray?.filter(
          (ele) =>
            ele?.id === selectedZone?.value &&
            ele?.maxOneTimeSpaces < numberOfSpaces
        )[0]?.maxOneTimeSpaces
      );
      setShowMaxRecurringVal(
        maxOneTimeSpaceAllZoneArray?.filter(
          (ele) =>
            ele?.id === selectedZone?.value &&
            ele?.maxOneTimeSpaces < numberOfSpaces
        )[0]?.maxRecurringSpaces
      );
      setShowAlert(true);
      setButtonDisabled(true);
    } else if (
      selectedFrequency === "RECURRING" &&
      !!zoneOverride &&
      maxRecurringSpaceAllZoneArray?.some(
        (ele) =>
          ele?.id === selectedZone?.value &&
          ele?.maxRecurringSpaces < numberOfSpaces
      )
    ) {
      setShowMaxOneTimeVal(
        maxRecurringSpaceAllZoneArray?.filter(
          (ele) =>
            ele?.id === selectedZone?.value &&
            ele?.maxRecurringSpaces < numberOfSpaces
        )[0]?.maxOneTimeSpaces
      );
      setShowMaxRecurringVal(
        maxRecurringSpaceAllZoneArray?.filter(
          (ele) =>
            ele?.id === selectedZone?.value &&
            ele?.maxRecurringSpaces < numberOfSpaces
        )[0]?.maxRecurringSpaces
      );
      setShowAlert(true);
      setButtonDisabled(true);
    }
  }, [selectedZone]);

  const handleClose = () => {
    previousStep();
    setSelectedZone(new ZoneRefOption({ value: null, displayValue: "" }));
    setSelectedZone1(new ZoneRefOption({ value: null, displayValue: "" }));
    setShowAlert(false);
  };
  const handleGoBack = () => {
    previousStep();
    setSelectedZone(new ZoneRefOption({ value: null, displayValue: "" }));
    setSelectedZone1(new ZoneRefOption({ value: null, displayValue: "" }));
  };
  return (
    <>
      {!zoneOverride && (
        <SelectZone
          zones={zones?.filter((ele) => !!ele?.displayValue)}
          selectedZone={selectedZone1}
          onZoneChange={onZoneChange1}
        />
      )}
      <br />
      <div style={{ marginLeft: "3%" }}>
        {isRoleApproved(MANAGER_EDIT_ROLES, account?.userType) && (
          <OverrideZones
            zones={allZones?.filter((ele) => !!ele?.displayValue)}
            selectedZone={selectedZone}
            onZoneChange={onZoneChange}
            checked={zoneOverride}
            setChecked={setZoneOverride}
          />
        )}
        {((!!selectedZone && !!zoneOverride) || !!selectedZone1) &&
          isRoleApproved(MANAGER_EDIT_ROLES, account?.userType) && (
            <OverridePricing
              checked={zonePriceOverride}
              setChecked={setZonePriceOverride}
              price={price}
              onPriceChange={(val) => onPriceChange(val)}
              selectedZone1={selectedZone1}
              selectedZone={selectedZone}
            />
          )}
      </div>

      {showAlert && (
        <>
          <Modal className={"modal-form alert-message"}>
            <div style={{ width: "80%", marginTop: "18%", marginLeft: "7%" }}>
              <div className="modal-content">
                <div className={"modal-header d-flex"}>
                  <h5 className="modal-title">Space Limit exceeded </h5>
                </div>
                <div className="modal-body">
                  <p>Booking requirements for this location: </p>
                  <p>
                    Maximum of <b>{showMaxOneTimeVal}</b> spaces for One Time,{" "}
                  </p>
                  <p>
                    Maximum of <b>{showMaxRecurringVal}</b> spaces for
                    Recurring.{" "}
                  </p>
                  <p style={{ marginTop: "16px" }}>
                    Please book your spaces accordingly and try again.
                  </p>
                </div>
                <div
                  className="modal-footer"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    type="button"
                    className="ss-button-primary-modal-form"
                    onClick={handleClose}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}

      <Steps
        step={stepNumber}
        canContinue={buttonDisabled}
        handleGoBack={handleGoBack}
        type="zonePicker"
        {...props}
      />
    </>
  );
};

export default ZonePicker;
