import React, { useEffect } from "react";
import ReportRow from "../../report/ReportRow";
import ReportDataRow from "../../report/ReportDataRow";
import ReportData from "../../report/ReportData";
import ReportBackgroundContainer from "../../report/ReportBackgroundContainer";
import FineAdd from "./FineAdd";
import useFineAddLogic from "./UseFineAddLogic";
import FineMenu from "./FineMenu";
import "../../../css/components/report/report-header.css";
import { displayDaysOfWeek } from "../../../util/DateUtils";
import { ADMIN_EDIT_ROLES, isRoleApproved } from "../../../util/user/UserUtil";

const FineViewEdit = (props) => {
  const {
    setFineType,
    addLocationId,
    addPeakPricingAdditionalCharge,
    addFineInfractions,
    addPeakPricingWindows,
    cancel,
    saveFineToApi,
    addStartDate,
    addEndDate,
    editFine,
    deleteFine,
    toggleEditMode,
    editMode,
    fines,
    fine,
    setFine,
    show,
  } = useFineAddLogic();

  const { account } = props;

  return (
    <div>
      <ReportBackgroundContainer>
        {editMode ? (
          <div>
            <FineAdd
              heading={"EDIT FINE"}
              setFineType={setFineType}
              addLocationId={addLocationId}
              addPeakPricingAdditionalCharge={addPeakPricingAdditionalCharge}
              addFineInfractions={addFineInfractions}
              addPeakPricingWindows={addPeakPricingWindows}
              cancel={cancel}
              saveFineToApi={saveFineToApi}
              addStartDate={addStartDate}
              addEndDate={addEndDate}
              fine={fine}
              setFine={setFine}
              editMode={editMode}
              toggleEditMode={toggleEditMode}
            />
          </div>
        ) : fines?.length > 0 ? (
          <div>
            {fines?.map((fine) => {
              return (
                <div key={fine.id}>
                  <header>
                    <ul className="breadcrumb">
                      <li>FINES</li>
                      <li>view/edit fines</li>
                    </ul>
                    <h1 className="ss-report-header-title">
                      {fine.fineType.name}
                    </h1>
                  </header>

                  <ReportRow>
                    {isRoleApproved(ADMIN_EDIT_ROLES, account?.userType) && (
                      <FineMenu
                        fine={fine}
                        editFine={editFine}
                        deleteFine={deleteFine}
                      />
                    )}
                    <ReportDataRow>
                      <ReportData label={"Fine Name"}>
                        {fine.fineType.name}
                      </ReportData>
                      <ReportData label={"Start Date"}>
                        {fine.startDate}
                      </ReportData>
                      <ReportData label={"End Date"}>{fine.endDate}</ReportData>
                      {!!fine?.peakPricingWindows &&
                        fine?.peakPricingWindows?.length >= 1 && (
                          <ReportData label={"Peak Pricing Additional Charge"}>
                            $
                            {(fine.peakPricingAdditionalCharge / 100).toFixed(
                              2
                            )}
                          </ReportData>
                        )}
                      {!!fine?.peakPricingWindows &&
                      fine?.peakPricingWindows?.length >= 1 ? (
                        <ReportData label={"Peak Pricing Windows"}>
                          {fine?.peakPricingWindows?.map(
                            (peakPricingWindow) => {
                              return (
                                <div key={peakPricingWindow.id}>
                                  <ReportRow>
                                    <ReportDataRow>
                                      <ReportData label={"Days of Week"}>
                                        {displayDaysOfWeek(
                                          peakPricingWindow.daysOfWeek
                                        )}
                                      </ReportData>
                                      <ReportData label={"Start Time"}>
                                        {peakPricingWindow.startTime}
                                      </ReportData>
                                      <ReportData label={"End Time"}>
                                        {peakPricingWindow.endTime}
                                      </ReportData>
                                    </ReportDataRow>
                                  </ReportRow>
                                </div>
                              );
                            }
                          )}
                        </ReportData>
                      ) : (
                        ""
                      )}
                      <ReportData label={"Fine Infractions"}>
                        {fine?.fineInfractions?.map((fineInfraction) => {
                          return (
                            <div key={fineInfraction.id}>
                              <ReportRow>
                                <ReportDataRow>
                                  <ReportData label={"Offense"}>
                                    {fineInfraction.infractionOffense}
                                  </ReportData>
                                  <ReportData label={"Amount"}>
                                    $
                                    {(
                                      fineInfraction.infractionAmount / 100
                                    ).toFixed(2)}
                                  </ReportData>
                                </ReportDataRow>
                              </ReportRow>
                            </div>
                          );
                        })}
                      </ReportData>
                    </ReportDataRow>
                  </ReportRow>
                </div>
              );
            })}
            {fines?.length > 0 && (
              <div className="ss-load-more-records">
                You have reached end of the list
              </div>
            )}
          </div>
        ) : (
          <>
            <header>
              <ul className="breadcrumb">
                <li>FINES</li>
                <li>view/edit fines</li>
              </ul>
            </header>
            {show && <div className="ss-load-more-records">No data found</div>}
            {/* <div
              style={{ height: "30px", marginTop: "10px", textAlign: "center" }}
            >
              You have reached end of list
            </div> */}
          </>
        )}
      </ReportBackgroundContainer>
    </div>
  );
};

export default FineViewEdit;
