import React, { useEffect, useState } from "react";
import ReportBackgroundContainer from "../components/report/ReportBackgroundContainer";
import ReportHeader from "../components/report/ReportHeader";
import { SupplierInterchangeListWithGroupAndSort } from "../components/interchange/SupplierInterchangeList";
import { requestMonthlyDurationByDates } from "../components/interchange/requests/interchange-requests";
import { toastErrorMessage } from "../util/NetworkErrorUtil";
import Busy from "../components/Busy";
import { DateFormats } from "../components/constants/securspace-constants";
import moment from "moment";
import ReportFormContainer from "../components/report/ReportFormContainer";
import ReportFormDateInput from "../components/report/ReportFormDateInput";
import Filter from "./bookings/Filter";
import { searchRecordsByKeywordWaitListAvailability } from "../util/SearchUtils";
import ReportEmpty from "../components/report/ReportEmpty";
import { extractDay } from "../components/constants/securspace-constants";
import "../App.css";

const MonthlyDurationReportContent = (props) => {
  const { account } = props;
  const [supplierInterchanges, setSupplierInterchanges] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().subtract(30, "days").format(DateFormats.DAY)
  );
  const [endDate, setEndDate] = useState(moment().format(DateFormats.DAY));
  const [searchBox, setSearchBox] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const groupDateFormat = (date) => moment(date).format(DateFormats.DATE);

  useEffect(() => {
    Busy.set(true);
    requestMonthlyDurationByDates(account?.id, startDate, endDate)
      .then((response) => {
        setSupplierInterchanges(response.body);
        Busy.set(false);
      }, toastErrorMessage)
      .catch(toastErrorMessage);
  }, [account, startDate, endDate]);

  useEffect(() => {
    if (supplierInterchanges) {
      setFilteredData(
        searchRecordsByKeywordWaitListAvailability(
          searchBox,
          supplierInterchanges
        )
      );
    }
  }, [searchBox, supplierInterchanges]);

  const handleSearchBox = (event) => {
    setSearchBox(event.target.value);
  };

  const handleStartDate = (date) => {
    let day1 = extractDay(date);
    let day2 = extractDay(startDate);

    // if (!(day1 === day2)) {
    //   setStartDate(date);
    // }
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    let day1 = extractDay(date);
    let day2 = extractDay(endDate);

    // if (!(day1 === day2)) {
    //   setEndDate(date);
    // }
    setEndDate(date);
  };

  const REPORT_FIELDS = Object.freeze([
    { label: "ACTIVITY", name: "activity" },
    { label: "DATE", name: "activityDate" },
    { label: "CONTAINER NUMBER", name: "containerNumber" },
    { label: "TRAILER NUMBER", name: "trailerNumber" },
    { label: "CHASSIS NUMBER", name: "chassisNumber" },
    {
      label: "CHASSIS LICENSE PLATE NUMBER",
      name: "chassisLicensePlateNumber",
    },
    { label: "EQUIPMENT LENGTH", name: "assetSize" },
    { label: "DRIVER FIRST NAME", name: "driverFirstName" },
    { label: "DRIVER LAST NAME", name: "driverLastName" },
    { label: "DRIVER LICENSE NUMBER", name: "driverLicenseNumber" },
    { label: "TRUCK LICENSE PLATE NUMBER", name: "truckLicensePlateNumber" },
    { label: "SEAL NUMBER", name: "sealNumber" },
    { label: "EQUIPMENT TYPE", name: "assetType" },
    { label: "BOOKING NUMBER", name: "orderNumber" },
    { label: "CUSTOMER", name: "buyerName" },
    { label: "LOCATION", name: "locationName" },
    { label: "INVENTORY", name: "currentInventory" },
    { label: "DURATION OF STAY", name: "dwellTimeInMillis" },
    {
      label: "LAST UPDATE ON",
      name: "lastUpdatedOn",
      groupDisplayFormat: groupDateFormat,
    },
    { label: "LAST UPDATE BY", name: "lastUpdatedBy" },
    { label: "NOTES", name: "notes" },
    { label: "ZONE", name: "zoneName" },
    { label: "TOW AWAY", name: "towAway" },
  ]);

  return (
    <ReportBackgroundContainer>
      <header>
        <ul className="breadcrumb">
          <li>Report</li>
          <li>Monthly Duration</li>
        </ul>
        <h1 className="ss-report-header-title">Monthly Duration Report</h1>
      </header>
      <div className="width49">
        <ReportFormContainer>
          <ReportFormDateInput
            label={"START DATE:"}
            date={startDate}
            onDateChange={handleStartDate}
          />
          <ReportFormDateInput
            label={"END DATE:"}
            date={endDate}
            onDateChange={handleEndDate}
          />
        </ReportFormContainer>
      </div>

      {supplierInterchanges?.length > 0 && (
        <Filter
          searchBox={searchBox}
          searchChangeHandler={handleSearchBox}
          placeHolder="Type any field to filter monthly duration report result"
        />
      )}

      {!!filteredData && filteredData?.length > 0 ? (
        <>
          <SupplierInterchangeListWithGroupAndSort
            supplierInterchanges={filteredData}
            reportFields={REPORT_FIELDS}
          />
          {filteredData?.length > 0 && (
            <div className="text-center"> You have reached end of the list</div>
          )}
          <div className="ss-supplier-active-bookings-endlist">
            <h6>{!!filteredData && filteredData.length > 0 ? " " : " "}</h6>
          </div>
        </>
      ) : (
        <ReportEmpty />
      )}
    </ReportBackgroundContainer>
  );
};

export default MonthlyDurationReportContent;
