import React from "react";
import ReportKebabButton from "../../components/report/ReportKebabButton";
import { MenuItem } from "@material-ui/core";
import ReportFlexRightContainer from "../../components/report/ReportFlexRightContainer";
import { useHistory } from "react-router-dom";
import { isRoleApproved, MANAGER_EDIT_ROLES } from "../../util/user/UserUtil";
import {
  requestBookingById,
  requestZonesByBookingId,
} from "./requests/edit-booking-requests";
import Busy from "../../components/Busy";
import { toastErrorMessage } from "../../util/NetworkErrorUtil";
import { toast } from "react-toastify";
import moment from "moment";

const BookingReportKebabButton = (props) => {
  const {
    bookingId,
    userType,
    booking,
    setShow,
    setBookingId,
    setOrderNumber,
  } = props;
  const history = useHistory();

  const handleMoveZone = () => {
    Busy.set(true);
    requestZonesByBookingId(bookingId)
      .then((response) => {
        if (response?.body?.length > 0) {
          history.push(`/booking/${bookingId}`);
        } else {
          toast.error("No zones available to change this booking's zone!");
        }
        Busy.set(false);
      })
      .catch(toastErrorMessage);
  };

  const handleCancelBooking = () => {
    setShow(true);
    setBookingId(bookingId);
    setOrderNumber(booking?.orderNumber);
    //history.push(`/booking/${bookingId}/cancel`);
  };

  const handleCancelBookingSubscription = () => {
    history.push(`/booking/${bookingId}/cancelSubscription`);
  };

  const menuItems = [];
  const givenDate = moment(booking?.endDate, "MM/DD/YYYY");
  const today = moment().startOf("day"); // Start of day to ignore the time part

  if (isRoleApproved(MANAGER_EDIT_ROLES, userType)) {
    if (booking?.status === "Cancelled" && !booking?.active) {
    } else {
      if (booking.frequency === "RECURRING") {
        menuItems.push(
          <MenuItem
            key="cancel-booking-subscription"
            onClick={handleCancelBookingSubscription}
          >
            Cancel Booking Subscription
          </MenuItem>
        );
        menuItems.push(
          <MenuItem key="cancel-booking" onClick={handleCancelBooking}>
            Cancel Booking
          </MenuItem>
        );
        if (booking.locationId) {
          menuItems.push(
            <MenuItem key="change-booking-zone" onClick={handleMoveZone}>
              Change Booking's Zone
            </MenuItem>
          );
        }
      } else {
        menuItems.push(
          <MenuItem key="cancel-booking" onClick={handleCancelBooking}>
            Cancel Booking
          </MenuItem>
        );
        if (booking.locationId) {
          menuItems.push(
            <MenuItem key="change-booking-zone" onClick={handleMoveZone}>
              Change Booking's Zone
            </MenuItem>
          );
        }
      }
    }
  }
  return (
    <ReportFlexRightContainer>
      {menuItems.length > 0 ? (
        <ReportKebabButton>{menuItems}</ReportKebabButton>
      ) : (
        <></>
      )}
    </ReportFlexRightContainer>
  );
};

export default BookingReportKebabButton;
