import React, { useEffect, useState } from "react";
import "../../css/components/report/report-total.css";

const ReportTotal = (props) => {
  const {
    data,
    totalFieldLabel,
    totalFieldName,
    totalFieldFormat,
    totalFieldFilter,
    bold,
  } = props;
  const [totalDisplay, setTotalDisplay] = useState("");

  useEffect(() => {
    const SUM_START = 0;
    const mapByFieldName = (x) => x[totalFieldName];

    const sum = (p, c) => p + c;
    if (data?.length && totalFieldName) {
      const summedField = !totalFieldFilter
        ? data.map(mapByFieldName).reduce(sum, SUM_START)
        : data
            .filter(totalFieldFilter)
            .map(mapByFieldName)
            .reduce(sum, SUM_START);
      if (totalFieldFormat) {
        setTotalDisplay(totalFieldFormat(summedField));
      } else {
        setTotalDisplay(summedField);
      }
    }
  }, [data, totalFieldName, totalFieldFormat, totalFieldFilter]);

  return (
    <div>
      <div
        className={
          bold ? "ss-report-total-label-bold" : "ss-report-total-label"
        }
      >
        Total{totalFieldLabel ? " " + totalFieldLabel : ""}:
      </div>
      <div className={"ss-report-total-total"}>{totalDisplay}</div>
    </div>
  );
};

export default ReportTotal;
