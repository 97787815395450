import React from "react";
import ReportRow from "../../report/ReportRow";
import ZoneMenu from "./ZoneMenu";
import ZoneCard from "./ZoneCard";
import { ALL_MANAGER_ROLES, isRoleApproved } from "../../../util/user/UserUtil";

const ZoneList = (props) => {
  const { convertedZones, setUpdateZones, account } = props;

  return (
    <div>
      {convertedZones &&
        convertedZones
          .sort(
            (a, b) =>
              new Date(b.lastModifiedDate) - new Date(a.lastModifiedDate)
          )
          .map((zone) => {
            return (
              <div key={zone.id}>
                <ReportRow>
                  {isRoleApproved(ALL_MANAGER_ROLES, account.userType) && (
                    <ZoneMenu
                      zone={zone}
                      setUpdateZones={setUpdateZones}
                      account={account}
                    />
                  )}
                  <ZoneCard zone={zone} />
                </ReportRow>
              </div>
            );
          })}
    </div>
  );
};

export default ZoneList;
