import React, { Component } from "react";
import ContainerEntryField from "./ContainerEntryField";
import Error from "./Error";
import "../css/views/booking-common.css";
import "../css/theme/mainContent.css";
import "../css/theme/forms.css";
import "../css/theme/forms-block.css";
import "../css/theme/buttons.css";
import Select from "./Select";
import { createLogoutOnFailureHandler } from "../util/LogoutUtil";
import Busy from "./Busy";
import moment from "moment/moment";
import DateTimePicker from "./DateTimePicker";
import "../views/thirdparty/checkout/checkout.css";
import { getApprovedDrivers } from "./checkout/checkoutRequests";
import { DriverOption } from "../controls/DriverOption";
import { toast } from "react-toastify";
import { getErrorMessageForStandardResponse } from "../util/NetworkErrorUtil";
import DriverSelect from "./checkin/component/DriverSelect";
import { ajaxRequest } from "../ajax";
import ReferenceOption from "../controls/ReferenceOption";
import { EquipmentTypeForm } from "./constants/securspace-constants";

const active_AssetTypes = [
  "CONTAINER_LOADED",
  "CONTAINER_EMPTY",
  "CHASSIS_ONLY",
  "TRUCK_TRAILER_LOADED",
  "TRUCK_TRAILER_EMPTY",
  "TRUCK_ONLY",
  "TRAILER_LOADED",
  "TRAILER_EMPTY",
  "REEFER_LOADED_PLUGIN",
];

export default class EditInterchange extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.initFormFields(this.props.editItem);
    this.loadAssetTypes();
    this.loadApprovedDrivers();
  }

  loadApprovedDrivers = () => {
    getApprovedDrivers(this.props.editItem.buyerId)
      .then(
        (response) => {
          const drivers = response.body;
          this.setState({ approvedDrivers: drivers });
          if (drivers?.length > 0) {
            let driverOptions = drivers.map((item) => {
              return new DriverOption(item);
            });
            this.setState({ driverOptions: driverOptions });
          } else if (drivers?.length === 0) {
            toast.error("There are no approved drivers for this booking");
          }
        },
        (error) => {
          toast.error(getErrorMessageForStandardResponse(error));
        }
      )
      .catch((error) => {
        toast.error(getErrorMessageForStandardResponse(error));
      });
  };

  handleDriverChange = (event) => {
    const driverId = event.target.value.value;
    if (this.state.approvedDrivers.length > 0) {
      for (const driver of this.state.approvedDrivers) {
        if (driverId === driver.id) {
          this.setState({
            selectedDriver: driver,
            driverLicenseNumber: driver.licenseNumber,
            driverFirstName: driver.firstName,
            driverLastName: driver.lastName,
          });
        }
      }
    }
  };

  loadAssetTypes() {
    ajaxRequest({
      url: "types/assets",
      type: "GET",
      contentType: "application/json; charset=UTF-8",
      dataType: "json",
      success: (data) => {
        if (data) {
          let temp_assetTypes = data.filter((type) => {
            return active_AssetTypes.indexOf(type.key) > -1;
          });
          this.loadSelectedAsset(temp_assetTypes);
        }
      },
      statusCode: {
        401: createLogoutOnFailureHandler(this.props.handleLogout),
      },
      error: this.handleFailure,
    });
  }

  loadSelectedAsset = (assetTypes) => {
    ajaxRequest({
      url: `interchanges/${this.props.editItem.id}`,
      type: "GET",
      contentType: "application/json; charset=UTF-8",
      dataType: "json",
      success: (data) => {
        const valAssetType = this.props.editItem?.assetType;
        if (data) {
          let selectedAssetType = assetTypes?.find(function (assetType) {
            return assetType.key === valAssetType;
          });
          this.setState({
            assetTypes: assetTypes,
            assetType: selectedAssetType
              ? new ReferenceOption(
                  selectedAssetType.key,
                  selectedAssetType.value
                )
              : "",
            containerNumber: data.containerNumber ? data.containerNumber : "",
            chassisNumber: data.chassisNumber ? data.chassisNumber : "",
            chassisLicensePlateNumber: data.chassisLicensePlateNumber
              ? data.chassisLicensePlateNumber
              : "",
            sealNumber: data.sealNumber ? data.sealNumber : "",
            eventType: data.eventType,
            eventDate: data.eventDate,
            driverId: data.driver?.id,
            driverFirstName: data.driver?.firstName
              ? data.driver?.firstName
              : "",
            driverLastName: data.driver?.lastName ? data.driver?.lastName : "",
            driverLicenseNumber: data.driver?.licenseNumber
              ? data.driver?.licenseNumber
              : "",
            truckLicensePlateNumber: data.truckLicensePlate
              ? data.truckLicensePlate
              : "",
            notes: data.notes ? data.notes : "",
            selectedDriver: data.driver,
          });
        }
      },
      statusCode: {
        401: createLogoutOnFailureHandler(this.props.handleLogout),
      },
      error: this.handleFailure,
    });
  };

  handleFieldChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    if (name !== "eventDate") {
      value = value.toUpperCase();
    }

    this.setState({ [name]: value });
  };

  handleContainerIdFieldChange = (containerNumber) => {
    this.setState({ containerNumber: containerNumber });
  };

  initFormFields = (data) => {
    this.setState({
      id: data.id ? data.id : "",
      locationId: data.locationId ? data.locationId : "",
      eventDate: data.eventDate ? data.eventDate : "",
      errorMessage: "",
      containerNumber: data.containerNumber ? data.containerNumber : "",
      chassisNumber: data.chassisNumber ? data.chassisNumber : "",
      chassisLicensePlateNumber: data.chassisLicensePlateNumber
        ? data.chassisLicensePlateNumber
        : "",
      sealNumber: data.sealNumber ? data.sealNumber : "",
      driverFirstName: data.driverFirstName ? data.driverFirstName : "",
      driverLastName: data.driverLastName ? data.driverLastName : "",
      driverLicenseNumber: data.driverLicenseNumber
        ? data.driverLicenseNumber
        : "",
      truckLicensePlateNumber: data.truckLicensePlateNumber
        ? data.truckLicensePlateNumber
        : "",
      trailerNumber: data?.trailerNumber ? data?.trailerNumber : "",
      notes: data.notes ? data.notes : "",
      assetSize: data.assetSize ? data.assetSize : "",
      assetTypes: [],
      assetType: "",
    });
  };

  saveChanges = () => {
    this.setState({ errorMessage: "" });

    if (!this.state.eventDate) {
      this.setState({ errorMessage: "Please enter an event date" });
      return;
    }

    Busy.set(true);

    let eventDate = moment(this.state.eventDate).toISOString(true);
    ajaxRequest({
      url: "inventory-activity",
      data: JSON.stringify({
        id: this.state.id,
        assetType: this.state.assetType,
        driverLicenseNumber: this.state.driverLicenseNumber,
        assetSize: this.state.assetSize,
        containerNumber: this.state.containerNumber,
        chassisNumber: this.state.chassisNumber,
        chassisLicensePlateNumber: this.state.chassisLicensePlateNumber,
        sealNumber: this.state.sealNumber,
        driver: this.state.selectedDriver,
        truckLicensePlate: this.state.truckLicensePlateNumber,
        notes: this.state.notes,
        eventDate: eventDate,
        eventType: this.state.eventType,
      }),
      type: "PUT",
      contentType: "application/json; charset=UTF-8",
      dataType: "json",
      success: (data) => {
        if (data.id) {
          toast.success("Changes successfully saved!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          Busy.set(false);
          this.props.handlePostSaveEvent();
        }
      },
      statusCode: {
        401: createLogoutOnFailureHandler(this.props.handleLogout),
      },
      error: (error) => {
        Busy.set(false);
      },
    });
  };
  handleAssetTypeChange = (event) => {
    let assetType = event.target.value;
    this.setState({ assetType: assetType });
  };

  render() {
    const disableSaveButton = (value) => {
      switch (value) {
        case EquipmentTypeForm.CONTAINER_LOADED:
        case EquipmentTypeForm.TRUCK_CONTAINER_LOADED:
          return (
            !!this.state.containerNumber &&
            !!this.state.chassisNumber &&
            !!this.state.chassisLicensePlateNumber &&
            !!this.state.sealNumber &&
            !!this.state.truckLicensePlateNumber
          );
        case EquipmentTypeForm.CONTAINER_EMPTY:
        case EquipmentTypeForm.TRUCK_CONTAINER_EMPTY:
          return (
            !!this.state.containerNumber &&
            !!this.state.chassisNumber &&
            !!this.state.chassisLicensePlateNumber &&
            !!this.state.truckLicensePlateNumber
          );
        case EquipmentTypeForm.CHASSIS_ONLY:
          return (
            !!this.state.chassisNumber &&
            !!this.state.truckLicensePlateNumber &&
            !!this.state.chassisLicensePlateNumber
          );
        case EquipmentTypeForm.TRUCK_ONLY:
          return !!this.state.truckLicensePlateNumber;
        case EquipmentTypeForm.TRUCK_TRAILER_EMPTY:
        case EquipmentTypeForm.TRAILER_EMPTY:
          return (
            !!this.state.trailerNumber && !!this.state.truckLicensePlateNumber
          );
        case EquipmentTypeForm.REEFER_LOADED_PLUGIN:
        case EquipmentTypeForm.TRAILER_LOADED:
        case EquipmentTypeForm.TRUCK_TRAILER_LOADED:
        case EquipmentTypeForm.REEFER_LOADED_NO_PLUGIN:
        case EquipmentTypeForm.TRUCK_REEFER_LOADED_PLUGIN:
        case EquipmentTypeForm.TRUCK_REEFER_LOADED_NO_PLUGIN:
          return (
            !!this.state.sealNumber &&
            !!this.state.truckLicensePlateNumber &&
            !!this.state.driverLicenseNumber
          );
      }
    };
    const getEditForm = (value) => {
      switch (value) {
        case EquipmentTypeForm.CONTAINER_LOADED:
        case EquipmentTypeForm.TRUCK_CONTAINER_LOADED:
          return (
            <div>
              <ContainerEntryField
                className="ss-top"
                valueCallback={this.handleContainerIdFieldChange}
                initState={this.state.containerNumber}
                handleErrorMessageContainer={this.handleErrorMessageContainer}
              />
              <fieldset
                className="checkin-selector borderCol equipment-disable"
                disabled
              >
                <label>EQUIPMENT LENGTH</label>
                <Select
                  id="assetSize"
                  name="assetSize"
                  comp="editAssetType"
                  className="ss-book-space-form-asset-size"
                  handleChange={this.handleFieldChange}
                  selectedOption={this.state.assetSize}
                  placeholder="Choose"
                  options={[
                    "25' MAX (Truck Only)",
                    "45' MAX (Small Container)",
                    "53' MAX (Large Container)",
                    "83' MAX (Truck + Trailer)",
                  ]}
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="chassisNumber">CHASSIS NUMBER</label>
                <input
                  type="text"
                  id="chassisNumber"
                  name="chassisNumber"
                  value={this.state.chassisNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the chassis number"
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="chassisLicensePlateNumber">
                  CHASSIS LICENSE PLATE NUMBER
                </label>
                <input
                  type="text"
                  id="chassisLicensePlateNumber"
                  name="chassisLicensePlateNumber"
                  value={this.state.chassisLicensePlateNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the chassis license plate number"
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="sealNumber">SEAL NUMBER</label>
                <input
                  type="text"
                  id="sealNumber"
                  name="sealNumber"
                  value={this.state.sealNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the seal number"
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="truckLicensePlateNumber">
                  TRUCK LICENSE PLATE NUMBER
                </label>
                <input
                  type="text"
                  id="truckLicensePlateNumber"
                  name="truckLicensePlateNumber"
                  value={this.state.truckLicensePlateNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the truck's license plate number"
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="notes">REMARKS OR NOTES</label>
                <textarea
                  type="text"
                  id="notes"
                  name="notes"
                  value={this.state.notes}
                  onChange={(event) =>
                    this.setState({ [event.target.name]: event.target.value })
                  }
                  placeholder="Enter any notes about the check in."
                />
              </fieldset>
            </div>
          );
        case EquipmentTypeForm.CONTAINER_EMPTY:
        case EquipmentTypeForm.TRUCK_CONTAINER_EMPTY:
          return (
            <div>
              <ContainerEntryField
                className="ss-top"
                valueCallback={this.handleContainerIdFieldChange}
                initState={this.state.containerNumber}
                handleErrorMessageContainer={this.handleErrorMessageContainer}
              />
              <fieldset
                className="checkin-selector borderCol equipment-disable"
                disabled
              >
                <label>EQUIPMENT LENGTH</label>
                <Select
                  id="assetSize"
                  name="assetSize"
                  comp="editAssetType"
                  className="ss-book-space-form-asset-size"
                  handleChange={this.handleFieldChange}
                  selectedOption={this.state.assetSize}
                  placeholder="Choose"
                  options={[
                    "25' MAX (Truck Only)",
                    "45' MAX (Small Container)",
                    "53' MAX (Large Container)",
                    "83' MAX (Truck + Trailer)",
                  ]}
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="chassisNumber">CHASSIS NUMBER</label>
                <input
                  type="text"
                  id="chassisNumber"
                  name="chassisNumber"
                  value={this.state.chassisNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the chassis number"
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="chassisLicensePlateNumber">
                  CHASSIS LICENSE PLATE NUMBER
                </label>
                <input
                  type="text"
                  id="chassisLicensePlateNumber"
                  name="chassisLicensePlateNumber"
                  value={this.state.chassisLicensePlateNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the chassis license plate number"
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="truckLicensePlateNumber">
                  TRUCK LICENSE PLATE NUMBER
                </label>
                <input
                  type="text"
                  id="truckLicensePlateNumber"
                  name="truckLicensePlateNumber"
                  value={this.state.truckLicensePlateNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the truck's license plate number"
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="notes">REMARKS OR NOTES</label>
                <textarea
                  type="text"
                  id="notes"
                  name="notes"
                  value={this.state.notes}
                  onChange={(event) =>
                    this.setState({ [event.target.name]: event.target.value })
                  }
                  placeholder="Enter any notes about the check in."
                />
              </fieldset>
            </div>
          );
        case EquipmentTypeForm.CHASSIS_ONLY:
          return (
            <div>
              <fieldset
                className="checkin-selector borderCol equipment-disable"
                disabled
              >
                <label>EQUIPMENT LENGTH</label>
                <Select
                  id="assetSize"
                  name="assetSize"
                  comp="editAssetType"
                  className="ss-book-space-form-asset-size"
                  handleChange={this.handleFieldChange}
                  selectedOption={this.state.assetSize}
                  placeholder="Choose"
                  options={[
                    "25' MAX (Truck Only)",
                    "45' MAX (Small Container)",
                    "53' MAX (Large Container)",
                    "83' MAX (Truck + Trailer)",
                  ]}
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="chassisNumber">CHASSIS NUMBER</label>
                <input
                  type="text"
                  id="chassisNumber"
                  name="chassisNumber"
                  value={this.state.chassisNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the chassis number"
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="chassisLicensePlateNumber">
                  CHASSIS LICENSE PLATE NUMBER
                </label>
                <input
                  type="text"
                  id="chassisLicensePlateNumber"
                  name="chassisLicensePlateNumber"
                  value={this.state.chassisLicensePlateNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the chassis license plate number"
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="truckLicensePlateNumber">
                  TRUCK LICENSE PLATE NUMBER
                </label>
                <input
                  type="text"
                  id="truckLicensePlateNumber"
                  name="truckLicensePlateNumber"
                  value={this.state.truckLicensePlateNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the truck's license plate number"
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="notes">REMARKS OR NOTES</label>
                <textarea
                  type="text"
                  id="notes"
                  name="notes"
                  value={this.state.notes}
                  onChange={(event) =>
                    this.setState({ [event.target.name]: event.target.value })
                  }
                  placeholder="Enter any notes about the check in."
                />
              </fieldset>
            </div>
          );
        case EquipmentTypeForm.TRUCK_ONLY:
          return (
            <div>
              <fieldset
                className="checkin-selector borderCol equipment-disable"
                disabled
              >
                <label>EQUIPMENT LENGTH</label>
                <Select
                  id="assetSize"
                  name="assetSize"
                  comp="editAssetType"
                  className="ss-book-space-form-asset-size"
                  handleChange={this.handleFieldChange}
                  selectedOption={this.state.assetSize}
                  placeholder="Choose"
                  options={[
                    "25' MAX (Truck Only)",
                    "45' MAX (Small Container)",
                    "53' MAX (Large Container)",
                    "83' MAX (Truck + Trailer)",
                  ]}
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="truckLicensePlateNumber">
                  TRUCK LICENSE PLATE NUMBER
                </label>
                <input
                  type="text"
                  id="truckLicensePlateNumber"
                  name="truckLicensePlateNumber"
                  value={this.state.truckLicensePlateNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the truck's license plate number"
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="notes">REMARKS OR NOTES</label>
                <textarea
                  type="text"
                  id="notes"
                  name="notes"
                  value={this.state.notes}
                  onChange={(event) =>
                    this.setState({ [event.target.name]: event.target.value })
                  }
                  placeholder="Enter any notes about the check in."
                />
              </fieldset>
            </div>
          );
        case EquipmentTypeForm.TRUCK_TRAILER_EMPTY:
        case EquipmentTypeForm.TRAILER_EMPTY:
          return (
            <div>
              <fieldset
                className="checkin-selector borderCol equipment-disable"
                disabled
              >
                <label>EQUIPMENT LENGTH</label>
                <Select
                  id="assetSize"
                  name="assetSize"
                  comp="editAssetType"
                  className="ss-book-space-form-asset-size"
                  handleChange={this.handleFieldChange}
                  selectedOption={this.state.assetSize}
                  placeholder="Choose"
                  options={[
                    "25' MAX (Truck Only)",
                    "45' MAX (Small Container)",
                    "53' MAX (Large Container)",
                    "83' MAX (Truck + Trailer)",
                  ]}
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="trailerNumber">TRAILER NUMBER</label>
                <input
                  type="text"
                  id="trailerNumber"
                  name="trailerNumber"
                  value={this.state.trailerNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the trailer number"
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="truckLicensePlateNumber">
                  TRUCK LICENSE PLATE NUMBER
                </label>
                <input
                  type="text"
                  id="truckLicensePlateNumber"
                  name="truckLicensePlateNumber"
                  value={this.state.truckLicensePlateNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the truck's license plate number"
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="notes">REMARKS OR NOTES</label>
                <textarea
                  type="text"
                  id="notes"
                  name="notes"
                  value={this.state.notes}
                  onChange={(event) =>
                    this.setState({ [event.target.name]: event.target.value })
                  }
                  placeholder="Enter any notes about the check in."
                />
              </fieldset>
            </div>
          );
        case EquipmentTypeForm.REEFER_LOADED_PLUGIN:
        case EquipmentTypeForm.TRAILER_LOADED:
        case EquipmentTypeForm.TRUCK_TRAILER_LOADED:
        case EquipmentTypeForm.REEFER_LOADED_NO_PLUGIN:
        case EquipmentTypeForm.TRUCK_REEFER_LOADED_PLUGIN:
        case EquipmentTypeForm.TRUCK_REEFER_LOADED_NO_PLUGIN:
          return (
            <div>
              <fieldset
                className="checkin-selector borderCol equipment-disable"
                disabled
              >
                <label>EQUIPMENT LENGTH</label>
                <Select
                  id="assetSize"
                  name="assetSize"
                  comp="editAssetType"
                  className="ss-book-space-form-asset-size"
                  handleChange={this.handleFieldChange}
                  selectedOption={this.state.assetSize}
                  placeholder="Choose"
                  options={[
                    "25' MAX (Truck Only)",
                    "45' MAX (Small Container)",
                    "53' MAX (Large Container)",
                    "83' MAX (Truck + Trailer)",
                  ]}
                />
              </fieldset>
              <fieldset className="checkin-selector">
                <label htmlFor="sealNumber">SEAL NUMBER</label>
                <input
                  type="text"
                  id="sealNumber"
                  name="sealNumber"
                  value={this.state.sealNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the seal number"
                />
              </fieldset>
              <fieldset className="checkin-selector">
                <label htmlFor="trailerNumber">TRAILOR NUMBER</label>
                <input
                  type="text"
                  id="trailerNumber"
                  name="trailerNumber"
                  value={this.state.trailerNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the trailor number"
                />
              </fieldset>
              <fieldset className="checkin-selector">
                <label htmlFor="truckLicensePlateNumber">
                  TRUCK LICENSE PLATE NUMBER
                </label>
                <input
                  type="text"
                  id="truckLicensePlateNumber"
                  name="truckLicensePlateNumber"
                  value={this.state.truckLicensePlateNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the truck's license plate number"
                />
              </fieldset>

              <fieldset className="checkin-selector">
                <label htmlFor="notes">REMARKS OR NOTES</label>
                <textarea
                  type="text"
                  id="notes"
                  name="notes"
                  value={this.state.notes}
                  onChange={(event) =>
                    this.setState({ [event.target.name]: event.target.value })
                  }
                  placeholder="Enter any notes about the check in."
                />
              </fieldset>
            </div>
          );
        default:
          return (
            <div>
              <ContainerEntryField
                className="ss-top"
                valueCallback={this.handleContainerIdFieldChange}
                initState={this.state.containerNumber}
                handleErrorMessageContainer={this.handleErrorMessageContainer}
              />
              <fieldset
                className="checkin-selector borderCol equipment-disable"
                disabled
              >
                <label>EQUIPMENT LENGTH</label>
                <Select
                  id="assetSize"
                  name="assetSize"
                  comp="editAssetType"
                  className="ss-book-space-form-asset-size"
                  handleChange={this.handleFieldChange}
                  selectedOption={this.state.assetSize}
                  placeholder="Choose"
                  options={[
                    "25' MAX (Truck Only)",
                    "45' MAX (Small Container)",
                    "53' MAX (Large Container)",
                    "83' MAX (Truck + Trailer)",
                  ]}
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="chassisNumber">CHASSIS NUMBER</label>
                <input
                  type="text"
                  id="chassisNumber"
                  name="chassisNumber"
                  value={this.state.chassisNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the chassis number"
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="chassisLicensePlateNumber">
                  CHASSIS LICENSE PLATE NUMBER
                </label>
                <input
                  type="text"
                  id="chassisLicensePlateNumber"
                  name="chassisLicensePlateNumber"
                  value={this.state.chassisLicensePlateNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the chassis license plate number"
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="sealNumber">SEAL NUMBER</label>
                <input
                  type="text"
                  id="sealNumber"
                  name="sealNumber"
                  value={this.state.sealNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the seal number"
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="truckLicensePlateNumber">
                  TRUCK LICENSE PLATE NUMBER
                </label>
                <input
                  type="text"
                  id="truckLicensePlateNumber"
                  name="truckLicensePlateNumber"
                  value={this.state.truckLicensePlateNumber}
                  onChange={this.handleFieldChange}
                  placeholder="Enter the truck's license plate number"
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="driverFirstName">DRIVER FIRST NAME</label>
                <input
                  type="text"
                  id="driverFirstName"
                  name="driverFirstName"
                  value={this.state.driverFirstName}
                  disabled={true}
                />
              </fieldset>
              <fieldset className="checkin-selector borderCol">
                <label htmlFor="driverLastName">DRIVER LAST NAME</label>
                <input
                  type="text"
                  id="driverLastName"
                  name="driverLastName"
                  value={this.state.driverLastName}
                  disabled={true}
                />
              </fieldset>

              <fieldset className="checkin-selector borderCol">
                <label htmlFor="notes">REMARKS OR NOTES</label>
                <textarea
                  type="text"
                  id="notes"
                  name="notes"
                  value={this.state.notes}
                  onChange={(event) =>
                    this.setState({ [event.target.name]: event.target.value })
                  }
                  placeholder="Enter any notes about the check in."
                />
              </fieldset>
            </div>
          );
      }
    };
    return (
      <div>
        <form className="ss-form ss-block">
          {this.state.id ? (
            <div>
              <div className="modal-body">
                <DriverSelect
                  drivers={this.state.approvedDrivers}
                  handleDriverChange={this.handleDriverChange}
                  selectedDriver={this.state.selectedDriver}
                  driverOptions={this.state.driverOptions}
                />
                <fieldset className="checkin-selector borderCol">
                  <label htmlFor="driverLicenseNumber">
                    DRIVER LICENSE NUMBER
                  </label>
                  <input
                    type="text"
                    id="driverLicenseNumber"
                    name="driverLicenseNumber"
                    value={this.state.driverLicenseNumber}
                    onChange={this.handleFieldChange}
                  />
                </fieldset>
                <div>
                  <fieldset
                    className="checkin-selector borderCol equipment-disable"
                    disabled
                  >
                    <label>EQUIPMENT TYPE</label>
                    {this.state.assetTypes?.length > 0 && (
                      <Select
                        id="assetType"
                        name="assetType"
                        comp="editAssetType"
                        optionsWidth="300px"
                        className="ss-book-space-form-asset-type"
                        handleChange={this.handleAssetTypeChange}
                        selectedOption={this.state.assetType}
                        placeholder="Select the equipment type being checked in"
                        options={this.state.assetTypes.map(
                          (item) => new ReferenceOption(item.key, item.value)
                        )}
                      />
                    )}
                  </fieldset>
                </div>
                <div>
                  {this.state?.assetType
                    ? getEditForm(this.state?.assetType?.getValue())
                    : ""}
                  {this.state.errorMessage ? (
                    <Error>{this.state.errorMessage}</Error>
                  ) : (
                    ""
                  )}
                </div>

                {this.state.errorMessage ? (
                  <Error>{this.state.errorMessage}</Error>
                ) : (
                  ""
                )}
              </div>
              <div className="modal-footer">
                <div className="newbuttonDivSubmitDelete newButtonDiv">
                  <button
                    type="button"
                    className="ss-button-secondary"
                    onClick={() => this.props.handlePanelCloseEvent()}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="ss-button-primary"
                    onClick={() => this.saveChanges()}
                    disabled={
                      this.state?.assetType
                        ? !this.state.driverLicenseNumber
                          ? true
                          : !disableSaveButton(
                              this.state?.assetType?.getValue()
                            )
                        : false
                    }
                    style={
                      this.state?.assetType
                        ? disableSaveButton(
                            this.state?.assetType?.getValue()
                          ) && !!this.state.driverLicenseNumber
                          ? {
                              marginLeft: "10px",
                            }
                          : {
                              opacity: "0.5",
                              cursor: "not-allowed",
                              marginLeft: "10px",
                            }
                        : {
                            marginLeft: "10px",
                          }
                    }
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </form>
      </div>
    );
  }
}
