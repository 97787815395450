import request from "../../util/SuperagentUtils";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const reserveWaitListEntry = (waitListEntryId, zoneId) => {
  return request
    .post(`${baseUrl}/api/wait-list/` + waitListEntryId + "/reserve-space")
    .query({ zoneId: zoneId })
    .withCredentials();
};

export const getAllWaitListEntries = () => {
  return request.get(`${baseUrl}/api/wait-list`).withCredentials();
};

export const getWaitListReportData = (dates) => {
  return request
    .get(`${baseUrl}/api/wait-list/report`)
    .query(dates)
    .withCredentials();
};

export const deleteWaitListEntryById = (waitListEntryId) => {
  return request
    .delete(`${baseUrl}/api/wait-list/` + waitListEntryId)
    .withCredentials();
};
