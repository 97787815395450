import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import "../css/components/dropzone.css"; // Keep the same CSS import
import { toast } from "react-toastify";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "../App.css";
import ModalForm from "../components/ModalForm";
import { s3BucketUrl } from "./constants/securspace-constants";

const MAX_FILE_SIZE_MB = 20;

const DropGallery = (props) => {
  const MAX_FILES =
    props?.comp === "imageGallery" ||
    props?.comp === "checkIn" ||
    props?.comp === "checkOut"
      ? 10
      : props?.comp === "listingImage"
      ? 1
      : 2;

  const [files, setFiles] = useState([]);
  const [cropFiles, setCropFiles] = useState([]);
  const [showCropper, setShowCropper] = useState(false);
  const cropperRef = useRef(null);
  // console.log("props?.locationGallery", props?.locationGallery);

  // Preload images on locationGallery change
  useEffect(() => {
    if (props?.locationGallery && props?.locationGallery.length > 0) {
      preloadImages();
    }
  }, []);
  useEffect(() => {}, [files]);

  const preloadImages = () => {
    const preloadedFiles = props?.locationGallery.map((file) => {
      //const url = `${s3BucketUrl}${props.bucket}/${props.locationId}/${file.galleryImageFileName}`;
      const url =
        props?.comp === "listingImage"
          ? `${s3BucketUrl}${props.bucket}/${file.galleryImageFileName}`
          : `${s3BucketUrl}${props.bucket}/${props.locationId}/${file.galleryImageFileName}`;

      return {
        name: file.galleryImageFileName,
        id: file?.id,
        cropped: 1,
        size: null,
        accepted: true,
        type: "fake",
        url: url,
      };
    });
    setFiles(preloadedFiles);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const validTypes = ["image/jpeg", "image/png"];
      const newFiles = acceptedFiles.filter(
        (file) =>
          validTypes.includes(file.type) &&
          file.size <= MAX_FILE_SIZE_MB * 1024 * 1024
      );

      if (newFiles.length === 0) {
        toast.error("Invalid file type or file size too large.");
        return;
      }

      if (files.length + newFiles.length > MAX_FILES) {
        toast.error("Maximum files exceeded.");
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        const newFile = {
          name: newFiles[0].name,
          size: newFiles[0].size,
          type: newFiles[0].type,
          dataURL: reader.result,
          cropped: 0,
        };
        setCropFiles((prev) => [...prev, newFile]);
        setShowCropper(true);
      };
      reader.readAsDataURL(newFiles[0]);
    },
    [files.length]
  );

  const handleCrop = () => {
    const file = cropFiles[0];
    const croppedImage = cropperRef.current.cropper
      .getCroppedCanvas({
        width: 1024,
        height: 680,
        fillColor: "#fff",
        imageSmoothingEnabled: false,
        imageSmoothingQuality: "high",
      })
      .toDataURL();

    const croppedFile = { ...file, dataURL: croppedImage, cropped: 1 };
    setFiles((prev) => [...prev, croppedFile]);
    setCropFiles((prev) => prev.slice(1));
    setShowCropper(cropFiles.length > 1);
  };

  const handleRemoveFile = (index, file) => {
    setFiles((prev) => prev.filter((_, i) => i !== index));
    if (props?.comp === "listingImage") {
      props.updateLocationListingImage();
    }
    if (props?.comp === "imageGallery") {
      const updatedGalleries = props.locationGalleries.map((ele) => {
        if (ele?.id === file.id) return { ...ele, galleryDeleted: true };
        return ele;
      });
      props?.updateLocationGalleries(updatedGalleries);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: MAX_FILES,
    multiple: false,
    accept: "image/jpeg, image/png,image/jpg",
  });

  const handleCancel = () => {
    setCropFiles([]);
    setShowCropper(false);
  };

  // Pass `open` method or other Dropzone utilities via `updateDropzone` prop
  useEffect(() => {
    if (props.updateDropzone) {
      props.updateDropzone({ files });
    }
  }, [files]);
  // console.log("files", files);
  // console.log("cropFiles", cropFiles);

  return (
    <div>
      {/* Dropzone area */}
      <div {...getRootProps({ className: "filepicker dropzone dz-clickable" })}>
        <input {...getInputProps()} />
        <div className="dz-message">
          <span>Click to select file or drop file</span>
        </div>
      </div>

      {/* Gallery display */}
      <div className="dz-gallery">
        {files.length > 0 &&
          files.map((file, index) => (
            <div
              key={index}
              className="dz-image custom-style-border"
              style={{ marginTop: "10px" }}
            >
              <img
                src={file.type === "fake" ? file.url : file.dataURL}
                alt="gallery"
              />
              <span>
                <button
                  type="button"
                  className="dz-remove"
                  onClick={() => handleRemoveFile(index, file)}
                  title="Remove file"
                >
                  Remove file
                </button>
              </span>
            </div>
          ))}
      </div>

      {/* Cropper modal */}
      {showCropper && cropFiles.length > 0 && (
        <ModalForm
          showForm={showCropper}
          size="large"
          title="Crop Uploaded Images"
          onClose={() => handleCancel()}
          proceedEventHandler={handleCrop}
          textOk={cropFiles.length > 1 ? "Next" : "Save"}
        >
          <Cropper
            ref={cropperRef}
            src={cropFiles[0].dataURL}
            style={{ width: "100%", height: 340 }}
            guides={true}
            highlight={true}
            viewMode={1}
          />
        </ModalForm>
      )}
    </div>
  );
};

export default DropGallery;
