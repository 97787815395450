import React from "react";
import Select from "../Select";
import StyledButton from "../form/StyledButton";
import "../../css/views/loginAsAccount.css";

const ZoneOverrideSelect = (props) => {
  const { handleClose, handleSelectZone, selectedZone, zones, handleSubmit } =
    props;

  return (
    <>
      <div className="unselectable">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="popup-header" style={{ borderBottom: "none" }}>
              <h1>
                There are no zones that meet search criteria, please manually
                select a zone
              </h1>
              <button
                type="button"
                className="close zoneOverride"
                aria-label="Close"
                onClick={handleClose}
              >
                <img alt="" src="../app-images/close.png" />
              </button>
            </div>
            <fieldset className="ss-stand-alone">
              <div style={{ paddingBottom: "25em", textAlign: "center" }}>
                <Select
                  id="selectZone"
                  name="selectZone"
                  handleChange={handleSelectZone}
                  className="ss-account-select"
                  selectedOption={selectedZone?.value}
                  placeholder="Choose"
                  compName="waitList"
                  options={zones}
                  canSearch="1"
                />
              </div>
            </fieldset>
            <div style={{ padding: "3em" }}>
              <StyledButton label={"Submit"} onClick={handleSubmit} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ZoneOverrideSelect;
