import React from "react";
import Steps from "../steps/Steps";
import FineNotesTextArea from "./FineNotesTextArea";
import PropTypes from "prop-types";

const FineNotes = (props) => {
  const { stepNumber, onNotesChange, notes } = props;

  const onChange = (event) => {
    const value = event.target.value;
    if (!/^\s/.test(value) && !/\s{3,}/.test(value)) {
      onNotesChange(value);
    }
  };

  return (
    <div>
      <hr />
      <FineNotesTextArea notes={notes} onChange={onChange} />
      <hr />
      <Steps type="fineNote" step={stepNumber} {...props} />
    </div>
  );
};

FineNotes.propTypes = {
  stepNumber: PropTypes.number,
};

export default FineNotes;
