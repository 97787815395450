import React from "react";
import ReportRow from "../../report/ReportRow";
import ReportDataRow from "../../report/ReportDataRow";
import ReportData from "../../report/ReportData";
import { formatCurrencyValue } from "../../../util/PaymentUtils";
import getDisplayValue from "../../SupplierTransactionStatus";
import { InvoiceStatusType } from "../../constants/securspace-constants";
import { formatBookingTransaction } from "../../../util/InvoiceUtils";

const AdminInvoiceListItem = (props) => {
  const { invoice } = props;
  InvoiceStatusType;
  formatBookingTransaction;

  return (
    <ReportRow>
      <ReportDataRow>
        <ReportData label={"INVOICE NUMBER:"}>
          {invoice?.transactionNumber || "-"}
        </ReportData>
        <ReportData label={"SERVICE DATES:"}>
          {invoice?.serviceDates || "-"}
        </ReportData>
        <ReportData label={"CUSTOMER AMOUNT:"}>
          {invoice?.buyerAmount
            ? formatCurrencyValue(invoice.buyerAmount, true)
            : "-"}
        </ReportData>
        <ReportData label={"PAYMENT PROCESSOR AMOUNT:"}>
          {invoice?.paymentProcessorFees
            ? formatCurrencyValue(invoice.paymentProcessorFees, true)
            : "-"}
        </ReportData>
        <ReportData label={"PARTNER AMOUNT:"}>
          {invoice?.supplierAmount
            ? formatCurrencyValue(invoice.supplierAmount, true)
            : "-"}
        </ReportData>
        <ReportData label={"BOOKING STATE:"}>
          {invoice?.locationState || "-"}
        </ReportData>
        <ReportData label={"CHARGE TYPE:"}>
          {!!invoice?.transactionType
            ? formatBookingTransaction(invoice?.transactionType)
            : "-"}
        </ReportData>
        <ReportData label={"INVOICE CREATED:"}>
          {invoice?.createdOn || "-"}
        </ReportData>
        <ReportData label={"PAYMENT TYPE:"}>
          {invoice?.paymentType || "-"}
        </ReportData>
        <ReportData label={"PAYMENT INITIATED:"}>
          {invoice?.paymentCreatedOn || "-"}
        </ReportData>
        <ReportData label={"PAYMENT COMPLETED:"}>
          {invoice?.paymentCompletedOn || "-"}
        </ReportData>
        <ReportData label={"CUSTOMER:"}>
          {invoice?.buyerEmail || "-"}
        </ReportData>
        <ReportData label={"LOCATION:"}>
          {invoice?.locationName || "-"}
        </ReportData>
        <ReportData label={"PARTNER:"}>
          {invoice?.supplierCompanyName || "-"}
        </ReportData>
        <ReportData label={"BOOKING:"}>
          {invoice?.bookingNumber || "-"}
        </ReportData>
        <ReportData label={"SPACES:"}>
          {invoice?.numberOfSpaces || "-"}
        </ReportData>
        <ReportData label={"STATUS:"}>
          {!!invoice?.status ? InvoiceStatusType[invoice?.status] : "-"}
        </ReportData>
        <ReportData label={"BOOKING CREATED ON:"}>
          {invoice?.bookingCreatedOn || "-"}
        </ReportData>
        <ReportData label={"BOOKING CREATED BY:"}>
          {invoice?.bookingCreatedBy || "-"}
        </ReportData>
      </ReportDataRow>
    </ReportRow>
  );
};

export default AdminInvoiceListItem;
