import React, { useState, useEffect } from "react";
import Select from "../Select";
import DropGallery from "../DropGallery";
import Busy from "../Busy";
import { ajaxRequest } from "../../ajax";
import { toast } from "react-toastify";
import { isImageFile, s3BucketUrl } from "../constants/securspace-constants";
import {
  getSecurityDepositPaidAmount,
  getActiveBookingAmount,
} from "../../views/thirdparty/requests/thirdparty-requests";
import { getErrorMessageForStandardResponse } from "../../util/NetworkErrorUtil";
import { formatCurrencyValue } from "../../util/PaymentUtils";

const EditSecurityDepositForm = ({
  securityDepositParticularRecordDetails,
  setShowModal,
  setLoadGetAPi,
  loadGetApi,
}) => {
  const GALLERY_BUCKET = "security-deposit";
  const [dropfile, setDropFile] = useState(null);
  const [refundStatusName, setRefundStatusName] = useState("");
  const [modeOfPayment, setModeOfPayment] = useState("");
  const [notes, setNotes] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [modeOfPaymentNumber, setModeOfPaymentNumber] = useState(null);
  const [activeBookingAmountPaid, setActiveBookingAMountPaid] = useState("");
  const [securityDepositPaid, setSecurityDepositPaid] = useState("");

  //const dropfile = useRef(null); // Use ref for dropfile

  useEffect(() => {
    Busy.set(true);

    getSecurityDepositPaidAmount(
      securityDepositParticularRecordDetails?.accountId?.id
    )
      .then((response) => {
        setSecurityDepositPaid(response?.body?.securityDepositPaid);
        Busy.set(false);
      })
      .catch((error) => {
        toast.error(getErrorMessageForStandardResponse(error));
        Busy.set(false);
      });
  }, []);
  useEffect(() => {
    Busy.set(true);
    getActiveBookingAmount(
      securityDepositParticularRecordDetails?.accountId?.id
    )
      .then((response) => {
        setActiveBookingAMountPaid(response?.body?.activeBookingAmount);
        Busy.set(false);
      })
      .catch((error) => {
        toast.error(getErrorMessageForStandardResponse(error));
        Busy.set(false);
      });
  }, []);

  const updateDropzone = (dropzone) => {
    setDropFile(dropzone);
  };

  const validateFiles = (allUploadFiles) => {
    for (let i = 0; i < allUploadFiles.length; i++) {
      let fileToUpload = allUploadFiles[i];
      let file = fileToUpload.files ? fileToUpload.files[0] : "";

      // Validate file size
      if (file.size > 20480000) {
        setErrorMessage("Selected file is too large. Max file size is 20 MB.");
        return false;
      }

      // Check if it's an image file
      if (fileToUpload.isImageFile) {
        if (file && !isImageFile(file)) {
          setErrorMessage(
            "File selected for listing image is not an image file."
          );
          return false;
        }
      }
    }
    // If all validations pass
    return true;
  };

  const handleFileUpload = () => {
    let allUploadFiles = [];

    if (!!dropfile && dropfile?.files?.length > 0) {
      for (let i = 0; i < dropfile.files.length; i++) {
        let file = dropfile.files[i];

        // New file
        allUploadFiles.push({
          files: [file],
          fileNameProperty: "securityDepositFileName",
          folder: GALLERY_BUCKET,
          isImageFile: true,
        });
      }
    }

    if (validateFiles(allUploadFiles)) {
      let uploadedGalleryFiles = [];

      if (!!dropfile && dropfile?.files?.length > 0) {
        for (let i = 0; i < dropfile.files.length; i++) {
          let file = dropfile.files[i];
          const fileData = {
            name: file.name,
            size: file.size,
            type: file.type,
            dataUrl: file.dataURL,
          };
          uploadedGalleryFiles.push(fileData);
        }
      }
      return uploadedGalleryFiles;
    } else {
      toast.error("File validation failed");
      return [];
    }
  };

  const uploadFilesAndSubmitForm = (filesToUpload) => {
    return new Promise((resolve, reject) => {
      let uploadedFileNames = [];

      const uploadNextFile = () => {
        if (filesToUpload.length === 0) {
          resolve(uploadedFileNames);
          return;
        }

        let fileToUpload = filesToUpload.shift();
        let file = fileToUpload.files ? fileToUpload.files[0] : "";

        if (file && file.name) {
          uploadFile(
            fileToUpload.folder,
            fileToUpload.oldFileName,
            file,
            (uploadedFileName, textStatus, jqXHR) => {
              uploadedFileNames.push(uploadedFileName);

              uploadNextFile();
            },
            (error) => {
              reject(error);
            }
          );
        } else {
          reject("No file found");
        }
      };

      uploadNextFile(); // Start uploading the first file
    });
  };

  const uploadFile = (folder, oldFileName, file, onSuccess, onError) => {
    let data = new FormData();
    data.append("inputStream", file.dataURL);
    let profileFileName = securityDepositParticularRecordDetails?.id;
    const fileName = file?.name?.replace(/\s+/g, "");

    let url = `file/upload/gallery?name=${fileName}&inventoryId=${profileFileName}&contentType=${file.type}`;

    ajaxRequest({
      url: url,
      type: "POST",
      data: data,
      cache: false,
      processData: false, // Don't process the files
      contentType: false, // Set content type to false as jQuery will tell the server it's a query string request
      success: function (data, textStatus, jqXHR) {
        onSuccess(fileName, textStatus, jqXHR); // Pass the file name on success
      },
      error: function (jqXHR, textStatus, errorThrown) {
        Busy.set(false);
        onError(errorThrown); // Call the error callback
      },
    });
  };

  const handleRefundStatus = (event) => {
    setRefundStatusName(event.target.value);
  };
  const handleModeOfPayment = (event) => {
    setModeOfPayment(event.target.value);
  };
  const handleSuccessSecurity = () => {
    toast.success("Refund status updated successfully!");
    Busy.set(false);
    setLoadGetAPi(!loadGetApi);
    setShowModal(false);
  };
  const handleFailed = (err) => {
    toast.error(err?.responseJSON?.message);
    Busy.set(false);
  };
  const getRefundPaymentType = (val) => {
    let refundType = "";
    switch (val) {
      case "CHEQUE":
        refundType = "CHEQUE_NUMBER";
        break;
      case "DEMAND DRAFT":
        refundType = "DD";
        break;
      case "ONLINE PAYMENT":
        refundType = "ONLINE_PAYMENT";
        break;
      default:
        refundType = null;
    }
    return refundType;
  };
  const getStatusType = (val) => {
    let statusType = "";
    switch (val) {
      case "Requested":
        statusType = "REQUESTED";
        break;
      case "In Progress":
        statusType = "IN_PROGRESS";
        break;
      case "Rejected":
        statusType = "REJECTED";
        break;
      case "Refunded":
        statusType = "COMPLETED";
        break;
      case "Cancelled":
        statusType = "CANCELLED";
        break;
    }
    return statusType;
  };
  const dataUrlToFile = (dataUrl, fileName) => {
    // Step 1: Extract the MIME type and base64 data
    const [metadata, base64Data] = dataUrl.split(",");

    // Step 2: Decode the base64 string into binary data
    const byteString = atob(base64Data);

    // Step 3: Convert binary string to an array of bytes
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    // Step 4: Create a Blob object from the byte array
    const mimeType = metadata.match(/:(.*?);/)[1]; // Extract MIME type (e.g., "image/jpeg")
    const blob = new Blob([uintArray], { type: mimeType });

    // Step 5: Create a File from the Blob
    const file = new File([blob], fileName, { type: mimeType });

    return file;
  };

  const handleSubmit = async () => {
    try {
      Busy.set(true);
      // Await the handleFileUpload function
      let uploadedFileName = [];
      uploadedFileName = handleFileUpload();
      const updatedArray = [];
      if (uploadedFileName?.length > 0) {
        for (let i = 0; i < uploadedFileName?.length; i++) {
          const newFile = dataUrlToFile(
            uploadedFileName[i]?.dataUrl,
            uploadedFileName[i]?.name
          );
          updatedArray.push(newFile);
        }
      }

      if (
        uploadedFileName?.length === 0 &&
        getStatusType(refundStatusName) === "COMPLETED"
      ) {
        toast.error("Please upload image file!!");
        Busy.set(false);
        return;
      }

      // Proceed only if handleFileUpload is successful
      const payloadData = {
        id: securityDepositParticularRecordDetails?.id,
        refundPaymentType: getRefundPaymentType(modeOfPayment),
        refundReturnRequestNumber: modeOfPaymentNumber,
        refundStatus: getStatusType(refundStatusName),
        notes: notes,
      };
      const dataToBeSent = new FormData();
      dataToBeSent.append("refundRequest", JSON.stringify(payloadData));
      if (updatedArray?.length == 0) dataToBeSent.append("imageGallery", null);
      updatedArray.forEach((file, index) => {
        dataToBeSent.append("refundImages", file); // Notice the "[]" which indicates an array in FormData
      });

      ajaxRequest({
        url: "security-deposit",
        data: dataToBeSent,
        type: "POST",
        contentType: false, // Don't manually set contentType when sending FormData
        processData: false, // Don't process data, let FormData handle it
        dataType: "json",
        success: handleSuccessSecurity,
        error: handleFailed,
      });
    } catch (error) {
      setErrorMessage("File upload failed. Please try again.");
    }
  };

  // console.log(
  //   "securityDepositParticularRecordDetails",
  //   securityDepositParticularRecordDetails
  // );

  return (
    <>
      <form className="ss-form ss-block">
        <div
          style={{
            fontWeight: "bolder",
            marginLeft: "10px",
            marginTop: "10px",
            marginBottom: "10px",
            marginLeft: "50px",
          }}
        >
          Active booking amount :{" "}
          {formatCurrencyValue(activeBookingAmountPaid, true)}
        </div>
        <div
          style={{
            fontWeight: "bolder",
            marginLeft: "10px",
            marginBottom: "10px",
            marginLeft: "50px",
          }}
        >
          Security deposit amount :{" "}
          {formatCurrencyValue(securityDepositPaid, true)}
        </div>

        {Number(activeBookingAmountPaid) / 100 < 10000 ? (
          <>
            <div style={{ paddingRight: "44px", paddingLeft: "48px" }}>
              <div className="checkin-selector">
                <label>REFUND STATUS</label>

                <Select
                  id="refundStatus"
                  name="refundStatus"
                  className="ss-book-space-form-asset-type"
                  optionsWidth="300px"
                  handleChange={handleRefundStatus}
                  selectedOption={refundStatusName}
                  placeholder="Please select"
                  options={
                    securityDepositParticularRecordDetails?.refundStatus ===
                      "NA" ||
                    securityDepositParticularRecordDetails?.refundStatus ===
                      "REJECTED" ||
                    securityDepositParticularRecordDetails?.refundStatus ===
                      "CANCELLED"
                      ? ["Requested"]
                      : securityDepositParticularRecordDetails?.refundStatus ===
                        "REQUESTED"
                      ? ["In Progress"]
                      : ["Cancelled", "Refunded", "Rejected"]
                  }
                />
              </div>
              {(refundStatusName === "Cancelled" ||
                refundStatusName === "Rejected") && (
                <>
                  {/* <p style={{ marginTop: "10px" }}>Click below to add image</p>
              <fieldset
                className="ss-top ss-dz"
                style={{ marginBottom: "-24px" }}
              >
                <DropGallery
                  bucket={GALLERY_BUCKET}
                  comp="securityDeposit"
                  //locationGallery={this.state.locationGallery}
                  locationId={securityDepositParticularRecordDetails?.id}
                  setDropzone={updateDropzone}
                  updateDropzone={updateDropzone}
                />
              </fieldset>
              <div style={{ marginBottom: "35px" }}></div> */}

                  <fieldset
                    className="ss-middle"
                    style={{ borderRadius: "5px", border: "solid 1px" }}
                  >
                    <label htmlFor="notes">REMARKS OR NOTES</label>
                    <textarea
                      id="notes"
                      name="notes"
                      value={notes}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.startsWith(" ")) {
                          setNotes(value.trimStart()); // Removes any leading spaces
                        } else {
                          setNotes(value);
                        }
                      }}
                      placeholder="Enter notes regarding the refund status."
                    />
                  </fieldset>
                </>
              )}
              {refundStatusName === "Refunded" && !!refundStatusName && (
                <>
                  {" "}
                  <div className="checkin-selector">
                    <label>MODE OF REFUND PAYMENT</label>

                    <Select
                      id="modeOfPayment"
                      name="modeOfPayment"
                      className="ss-book-space-form-asset-type"
                      optionsWidth="300px"
                      handleChange={handleModeOfPayment}
                      selectedOption={modeOfPayment}
                      placeholder="Please select"
                      options={["CHEQUE", "ONLINE PAYMENT"]}
                    />
                  </div>
                  {!!modeOfPayment && (
                    <>
                      <fieldset
                        className="ss-middle"
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #e1e1e1",
                        }}
                      >
                        <label htmlFor="modeOfPaymentNumber">
                          {modeOfPayment}
                        </label>
                        <input
                          name="modeOfPaymentNumber"
                          id="modeOfPaymentNumber"
                          value={modeOfPaymentNumber}
                          onChange={(e) =>
                            setModeOfPaymentNumber(e.target.value?.trim())
                          }
                          placeholder={`Enter ${modeOfPayment} number`}
                        />
                      </fieldset>
                      <p style={{ marginTop: "10px" }}>
                        Click below to add image of {modeOfPayment}
                      </p>
                      <fieldset
                        className="ss-top ss-dz"
                        //  style={{ marginBottom: "-24px" }}
                      >
                        <DropGallery
                          bucket={GALLERY_BUCKET}
                          // comp="securityDeposit"
                          locationId={
                            securityDepositParticularRecordDetails?.id
                          }
                          updateDropzone={updateDropzone}
                        />
                      </fieldset>
                      <div style={{ marginBottom: "35px" }}></div>
                    </>
                  )}
                  <fieldset
                    className="ss-middle"
                    style={{
                      borderRadius: "5px",
                      border: "solid 1px #e1e1e1",
                      marginTop: "10px",
                    }}
                  >
                    <label htmlFor="notes">REMARKS OR NOTES</label>
                    <textarea
                      id="notes"
                      name="notes"
                      value={notes}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.startsWith(" ")) {
                          setNotes(value.trimStart()); // Removes any leading spaces
                        } else {
                          setNotes(value);
                        }
                      }}
                      placeholder="Enter notes regarding the refund status."
                    />
                  </fieldset>
                </>
              )}
            </div>
            <div className="modal-footer" style={{ border: "none" }}>
              <div
                className="table text-left"
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <button
                  onClick={() => setShowModal(false)}
                  type="button"
                  className="ss-button-primary-modal-form reverse"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="ss-button-primary-modal-form"
                  onClick={handleSubmit}
                  disabled={
                    !(refundStatusName === "Refunded"
                      ? !!(modeOfPayment?.trim()?.length > 0) &&
                        !!(modeOfPaymentNumber?.trim()?.length > 0) &&
                        !!notes
                        ? true
                        : false
                      : refundStatusName === "Cancelled" ||
                        refundStatusName === "Rejected"
                      ? !!notes
                        ? true
                        : false
                      : !refundStatusName
                      ? false
                      : true)
                  }
                  style={
                    !(refundStatusName === "Refunded"
                      ? !!(modeOfPayment?.trim()?.length > 0) &&
                        !!(modeOfPaymentNumber?.trim()?.length > 0) &&
                        !!notes
                        ? true
                        : false
                      : refundStatusName === "Cancelled" ||
                        refundStatusName === "Rejected"
                      ? !!notes
                        ? true
                        : false
                      : !refundStatusName
                      ? false
                      : true)
                      ? { opacity: "0.5" }
                      : {}
                  }
                >
                  Save
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                marginLeft: "47px",
                marginRight: "47px",
                marginTop: "25px",
              }}
            >
              <div
                className="ss-error"
                style={{ display: "flex", justifyContent: "center" }}
              >
                The current selected security deposit has not been processed due
                to an active booking that requires a security deposit
              </div>
            </div>

            <div className="modal-footer" style={{ border: "none" }}>
              <button
                onClick={() => setShowModal(false)}
                type="button"
                className="ss-button-primary-modal-form reverse"
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </form>{" "}
    </>
  );
};

export default EditSecurityDepositForm;
