import React from "react";
import SelectInput from "../common/SelectInput";
import CustomDropdown from "../common/customDropdown";

const FrequencySelect = (props) => {
  const { frequencyTypes, selectedFrequency, onFrequencyChange } = props;

  return (
    // <div>
    //   <CustomDropdown
    //     name={"Select Frequency: "}
    //     value={selectedFrequency}
    //     placeholder={"Select Frequency: "}
    //     items={frequencyTypes}
    //     onChange={onFrequencyChange}
    //   />
    //   <p>Selected Value: {selectedFrequency}</p>
    // </div>
    <SelectInput
      label={"Select Frequency: "}
      name={"Select Frequency: "}
      value={selectedFrequency}
      onChange={onFrequencyChange}
      items={frequencyTypes}
    />
  );
};

export default FrequencySelect;
