import React, { useEffect, useState } from "react";
import { requestSecurityDeposit } from "../invoice/requests/invoice-requests";
import { getErrorMessageForStandardResponse } from "../../util/NetworkErrorUtil";
import { toast } from "react-toastify";
import {
  SORT_DIRECTION_ASC,
  SORT_DIRECTION_DESC,
} from "../../util/report/ReportUtils";
import { searchRecordsByKeywordWaitListAvailability } from "../../util/SearchUtils";
import _ from "underscore";
import ReportBackgroundContainer from "../report/ReportBackgroundContainer";
import ReportHeader from "../report/ReportHeader";
import Filter from "../../views/bookings/Filter";
import GroupAndSort from "../../views/bookings/GroupAndSort";
import {
  compareObjectsAscending,
  compareObjectsDescending,
  DateFormats,
} from "../constants/securspace-constants";
import moment from "moment";
import ReportEmpty from "../report/ReportEmpty";
import { extractDay } from "../constants/securspace-constants";
import SecurityDepositCard from "./securityDepositCard";
import ReportRow from "../report/ReportRow";
import SecurityDepositKebabButton from "./securityDepositKebabButton";
import ModalDialog from "../ModalDialog";
import EditSecurityDepositForm from "./editSecurityDepositForm";
import Busy from "../Busy";
import ReportFormContainer from "../report/ReportFormContainer";
import ReportFormDateInput from "../report/ReportFormDateInput";
import "../../App.css";

const securityDepositReport = (props) => {
  const reportFields = [
    { label: "Amount", name: "securityDepositAmount" },
    { label: "Payment Type", name: "paymentType" },
    { label: "Invoice Number", name: "invoiceNumber" },
    { label: "Refund Status", name: "refundStatus" },
  ];

  const { account } = props;
  const [loadGetApi, setLoadGetAPi] = useState(false);

  const defaultStartDate = moment()
    .subtract(30, "days")
    .format(DateFormats.DAY);
  const defaultEndDate = moment().add(1, "days").format(DateFormats.DAY);
  const [showModal, setShowModal] = useState(false);
  const [
    securityDepositParticularRecordDetails,
    setSecurityDepositParticularRecordDetails,
  ] = useState(null);
  const [securityDeposit, setSecurityDeposit] = useState([]);
  const [securityDepositInitialData, setSecurityDepositInitialData] = useState(
    []
  );

  const [groupBy, setGroupBy] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION_ASC);
  const [groupDirection, setGroupDirection] = useState(SORT_DIRECTION_ASC);
  const [searchBox, setSearchBox] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [recordsShowing, setRecordsShowing] = useState([]);
  const visibleRecordBatchSize = 10;

  useEffect(() => {
    Busy.set(true);
    requestSecurityDeposit()
      .then(
        (response) => {
          const data = response.body;
          setSecurityDepositInitialData(data);
          setRecordsShowing(data);
          // setFilteredData(data);
          Busy.set(false);
        },
        (error) => {
          toast.error(getErrorMessageForStandardResponse(error));
          Busy.set(false);
        }
      )
      .catch((error) => {
        toast.error(getErrorMessageForStandardResponse(error));
        Busy.set(false);
      });
  }, [loadGetApi]);
  useEffect(() => {}, [recordsShowing?.length, sortBy?.name]);

  useEffect(() => {
    let data = securityDepositInitialData;

    if (startDate !== "") {
      data = data?.filter((zone) =>
        moment(zone?.dateOfDepositCharged).add(0, "day").isAfter(startDate)
      );
    }
    if (endDate !== "") {
      data = data?.filter((zone) =>
        moment(zone?.dateOfDepositCharged).subtract(0, "day").isBefore(endDate)
      );
    }

    setSecurityDeposit(data);
    setRecordsShowing(
      data?.length >= visibleRecordBatchSize
        ? data?.slice(0, visibleRecordBatchSize)
        : data
    );
  }, [startDate, endDate, securityDepositInitialData]);

  useEffect(() => {
    sortData(sortBy);
  }, [startDate, endDate, sortBy?.name, sortDirection, recordsShowing?.length]);

  const refreshVisibleRecords = (entry) => {
    setRecordsShowing(
      entry.length >= visibleRecordBatchSize
        ? entry.slice(0, visibleRecordBatchSize)
        : entry
    );
  };

  useEffect(() => {
    refreshVisibleRecords(securityDeposit);
  }, [sortBy, securityDeposit]);

  const fetchMoreData = () => {
    if (recordsShowing?.length >= securityDeposit?.length) {
      return;
    }

    const end = recordsShowing?.length + visibleRecordBatchSize;
    setRecordsShowing(
      securityDeposit?.length >= end
        ? securityDeposit?.slice(0, end)
        : securityDeposit
    );
  };

  const handleStartDate = (date) => {
    let day1 = extractDay(date);
    let day2 = extractDay(startDate);

    // if (!(day1 === day2)) {
    //   setStartDate(date);
    // }
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    let day1 = extractDay(date);
    let day2 = extractDay(endDate);

    // if (!(day1 === day2)) {
    //   setEndDate(date);
    // }
    setEndDate(date);
  };

  const searchChangeHandler = (event) => {
    const value = event.target.value;
    setSearchBox(value);
    const newArray = securityDeposit?.map((ele) => ({
      firstName: ele?.accountId?.firstName,
      lastName: ele?.accountId?.lastName,
      ...ele,
    }));
    // setFilteredData(searchRecordsByKeywordSecurity(value, newArray));
    setRecordsShowing(
      searchRecordsByKeywordWaitListAvailability(value, newArray)
    );
  };

  const groupByChangeHandler = (event) => {
    const value = event.target.value;
    setGroupBy(value);
    groupData(value);
  };

  const groupData = (value) => {
    const groupedData = _.groupBy(filteredData, value);
    const toArray = _.toArray(groupedData);
    const flattened = _.flatten(toArray);
    // setFilteredData(flattened);
    setRecordsShowing(flattened);
  };

  const sortByChangeHandler = (event) => {
    const value = event.target.value;
    setSortBy(value);
    //sortData(value);
  };
  // console.log("securityDeposit", securityDeposit);
  //console.log("filteredData", filteredData);

  const sortData = (value) => {
    let tempData = [...recordsShowing];

    if (tempData) {
      if (value?.name) {
        if (sortDirection === SORT_DIRECTION_ASC) {
          tempData = tempData.sort((a, b) => {
            return compareObjectsAscending(a, b, value);
          });
        }
        if (sortDirection === SORT_DIRECTION_DESC) {
          tempData = tempData.sort((a, b) => {
            return compareObjectsDescending(a, b, sortBy);
          });
        }
      }
    }

    setRecordsShowing(tempData);

    // setFilteredData(tempData);
  };

  const sortDirectionChangeHandler = (value) => {
    setSortDirection(
      sortDirection === SORT_DIRECTION_ASC
        ? SORT_DIRECTION_DESC
        : SORT_DIRECTION_ASC
    );
  };

  const groupDirectionChangeHandler = () => {
    setGroupDirection(
      groupDirection === SORT_DIRECTION_ASC
        ? SORT_DIRECTION_DESC
        : SORT_DIRECTION_ASC
    );
    groupData(groupBy);
  };

  return (
    <ReportBackgroundContainer>
      <ReportHeader>Security Deposits</ReportHeader>
      <div className="width49">
        <ReportFormContainer>
          <ReportFormDateInput
            label={"START DATE:"}
            date={startDate}
            onDateChange={handleStartDate}
          />
          <ReportFormDateInput
            label={"END DATE:"}
            date={endDate}
            onDateChange={handleEndDate}
          />
        </ReportFormContainer>
      </div>
      {!!securityDeposit && securityDeposit.length > 0 ? (
        <>
          <Filter
            searchBox={searchBox}
            searchChangeHandler={searchChangeHandler}
            placeHolder="Type any field to filter security deposit result"
          />
          {recordsShowing?.length > 0 && (
            <>
              <GroupAndSort
                groupByOptions={reportFields}
                groupByChangeHandler={groupByChangeHandler}
                groupBy={groupBy}
                groupDirection={groupDirection}
                groupDirectionChangeHandler={groupDirectionChangeHandler}
                sortBy={sortBy}
                sortByChangeHandler={sortByChangeHandler}
                sortDirection={sortDirection}
                sortDirectionChangeHandler={sortDirectionChangeHandler}
                asc={SORT_DIRECTION_ASC}
                desc={SORT_DIRECTION_DESC}
              />
              <br />
              <div className="reports_title contentDisplay">
                <h3>
                  {securityDeposit?.length} RECORDS
                  {recordsShowing?.length !== securityDeposit?.length ? (
                    <span className="ss-record-count">
                      {" "}
                      (showing {recordsShowing?.length} of{" "}
                      {securityDeposit?.length} records)
                    </span>
                  ) : (
                    <span className="ss-record-count">
                      {" "}
                      (showing all {securityDeposit?.length} records)
                    </span>
                  )}
                </h3>
              </div>
              {recordsShowing?.map((ele) => {
                return (
                  <div key={ele.id}>
                    <ReportRow>
                      <SecurityDepositKebabButton
                        setShowModal={setShowModal}
                        setSecurityDepositParticularRecordDetails={
                          setSecurityDepositParticularRecordDetails
                        }
                        ele={ele}
                      />
                      <SecurityDepositCard securityDepositValue={ele} />
                    </ReportRow>
                  </div>
                );
              })}

              {recordsShowing.length !== securityDeposit?.length &&
              !searchBox ? (
                <div className="ss-load-more-records">
                  <a
                    href="/#"
                    className="ss-load-more-records-link"
                    onClick={(event) => {
                      fetchMoreData();
                      event.preventDefault();
                    }}
                  >
                    Show next{" "}
                    {visibleRecordBatchSize <=
                    securityDeposit?.length - recordsShowing.length
                      ? visibleRecordBatchSize
                      : securityDeposit?.length - recordsShowing.length}{" "}
                    records ({securityDeposit?.length - recordsShowing.length}{" "}
                    not shown)
                  </a>
                </div>
              ) : (
                <div className="ss-load-more-records">Showing all records</div>
              )}
            </>
          )}
          {showModal && (
            <>
              <ModalDialog
                dialogClass="modal fade show"
                comp="securityDeposit"
                title="Edit Refund Status"
                handleCloseEvent={() => setShowModal(false)}
              >
                <EditSecurityDepositForm
                  securityDepositParticularRecordDetails={
                    securityDepositParticularRecordDetails
                  }
                  setShowModal={setShowModal}
                  setLoadGetAPi={setLoadGetAPi}
                  loadGetApi={loadGetApi}
                />
              </ModalDialog>
            </>
          )}
          {recordsShowing?.length === 0 && <ReportEmpty />}
        </>
      ) : (
        <ReportEmpty />
      )}
    </ReportBackgroundContainer>
  );
};

export default securityDepositReport;
